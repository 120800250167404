import { useState } from "react";
import { Form, Modal, ModalProps } from "react-bootstrap";
import IconButton from "./IconButton";
import { Close, CloseRounded, Done } from "@mui/icons-material";
import { Variant } from "react-bootstrap/esm/types";

interface DestructiveModalProps extends ModalProps {
  className?: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  show: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onDeleted?: () => void;

  textToEnterToConfirm?: string;

  // button customisation
  btnVariant?: Variant;
  confirmBtnText?: string;
  cancelBtnText?: string;
}

export default function DestructiveModal({
  title,
  description,
  show,
  onClose,
  onConfirm,
  onDeleted,
  textToEnterToConfirm,
  btnVariant = "danger",
  cancelBtnText = "Cancel 取消",
  confirmBtnText,
  ...props
}: DestructiveModalProps) {
  //err state
  const [err, setErr] = useState("");
  //network req state
  const [isLoading, setLoading] = useState(false);

  const handleConfirm = async () => {
    //hide error message before query
    setErr("");
    setLoading(true);
    try {
      await onConfirm?.();
      setLoading(false);
      onModalClose();

      await onDeleted?.();
    } catch (error: any) {
      setLoading(false);
      setErr(error.message || "Unexpected error");
    }
  };

  const onModalClose = () => {
    if (!isLoading) {
      setErr("");
      onClose();
    }
  };

  //confirm text states
  const [confirmTextVal, setConfirmTextVal] = useState("");

  return (
    <Modal centered {...props} show={show} onHide={onModalClose}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <IconButton
          Icon={CloseRounded}
          title="Close"
          className="ms-auto"
          onClick={() => onModalClose()}
          disabled={isLoading}
        />
      </Modal.Header>
      <Modal.Body>
        {description}
        {textToEnterToConfirm !== undefined && (
          <div className="mt-2">
            <Form.Label className="small text-uppercase text-secondary">
              Enter <b className="text-muted">{textToEnterToConfirm}</b> to
              confirm
            </Form.Label>
            <Form.Control
              value={confirmTextVal}
              onChange={(e) => setConfirmTextVal(e.target.value)}
              placeholder={textToEnterToConfirm}
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {err !== "" && <p className="text-danger text-truncate w-100">{err}</p>}
        <IconButton
          transparent
          Icon={Close}
          iconHtmlColor={`var(--${btnVariant})`}
          variant={`outline-${btnVariant}`}
          className={`px-4 border border-${btnVariant} text-${btnVariant} me-3`}
          onClick={onModalClose.bind(undefined, false)}
          disabled={isLoading}
          label={cancelBtnText}
        />
        <IconButton
          Icon={Done}
          iconHtmlColor="var(--white)"
          variant={btnVariant}
          className={`px-4 bg-${btnVariant}`}
          onClick={handleConfirm}
          disabled={
            isLoading ||
            (textToEnterToConfirm !== undefined &&
              textToEnterToConfirm !== confirmTextVal)
          }
          label={
            confirmBtnText === undefined
              ? isLoading
                ? "Deleting 现在删除..."
                : "Delete 删除"
              : isLoading
              ? "Loading 加载中..."
              : confirmBtnText
          }
        />
      </Modal.Footer>
    </Modal>
  );
}
