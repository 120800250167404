import { SvgIconComponent } from "@mui/icons-material";
import IconButton from "../utils/IconButton";
import classNames from "classnames";
import React from "react";
import styles from "./ui.module.scss";

export interface ToggleItem {
    Icon: SvgIconComponent | React.FC<React.SVGProps<SVGSVGElement>>;
    label: string;
    active: boolean;
    render?: (item: Omit<ToggleItem, 'render'>) => JSX.Element;
}

export interface ToggleButtonGroupProps<I extends ToggleItem> extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children' | 'onSelect'> {
    items: I[];
    onSelect?: (item: I, ev: React.MouseEvent<HTMLButtonElement>) => void;
}

export default function ToggleButtonGroup<I extends ToggleItem>({ items, onSelect, style, ...props } : ToggleButtonGroupProps<I>) {
    return (
        <div
            {...props}
            style={{
                display: 'flex',
                ...style
            }}
        >
            {
                items.map((item, idx) => (
                    item.render !== undefined
                    ? item.render(item)
                    : (
                        <IconButton
                            key={item.label}
                            title={item.label}
                            label={!item.active ? "" : item.label}
                            Icon={item.Icon}
                            onClick={onSelect?.bind(null, item)}
                            iconHtmlColor={!item.active ? "var(--bs-gray-600)" : undefined}
                            className={classNames({
                                [styles['toggle-btn']]: true,
                                [styles.active]: item.active,
                                "border flex-shrink-0 py-2": true,
                                "me-2": idx !== items.length - 1,
                                "bg-gray": item.active,
                                "border-gray bg-transparent px-2": !item.active,
                            })}
                        />
                    )
                ))
            }
        </div>
    )
}