import { useAuth0 } from "@auth0/auth0-react";
import axios, { Method, AxiosRequestConfig, AxiosResponse } from "axios";
import { useCallback, useMemo, useState } from "react";
import { QueryKey, QueryFunction, useMutation, UseMutationOptions, useQuery, useQueryClient, useInfiniteQuery, UseQueryOptions, UseInfiniteQueryOptions } from "react-query";

//Imported from Custom Ebic error class
export class EbicError extends Error {
    status: number;

    constructor(message: string, status = 500) {
        // 'Error' breaks prototype chain here
        super(message);
        // restore prototype chain
        const actualProto = new.target.prototype;

        if (Object.setPrototypeOf) { Object.setPrototypeOf(this, actualProto); }
        //backwards compatibilty
        else { (this as any).__proto__ = actualProto; }

        //init
        this.status = status;
    }
}
//react query wrapper
export default function useEbicAPI<
    TQueryFnData = unknown,
    TError = EbicError,
>(
    key: QueryKey,
    url: string,
    useAuthToken = true,
    options?: Omit<UseQueryOptions<TQueryFnData, TError>, 'queryKey' | 'queryFn'>
) {

    const { getAccessTokenSilently } = useAuth0();
    
    const _fetch : QueryFunction<TQueryFnData> = async ({ signal }) => {
        //set auth header if useToken is not set to false
        const headers : Record<string, string> = {};
        if (useAuthToken) {
            const token = await getAccessTokenSilently();
            headers['authorization'] = `Bearer ${token}`;
        }

        try {
            const response = await axios.get(url, {
                responseType: 'json',
                headers,
                signal
            })
            return response.data;
        }
        catch(err: unknown) {
            handleAxiosError(err);
        }
    }

    return useQuery(key, {
        queryFn: _fetch,
        ...options
    })
}

//react query wrapper for paginated queries
export interface EbicPaginatedResult<D = unknown> {
    result: D;
    page: number;
    total: number;
    limit: number;
}

export function useEbicPaginatedQuery<
    TQueryFnData = unknown,
    TError = EbicError,
>(
    key: QueryKey,
    url: string,
    useAuthToken = true,
    options?: Omit<UseInfiniteQueryOptions<EbicPaginatedResult<TQueryFnData>, TError>, 'queryKey' | 'queryFn' | 'getNextPageParam'>
) {

    const { getAccessTokenSilently } = useAuth0();

    return useInfiniteQuery(key, {
        queryFn: async ({ pageParam = 1, signal }) => {
            //set auth header if useToken is not set to false
            const headers : Record<string, string> = {};
            if (useAuthToken) {
                const token = await getAccessTokenSilently();
                headers['authorization'] = `Bearer ${token}`;
            }
            
            const _url = new URL(url, window.location.origin);
            _url.searchParams.set('page', pageParam);

            try {
                const response = await axios.get(_url.toString(), {
                    responseType: 'json',
                    headers,
                    signal
                })
                return response.data;
            }
            catch(err: unknown) {
                handleAxiosError(err);
            }
        },
        getNextPageParam: (lastPage, allPages) => {
            if (allPages.length < lastPage.total) {
                return lastPage.page + 1;
            }
            return undefined; //return undefined for no morepages to fetch
        },
        ...options
    })
}

//Mutations
export type Updater<TData = any> =
|   {
    action: (data: TData) => TData;
    /**
     * `false` by default. If `true`, the updater will run immediately after the network request.
     *  Any changes will be rollback if the request fails
     */
    optimistic?: boolean;
    /**
     * Query key to run updater on
     */
    queryKey: QueryKey;
    /**
     * `false` by default. If `true`, the updater will run for query keys with a partial match.
     */
    partialMatch?: boolean;
}
|   {
    action: 'refetch';
    /**
     * Query key to run updater on
     */
    queryKey: QueryKey;
    /**
     * `false` by default. If `true`, the updater will run for query keys with a partial match.
     */
    partialMatch?: boolean;
    optimistic?: never;
}

export interface EbicMutationOptions<TData = any, TBody = unknown> {
    url: string;
    method: Method;
    requestBody?: TBody;
    /** `true` by default */
    useAuthToken?: boolean;
    /** `false` by default */
    useFormData?: boolean;
    /** Updater to run on the state (won't be run if the query hasn't been fetched yet) */
    updater?:
        |   Updater<TData>
        |   Array<Updater<TData>>
    /** Request config to pass to axios */
    axiosConfig?: Omit<AxiosRequestConfig, 'method' | 'data' | 'url'>;
}

export function useEbicMutation<TParams = unknown, TData = unknown, TBody = unknown>(
    mutationInfo: (params: TParams) => EbicMutationOptions<TData, TBody>,
    trackProgress = false,
    {
        onSuccess,
        onMutate,
        onError,
        onSettled,
        ...options
    }: Omit<UseMutationOptions<EbicMutationOptions<TData, TBody>, EbicError, TParams, undefined>, 'mutationFn'> = {}
) {

    const [ progress, setProgress ] = useState(0);

    const transformUpdaters = (updater: EbicMutationOptions<TData>['updater'] = []) => {
        return Array.isArray(updater) ? updater : [ updater ];
    }

    const { getAccessTokenSilently } = useAuth0();

    const mutate = async (params: TParams) => {

        const meta = mutationInfo(params);
        const {
            url,
            method,
            requestBody,
            useFormData = false,
            useAuthToken = true,
            axiosConfig: {
                headers: initialHeaders,
                ...initialConfig
            } = {}
        } = meta;

        const headers = {
            ...initialHeaders
        };
        if (useAuthToken !== false) {
            const token = await getAccessTokenSilently();
            headers['authorization'] = `Bearer ${token}`;
        }

        let body;
        if (requestBody !== undefined) {
            if (useFormData) {
                body = new FormData();
                for (const [key, value] of Object.entries(requestBody)) {
                    //add multiple entries to form data for array types
                    if (Array.isArray(value)) {
                        for (const val of value) body.append(key, val);
                    }
                    else if (value !== undefined) {
                        body.append(key, value);
                    }
                }
            }
            else {
                headers['content-type'] = 'application/json';
                body = JSON.stringify(requestBody)
            }
        }

        try {
            const response = await axios({
                method,
                data: body,
                headers,
                url,
                //set progress state if option is true
                onUploadProgress: !trackProgress ? undefined : (ev) => {
                    setProgress((ev.loaded/ev.total) * 100);
                },
                ...initialConfig
            })
            return {
                ...meta,
                response
            }
        }
        catch(err) {
            handleAxiosError(err);
        }
    }

    const queryClient = useQueryClient();

    type TContext = Array<{
        queryKey: QueryKey;
        previousState?: TData;
    }>

    type TReturnData = EbicMutationOptions<TData, TBody> & {
        response: AxiosResponse
    }

    const mutation = useMutation<TReturnData, EbicError, TParams, TContext>(mutate, {
        ...options,
        onSuccess: async (meta, params) => {
            await onSuccess?.(meta, params, undefined);

            const updaters = transformUpdaters(meta.updater);

            for (const updater of updaters) {
                const {
                    queryKey,
                    action,
                    partialMatch = false,
                    optimistic = false
                } = updater;

                if (action === 'refetch') {
                    await queryClient.invalidateQueries(queryKey, { exact: !partialMatch })
                }
                // if optimistic, it's already run!
                else if (!optimistic) {
                    if (partialMatch) {
                        const matchedQueries = queryClient.getQueriesData(queryKey);
                        for (const [key, data] of matchedQueries) {
                            //make sure the data exists for query key
                            if (data !== undefined) {
                                queryClient.setQueryData(key, action as any);
                            }
                        }
                    }
                    else {
                        //make sure the data exists for query key
                        const data = queryClient.getQueryData(queryKey);
                        if (data !== undefined) {
                            queryClient.setQueryData(queryKey, action as any); //assert as prev data won't be undefined
                        }
                    }
                }
            }
        },
        //for optimistic updates
        onMutate: (params) => {
            onMutate?.(params);
            
            const meta = mutationInfo(params);
            const updaters = transformUpdaters(meta.updater);

            const context : TContext = [];

            for (const { action, optimistic = false, queryKey, partialMatch = false } of updaters) {
                //only for optimistic updaters
                if (optimistic && action !== 'refetch') {
                    //could be multiple data caches if partial match
                    if (partialMatch) {
                        //snapshot all matches
                        const matchedQueries = queryClient.getQueriesData<TData>(queryKey);

                        for (const [key, data] of matchedQueries) {
                            //make sure the data exists for query key
                            if (data !== undefined) {
                                //save previous state
                                context.push({
                                    queryKey: key, previousState: data
                                });
                                queryClient.setQueryData(key, action as any);
                            }
                        }
                    }
                    else {
                        //snapshot previous value
                        const previousState = queryClient.getQueryData<TData>(queryKey);

                        if (previousState !== undefined) {
                            //save previous state
                            context.push({
                                queryKey, previousState
                            })
                            queryClient.setQueryData(queryKey, action as any); //assert as prev data won't be undefined
                        }
                    }
                }
            }
            //return previous state to be able to rollback if error
            return context;
        },
        onError: async (err, params, context = []) => {
            await onError?.(err, params, undefined);
            //rollback optimistic updates
            for (const { previousState, queryKey } of context) {
                if (previousState) {
                    queryClient.setQueryData(queryKey, previousState);
                }
            }
        }
    })

    return useMemo(() => ({
        ...mutation, progress
    }), [mutation, progress])
}

/**
 * Will always throw an error of type EbicError
 */
function handleAxiosError(err: any) : never {
    let statusCode = 0;
    let errorMsg = "Unexpected error!";

    if (axios.isAxiosError(err)) {
        if (Number(err.code)) statusCode = Number(err.code);
        if (err.message) errorMsg = err.message;

        if (err.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            //JSON error
            if (err.response.headers['content-type']?.startsWith('application/json')) {
                const { statusCode: _statusCode, message } = err.response.data;
                if (_statusCode) statusCode = _statusCode;
                if (message) errorMsg = message;
            }
            else {
                statusCode = err.response.status;
                //set error message if body is string
                if (typeof err.response.data === 'string') errorMsg = err.response.data;
            }
        }
        else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            const req = (err.request as XMLHttpRequest);
            if (req.status) statusCode = req.status;
            if (req.statusText) errorMsg = req.statusText;
        }
    }
    console.error(`Request failed ${statusCode}: ${errorMsg}`);
    throw new EbicError(errorMsg, statusCode);
}

export const EbicDownloadRoutes = {

    DOWNLOADABLES: (dnld_id: number) => `api/units/downloadables/downloaditem/${dnld_id}` as const,
};

export function useDownloadWithPostToken() {
    
    const { getAccessTokenSilently } = useAuth0();

    return useCallback(async <Key extends keyof typeof EbicDownloadRoutes>(route: Key, ...args: Parameters<typeof EbicDownloadRoutes[Key]>) => {
        try {
            const token = await getAccessTokenSilently();

            const urlBuilder = EbicDownloadRoutes[route];
            const url = (urlBuilder as any).apply(null, args);
            
            const tokenInput = document.createElement('input')
            tokenInput.type = 'hidden'
            tokenInput.name = 'token'
            tokenInput.value = token
    
            const form = document.createElement('form')
            form.method = 'post'
            form.action = url
            form.target = '_blank'
            form.append(tokenInput)
    
            //add form to body and submit
            document.body.appendChild(form)
            form.submit()
            //cleanup
            form.remove()
            tokenInput.remove()
        }
        catch(err) {
            console.error("Error getting document: " + err)
        }
    }, [ getAccessTokenSilently ])
}
