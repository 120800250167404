import { Close, Done, EditTwoTone } from "@mui/icons-material";
import React, { useState } from "react";
import { Row, Col, Card, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import useAppTitle from "../../hooks/useAppTitle";
import { useEbicUser, useUserMutations } from "../../api/user.api";
import { ProfileHeader } from "./SocialProfile";
import IconButton from "../utils/IconButton";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import {
  BootstrapInput,
  BootstrapInputPhone,
} from "../utils/FormikBootstrapInputs";
import { useActivityLogMutations } from "../../api/activitylog.api";
import { useOrgProfileById } from "../../api/organization.api";

const Profile = () => {
  //set page title
  useAppTitle("Profile");

  const { data: dbUser } = useEbicUser();

  return (
    <div className="p-5 pb-2">
      <div>
        <ProfileHeader isOwnUser user={dbUser} />
        <ProfileRegion />
      </div>
    </div>
  );
};

const ProfileRegion = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { data: dbUser } = useEbicUser();

  const emptyValueMsg = "Not set";

  const [isEditing, setIsEditing] = useState(false);

  return (
    <Card ref={ref} className="my-3">
      <Card.Body>
        {isEditing ? (
          <ProfileFormik onDone={() => setIsEditing(false)} />
        ) : (
          <>
            <Row>
              <Col className="mb-3">
                <div className="d-flex overflow-hidden">
                  <h1>Personal Profile 个人资料</h1>
                  <IconButton
                    transparent
                    Icon={EditTwoTone}
                    title="Edit"
                    className="ms-auto"
                    onClick={() => setIsEditing(true)}
                  />
                </div>
              </Col>
            </Row>
            <Row sm={2} xs={1}>
              <Col className="d-inline-block mb-3">
                <p className="fw-bold text-secondary small mb-0">
                  {" "}
                  First Name 名
                </p>
                <span>{dbUser?.user_firstname ?? emptyValueMsg}</span>
              </Col>
              <Col className="d-inline-block mb-3">
                <p className="fw-bold text-secondary small mb-0">
                  {" "}
                  Last Name 姓
                </p>
                <span>{dbUser?.user_lastname ?? emptyValueMsg}</span>
              </Col>
            </Row>
            <Row sm={2} xs={1}>
              <Col className="d-inline-block mb-3">
                <p className="fw-bold text-secondary small mb-0">
                  Mobile Number 手机号
                </p>
                <span>
                  {dbUser?.user_mobileno
                    ? formatPhoneNumberIntl(String(dbUser?.user_mobileno))
                    : emptyValueMsg}
                </span>
              </Col>
              <Col className="d-inline-block mb-3">
                <p className="fw-bold text-secondary small mb-0"> Email 电邮</p>
                <span>{dbUser?.user_email ?? emptyValueMsg}</span>
              </Col>
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
});

interface FormikProps {
  onDone: () => void;
}

function ProfileFormik({ onDone }: FormikProps) {
  interface ProfileForm {
    user_firstname: string;
    user_lastname: string;
    user_mobileno: string;
  }

  const { data: dbUser } = useEbicUser();
  const { mutate: editUser, isLoading, error } = useUserMutations("EDIT");

  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: organization } = useOrgProfileById(dbUser?.org_id ?? 0);

  const handleSubmit = async (values: ProfileForm) => {
    // create req.body
    const updatedUser = {
      user_firstname: values.user_firstname,
      user_lastname: values.user_lastname,
      user_mobileno: values.user_mobileno,
    };

    editUser(updatedUser, {
      onSuccess: () => {
        onDone();
        addLog({
          alog_type: "I",
          alog_module: "U",
          alog_action: "E",
          alog_accessfrom: 0,
          alog_modulename: dbUser?.user_email ?? "",
          alog_details: "Edit User Profile",
          org_name: organization?.org_name ?? null,
        });
      },
      onError: () => {
        addLog({
          alog_type: "E",
          alog_module: "U",
          alog_action: "E",
          alog_accessfrom: 0,
          alog_modulename: dbUser?.user_email ?? "",
          alog_details: "Edit User Profile Failed.",
          org_name: organization?.org_name ?? null,
        });
      },
    });
  };

  const schema = yup.object().shape({
    user_firstname: yup
      .string()
      .required("First Name is required")
      .max(30, "First Name must be 30 or less characters"),
    user_lastname: yup
      .string()
      .required("Last Name is required")
      .max(30, "Last Name must be 30 or less characters"),
    user_mobileno: yup
      .string()
      .nullable()
      .typeError("Please enter a valid number")
      .max(12, "Mobile number must be 12 or less characters"),
    // user_email: yup.string().required('Required'),
  });

  return (
    <Formik
      initialValues={{
        user_firstname: dbUser?.user_firstname ?? "",
        user_lastname: dbUser?.user_lastname ?? "",
        user_mobileno: dbUser?.user_mobileno ?? "",
        // user_email: dbUser?.user_email ?? '',
      }}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        setFieldValue,
        values,
        touched,
        errors,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col>
                <h1>Personal Profile 个人资料</h1>
              </Col>
              <Col className="d-flex align-items-start justify-content-end">
                {error !== null && (
                  <p className="text-danger">{error.message}</p>
                )}
                <IconButton
                  key="cancel"
                  transparent
                  Icon={Close}
                  disabled={isLoading}
                  iconHtmlColor="var(--danger)"
                  className="me-2"
                  onClick={onDone}
                />
                <IconButton
                  key="save"
                  transparent
                  Icon={Done}
                  iconHtmlColor="var(--success)"
                  disabled={isLoading}
                  type="submit"
                />
              </Col>
            </Row>

            <Row className="mb-3" xs="1" md="3">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary mb-0 text-secondary text-uppercase small fw-bold">
                  First Name 名
                </Form.Label>
                <InputGroup hasValidation>
                  <BootstrapInput
                    id="user_firstname"
                    placeholder="First Name"
                    required
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label className="text-secondary mb-0 text-secondary text-uppercase small fw-bold">
                  Last Name 姓
                </Form.Label>
                <InputGroup hasValidation>
                  <BootstrapInput
                    id="user_lastname"
                    placeholder="Last Name"
                    required
                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="text-secondary mb-0 text-secondary text-uppercase small fw-bold">
                  Mobile No. 手机号
                </Form.Label>
                <InputGroup hasValidation>
                  <BootstrapInputPhone
                    id="user_mobileno"
                    placeholder="Enter Phone Number"
                    value={values.user_mobileno}
                    onChange={(val: any) => {
                      setFieldValue("user_mobileno", val ?? "");
                    }}
                  />
                </InputGroup>
              </Form.Group>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
}
export default Profile;
