import React from "react";
import * as EBIC from "@ebic-bbrm/types";
import {
  useEbicUser,
  UserDetails,
  useUserDetails,
  useUserMutations,
} from "../../api/user.api";
import * as yup from "yup";
import { Formik } from "formik";
import { Modal, Form } from "react-bootstrap";
import {
  formatDate,
  formatDateByTimeDifference,
} from "../utils/HelperFunctions";
import { useEffect, useRef, useState, forwardRef } from "react";
import { useParams } from "react-router-dom";
import UserAvatar from "./UserAvatar";
import IconButton from "../utils/IconButton";
import { CloseRounded, CameraAltOutlined, Upload } from "@mui/icons-material";
import { useActivityLogMutations } from "../../api/activitylog.api";
import { useOrgProfileById } from "../../api/organization.api";

const SocialProfile = () => {
  const { id } = useParams<{ id?: string }>();
  const { data: ownUser } = useEbicUser();

  const isOwnUser = id === undefined || ownUser?.user_id === Number(id);

  const { data: userDetails } = useUserDetails(Number(id), !isOwnUser);

  const [user, setUser] = useState<UserDetails>();

  useEffect(() => {
    if (isOwnUser) {
      if (!ownUser) {
        return setUser(undefined);
      }
      setUser({
        org_id: ownUser.org_id,
        user_id: ownUser.user_id,
        user_firstname: ownUser.user_firstname,
        user_lastname: ownUser.user_lastname,
        user_email: ownUser.user_email,
        user_mobileno: ownUser.user_mobileno,
        user_role: ownUser.user_role,
        user_signup: ownUser.user_signup,
        user_lastlogin: ownUser.user_lastlogin,
        user_pic: ownUser.user_pic,
        user_status: 1,
        isTeacher: ownUser.isTeacher,
        classId: ownUser.classId,
        class_name: ownUser.class_name,
        org_name: ownUser.org_name,
      });
    } else {
      if (Number(id) === userDetails?.user_id) {
        return setUser(userDetails);
      }
      setUser(undefined);
    }
  }, [id, isOwnUser, ownUser, userDetails]);

  return (
    <>
      <ProfileHeader isOwnUser={isOwnUser} user={user} />
    </>
  );
};

interface ProfileHeaderProps {
  isOwnUser: boolean;
  user: UserDetails | undefined;
}

export const ProfileHeader = forwardRef<HTMLDivElement, ProfileHeaderProps>(
  ({ isOwnUser, user }, ref) => {
    const { data: ownUser } = useEbicUser();
    const { id } = useParams<{ id?: string }>();

    useEffect(() => {
      //reset when url param changes
      setShowEditModal(false);
    }, [id]);

    // edit profile stuff
    const [showEditModal, setShowEditModal] = useState(false);

    if (!user) {
      return (
        <div
          ref={ref}
          className="d-flex flex-row justify-content-center align-items-center p-4 mb-4 bg-light shadow rounded-4"
        ></div>
      );
    }

    return (
      <div
        ref={ref}
        className="d-flex flex-column align-items-center p-4 mb-4 bg-light shadow rounded-4"
      >
        <div className="d-flex flex-row align-items-center align-self-stretch">
          <UserAvatar
            user={user}
            style={{
              aspectRatio: "1 / 1",
              width: "min(20%, 100px)",
              height: "auto",
            }}
            className="me-4 flex-shrink-0"
          />

          <div className="d-flex flex-column flex-grow-1">
            <div className="mb-1 d-flex flex-row align-items-center fw-bold w-100">
              <h2 className="mb-0 text-break">{user.user_firstname}</h2>

              <IconButton
                transparent
                Icon={CameraAltOutlined}
                title="Edit"
                className="ms-4"
                onClick={() => setShowEditModal(true)}
              />
            </div>
            <span className="text-muted">
              {user.user_lastlogin !== null && (
                <small>
                  Last seen: {formatDateByTimeDifference(user.user_lastlogin)}{" "}
                  &bull;{" "}
                </small>
              )}
              <small> Joined: {formatDate(user.user_signup)}</small>
            </span>

            {(user.user_email !== null || user.user_mobileno !== null) && (
              <div className="d-flex flex-wrap small mt-2">
                {user.user_email !== null && (
                  <span>
                    <span className="fw-semibold">Email:</span>{" "}
                    {user.user_email}
                  </span>
                )}
                {user.user_mobileno !== null && (
                  <span>
                    {user.user_email !== null && <span>&nbsp;&bull; </span>}
                    <span className="fw-semibold">Mobile:</span>{" "}
                    {user.user_mobileno}
                  </span>
                )}
              </div>
            )}
          </div>
          {!isOwnUser && (
            <div className="d-flex flex-column align-items-stretch flex-shrink-0"></div>
          )}
        </div>

        {isOwnUser && ownUser !== null && ownUser !== undefined && (
          <SocialProfileEditModal
            show={showEditModal}
            onClose={() => setShowEditModal(false)}
            user={ownUser}
          />
        )}
      </div>
    );
  }
);

interface SocialProfileEditModalProps {
  show: boolean;
  onClose: () => void;

  user: EBIC.User.User;
}

function SocialProfileEditModal({
  show,
  onClose,
  user,
}: SocialProfileEditModalProps) {
  const schema = yup.object().shape({
    user_firstname: yup
      .string()
      .required("Please enter a display name")
      .max(20, "Maximum 20 characters"),
    user_lastname: yup
      .string()
      .required("Please enter a display name")
      .max(20, "Maximum 20 characters"),
    user_mobileno: yup.string().required().max(12, "12 character limit"),
  });

  type SocialProfileForm = Required<
    Pick<EBIC.User.User, "user_firstname" | "user_lastname" | "user_mobileno">
  >;

  //Image states
  const { mutate: editPhoto, isLoading: isLoadingPhoto } =
    useUserMutations("EDIT_PHOTO");
  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: organization } = useOrgProfileById(user.org_id ?? 0);

  const [isReadingPhoto, setIsReadingPhoto] = useState(false);
  const [imageReadError, setImageReadError] = useState<string>();

  const [image, setImage] = useState<{ file: File; url: string }>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImage(undefined);
  }, [show]);

  const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return setImageReadError("Couldn't read image");
    }
    if (!file.type.startsWith("image")) {
      return setImageReadError("Invalid image file");
    }

    setIsReadingPhoto(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      setIsReadingPhoto(false);

      if (e.target?.result) {
        setImage({
          file,
          url: e.target.result as string,
        });
      }
    };
    reader.onerror = (err) => {
      console.error(err);
      setIsReadingPhoto(false);
    };
    reader.readAsDataURL(file);
    /**
     * Return false in event handler, don't change the value of underlying input
     */
    return false;
  };

  const handleEdit = async (values: SocialProfileForm) => {
    if (!image) {
    } else {
      editPhoto(image.file, {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "U",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: user.user_email ?? "",
            alog_details: "Edit User Profile Picture",
            org_name: organization?.org_name ?? null,
          });
          onClose();
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "U",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: user.user_email ?? "",
            alog_details: "Edit User Profile Picture Failed.",
            org_name: organization?.org_name ?? null,
          });
          onClose();
        },
      });
    }
  };

  const isLoading = isLoadingPhoto || isReadingPhoto;

  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Header className="p-3">
        <Modal.Title className="fw-normal">
          Edit Profile Picture 编辑头像
        </Modal.Title>
        <IconButton
          Icon={CloseRounded}
          title="Close"
          className="ms-auto"
          disabled={isLoading}
          onClick={onClose}
        />
        <input
          hidden
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={handleImageSelect}
        ></input>
      </Modal.Header>
      <Formik
        initialValues={{
          user_firstname: user.user_firstname,
          user_lastname: user.user_lastname,
          user_mobileno: user.user_mobileno ?? "",
        }}
        onSubmit={handleEdit}
        validationSchema={schema}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="small px-4 py-3 ">
              <div className="mb-3 d-flex align-items-center">
                <UserAvatar
                  width={100}
                  height={100}
                  user={user}
                  className="me-4"
                  style={{ objectFit: "cover" }}
                  src={image?.url}
                />
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black fw-bold"
                  title="Change Profile Picture"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <p className="text-danger">{imageReadError}</p>
              <IconButton
                iconHtmlColor="var(--primary)"
                disabled={isLoading}
                label="CANCEL 取消"
                transparent
                className="text-primary border-primary px-4 me-3"
                onClick={() => onClose()}
              />
              <IconButton
                disabled={isLoading}
                type="submit"
                onClick={() => handleEdit(values)}
                label="SAVE 保存"
                className="px-4"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
export default SocialProfile;
