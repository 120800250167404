import {  useState } from "react";
import useAppTitle from "../../hooks/useAppTitle";
import OrgProfile from "./OrgProfile";
import * as EBIC from "@ebic-bbrm/types";
import { Switch, Route } from "react-router-dom";
import AllOrganizations from "./AllOrganizations/AllOrganizations";
import { IProfileType } from "./OrgProfileSetup";
import OrgProfileSetupCompany from "./OrgProfileSetupCompany";

interface organizationProps {
  classes: EBIC.Classes.ClassInfo;
  classMembers: EBIC.Classes.ClassMembers;
}
export enum OrgRoutes {
  CREATEORGANIZATION = "/organizations/create",
  ORGANIZATIONS = "/organizations/self/:orgId",
  OWNORG = "/organizations/self",
  ALLORGANIZATIONS = "/organizations",
}

type TArgs =
  | [OrgRoutes]
  | [OrgRoutes, { [param: string]: string | number }];

export function createOrganizationRoute(...args: TArgs) {
  const [path, params] = args;

  if (params === undefined) {
    return path;
  }
  //replace all params with the params passed
  return Object.entries(params).reduce(
    (previousValue: string, [param, value]) => {
      return previousValue.replace(`:${param}`, "" + value);
    },
    path
  );
}

const Organizations = ({ classes, classMembers }: organizationProps) => {
  useAppTitle("Institution");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [profileType, setProfileType] = useState<IProfileType>();

  return (
    <div>

      <Switch>
        <Route path={OrgRoutes.CREATEORGANIZATION}>
          <OrgProfileSetupCompany
            setProfileType={setProfileType} />
        </Route>
        <Route path={[OrgRoutes.ORGANIZATIONS, OrgRoutes.OWNORG ]}>
          <OrgProfile
            classes={classes}
            classMembers={classMembers}
          />
        </Route>
        <Route path={OrgRoutes.ALLORGANIZATIONS} exact={true}>
          <AllOrganizations />
        </Route>

      </Switch>


    </div>
  );
};





export default Organizations;
