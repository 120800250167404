import useEbicAPI, { EbicMutationOptions, useEbicMutation, useEbicPaginatedQuery } from "./useEbicAPI";
import * as EBIC from "@ebic-bbrm/types";
import { ALogQueries } from "./activitylog.api";

export const courseQueries = {
    COURSE: (courseId: number) => ['content', 'courses', courseId] as const,
    COURSES: (params: FetchCoursesOptions) => ['content', 'courses', params] as const
}

export const courseBaseRoute = `/api/courses`;

export function useCourseById(courseId: number, enabled = true) {
    return useEbicAPI<EBIC.Content.Course>(
        courseQueries.COURSE(courseId),
        `${courseBaseRoute}/${courseId}`,
        true,
        {
            enabled,
            retry: (_, error) => error.status !== 401
        }
    )
}

export interface FetchCoursesOptions {
    sort?: "alphabetical" | "newest";
    search?: string;

    limit?: number;

    orderBy?: number;
    orderOrgId?: number;
    orderExpiry?: boolean;

    published?: boolean;
    draft?: boolean;
    own_class?: boolean;
    isTeacher?: boolean;
}

export function useCourses(params: FetchCoursesOptions = {}, enabled = true) {
    // Set defaults
    const { ...rest } = params;

    const options = {
        ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.Content.Course[]>(
        courseQueries.COURSES(options),
        `${courseBaseRoute}/all?${searchParams.toString()}`,
        true,
        { enabled }
    )
}

export interface CreateCourseInterface {
    cour_name: string;
    cour_synopsis: string;
    cour_desc: string;
    cour_pic: File | undefined;
}

export interface EditCourseInterface extends CreateCourseInterface {
    cour_id: number;
}

export interface PublishCourseInterface {
    cour_id: number;
    cour_published: 0 | 1;
}

const CourseMutations = {
    CREATE_COURSE: (course: CreateCourseInterface): EbicMutationOptions => ({
        url: `${courseBaseRoute}`,
        method: 'POST',
        requestBody: course,
        useFormData: true,
        updater: [
            {
                queryKey: courseQueries.COURSES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    EDIT_COURSE: ({ cour_id, ...course }: EditCourseInterface): EbicMutationOptions => ({
        url: `${courseBaseRoute}/${cour_id}`,
        method: 'PUT',
        requestBody: course,
        useFormData: true,
        updater: [
            {
                queryKey: courseQueries.COURSES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: courseQueries.COURSE(cour_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]

    }),

    PUBLISH_COURSE: ({ cour_id, cour_published }: PublishCourseInterface): EbicMutationOptions => ({
        url: `${courseBaseRoute}/${cour_id}/publish`,
        method: 'PUT',
        requestBody: { cour_published },
        updater: [
            {
                queryKey: courseQueries.COURSES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: courseQueries.COURSE(cour_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    DELETE_COURSE: (cour_id: number): EbicMutationOptions => ({
        url: `${courseBaseRoute}/${cour_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: courseQueries.COURSES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    })
}

export function useCourseMutations<Key extends keyof typeof CourseMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = CourseMutations[key] as (
        params: Parameters<typeof CourseMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}
