import { BaseModalProps } from "../../../../hooks/useModal";
import * as yup from "yup";
import {
  useKeywordMutations,
  CreateKeywordInterface,
} from "../../../../api/lessons.api";
import { useEffect, useRef } from "react";
import { Formik } from "formik";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import {
  extractFileExtension,
  formCloseHandler,
} from "../../../utils/HelperFunctions";
import IconButton from "../../../utils/IconButton";
import { CloseRounded, Upload } from "@mui/icons-material";
import {
  BootstrapInput,
  BootstrapSelect,
} from "../../../utils/FormikBootstrapInputs";
import { NewLessonKeywordInterface } from "../LessonCreate";
import { allowedAudioTypes } from "../Passages/PassageCreateModal";

export interface PassageDropdown {
  pass_id: number;
  pass_ref: string;
  pass_refCSL: string;
}

// Two different interfaces depending on whether creating from New Lesson or from the edit page of created lesson
interface NewLessonCreateProps extends BaseModalProps {
  addKeyword?: (keyword: NewLessonKeywordInterface) => void; // function to add keyword to array
  index?: number; // current keyw index
  passages: PassageDropdown[]; // For dropdown of passages to choose from, passed in from passages useState
}

interface OldLessonCreateProps extends BaseModalProps {
  passages: PassageDropdown[]; // For dropdown of passages to choose from, passed in from getAllPassages query from lessonId
  index?: number; // Last keyword's seqno + 1
  addKeyword?: never;
}

type KeywordCreateModalProps = NewLessonCreateProps | OldLessonCreateProps;

export default function KeywordCreateModal({
  show,
  onClose,
  addKeyword,
  index,
  passages,
  ...props
}: KeywordCreateModalProps) {
  // file form element
  const fileInput = useRef<HTMLInputElement>(null);
  const fileRef = useRef<File | null>(null);

  const {
    mutate: createKeyword,
    isLoading,
    error,
    progress,
    reset,
  } = useKeywordMutations("CREATE_KEYWORD");

  useEffect(() => {
    reset();
  }, [show, reset]);

  interface CreateKeywordForm
    extends Omit<
      CreateKeywordInterface,
      "pass_id" | "less_id" | "keyw_audiofile" | "keyw_audioname" | "keyw_seqno"
    > {
    pass_id: number | undefined;
    pass_refCSL: string;
    keyw_audiofile?: Blob;
    keyw_audioname?: string;
    keyw_audiotype?: string;
  }

  const initialValues: CreateKeywordForm = {
    pass_id: undefined,
    pass_refCSL: "",
    keyw_text: "",
    keyw_textCSL: "",
    keyw_explanation: "",
    keyw_audiofile: undefined,
    keyw_audioname: undefined,
    keyw_audiotype: undefined,
  };

  const validationSchema = yup.object().shape({
    pass_id: yup.number().required("Passage reference is required"),
    keyw_text: yup
      .string()
      .max(
        20,
        ({ max }) => `Keyword text cannot be more than ${max} characters`
      )
      .default(""),
    keyw_textCSL: yup
      .string()
      .required("Chinese keyword text is required")
      .max(
        20,
        ({ max }) =>
          `Chinese keyword text cannot be more than ${max} characters`
      )
      .default(""),
    keyw_explanation: yup
      .string()
      .max(
        200,
        ({ max }) => `Keyword explanation cannot be more than ${max} characters`
      ),

    keyw_audiofile: yup.string(),
    keyw_audioname: yup
      .string()
      .max(60, ({ max }) => `Name cannot be more than ${max} characters`),
    keyw_audiotype: yup
      .string()
      .oneOf(
        allowedAudioTypes,
        (obj) => `'${obj.value}' files aren't allowed.`
      ),
  });


  const onSubmit = (values: CreateKeywordForm, { resetForm }: any) => {
    const file = fileRef.current;
    if (addKeyword) {
      // Create a keyword using row.insertId in backend
      const newKeyword = {
        keyw_id: index ?? 0,
        pass_id: values.pass_id ?? 0,
        pass_refCSL: values.pass_refCSL,
        keyw_text: values.keyw_text,
        keyw_textCSL: values.keyw_textCSL,
        keyw_explanation: values.keyw_explanation,
        keyw_audiofile: file ?? undefined,
        keyw_audioname: values.keyw_audioname ?? undefined,
      };
      addKeyword(newKeyword);
      resetForm();
      onClose();
    } else {
      // Create a keyword in existing lesson
      createKeyword(
        {
          pass_id: values.pass_id ?? 0,
          keyw_seqno: index ?? 0,
          keyw_text: values.keyw_text,
          keyw_textCSL: values.keyw_textCSL,
          keyw_explanation: values.keyw_explanation ?? "",
          keyw_audiofile: file ?? undefined,
          keyw_audioname: values.keyw_audioname ?? undefined,
        },
        {
          onSuccess: () => {
            resetForm();
            onClose();
          },
        }
      );
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        values,
        errors,
        resetForm,
      }) => {
        const setFile = (files: File[]) => {
          if (files[0]) {
            fileRef.current = files[0];
            const file = fileRef.current;

            if (file) {
              setFieldValue("keyw_audiofile", file, true);
              setFieldValue("keyw_audioname", file.name, true);
              const extension = extractFileExtension(file.name ?? "");
              setFieldValue("keyw_audiotype", extension, true);
            }
            return files[0];
          }
        };

        const handleClose = () => {
          // Reset files
          setFile([]);
          if (error !== null) error.message = "";
          resetForm();
          onClose();
        };
        return (
          <Modal
            {...props}
            centered
            show={show}
            onHide={() => {
              formCloseHandler(handleClose, dirty);
            }}
            keyboard={!isLoading}
            backdrop={isLoading ? "static" : undefined}
            className="modal-layer-1"
            backdropClassName="modal-layer-1"
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Create a Keyword</Modal.Title>
              <IconButton
                title="Close modal"
                Icon={CloseRounded}
                className="ms-auto"
                onClick={() => {
                  formCloseHandler(handleClose, dirty);
                }}
              />
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <input
                  onChange={(ev) => {
                    const file = setFile(
                      Array.from(ev.currentTarget.files ?? [])
                    );
                    if (file) {
                      setFieldValue("keyw_audiofile", file, true);
                      const extension = extractFileExtension(file.name ?? "");
                      setFieldValue("keyw_audiotype", extension, true);
                      setFieldValue("keyw_audioname", file.name ?? "", true);
                    }
                    return false;
                  }}
                  ref={fileInput}
                  className="d-none"
                  type="file"
                  accept="audio/*"
                ></input>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <BootstrapSelect
                      id="pass_id"
                      label="Passage 经文段落"
                      allowedKeys={
                        passages?.map((p) => p.pass_id.toString()) ?? []
                      }
                      allowedLabels={Object.values(
                        passages?.map((p) => p.pass_refCSL) ?? []
                      )}
                      onChange={(e) => {
                        const selectedPassage = passages.find(
                          (p) => p.pass_id === parseInt(e.target.value)
                        );
                        setFieldValue("pass_id", parseInt(e.target.value));
                        setFieldValue(
                          "pass_refCSL",
                          selectedPassage?.pass_refCSL ?? ""
                        );
                      }}
                      disabled={isLoading || isSubmitting}
                      required={true}
                    />
                    {errors.pass_id && (
                      <Form.Control.Feedback>
                        {errors.pass_id}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Keyword ⽣词
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="keyw_textCSL"
                      required
                      placeholder="后裔、⼦孙"
                      aria-label="Keyword ⽣词"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      In English (英⽂)
                    </Form.Label>
                    <BootstrapInput
                      id="keyw_text"
                      required
                      placeholder="Descendents"
                      aria-label="In English (英⽂)"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Explanation 解释
                    </Form.Label>
                    <BootstrapInput
                      id="keyw_explanation"
                      as="textarea"
                      style={{ minHeight: "5rem" }}
                      placeholder="Keyword Explanation"
                      aria-label="Explanation"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Audio File ⾳频⽂件
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Upload an audio file 上传音频文件"
                        readOnly
                        value={
                          values.keyw_audioname
                            ? values.keyw_audioname.toString()
                            : ""
                        }
                      />
                      <Button
                        onClick={() => fileInput.current?.click()}
                        title={"Upload an audio file 上传音频文件"}
                        disabled={isLoading || isSubmitting}
                      >
                        <Upload />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer className="overflow-hidden">
                <p className="text-danger w-100 text-truncate">
                  {error?.message ||
                    errors.keyw_audiofile ||
                    errors.keyw_audioname ||
                    errors.keyw_audiotype}
                </p>
                <div className="d-flex align-items-center">
                  <Button
                    variant="outline-primary"
                    className="px-4 me-3 border border-primary text-primary"
                    title="Cancel"
                    disabled={isLoading || isSubmitting}
                    onClick={() => formCloseHandler(handleClose, dirty)}
                  >
                    Cancel 取消
                  </Button>
                  <Button
                    className="text-uppercase"
                    title="Save"
                    type="submit"
                    disabled={isLoading || isSubmitting}
                  >
                    Save 保存
                  </Button>
                </div>
                {isLoading && (
                  <div
                    className="bg-primary m-0"
                    style={{
                      position: "absolute",
                      left: "0",
                      bottom: "0",
                      width: `${progress}%`,
                      height: "5px",
                    }}
                  ></div>
                )}
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
