import { forwardRef, HTMLAttributes } from "react";
import * as EBIC from "@ebic-bbrm/types";
import UnitsItem from "./UnitsItem";

export type UnitsDraggableItemProps = HTMLAttributes<HTMLDivElement> & {
  activeId: number;
  units: EBIC.Content.Unit[];
  withOpacity?: boolean;
  isDragging?: boolean;
};

const UnitsDraggableItem = forwardRef<HTMLDivElement, UnitsDraggableItemProps>(
  ({ activeId, units }, ref) => {
    const unit = units.find((u) => u.unit_seqno === activeId);

    if (!unit) return <></>;
    return (
      <div ref={ref}>
        <UnitsItem unit={{ id: activeId, ...unit }} />
      </div>
    );
  }
);

export default UnitsDraggableItem;
