import * as EBIC from "@ebic-bbrm/types";
import { useCallback, useEffect, useMemo } from "react";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import useQuery from "../../../hooks/useQuery";
import {
  SearchClassesOptions,
  useClassesSearch,
} from "../../../api/classes.api";
import SubscriptionTable from "./SubscriptionTable";
import { useHistory } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import { useAllOrgProfile } from "../../../api/organization.api";
import { ExportToCsv } from "export-to-csv";
import { Pagination } from "@mui/material";

interface SubscriptionReportProps {
  classes: EBIC.Classes.ClassInfo;
}

const SubscriptionReport = ({ classes }: SubscriptionReportProps) => {
  const search = useQuery();
  const history = useHistory();

  let org = decodeURIComponent(search.get("o") || "");

  const params = useMemo(() => {
    const params: SearchClassesOptions = {};

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
    const query = decodeURIComponent(search.get("q") || "");

    if (query) params.query = query;

    if (org) params.org_id = parseInt(org);

    return params;
  }, [search, org]);

  const {
    data: subscription,
    isLoading,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useClassesSearch(params);

  const handleChange = (event: any, value: number) => {
    search.set("page", value.toString());
    history.replace({
      search: search.toString(),
    });
    //document.documentElement.scrollTop = 0
    fetchNextPage({ pageParam: value });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCurrentPage = useCallback(
    (page: number) => {
      return subscription?.pages.find((p) => p.page === page);
    },
    [subscription]
  );

  const tab = useQuery().get("t");
  const page = parseInt(search.get("page") ?? "1");
  useEffect(() => {
    if (!getCurrentPage(page) && tab === "c") {
      //check if next page exists
      fetchNextPage({ pageParam: page });
    }
  }, [getCurrentPage, page, fetchNextPage, tab]);

  const _subscription = getCurrentPage(page)?.result;

  const handleOrgChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    search.set("o", e.target.value);
    search.delete("page");
    history.replace({
      search: search.toString(),
    });
  };

  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    filename: "Subscription_report",
    title: "Subscription Report Details",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [
      "Institution Name",
      "Class Name",
      "Course Name",
      "No. of Teachers",
      "No. of Students",
    ],
  };

  const csvExporter = new ExportToCsv(options);

  const { data: organization } = useAllOrgProfile();

  const subscribe = useMemo(() => {
    const subscribe = _subscription?.map((sub) => {
      return {
        org_name: sub.org_name,
        class_name: sub.class_name,
        course_name: sub.cour_name ? sub.cour_name : "No Selected Course",
        teacher_size: sub.teacher_size,
        student_size: sub.student_size,
      };
    });
    return subscribe;
  }, [_subscription]);

  if (
    isLoading ||
    !subscription ||
    !organization ||
    isRefetching ||
    isFetchingNextPage
  )
    return <BootstrapSpinner />;

  return (
    <>
      <Row>
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <Form.Select
            className="mx-2"
            style={{ width: "15rem" }}
            onChange={handleOrgChange}
            value={org}
          >
            <option value="">All</option>
            {organization.map((o) => (
              <option key={o.org_id} value={o.org_id}>
                {o.org_name}
              </option>
            ))}
          </Form.Select>
          <Row>
            <Col>
              <Button
                onClick={() => {
                  csvExporter.generateCsv(subscribe);
                }}
                className="mt-3"
              >
                Download CSV
              </Button>
            </Col>
          </Row>
        </div>
      </Row>
      <Row>
        <SubscriptionTable
          classes={classes}
          classDetails={_subscription ?? []}
          isLoading={isLoading}
        />
      </Row>
      {subscription?.pages !== undefined &&
        subscription?.pages[0]?.total > 15 && (
          <div className="d-flex justify-content-center my-3">
            <Pagination
              count={Math.ceil(
                subscription.pages[0]?.total / subscription?.pages[0].limit
              )}
              page={page}
              onChange={handleChange}
            />
          </div>
        )}
    </>
  );
};

export default SubscriptionReport;
