import * as EBIC from "@ebic-bbrm/types"
import { ALogQueries } from "./activitylog.api";
import { ClassesQueries } from "./classes.api";
import useEbicAPI, { EbicMutationOptions, useEbicMutation } from './useEbicAPI';

export const ProductQueries = {
    PRODUCTS: ['products', 'details'] as const,

    PRODUCTSBYID: (prodId: number) => ['products', 'details', prodId] as const
}

export const productsBaseRoute = '/api/products'

export function useProducts() {
    return useEbicAPI<EBIC.Order.Product>
        (ProductQueries.PRODUCTS, `${productsBaseRoute}`,
            true, {
            refetchOnMount: 'always'
        })
}

export function useProductsById(prodId: number, enabled = true) {
    return useEbicAPI<EBIC.Order.Product>(
        ProductQueries.PRODUCTSBYID(prodId),
        `${productsBaseRoute}/${prodId}`,
        true,
        { enabled }
    );
}

export function initaliseProductObject(product: EBIC.Order.Product) {
    const requiredFields = [
        'prod_id',
        'cour_id',
        'prod_name',
        'prod_teachers',
        'prod_students'

    ];

    const originalProduct: EBIC.Order.Product = requiredFields.reduce(
        (accumulator, currentValue) => {
            return {
                ...accumulator,
                ...{
                    [currentValue]: product[currentValue as keyof typeof product],
                },
            };
        },
        {} as EBIC.Order.Product
    );

    return originalProduct;
}

interface TCreateProducts {
    cour_id: number;
    prod_name: string,
    prod_teachers: number | null;
    prod_students: number | null;
}

interface editProducts {
    prod_id: number;
    cour_id: number;
    prod_name: string,
    prod_teachers: number | null;
    prod_students: number | null;
}

const ProductsMutations = {
    CREATE: ({
        cour_id, ...products
    }: TCreateProducts): EbicMutationOptions => ({
        url: `${productsBaseRoute}`,
        method: 'POST',
        requestBody: { cour_id, ...products },
        updater: [
            {
                queryKey: ProductQueries.PRODUCTS,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),
    EDIT: ({ prod_id, ...products }: editProducts): EbicMutationOptions => ({
        url: `${productsBaseRoute}/${prod_id}`,
        method: 'PUT',
        requestBody: products,
        updater: [
            {
                queryKey: ProductQueries.PRODUCTSBYID(prod_id),
                action: (prev?: EBIC.Order.Product) => {
                    if (prev !== undefined) {
                        prev.cour_id = products.cour_id;
                        prev.prod_name = products.prod_name;
                        prev.prod_teachers = products.prod_teachers;
                        prev.prod_students = products.prod_students;


                    }
                }
            },
            {
                queryKey: ProductQueries.PRODUCTS,
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]

    }),
    DELETE: ({ prod_id }: { prod_id: number }): EbicMutationOptions => ({
        url: `${productsBaseRoute}/${prod_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: ProductQueries.PRODUCTSBYID(prod_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ProductQueries.PRODUCTS,
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    })
}

export function useProductsMutations<Key extends keyof typeof ProductsMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ProductsMutations[key] as (
        params: Parameters<typeof ProductsMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}