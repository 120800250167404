import { useEbicUser } from "../../api/user.api";
import useAppTitle from "../../hooks/useAppTitle";
import ClassesSearch from "./ClassesSearch";
import * as EBIC from "@ebic-bbrm/types";

interface ClassesProps {
  classes: EBIC.Classes.ClassInfo;
}

const Classes = ({ classes }: ClassesProps) => {
  useAppTitle("Classes");

  const { data: dbUser } = useEbicUser();

  //roles
  const isTeacher = Boolean(dbUser?.isTeacher === 1);
  const isSysAdmin = Boolean(
    dbUser?.user_role === "S" || dbUser?.user_role === "O"
  );

  if (!isSysAdmin && !isTeacher)
    return <>You are not authorized to view this page. 您无权查看此页面。</>;

  return (
    <>
      <div className="p-5 pb-2">
        <ClassesSearch classes={classes} />
      </div>
    </>
  );
};

export default Classes;
