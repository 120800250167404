import { AddRounded } from "@mui/icons-material";
import classNames from "classnames";
import React, { CSSProperties } from "react";
import { Card } from "react-bootstrap";
import styles from "./utils.module.css";

interface EbicListCardAddProps extends React.HTMLAttributes<HTMLButtonElement> {
  handleClick: () => void;
  className?: string;
  text?: string;
  iconFontSize?: "small" | "inherit" | "large" | "medium";
  iconStyles?: CSSProperties;
}

const EbicListCardAdd = ({
  handleClick,
  className,
  text,
  iconFontSize,
  iconStyles,
  ...props
}: EbicListCardAddProps) => {
  return (
    <Card
      as="button"
      className={classNames(
        "border-0 shadow cursor-pointer w-100 h-100",
        className && className,
        styles.plusCard
      )}
      onClick={handleClick}
      {...props}
    >
      <Card.Body className="p-2 d-flex justify-content-center align-items-center w-100 h-100">
        <AddRounded fontSize={iconFontSize} style={iconStyles} />
        {text && <span>{text}</span>}
      </Card.Body>
    </Card>
  );
};

export default EbicListCardAdd;
