import * as EBIC from "@ebic-bbrm/types";
import useAppTitle from "../../../hooks/useAppTitle";
import { Flag, Payment } from "@mui/icons-material";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "../../utils/Sidebar";
import { Switch, Route, Redirect } from "react-router-dom";
import ActivityReports from "./ActivityReports";
import SubscriptionReport from "./SubscriptionReport";

interface ReportProps {
    ActivityLog: EBIC.ActivityLog.LogInfo;
    classes: EBIC.Classes.ClassInfo;
}

export enum ReportRoutes {
    ACTIVITYLOG = '/reports/activitylog',
    SUBSCRIPTION = '/reports/subscription'
}

type TArgs = [ReportRoutes] | [ReportRoutes, { [param: string]: string | number }];

export function createReportRoute(...args: TArgs) {
    const [path, params] = args;

    if (params === undefined) {
        return path;
    }
    //replace all params with the params passed
    return Object.entries(params).reduce((previousValue: string, [param, value]) => {
        return previousValue.replace(`:${param}`, '' + value);
    }, path);
}



const Reports = ({ ActivityLog, classes }: ReportProps) => {

    useAppTitle("Reports");

    //define tabs for the side bar
    const tabs = [
        [
            Flag,
            'Activity Reports',
            {
                to: ReportRoutes.ACTIVITYLOG,
                exact: true
            },
            ReportRoutes.ACTIVITYLOG
        ],
        [
            Payment,
            'Subscription Reports',
            {
                to: ReportRoutes.SUBSCRIPTION,
                exact: true
            },
            ReportRoutes.SUBSCRIPTION

        ]
    ] as const;

    type TTabs = Partial<Record<ReportRoutes, boolean>>;

    const hiddenTabs: TTabs = {
        [ReportRoutes.ACTIVITYLOG]: false,
        [ReportRoutes.SUBSCRIPTION]: false
    };

    return (

        <>
            <Container bsPrefix=" " className="p-5 pb-2">
                <Row>
                    <Col xs="12" md="2" className="sticky-after-navbar">
                        <Sidebar
                            tabs={tabs
                                .filter((t) => {
                                    if (typeof t === "string") {
                                        return hiddenTabs[t] !== true;
                                    }
                                    return hiddenTabs[t[3]] !== true;
                                })
                                .map((t) => {
                                    if (typeof t === "string") return t;
                                    return [t[0], t[1], t[2]];
                                })}
                            className="my-3 text-decoration-none"
                        />
                    </Col>
                    <Col xs="12" md="10" className="my-4">
                        <Switch>
                            <Route exact path={ReportRoutes.ACTIVITYLOG}>
                                <ActivityReports
                                    ActivityLog={ActivityLog}
                                />
                            </Route>
                            <Route path={ReportRoutes.SUBSCRIPTION}>
                                <SubscriptionReport
                                classes={classes}
                                />
                            </Route>
                            <Redirect to={ReportRoutes.ACTIVITYLOG} />
                        </Switch>
                    </Col>
                </Row>

            </Container>



        </>
    );
}

export default Reports;