import { Modal } from "react-bootstrap";
import { BaseModalProps } from "../../../hooks/useModal";
import * as EBIC from "@ebic-bbrm/types";
import IconButton from "../../utils/IconButton";
import { CloseRounded } from "@mui/icons-material";
import { useOrganizationMutations } from "../../../api/organization.api";
import { useActivityLogMutations } from "../../../api/activitylog.api";

interface OrganizationDeleteProps extends BaseModalProps {
  organization: EBIC.Organization.Profile;
}

export default function OrgDeleteModal({
  show,
  onClose,
  organization,
}: OrganizationDeleteProps) {
  const {
    mutate: deleteOrg,
    isLoading,
    error,
  } = useOrganizationMutations("DELETE");
  const { mutate: addLog } = useActivityLogMutations("CREATE");

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop={isLoading ? "static" : undefined}
      keyboard={!isLoading}
    >
      <Modal.Header>
        <Modal.Title>
          Delete organization <i>{organization.org_name}</i> 删除机构
        </Modal.Title>
        <IconButton
          title="Close modal"
          Icon={CloseRounded}
          className="ms-auto"
          disabled={isLoading}
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        <p>
          This will permanently remove this organization from the database.
          <br />
          这将从数据库中永久删除该机构。
        </p>
      </Modal.Body>
      <Modal.Footer>
        <IconButton
          iconHtmlColor="var(--danger)"
          title="Cancel"
          label="CANCEL 取消"
          onClick={onClose}
          disabled={isLoading}
          className="bg-transparent border-danger text-danger"
        />
        <IconButton
          title="Delete"
          label="DELETE 删除"
          disabled={isLoading}
          className="bg-danger text-light"
          onClick={() =>
            deleteOrg(organization.org_id, {
              onSuccess: () => {
                addLog({
                  alog_type: "I",
                  alog_module: "O",
                  alog_action: "E",
                  alog_accessfrom: 0,
                  alog_modulename: organization.org_name ?? "",
                  alog_details: "Delete Institution",
                  org_name: organization.org_name ?? null,
                });
                onClose();
              },
              onError: () => {
                addLog({
                  alog_type: "E",
                  alog_module: "O",
                  alog_action: "E",
                  alog_accessfrom: 0,
                  alog_modulename: organization.org_name ?? "",
                  alog_details: "Delete Institution Failed.",
                  org_name: organization.org_name ?? null,
                });
                alert(error?.message);
              },
            })
          }
        />
      </Modal.Footer>
    </Modal>
  );
}
