import { useState, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import { useOrgClasses } from "../../../api/classes.api";
import styles from "./classes.module.css";
import classNames from "classnames";
import * as EBIC from "@ebic-bbrm/types";
import OrgClassesTable from "./OrgClasses";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useEbicUser } from "../../../api/user.api";
import { useOrgProfileById } from "../../../api/organization.api";
import IconButton from "../../utils/IconButton";
import {
  AddCircle,
  FormatListBulletedRounded,
  GridViewRounded,
} from "@mui/icons-material";
import OrgClassAddModal from "./OrgClassAddModal";
import EbicCard from "../../utils/EbicCard";
import { style } from "@mui/system";
import OrgClassesItem from "./OrgClassesItem";
import ClassEditModal from "./ClassEditModal";

export enum ClassesViews {
  LIST,
  GRID,
}

interface OrgClassesViewProps {
  emptyMessage?: string;
  orgId: number;
  classes: EBIC.Classes.ClassInfo;
  classMembers: EBIC.Classes.ClassMembers;

  activeOrg: boolean;
}

export default function OrgClassesView({
  emptyMessage,
  orgId,
  classes,
  classMembers,
  activeOrg,
}: OrgClassesViewProps) {
  const { data: allClasses, isLoading } = useOrgClasses(orgId);
  const { data: dbUser } = useEbicUser();
  const { data: org } = useOrgProfileById(orgId);

  const classDetails = useMemo(() => {
    if (!allClasses) return [];
    let classesArray = [];
    if (Array.isArray(allClasses)) classesArray = allClasses;
    else classesArray = [allClasses];

    return classesArray;
    // eslint-disable-next-line
  }, [allClasses, classes]);

  // roles
  const isOrgOwner = Boolean(org?.org_id === dbUser?.org_id && dbUser?.user_role === "O");
  const isSysAdmin = Boolean(dbUser?.user_role === "S");

  const [viewMode, setViewMode] = useState<ClassesViews>(ClassesViews.GRID);

  if (!allClasses || isLoading || allClasses === undefined)
    return <BootstrapSpinner />;

  return (
    <>
      <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
        <OrgClassesHeader
          view={viewMode}
          setView={setViewMode}
          orgId={orgId}
          classes={classes}
          classMembers={classMembers}
          isOrgOwner={isOrgOwner}
          isSysAdmin={isSysAdmin}
          activeOrg={activeOrg}
        />
      </div>

      <div
        className={classNames({
          [styles.list]: viewMode === ClassesViews.LIST,
          [styles.grid]: viewMode === ClassesViews.GRID,
        })}
      >
        <EbicCard
          items={{ title: "Classes 班级" }}
          style={{
            borderRadius: "24px",
            ...style,
          }}
          className="border-0 mb-3"
        >
          {viewMode === ClassesViews.LIST ? (
            <OrgClassesTable
              classes={allClasses}
              classDetails={classDetails}
              classMembers={classMembers}
              isLoading={isLoading}
              isOrgOwner={isOrgOwner}
              isSysAdmin={isSysAdmin}
              orgId={orgId}
            />
          ) : (
            <Row className="gy-3 gy-lg-4" xs="1" lg="5">
              {classDetails.map((c) => (
                <Col key={c.class_id} style={{ minHeight: "27rem" }}>
                  <OrgClassesItem
                    classes={c}
                    classMembers={classMembers}
                    activeOrg={activeOrg}
                  />
                </Col>
              ))}
            </Row>
          )}
        </EbicCard>
      </div>
    </>
  );
}

interface OrgClassesHeaderProps {
  view: ClassesViews;
  setView: (state: ClassesViews) => void;

  orgId: number;
  classes: EBIC.Classes.ClassInfo;
  classMembers: EBIC.Classes.ClassMembers;

  isOrgOwner: boolean;
  isSysAdmin: boolean;
  activeOrg: boolean;
}

function OrgClassesHeader({
  view,
  setView,
  orgId,
  classes,
  classMembers,
  isOrgOwner,
  isSysAdmin,
  activeOrg,
}: OrgClassesHeaderProps) {
  const [showModal, setShowModal] = useState(false);

  const [classId, setClassId] = useState<number | null>(null);

  const onCreateClassModalOpen = () => {
    setShowModal(true);
  };
  const onCreateClassModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="ms-auto d-flex">
        <div
          className="d-flex align-items-center my-auto"
          style={{
            height: "fit-content",
            position: "relative",
            bottom: "-25px",
            left: 0,
          }}
        >
          <IconButton
            transparent
            border={false}
            Icon={GridViewRounded}
            title="Grid view"
            className={classNames(
              view === ClassesViews.GRID ? "text-black" : "text-secondary"
            )}
            onClick={() => setView(ClassesViews.GRID)}
          />
          {/* separator */}
          <div
            style={{
              width: "1px",
            }}
            className="mx-2 my-1 align-self-stretch bg-secondary"
          ></div>
          <IconButton
            transparent
            border={false}
            Icon={FormatListBulletedRounded}
            title="List view"
            className={classNames(
              view === ClassesViews.LIST ? "text-black" : "text-secondary"
            )}
            onClick={() => setView(ClassesViews.LIST)}
          />
          {(isSysAdmin || (isOrgOwner && activeOrg)) && (
            <>
              {/* separator */}
              <div
                style={{
                  width: "1px",
                }}
                className="mx-2 my-1 align-self-stretch bg-secondary"
              ></div>

              <IconButton
                Icon={AddCircle}
                title="Add A Class"
                onClick={() => onCreateClassModalOpen()}
                style={{
                  backgroundColor: "transparent",
                  color: "#aa8067",
                }}
              />
            </>
          )}
        </div>
        <OrgClassAddModal
          show={showModal}
          onClose={onCreateClassModalClose}
          setClassId={setClassId}
          orgId={orgId}
        />
        <ClassEditModal
          show={classId != null}
          onClose={() => setClassId(null)}
          classId={classId}
          orgId={orgId}
          classMembers={classMembers}
        />
      </div>
    </>
  );
}
