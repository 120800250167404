import {
  CreateCourseInterface,
  useCourseMutations,
} from "../../../api/courses.api";
import { useEffect, useRef, useState } from "react";
import { AttachmentFile } from "../../../types/custom";
import * as yup from "yup";
import { extractFileSize, getFileWithUrl } from "../../utils/HelperFunctions";
import { Formik } from "formik";
import { Col, Form, Row, Image, Button } from "react-bootstrap";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import { useHistory } from "react-router-dom";
import imgPlaceHolder from "../../../assets/img_placeholder.jpg";
import IconButton from "../../utils/IconButton";
import { Upload } from "@mui/icons-material";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";

export default function CourseCreate() {
  const history = useHistory();
  const {
    mutate: createCourse,
    isLoading,
    error,
  } = useCourseMutations("CREATE_COURSE");

  useEffect(() => {
    setFile(undefined);
  }, []);

  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  interface CreateCourseForm extends Omit<CreateCourseInterface, "cour_pic"> {}

  const initialValues: CreateCourseForm = {
    cour_name: "",
    cour_synopsis: "",
    cour_desc: "",
  };

  const validationSchema = yup.object().shape({
    cour_name: yup
      .string()
      .required("Course name is required")
      .max(
        80,
        ({ max }) => `Course name cannot be more than ${max} characters`
      ),
    cour_synopsis: yup
      .string()
      .required("Course synopsis is required")
      .max(
        120,
        ({ max }) => `Course synopsis cannot be more than ${max} characters`
      ),
    cour_desc: yup
      .string()
      .required("Course description is required")
      .max(
        200,
        ({ max }) => `Course description cannot be more than ${max} characters`
      ),
  });

  const onSubmit = (values: CreateCourseForm) => {
    createCourse(
      {
        cour_name: values.cour_name,
        cour_synopsis: values.cour_synopsis,
        cour_desc: values.cour_desc,
        cour_pic: file?.file,
      },
      {
        onSuccess: () => {
          history.push(createContentRoute(ContentRoutes.COURSES));
        },
      }
    );
  };

  // TODO: Due to the nature of the current yarn deployment, we need to have separate pull requests for deployment of production and development. Would have to fix this in future builds.
  // The purpose of this comment only serves to have a different commit to be able to sync with the development site

  return (
    <div className="p-5">
      <h3
        className="text-uppercase mb-5"
        style={{ color: "var(--bs-gray-600)" }}
      >
        Creating a Course 加新课程
      </h3>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit, setFieldValue, errors, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col
                md={3}
                className="d-flex flex-column align-items-center justify-content-start"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      await getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    } // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                ></input>
                {file !== undefined ? (
                  <Image
                    src={file.url}
                    title={
                      file.file.name + " " + extractFileSize(file.file.size)
                    }
                    className={
                      "shadow-none rounded-circle border border-secondary "
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Image
                    src={imgPlaceHolder}
                    className={
                      "shadow-none rounded-circle border border-secondary"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                    title="Image placeholder"
                  />
                )}
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black mt-3 fw-bold"
                  title="Upload a course photo"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                  disabled={isLoading || isSubmitting}
                />
              </Col>
              <Col>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Course Name 课程名
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="cour_name"
                      required
                      placeholder="中学系列S1A"
                      aria-label="Course Name"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Course Summary 课程总结
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="cour_synopsis"
                      required
                      placeholder="Summary"
                      aria-label="Course Summary"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Course Description 课程描述
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="cour_desc"
                      required
                      as="textarea"
                      style={{ minHeight: "120px" }}
                      placeholder="Description"
                      aria-label="Course Description"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center">
                      <Button
                        className="text-uppercase me-2"
                        title="Cancel"
                        variant="outline-primary"
                        disabled={isLoading || isSubmitting}
                        onClick={() =>
                          history.push(
                            createContentRoute(ContentRoutes.COURSES)
                          )
                        }
                      >
                        Cancel 取消
                      </Button>
                      <Button
                        className="text-uppercase"
                        title="Save"
                        type="submit"
                        disabled={isLoading || isSubmitting}
                      >
                        Save 保存
                      </Button>
                    </div>
                  </div>
                </Row>
                {error !== null ? (
                  <Row className="text-danger">{error?.message}</Row>
                ) : null}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
