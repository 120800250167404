import { Image, ImageProps } from "react-bootstrap";
import styles from "../../Profile/social.module.css";
import classNames from "classnames";
import { useEffect, useState } from "react";
import * as EBIC from "@ebic-bbrm/types";
import UserIcon from "../../../assets/icons/person.png";

interface ClassAvatarProps extends ImageProps {
  classes?: Pick<EBIC.Classes.ClassInfo, "class_id" | "class_pic"> | null;
}

/**
 * If `src` is passed in props, it will always take precedence.
 */
export default function ClassAvatar({
  classes,
  className,
  alt = "Avatar",
  // roundedCircle = true,
  title,
  onError,
  style,
  src: srcPassedInProps,
  ...props
}: ClassAvatarProps) {
  const [src, setSrc] = useState<string | null>(classes?.class_pic ?? null);

  useEffect(() => {
    setSrc(classes?.class_pic ?? null);
  }, [classes?.class_pic]);

  return (
    <Image
      className={classNames(
        styles.ClassAvatar,
        className,
        !srcPassedInProps && !src && "bg-secondary p-1"
      )}
      alt={alt}
      // roundedCircle={roundedCircle}
      src={(srcPassedInProps || src) ?? UserIcon}
      style={{
        objectFit: "cover",
        borderRadius: "50%",
        ...style,
      }}
      {...props}
    />
  );
}
