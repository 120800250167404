import React from "react";
import { style } from "@mui/system";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import EbicCard from "../utils/EbicCard";
// import { useAllAnnouncements } from "../../api/announcements.api";
import * as EBIC from "@ebic-bbrm/types";
import useAppTitle from "../../hooks/useAppTitle";
import AnnouncementContent from "./AnnouncementContent";

interface AnnouncementProps {
  announcements: EBIC.Announcements[];
}
const AnnouncementCard = ({ announcements }: AnnouncementProps) => {
  useAppTitle("Home");

  return (
    <div>
      <EbicCard
        items={{ Icon: CampaignOutlinedIcon, title: "ANNOUNCEMENTS 公告" }}
        style={{
          borderRadius: "24px",
          width: "95%",
          height: "80%",
          ...style,
        }}
        className="mx-auto"
      >
        {announcements.map(
          (an) =>
            an.anno_status === "Active" && (
              <AnnouncementContent announcements={an} key={an.anno_id} />
            )
        )}
      </EbicCard>
    </div>
  );
};

export default AnnouncementCard;
