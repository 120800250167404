import * as EBIC from "@ebic-bbrm/types";
import ReactTable from "../../utils/ReactTable";
import { useMemo, useState } from "react";
import { Column } from "react-table";
import { Dropdown } from "react-bootstrap";
import { formatDate } from "../../utils/HelperFunctions";
import IconButton from "../../utils/IconButton";
import { MoreVert } from "@mui/icons-material";
import AnnoEditModal from "./AnnoEditModal";
import DestructiveModal from "../../utils/DestructiveModal";
import { useAnnouncementsMutations } from "../../../api/announcements.api";
import { useActivityLogMutations } from "../../../api/activitylog.api";

interface AnnouncementsTableInterface {
  Announcements: EBIC.Announcements;
  AnnouncementsDetails: EBIC.Announcements[];

  isLoading: boolean;
}

const AnnouncementsTable = ({
  Announcements,
  AnnouncementsDetails,
  isLoading,
}: AnnouncementsTableInterface) => {
  const { mutate: deleteAnno } = useAnnouncementsMutations("DELETE");
  const { mutate: addLog } = useActivityLogMutations("CREATE");

  //table columns
  const announcementsColumns = useMemo<Column<EBIC.Announcements>[]>(
    () => [
      {
        Header: "Date 日",
        accessor: "anno_date",
        minWidth: 100,
        Cell: ({ value, row }) => {
          const AnnouncementsDetails = row.original;
          return (
            <>
              {`${AnnouncementsDetails.anno_date}`
                ? formatDate(`${AnnouncementsDetails.anno_date}`, "long")
                : ""}
            </>
          );
        },
      },
      {
        Header: "Subject 主题",
        accessor: "anno_subject",
        minWidth: 200,
        Cell: ({ value, row }) => {
          return <>{value ? `${value}` : "No Subject."}</>;
        },
      },
      {
        Header: "Name 姓名",
        accessor: "user_name",
        minWidth: 100,
        Cell: ({ value, row }) => {
          const AnnouncementsDetails = row.original;
          return <>{`${AnnouncementsDetails.user_name}`}</>;
        },
      },
      {
        Header: "Expiry Date 结束日期",
        accessor: "anno_expire",
        minWidth: 30,
        Cell: ({ value }) => {
          return <>{`${value}` ? formatDate(`${value}`, "long") : ""}</>;
        },
      },
      {
        Header: "Status 状态",
        accessor: "anno_status",
        minWidth: 30,
        Cell: ({ value }) => {
          return <>{`${value}`}</>;
        },
      },
      {
        accessor: "anno_id",
        minWidth: 10,
        Cell: ({ value, row }) => {
          const AnnouncementsDetails = row.original;
          const [showEditModal, setShowEditModal] = useState(false);
          const [showDeleteModal, setShowDeleteModal] = useState(false);

          return (
            <>
              <Dropdown
                onToggle={(_, meta) => {
                  meta.originalEvent?.stopPropagation();
                  meta.originalEvent?.preventDefault();
                }}
                style={{ float: "right" }}
                drop="start"
              >
                <Dropdown.Toggle as="span" bsPrefix=" ">
                  <IconButton
                    transparent
                    border={false}
                    title="More"
                    Icon={MoreVert}
                    iconHtmlColor="var(--bs-gray)"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item onClick={() => setShowEditModal(true)}>
                    Edit
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <AnnoEditModal
                show={showEditModal}
                onClose={() => setShowEditModal(false)}
                annoId={AnnouncementsDetails.anno_id}
              />
              <DestructiveModal
                title="Delete Announcement 删除公告"
                description={
                  "This Announcement will be permanently removed from the system. 本公告将从系统中永久删除。"
                }
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={() =>
                  deleteAnno(
                    { anno_id: AnnouncementsDetails.anno_id },
                    {
                      onSuccess: () => {
                        addLog({
                          alog_type: "I",
                          alog_module: "A",
                          alog_action: "D",
                          alog_accessfrom: 0,
                          alog_modulename: AnnouncementsDetails.anno_subject,
                          alog_details: "Delete Announcement",
                          org_name: null,
                        });
                      },
                      onError: () => {
                        addLog({
                          alog_type: "E",
                          alog_module: "A",
                          alog_action: "D",
                          alog_accessfrom: 0,
                          alog_modulename: AnnouncementsDetails.anno_subject,
                          alog_details: "Delete Announcement Failed.",
                          org_name: null,
                        });
                      },
                    }
                  )
                }
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [Announcements]
  );

  return (
    <div className="m-0">
      <ReactTable
        data={AnnouncementsDetails}
        columns={announcementsColumns}
        isLoading={isLoading}
        placeholderMessage={"There are no Announcements yet."}
        pagination={true}
        Row={(props, data) => <tr {...props} />}
        style={{ borderColor: "white", width: "100%" }}
      ></ReactTable>
    </div>
  );
};

export default AnnouncementsTable;
