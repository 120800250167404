import { Row, Col } from "react-bootstrap";
import IconButton from "../../../utils/IconButton";
import styles from "../Passages/passage.module.css";
import {
  useKeywordMutations,
  useLessonById,
  usePassageById,
} from "../../../../api/lessons.api";
import * as EBIC from "@ebic-bbrm/types";
import useModal from "../../../../hooks/useModal";
import DestructiveModal from "../../../utils/DestructiveModal";
import { NewLessonKeywordInterface } from "../LessonCreate";
import KeywordEditModal from "./KeywordEditModal";
import { useMemo } from "react";
import { PassageDropdown } from "./KeywordCreateModal";
import { Delete } from "@mui/icons-material";
import { useActivityLogMutations } from "../../../../api/activitylog.api";

interface OldLessonKeywordItemProps {
  keyword: EBIC.Content.Keyword;
  passages: PassageDropdown[];
  editKeyword?: never;
  removeKeyword?: never;
}

interface NewLessonKeywordItemProps {
  keyword: NewLessonKeywordInterface;
  passages: PassageDropdown[];
  editKeyword?: (keyword: NewLessonKeywordInterface) => void;
  removeKeyword?: () => void;
}

type KeywordItemProps = NewLessonKeywordItemProps | OldLessonKeywordItemProps;

export default function KeywordItem({
  keyword,
  editKeyword,
  removeKeyword,
  passages,
}: KeywordItemProps) {
  const { mutate: deleteKeyword } = useKeywordMutations("DELETE_KEYWORD");

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const { data: pass } = usePassageById(
    (keyword as EBIC.Content.Keyword).pass_id
  );

  const { data: lesson } = useLessonById(pass ? pass.less_id : 0);

  const [deleteModal, openDelete] = useModal(DestructiveModal, {
    title: "Delete keyword 生词",
    description: (
      <>
        <p>
          Delete the {keyword.keyw_textCSL} ({keyword.keyw_text}) keyword?
        </p>
        <p>删除《{keyword.keyw_textCSL}》生词?</p>
      </>
    ),
    onConfirm: async () => {
      if (removeKeyword !== undefined) {
        removeKeyword();
      } else if ("keyw_id" in keyword) {
        await deleteKeyword(keyword.keyw_id, {
          onSuccess: () => {
            addLog({
              alog_type: "I",
              alog_module: "C",
              alog_action: "D",
              alog_accessfrom: 0,
              alog_modulename: lesson ? lesson.less_name : "",
              alog_details: "Delete Keywords",
              org_name: null,
            });
          },
          onError: () => {
            addLog({
              alog_type: "E",
              alog_module: "C",
              alog_action: "D",
              alog_accessfrom: 0,
              alog_modulename: lesson ? lesson.less_name : "",
              alog_details: "Delete Keywords Failed.",
              org_name: null,
            });
          },
        });
      }
    },
    confirmBtnText: "Delete 删除",
  });

  const props = useMemo(() => {
    if ("pass_id" in keyword && editKeyword === undefined) {
      return {
        keyword: keyword,
        passages: passages,
      };
    } else if (keyword) {
      return {
        keyword: keyword,
        editKeyword: editKeyword,
        passages: passages,
      };
    }
  }, [keyword, editKeyword, passages]);

  const [editModal, openEdit] = useModal(KeywordEditModal, props);

  return (
    <Row className={"p-1 " + styles.itemBody} title={keyword.keyw_text}>
      <Col className="d-flex justify-content-between align-items-center">
        <div onClick={openEdit}>
          <p className="m-0 linkUnderline">{keyword.keyw_textCSL}</p>
          <small className="m-0 text-muted">{keyword.keyw_text}</small>
        </div>
        <IconButton
          style={{ float: "right" }}
          Icon={Delete}
          transparent
          className={styles.dropdown}
          onClick={openDelete}
        />
      </Col>
      {editModal}
      {deleteModal}
    </Row>
  );
}
