import { PhotoRounded } from "@mui/icons-material";
import { Formik } from "formik";
import React, { useRef, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import * as yup from "yup";
import { useOrganizationMutations } from "../../api/organization.api";
import { AttachmentFile } from "../../types/custom";
import {
  BootstrapFormLabel,
  BootstrapInput,
  BootstrapInputPhone,
  BootstrapSelectCountry,
} from "../utils/FormikBootstrapInputs";
import { formCloseHandler, getFileWithUrl } from "../utils/HelperFunctions";
import IconButton from "../utils/IconButton";
import RouterPrompt from "../utils/RouterPrompt";
import OrgAvatar from "./OrgAvatar";
import { IProfileType } from "./OrgProfileSetup";
import * as EBIC from "@ebic-bbrm/types";
import { orgAllowedKeys, OrgTypeDropdown } from "./OrgDetails";
// import UpdateAddressModal from '../Classes/ui/UpdateAddressModal';
import { RegionDropdown } from "react-country-region-selector";

interface FormValues {
  org_name: string;
  org_type: EBIC.Organization.TypeKey;
  org_desc: string | null;
  org_pic: string | null;
  org_address: string;
  org_coord: string | null;
  org_unitno: string;
  org_country: string | null;
  org_state: string | null;
  org_city: string | null;
  org_website: string | null;
  org_email: string;
  org_contact: string;
  org_contactno: string;
}

interface OrgProfileSetupCompanyProps {
  setProfileType: React.Dispatch<
    React.SetStateAction<IProfileType | undefined>
  >;
}

const OrgProfileSetupCompany = ({
  setProfileType,
}: OrgProfileSetupCompanyProps) => {
  const {
    mutate: createOrg,
    isLoading,
    error,
  } = useOrganizationMutations("CREATE");

  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  const initialValues: FormValues = {
    org_name: "",
    org_type: "C",
    org_desc: "",
    org_pic: "",
    org_address: "",
    org_coord: null,
    org_unitno: "",
    org_country: "",
    org_state: "",
    org_city: "",
    org_website: "",
    org_email: "",
    org_contact: "",
    org_contactno: "",
  };

  const validationSchema = yup.object({
    org_name: yup
      .string()
      .required("Required")
      .max(120, ({ max }) => `Maximum ${max} characters`),
    org_contactno: yup
      .string()
      .max(12, ({ max }) => `Maximum ${max} characters`)
      .required("Contact Number is required"),
    org_email: yup
      .string()
      .max(120, ({ max }) => `Maximum ${max} characters`)
      .email("invalid email")
      .required("Email is required"),
    org_website: yup
      .string()
      .max(120, ({ max }) => `Maximum ${max} characters`)
      .url("invalid url")
      .nullable(),
    org_desc: yup
      .string()
      .max(512, ({ max }) => `Maximum ${max} characters`)
      .nullable(),
    org_country: yup.string().required("Country is required"),
    org_type: yup
      .string()
      .oneOf(orgAllowedKeys)
      .default("C")
      .required("Organization type is required"),
    org_contact: yup.string().required("Contact is required"),
    org_unitno: yup
      .string()
      .max(10, ({ max }) => `Maxmium ${max} characters`)
      .required("Unit number is required"),
  });

  // const addressValidationSchema = yup.object({
  //   org_address: yup
  //     .string()
  //     .max(256, ({ max }) => `Maximum ${max} characters`)
  //     .required("Address is required"),

  //   countryCode: yup
  //     .string()
  //     .max(2, ({ max }) => `Maximum ${max} characters for country`)
  //     .required("Country is required"),

  //   city: yup
  //     .string()
  //     .max(80, ({ max }) => `Maximum ${max} characters`)
  //     .nullable(),
  //   coordinates: yup
  //     .object({
  //       x: yup.number(),
  //       y: yup.number(),
  //     })
  //     .nullable(),
  // });

  const onSubmit = (values: FormValues) => {
    createOrg(
      {
        ...values,
        org_pic: file?.file ?? null,
      },
      {
        onSuccess: () => {
          formCloseHandler(handleClose);
        },
      }
    );
  };

  const handleClose = () => {
    if (error !== null) error.message = "";
    if (!isLoading) {
      setProfileType(undefined);
    }
  };

  // const [showAddressModal, setShowAddressModal] = useState(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        dirty,
        values,
        errors,
        handleSubmit,
        setFieldValue,
        setValues,
        isSubmitting,
      }) => (
        <Card body>
          <Form noValidate onSubmit={handleSubmit}>
            <RouterPrompt isBlocking={dirty} />
            <Row>
              <Col
                xs="12"
                lg="3"
                xl="2"
                className="d-flex flex-column align-items-center mb-3 mb-lg-0"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    }
                    // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                />

                <OrgAvatar
                  Organization={{
                    org_id: -1,
                    org_name: "Organization Profile",
                    org_pic: null,
                  }}
                  width={140}
                  height={140}
                  src={file?.url}
                  className="mb-3 shadow-sm"
                />

                <IconButton
                  transparent
                  Icon={PhotoRounded}
                  label="Upload photo"
                  title="Upload photo"
                  onClick={() => inputRef.current?.click()}
                  className="text-dark"
                  disabled={isLoading}
                />
              </Col>

              <Col xs="12" lg="9" xl="10">
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="org_name"
                      label="Institution Name"
                      placeholder="Institution Name"
                      aria-label="Institution Name"
                      disabled={isLoading}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <BootstrapFormLabel htmlFor="org_type">
                      Institution Type
                    </BootstrapFormLabel>
                    <OrgTypeDropdown
                      values={values}
                      isSubmitting={isSubmitting}
                      setFieldValue={setFieldValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg="3">
                    <BootstrapInput
                      id="org_contact"
                      label="Contact"
                      placeholder="Contact"
                      aria-label="Contact"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="org_desc"
                      label="Description"
                      as="textarea"
                      rows="2"
                      placeholder="Institution Profile Description"
                      aria-label="Institution Profile Description"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg="9">
                    <BootstrapInput
                      id="org_address"
                      label="Address"
                      placeholder="Address"
                      aria-label="Address"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="3">
                    <BootstrapInput
                      id="org_unitno"
                      label="Unit no"
                      placeholder="#01-10"
                      aria-label="Unit No"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <BootstrapSelectCountry
                      required={true}
                      id="org_country"
                      label="Country"
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback>
                      {errors.org_country}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <BootstrapFormLabel htmlFor="org_state">
                      State / Region
                    </BootstrapFormLabel>
                    <RegionDropdown
                      blankOptionLabel="Select a country first"
                      name="org_state"
                      valueType="short"
                      countryValueType="short"
                      country={values.org_country ?? ""}
                      value={values.org_state ?? ""}
                      onChange={(val: string) =>
                        setFieldValue("org_state", val)
                      }
                      disabled={isLoading}
                      classes="form-select"
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="org_city"
                      label="City"
                      placeholder="City"
                      aria-label="City"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg="3">
                    <BootstrapInputPhone
                      id="org_contactno"
                      label="Contact Number"
                      placeholder="Contact Number"
                      aria-label="Contact Number"
                      disabled={isLoading}
                      required={false}
                      value={values.org_contactno}
                      onChange={(val) => {
                        setFieldValue("org_contactno", val);
                      }}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="org_email"
                      label="Email"
                      placeholder="email@address.com"
                      aria-label="Email"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="org_website"
                      label="Website"
                      placeholder="https://example.com"
                      aria-label="Website"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>

                <div className="d-flex flex-wrap justify-content-end">
                  {error !== null && (
                    <p className="text-danger w-100 text-end">
                      {error.message}
                    </p>
                  )}
                  <Button
                    title="Cancel"
                    variant="outline-primary"
                    onClick={() => formCloseHandler(handleClose, dirty)}
                    className="me-2"
                    disabled={isLoading}
                  >
                    Cancel
                  </Button>
                  <Button title="Create" type="submit" disabled={isLoading}>
                    {isLoading ? "Creating..." : "Create"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Card>
      )}
    </Formik>
  );
};

export default OrgProfileSetupCompany;
