/* eslint-disable no-mixed-operators */
import { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { Image, Form as BootstrapForm } from "react-bootstrap";
import styles from "./asset.module.css";
import partnerStyles from "./organization.module.css";
import socialStyles from "../Profile/social.module.css";
import {
  initialiseOrganizationObject,
  useOrganizationMutations,
} from "../../api/organization.api";
import {
  BootstrapInput,
  BootstrapInputPhone,
} from "../utils/FormikBootstrapInputs";
import RouterPrompt from "../utils/RouterPrompt";
import {
  CountryDropdown,
  CountryRegionData,
  RegionDropdown,
} from "react-country-region-selector";
import IconButton from "../utils/IconButton";
import {
  EditOutlined,
  Language,
  MailOutlined,
  PhoneOutlined,
  Close,
  Done,
  ChurchOutlined,
  GroupsOutlined,
  Upload,
  Person,
} from "@mui/icons-material";
import OrgAvatar from "./OrgAvatar";
import { extractFileSize, getFileWithUrl } from "../utils/HelperFunctions";
import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import * as EBIC from "@ebic-bbrm/types";
import CustomToggle from "../utils/CustomToggle";
import { useEbicUser } from "../../api/user.api";
import { useActivityLogMutations } from "../../api/activitylog.api";

interface OrgEditButtonInterface {
  editing: boolean;
  isSubmitting: boolean;
  setEditing: (value: boolean) => void;
  resetForm: () => void;
  disable?: boolean;
}

export const OrgEditButton = ({
  editing,
  isSubmitting,
  setEditing,
  resetForm,
  disable = false,
}: OrgEditButtonInterface) => {
  return (
    <div className="d-flex justify-content-end">
      {editing ? (
        <>
          <IconButton
            key="cancel"
            transparent
            Icon={Close}
            disabled={isSubmitting}
            iconHtmlColor="var(--danger)"
            className="me-2"
            onClick={() => {
              setEditing(false);
              resetForm();
            }}
          />
          <IconButton
            key="save"
            transparent
            Icon={Done}
            iconHtmlColor="var(--success)"
            disabled={isSubmitting}
            type="submit"
          />
        </>
      ) : (
        <IconButton
          key="edit"
          transparent
          Icon={EditOutlined}
          disabled={isSubmitting || disable}
          onClick={setEditing.bind(undefined, true)}
          title="Edit"
        />
      )}
    </div>
  );
};

interface FormValues {
  org_name: string;
  org_type: EBIC.Organization.TypeKey;
  org_desc: string | null;
  org_pic: string | null;
  org_address: string;
  org_coord: string | null;
  org_unitno: string;
  org_country: string | null;
  org_state: string | null;
  org_city: string | null;
  org_website: string | null;
  org_email: string;
  org_contact: string;
  org_contactno: string;
}

interface OrgDetailsInterface {
  organization: EBIC.Organization.Profile;
}

export const OrgTypeIcon = ({
  org,
}: {
  org: Pick<EBIC.Organization.Profile, "org_type">;
}) => {
  const typeIconConfig = {
    C: ChurchOutlined,
    O: GroupsOutlined,
  } as const;

  if (!org.org_type) return <></>;

  const TypeIcon = typeIconConfig[org.org_type];

  return <TypeIcon />;
};

export const orgAllowedKeys = Object.keys(
  EBIC.Organization.Type
) as EBIC.Organization.TypeKey[];
export const orgAllowedLabels = Object.values(EBIC.Organization.Type);
const orgAllowedIcons = orgAllowedKeys.map((i, index) => (
  <OrgTypeIcon key={i} org={{ org_type: orgAllowedKeys[index] }} />
));

const OrgDetails = ({ organization }: OrgDetailsInterface) => {
  const { mutate: editOrg } = useOrganizationMutations("EDIT");
  const { data: dbUser } = useEbicUser();
  const { mutate: addLog } = useActivityLogMutations("CREATE");

  // roles constraint
  const isOrgOwner = Boolean(dbUser?.user_role === "O" && dbUser?.org_id === organization.org_id);
  const isSysAdmin = Boolean(dbUser?.user_role === "S");
  const inactiveOrg = Boolean(
    organization.org_status === 2 || organization.org_status === 3
  );
  const activeOrg = Boolean(
    (organization.org_status === 1 && (isOrgOwner || isSysAdmin)) ||
      (organization.org_status === 0 && (isOrgOwner || isSysAdmin))
  );
  // form state
  const [editing, setEditing] = useState(false);

  // Formik props
  const initialiseInitialValues = (organization: EBIC.Organization.Profile) => {
    return {
      org_name: organization.org_name,
      org_type: organization.org_type,
      org_desc: organization.org_desc ?? "",
      org_pic: organization.org_pic ?? "",
      org_address: organization.org_address ?? "",
      org_coord: organization.org_coord
        ? JSON.stringify(organization.org_coord)
        : "",
      org_unitno: organization.org_unitno ?? "",
      org_country: organization.org_country ?? "",
      org_state: organization.org_state ?? "",
      org_city: organization.org_city ?? "",
      org_website: organization.org_website ?? "",
      org_email: organization.org_email ?? "",
      org_contact: organization.org_contact ?? "",
      org_contactno: organization.org_contactno ?? "",
      org_signupdate: organization.org_signupdate ?? "",
      org_status: organization.org_status ?? "",
    } as FormValues;
  };

  let initialValues: FormValues = initialiseInitialValues(organization);

  const validationSchema = yup.object({
    org_name: yup
      .string()
      .max(120, ({ max }) => `Maxmium ${max} characters`)
      .required("Name is required."),
    org_address: yup
      .string()
      .max(256, ({ max }) => `Maxmium ${max} characters`)
      .required("Address is required"),
    org_contactno: yup
      .string()
      .max(12, ({ max }) => `Maxmium ${max} characters`)
      .required("Contact Number is required"),
    org_contact: yup
      .string()
      .max(120, ({ max }) => `Maxmium ${max} characters`)
      .required("Contact person is required."),
    org_email: yup
      .string()
      .email("Invalid Email")
      .max(120, ({ max }) => `Maxmium ${max} characters`),
    org_website: yup
      .string()
      .url("Invalid URL")
      .max(120)
      .default(null)
      .nullable(),
    org_desc: yup
      .string()
      .max(512, ({ max }) => `Maxmium ${max} characters`)
      .default(null)
      .nullable(),
    org_type: yup.string().oneOf(orgAllowedKeys).default(null),
    org_unitno: yup
      .string()
      .max(10, ({ max }) => `Maxmium ${max} characters`)
      .nullable(),
    org_city: yup
      .string()
      .max(80, ({ max }) => `Maxmium ${max} characters`)
      .nullable(),
  });

  const closeForm = (actions: FormikHelpers<FormValues>) => {
    setEditing(false);
    actions.resetForm({ values: initialValues });
  };

  const onSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) => {
    onImageUpload(file?.file ?? undefined);

    // check if any field updated
    let fieldUpdated: boolean = false;
    Object.keys(initialValues).forEach((key) => {
      let value = values[key as keyof typeof values];
      if (initialValues[key as keyof typeof initialValues] !== value) {
        fieldUpdated = true;
      }
    });

    if (fieldUpdated) {
      editOrg(
        {
          ...initialiseOrganizationObject(organization),
          ...values,
        },
        {
          onSuccess: (_, updatedOrgDetails) => {
            initialValues = initialiseInitialValues({
              ...updatedOrgDetails,
              org_coord: updatedOrgDetails.org_coord
                ? JSON.parse(updatedOrgDetails.org_coord)
                : null,
            });
            addLog({
              alog_type: "I",
              alog_module: "O",
              alog_action: "E",
              alog_accessfrom: 0,
              alog_modulename: organization.org_name ?? "",
              alog_details: "Edit Institution",
              org_name: organization.org_name ?? null,
            });
            closeForm(actions);
          },
          onError: (error) => {
            addLog({
              alog_type: "E",
              alog_module: "O",
              alog_action: "E",
              alog_accessfrom: 0,
              alog_modulename: organization.org_name ?? "",
              alog_details: "Edit Institution Failed.",
              org_name: organization.org_name ?? null,
            });
            closeForm(actions);
            alert(error.message);
          },
        }
      );
    } else {
      closeForm(actions);
    }
  };

  const { mutate: editPhoto } = useOrganizationMutations("EDIT_PHOTO");
  const [imageUpdate, setImageUpdate] = useState(0);

  const onImageUpload = async (ev: File | undefined) => {
    if (ev === undefined) {
      return;
    }
    const file = ev;

    if (file) {
      editPhoto(
        { org_id: organization.org_id, image: file },
        {
          //to rerender image component
          onSuccess: () => setImageUpdate((prev) => prev + 1),
        }
      );
    }
  };

  interface AttachmentFile {
    url: string;
    file: File;
  }
  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  // const [showAddressModal, setShowAddressModal] = useState(false);

  // const addressValidationSchema = yup.object({
  //   address: yup
  //     .string()
  //     .max(256, ({ max }) => `Maximum ${max} characters`)
  //     .nullable(),
  //   countryCode: yup
  //     .string()
  //     .max(2, ({ max }) => `Maximum ${max} characters for country`)
  //     .required("Country is required"),
  //   state: yup
  //     .string()
  //     .max(80, ({ max }) => `Maximum ${max} characters`)
  //     .nullable(),
  //   city: yup
  //     .string()
  //     .max(80, ({ max }) => `Maximum ${max} characters`)
  //     .nullable(),
  //   coordinates: yup
  //     .object({
  //       x: yup.number(),
  //       y: yup.number(),
  //     })
  //     .nullable(),
  // });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        resetForm,
        dirty,
        setValues,
      }) => {
        const address = [];
        if (values.org_address) address.push(values.org_address);
        if (values.org_unitno) address.push(values.org_unitno);
        if (values.org_city) address.push(values.org_city);
        if (values.org_country)
          address.push(
            CountryRegionData.find(([, key]) => key === values.org_country)?.[0]
          );
        if (values.org_state)
          address.push(
            CountryRegionData.find(([, key]) => key === values.org_country)?.[2]
              .split("|")
              .map((r) => {
                return r.split("~");
              })
              ?.find((region) => region[1] === values.org_state)?.[0]
          );
        let strAddress = address.join(", ");

        return (
          <Form noValidate onSubmit={handleSubmit}>
            <RouterPrompt isBlocking={dirty} />
            <Row className="d-flex">
              {editing ? (
                <>
                  <input
                    hidden
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={async (e) => {
                      if (e.target.files?.[0]) {
                        getFileWithUrl(e.target.files[0]).then(setFile);
                      } else {
                        setFile(undefined);
                      }
                      // reset value to allow reading the same file
                      if (inputRef.current) {
                        inputRef.current.value = "";
                      }
                    }}
                  ></input>

                  {file !== undefined ? (
                    <Col
                      className="d-flex flex-column flex-grow-0 flex-shrink-1"
                      // style={{ marginLeft: 0, marginRight: 0 }}
                      lg={2}
                    >
                      <Image
                        src={file.url}
                        width={140}
                        title={
                          file.file.name + " " + extractFileSize(file.file.size)
                        }
                        roundedCircle
                        className={"shadow-none " + socialStyles.userAvatar}
                        style={{
                          maxWidth: "100%",
                          aspectRatio: "1/1",
                          objectFit: "cover",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <IconButton
                        transparent
                        Icon={Upload}
                        label="UPLOAD 上传"
                        className="text-black mt-3 fw-bold "
                        title="Add photo"
                        onClick={(e) => inputRef.current?.click()}
                        style={{
                          width: 150,
                          maxWidth: "100%",
                          fontSize: "12px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </Col>
                  ) : (
                    <Col
                      className="d-flex flex-column flex-grow-0 flex-shrink-1"
                      lg={2}
                    >
                      <OrgAvatar
                        key={imageUpdate}
                        Organization={{
                          org_id: organization.org_id,
                          org_name: organization.org_name,
                          org_pic: organization.org_pic,
                        }}
                        width={150}
                        className="shadow-none"
                        style={{
                          maxWidth: "100%",
                          aspectRatio: "1/1",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                      <IconButton
                        transparent
                        Icon={Upload}
                        label="UPLOAD 上传"
                        className="text-black mt-3 fw-bold"
                        title="Add photo"
                        onClick={(e) => inputRef.current?.click()}
                        style={{
                          width: 150,
                          maxWidth: "100%",
                          fontSize: "12px",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </Col>
                  )}
                </>
              ) : (
                <Col className="text-center" lg={2} xl={2}>
                  <OrgAvatar
                    Organization={{
                      org_id: organization.org_id,
                      org_name: organization.org_name,
                      org_pic: organization.org_pic,
                    }}
                    width={140}
                    className="shadow-none"
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                    }}
                  />
                </Col>
              )}
              <Col className="d-flex flex-column justify-content-between">
                {editing ? (
                  <>
                    <div className="d-flex">
                      <div className="flex-shrink-0 flex-grow-1">
                        <OrgEditButton
                          editing={editing}
                          isSubmitting={isSubmitting}
                          setEditing={setEditing}
                          resetForm={resetForm}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1">
                      <div className="mb-2 d-flex flex-column flex-grow-1">
                        <div>
                          <small className="text-uppercase text-muted">
                            Institution Name 机构名
                          </small>
                          <span className="text-danger">*</span>
                        </div>
                        <BootstrapInput
                          id="org_name"
                          required
                          placeholder="Name"
                          aria-label="Name"
                          disabled={isSubmitting}
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1">
                      <div className="mb-2 d-flex flex-column flex-grow-1">
                        <div>
                          <small className="text-uppercase text-muted">
                            Institution Type 机构类型
                          </small>
                        </div>
                        <OrgTypeDropdown
                          values={values}
                          isSubmitting={isSubmitting}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                    <div>
                      <small className="text-uppercase text-muted">
                        Description 描述
                      </small>
                    </div>
                    <BootstrapInput
                      id="org_desc"
                      as="textarea"
                      rows="2"
                      required={false}
                      placeholder="Profile Description"
                      aria-label="Profile Description"
                      disabled={isSubmitting}
                      className="col-xs-4 mb-2"
                    />
                    <div>
                      <Row className="mb-2">
                        <Col lg="9">
                          <div>
                            <small className="text-uppercase text-muted">
                              Address 地址
                            </small>
                            <span className="text-danger">*</span>
                          </div>
                          <BootstrapInput
                            id="org_address"
                            as="textarea"
                            rows="1"
                            required={true}
                            placeholder="Address"
                            aria-label="Address"
                            disabled={isSubmitting}
                            className="col-xs-4"
                          />
                        </Col>
                        <Col>
                          <div>
                            <small className="text-uppercase text-muted">
                              Unit No. 门牌号
                            </small>
                          </div>
                          <BootstrapInput
                            id="org_unitno"
                            as="textarea"
                            rows="1"
                            required={false}
                            placeholder="Unit Number"
                            aria-label="Unit Number"
                            disabled={isSubmitting}
                            className="col-xs-2"
                          />
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <Row>
                        <Col className="mb-2">
                          <small className="text-muted text-uppercase">
                            Country 国家
                          </small>
                          <span className="text-danger">*</span>
                          <CountryDropdown
                            showDefaultOption={false}
                            name="org_country"
                            valueType="short"
                            onChange={(val: string) =>
                              setFieldValue("org_country", val)
                            }
                            classes="form-select"
                            value={values.org_country ?? ""}
                          />
                        </Col>
                        <Col>
                          <div className={styles.colItem}>
                            <small className="text-muted d-block text-uppercase">
                              State / Region 状态 / 区域
                            </small>
                            <RegionDropdown
                              blankOptionLabel="Select a country first"
                              name="org_state"
                              valueType="short"
                              countryValueType="short"
                              country={values.org_country ?? ""}
                              value={values.org_state ?? ""}
                              onChange={(val: string) =>
                                setFieldValue("org_state", val)
                              }
                              classes="form-select"
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className={styles.colItem}>
                            <small className="text-muted text-uppercase">
                              City 城市
                            </small>
                            <BootstrapInput
                              id="org_city"
                              placeholder="City"
                              aria-label="City"
                              disabled={isSubmitting}
                              required={false}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={3}>
                          <div className={styles.colItem}>
                            <small className="text-muted d-block text-uppercase">
                              Contact Number 联系电话
                              <span className="text-danger">*</span>
                            </small>

                            <BootstrapInputPhone
                              id="org_contactno"
                              required
                              placeholder="Contact Number"
                              disabled={isSubmitting}
                              value={values.org_contactno}
                              onChange={(val) => {
                                setFieldValue("org_contactno", val);
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className={styles.colItem}>
                            <small className="text-muted text-uppercase">
                              Contact Person 联络人
                            </small>
                            <span className="text-danger">*</span>
                            <BootstrapInput
                              id="org_contact"
                              placeholder="Contact Person"
                              aria-label="Contact Person"
                              disabled={isSubmitting}
                              required={true}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className={styles.colItem}>
                            <small className="text-muted text-uppercase">
                              Email 电邮
                            </small>
                            <BootstrapInput
                              id="org_email"
                              placeholder="Email"
                              aria-label="Email"
                              disabled={isSubmitting}
                              required={false}
                            />
                          </div>
                        </Col>
                        <Col>
                          <small className="text-muted d-block text-uppercase">
                            Website URL 网站
                          </small>
                          <BootstrapInput
                            id="org_website"
                            placeholder="https://example.com"
                            aria-label="Website"
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <>
                    <h1 className="m-0">{values.org_name}</h1>
                    {values.org_type && (
                      <span className="me-2 my-3 d-inline-block text-muted">
                        <OrgTypeIcon org={{ org_type: values.org_type }} />
                        <label className="mx-1">
                          {
                            EBIC.Organization.Type[
                              values.org_type as EBIC.Organization.TypeKey
                            ]
                          }
                        </label>
                      </span>
                    )}
                    <small className="text-muted text-uppercase">
                      Description 描述
                    </small>
                    <span>
                      {values.org_desc?.length === 0
                        ? "Information not available"
                        : values.org_desc ?? "Information not available"}
                    </span>
                    <small className="text-muted d-block text-uppercase mt-3">
                      Address 地址
                    </small>
                    {values.org_address && (
                      <div title="Address" className="d-inline-block">
                        {strAddress}
                      </div>
                    )}

                    <Row className="pt-3">
                      <Col xs={12}>
                        <small className="text-muted d-block text-uppercase">
                          Contact Number 联系电话
                        </small>
                        <div className="d-flex justify-content-between">
                          <span className="me-2 d-inline-block">
                            <PhoneOutlined
                              titleAccess="Phone Number"
                              color="disabled"
                              fontSize="small"
                              className="me-1"
                            />
                            <label>
                              {Boolean(
                                values.org_contactno &&
                                  values.org_contactno !== ""
                              )
                                ? values.org_contactno
                                : "No number"}{" "}
                            </label>
                          </span>
                          <span className="me-2 d-inline-block">
                            <Person
                              titleAccess="Contact Person"
                              color="disabled"
                              fontSize="small"
                              className="me-1"
                            />
                            <label>
                              {Boolean(
                                values.org_contact && values.org_contact !== ""
                              )
                                ? values.org_contact
                                : "No contact Person"}{" "}
                            </label>
                          </span>
                          <span className="me-2 d-inline-block">
                            <MailOutlined
                              titleAccess="Email Address"
                              color="disabled"
                              fontSize="small"
                              className="me-1"
                            />{" "}
                            {Boolean(
                              values.org_email && values.org_email !== ""
                            )
                              ? values.org_email
                              : "No email"}{" "}
                          </span>
                          <span className="me-2 d-inline-block">
                            {values.org_website !== undefined &&
                            values.org_website !== null &&
                            values.org_website[0] !== undefined ? (
                              <div className="d-flex">
                                <div
                                  onClick={() => {
                                    window.open(values.org_website ?? "");
                                  }}
                                  className={partnerStyles.partnerLink}
                                >
                                  <Language
                                    style={{
                                      cursor: "default",
                                    }}
                                    className="text-secondary me-1"
                                    fontSize="small"
                                  />
                                  &nbsp;
                                  {values.org_website}
                                </div>
                              </div>
                            ) : (
                              <>
                                <Language
                                  className="text-secondary me-1"
                                  fontSize="small"
                                />
                                {"No website 没有网站"}
                              </>
                            )}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>

              {((inactiveOrg && isSysAdmin) || activeOrg) && !editing && (
                <>
                  <Col className="d-flex flex-column flex-grow-0">
                    <OrgEditButton
                      editing={editing}
                      isSubmitting={isSubmitting}
                      setEditing={setEditing}
                      resetForm={resetForm}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

interface OrgTypeDropdownInterface {
  values: FormValues;
  isSubmitting: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const OrgTypeDropdown = ({
  values,
  isSubmitting,
  setFieldValue,
}: OrgTypeDropdownInterface) => {
  return (
    <BootstrapForm.Group as={Col} lg>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix=" "
          as={CustomToggle}
          className={classNames("w-100", { "disabled-with-bg": isSubmitting })}
        >
          {
            orgAllowedIcons[
              orgAllowedKeys.indexOf(
                values.org_type as EBIC.Organization.TypeKey
              )
            ]
          }
          {values.org_type
            ? EBIC.Organization.Type[
                values.org_type as EBIC.Organization.TypeKey
              ]
            : "-"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {orgAllowedKeys.map((i, index) => (
            <Dropdown.Item
              onClick={() => setFieldValue("org_type", orgAllowedKeys[index])}
              key={i}
              value={orgAllowedKeys[index]}
            >
              {orgAllowedLabels[index]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </BootstrapForm.Group>
  );
};

export default OrgDetails;
