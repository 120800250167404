import { Card, Col, Image, Dropdown } from "react-bootstrap";
import styles from "../cardstyles.module.css";
import ebicPlaceholder from "../../../assets/img_placeholder.jpg";
import { useHistory } from "react-router-dom";
import { HTMLAttributes } from "react";
import * as EBIC from "@ebic-bbrm/types";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import IconButton from "../../utils/IconButton";
import { DeleteOutline, EditOutlined, MoreHoriz } from "@mui/icons-material";
import { useLessonMutations } from "../../../api/lessons.api";
import DestructiveModal from "../../utils/DestructiveModal";
import useModal from "../../../hooks/useModal";
import LineClampText from "./../../utils/LineClampText";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useActivityLogMutations } from "../../../api/activitylog.api";

interface LessonItemProps extends HTMLAttributes<HTMLDivElement> {
  lesson: EBIC.Content.Lesson & { id: number };
}

export default function LessonsItem({ lesson }: LessonItemProps) {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: lesson.id });

  const history = useHistory();
  const { mutate: deleteLesson } = useLessonMutations("DELETE_LESSON");
  const { mutate: publishLesson } = useLessonMutations("PUBLISH_LESSON");

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const [deleteModal, openDelete] = useModal(DestructiveModal, {
    title: "Delete lesson 删除课",
    description: (
      <>
        <p>Delete the {lesson.less_name} lesson?</p>
        <p>删除《{lesson.less_name}》课?</p>
      </>
    ),
    onConfirm: async () => {
      await deleteLesson(lesson.less_id, {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "C",
            alog_action: "D",
            alog_accessfrom: 0,
            alog_modulename: lesson.less_name ?? "",
            alog_details: "Delete Lessons",
            org_name: null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "C",
            alog_action: "D",
            alog_accessfrom: 0,
            alog_modulename: lesson.less_name ?? "",
            alog_details: "Delete Lessons Failed.",
            org_name: null,
          });
        },
      });
    },
    confirmBtnText: "Delete 删除",
  });

  const [publishModal, openPublishModal] = useModal(DestructiveModal, {
    title: "Publish Lesson 公布课",
    description: (
      <>
        <p>
          Publish the {lesson.less_name} lesson? The lesson will be publicly
          available for viewing in the mobile app.
        </p>
        <p>公布《{lesson.less_name}》课? 课将在手机应用程序公开。</p>
      </>
    ),
    onConfirm: async () => {
      await publishLesson({ less_id: lesson.less_id, less_published: 1 });
    },
    confirmBtnText: "Publish 公布",
  });

  const [unpublishModal, openUnpublishModal] = useModal(DestructiveModal, {
    title: "Unpublish Lesson 取消公布课",
    description: (
      <>
        <p>
          Unpublish the {lesson.less_name} lesson? The lesson will no longer be
          publicly available for viewing in the mobile app.
        </p>
        <p>取消公布《{lesson.less_name}》课? 课将不再在手机应用程序公开。</p>
      </>
    ),
    onConfirm: async () => {
      await publishLesson({ less_id: lesson.less_id, less_published: 0 });
    },
    confirmBtnText: "Unpublish 取消公布",
  });

  const style = {
    minHeight: "20rem",
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    opacity: isDragging ? "0.5" : "1",
  };

  return (
    <Col ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Card className={"border-0 shadow h-100 " + styles.card}>
        <div className={"card-img-top " + styles.cardImageContainer}>
          <Card.Img
            className={"cursor-pointer " + styles.cardImage}
            as={Image}
            src={lesson.less_pic ?? ebicPlaceholder}
            onClick={() =>
              history.push(
                createContentRoute(ContentRoutes.EDITLESSON, {
                  courseId: lesson.cour_id,
                  unitId: lesson.unit_id,
                  lessonId: lesson.less_id,
                })
              )
            }
            variant="top"
          />
        </div>
        <Card.Body
          className="p-3 cursor-pointer"
          onClick={() =>
            history.push(
              createContentRoute(ContentRoutes.EDITLESSON, {
                courseId: lesson.cour_id,
                unitId: lesson.unit_id,
                lessonId: lesson.less_id,
              })
            )
          }
        >
          <LineClampText
            className={"fw-bold mb-3 " + styles.linkUnderline}
            maxLines={1}
          >
            {lesson.less_name}
          </LineClampText>
        </Card.Body>
        <Card.Footer className="d-flex p-0">
          <IconButton
            transparent
            border={false}
            Icon={EditOutlined}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              history.push(
                createContentRoute(ContentRoutes.EDITLESSON, {
                  courseId: lesson.cour_id,
                  unitId: lesson.unit_id,
                  lessonId: lesson.less_id,
                })
              );
              ev.stopPropagation();
            }}
            title={"Edit 修改"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomLeftRadius: 12,
            }}
          />
          <div className="vr my-1" />
          <Dropdown
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
            onToggle={(_, meta) => {
              meta.originalEvent?.stopPropagation();
              meta.originalEvent?.preventDefault();
            }}
          >
            <Dropdown.Toggle as="span" bsPrefix=" ">
              <IconButton
                transparent
                border={false}
                Icon={MoreHoriz}
                iconHtmlColor="var(--primary)"
                title={"Status"}
                className="py-3"
                style={{ width: "100%", borderRadius: 0 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {lesson.less_published === 1 ? (
                <Dropdown.Item onClick={openUnpublishModal}>
                  Unpublish 取消公布
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={openPublishModal}>
                  Publish 公布
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <div className="vr my-1" />
          <IconButton
            transparent
            border={false}
            Icon={DeleteOutline}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              openDelete();
              ev.stopPropagation();
            }}
            title={"Delete 删除"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
          />
          {deleteModal}
          {publishModal}
          {unpublishModal}
        </Card.Footer>
      </Card>
    </Col>
  );
}
