import * as EBIC from "@ebic-bbrm/types"
import { ALogQueries } from "./activitylog.api";
import { ClassesQueries } from "./classes.api";
import useEbicAPI, { EbicMutationOptions, useEbicMutation, useEbicPaginatedQuery } from './useEbicAPI';
import { UserQueries } from "./user.api";


export interface FetchOrganizationOptions {
    active?: boolean;
    pending?: boolean;
    suspended?: boolean;
    terminated?: boolean;

    limit?: number;
    query?: string;


}

export const OrganizationQueries = {
    ORG_PROFILE_SEARCH: (params: FetchOrganizationOptions) => ['org', 'profiles', 'search', params] as const,

    ORGANIZATION: ['org', 'profiles'] as const,
    USER: ['user', 'details'] as const,

    ORGANIZATIONBYID: (organizationId: number) => ['org', 'profile', organizationId] as const,


    ORGANIZATIONUSER: (organizationId: number) => ['org', 'user', organizationId] as const,

    USERCOUNT: (organizationId: number) => ['org', organizationId, 'users', 'count'] as const,



};


export const organizationBaseRoute = '/api/organization';

export function useAllOrganizations(params: FetchOrganizationOptions = {}, enabled = true) {
    //set defaults (to keep query keys more accurate)
    const { limit = 10, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.Organization.Profile[]>(
        OrganizationQueries.ORG_PROFILE_SEARCH(options),
        `/api/organization/allOrg?${searchParams.toString()}`,
        true,
        { enabled }
    );
}

export function useAllOrgProfile() {
    return useEbicAPI<EBIC.Organization.Profile[]>(
        OrganizationQueries.ORGANIZATION,
        `${organizationBaseRoute}/all`,
        true,
        {
            refetchOnMount: 'always',
        }
    );
}


export function useOrgProfile() {
    return useEbicAPI<EBIC.Organization.Profile>(
        OrganizationQueries.ORGANIZATION,
        `${organizationBaseRoute}`,
        true,
        {
            refetchOnMount: 'always',
        }
    );
}

export function useOrgProfileById(orgProfileId: number, enabled = true) {
    return useEbicAPI<EBIC.Organization.Profile>(
        OrganizationQueries.ORGANIZATIONBYID(orgProfileId),
        `${organizationBaseRoute}/${orgProfileId}`,
        true,
        { enabled }
    );
}

//organization users
export function useUserCount(organizationId: number) {
    return useEbicAPI<number>(
        OrganizationQueries.USERCOUNT(organizationId),
        `${organizationBaseRoute}/${organizationId}/users/count`
    )
}

export function useOrganizationUsers(organizationId: number, enabled = true) {
    return useEbicAPI<EBIC.User.User[]>(
        OrganizationQueries.ORGANIZATIONUSER(organizationId),
        `${organizationBaseRoute}/user/${organizationId}`,
        true,
        { enabled }
    );
}

export function initialiseOrganizationObject(organization: EBIC.Organization.Profile) {
    const requiredFields = [
        'org_id',
        'org_name',
        'org_type',
        'org_desc',
        'org_address',
        'org_unitno',
        'org_country',
        'org_state',
        'org_city',
        'org_website',
        'org_email',
        'org_contact',
        'org_contactno',

    ];
    const originalOrganization: EBIC.Organization.Profile = requiredFields.reduce(
        (accumulator, currentValue) => {
            return {
                ...accumulator,
                ...{
                    [currentValue]: organization[currentValue as keyof typeof organization],
                },
            };
        },
        {} as EBIC.Organization.Profile
    );

    return originalOrganization;
}



type OrganizationForm = Omit<EBIC.Organization.Profile, 'org_coord'> & { org_coord: string | null; };

type CreateOrganizationForm = Omit<
    EBIC.Organization.Profile,
    'org_id' | 'org_signupdate' | 'org_status' | 'org_coord' | 'org_pic' | 'org_size'
> & {
    org_pic: File | null;
    org_coord: string | null;
}
type TCreateUser = Pick<
    EBIC.User.User,
    | 'user_firstname'
    | 'user_lastname'
    | 'user_mobileno'
    | 'user_role'
> & {
    user_pic: File | null;
};

interface EditOrganizationPhoto {
    org_id: number;
    image: File;
}

type TCreateOrgUser = Pick<
    EBIC.User.User,
    | 'org_id'
    | 'user_firstname'
    | 'user_lastname'
    | 'user_email'
    | 'user_mobileno'
    | 'user_role'

> & {
    user_pic: File | null;
};

type EditUserForm = Pick<
    EBIC.User.User,
    | 'user_id'
    | 'org_id'
    | 'user_firstname'
    | 'user_lastname'
    | 'user_mobileno'
    | 'user_role'
>

interface EditUserPhoto {
    org_id: number;
    user_id: number;
    image: File;
}

interface EditOrgStatus {
    org_id: number;
    org_status: number;
}



const OrganizationMutations = {
    CREATE: ({
        ...organization
    }: CreateOrganizationForm): EbicMutationOptions => ({
        url: `${organizationBaseRoute}`,
        method: 'POST',
        requestBody: organization,
        useFormData: true,
        updater: [
            {
                queryKey: OrganizationQueries.ORGANIZATION,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                action: 'refetch'
            },
        ]

    }),
    CREATE_ORG_AND_USER: ({
        ...organization
    }: CreateOrganizationForm & TCreateUser): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/creation`,
        method: 'POST',
        requestBody: organization,
        useFormData: true,
        updater: [{
            queryKey: OrganizationQueries.ORGANIZATION,
            action: 'refetch'
        },
        {
            queryKey: UserQueries.USER,
            action: 'refetch'
        },
        {
            queryKey: ALogQueries.ALLACTIVITIES({}),
            partialMatch: true,
            action: 'refetch'
        },
        ]
    }),
    CHECK_EMAIL_AVAILABILITY: (body: {
        user_email: string;
    }): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/user/validity`,
        method: 'POST',
        requestBody: body,
    }),
    CREATEUSER: ({ org_id, ...body }: TCreateOrgUser): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/user/${org_id}`,
        method: 'POST',
        requestBody: { org_id, ...body },
        useFormData: true,
        updater: [
            {
                queryKey: OrganizationQueries.ORGANIZATIONUSER(org_id),
                action: 'refetch'
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    EDIT: ({ org_id, ...organization }: OrganizationForm): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/${org_id}`,
        method: 'PUT',
        requestBody: organization,
        updater: [
            {
                queryKey: OrganizationQueries.ORGANIZATIONBYID(org_id),
                action: (prev?: EBIC.Organization.Profile) => {
                    if (prev !== undefined) {
                        prev.org_name = organization.org_name;
                        prev.org_type = organization.org_type;
                        prev.org_desc = organization.org_desc;
                        prev.org_address = organization.org_address;
                        prev.org_unitno = organization.org_unitno;
                        prev.org_country = organization.org_country;
                        prev.org_state = organization.org_state;
                        prev.org_city = organization.org_city;
                        prev.org_website = organization.org_website;
                        prev.org_email = organization.org_email;
                        prev.org_contact = organization.org_contact;
                        prev.org_contactno = organization.org_contactno;
                        prev.org_status = organization.org_status;
                    }
                    return prev;
                },
            },
            {
                queryKey: OrganizationQueries.ORGANIZATION,
                action: (prev?: EBIC.Organization.Profile) => {
                    if (prev !== undefined) {
                        prev.org_name = organization.org_name;
                        prev.org_type = organization.org_type;
                        prev.org_desc = organization.org_desc;
                        prev.org_address = organization.org_address;
                        prev.org_unitno = organization.org_unitno;
                        prev.org_country = organization.org_country;
                        prev.org_state = organization.org_state;
                        prev.org_city = organization.org_city;
                        prev.org_website = organization.org_website;
                        prev.org_email = organization.org_email;
                        prev.org_contact = organization.org_contact;
                        prev.org_contactno = organization.org_contactno;
                        prev.org_status = organization.org_status;
                    }
                    return prev;
                },
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ],
    }),

    EDIT_STATUS: ({ org_id, ...organization }: EditOrgStatus): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/status/${org_id}`,
        method: 'PUT',
        requestBody: organization,
        updater: [
            {
                queryKey: OrganizationQueries.ORGANIZATIONBYID(org_id),
                action: (prev?: EBIC.Organization.Profile) => {
                    if (prev !== undefined) {
                        prev.org_status = organization.org_status;
                    }
                    return prev;
                },
            },
            {
                queryKey: OrganizationQueries.ORGANIZATION,
                action: (prev?: EBIC.Organization.Profile) => {
                    if (prev !== undefined) {
                        prev.org_status = organization.org_status;
                    }
                    return prev;
                },
            },
            {
                queryKey: OrganizationQueries.ORG_PROFILE_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },

        ],
    }),

    EDIT_PHOTO: ({ org_id, image }: EditOrganizationPhoto): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/${org_id}/profile`,
        method: 'PUT',
        requestBody: {
            org_pic: image,
        },
        updater: {
            queryKey: OrganizationQueries.ORGANIZATIONBYID(org_id),
            action: 'refetch'
        },
        useFormData: true,
    }),

    EDIT_USER: ({ user_id, org_id, ...body }: EditUserForm): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/user/${user_id}`,
        method: 'PUT',
        requestBody: { user_id, org_id, ...body },
        updater: [
            {
                queryKey: OrganizationQueries.ORGANIZATIONUSER(org_id),
                action: 'refetch'
            },
            {
                queryKey: UserQueries.USER_DETAILS(user_id),
                action: 'refetch'
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: UserQueries.USER,
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASSMEMBERS(user_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ],
    }),

    EDIT_USER_PHOTO: ({ org_id, user_id, image }: EditUserPhoto): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/user/photo/${user_id}`,
        method: 'PUT',
        requestBody: {
            user_pic: image
        },
        useFormData: true,
        updater: [
            {
                queryKey: OrganizationQueries.ORGANIZATIONUSER(org_id),
                action: 'refetch'
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },

        ]
    }),

    DELETE: (org_id: number): EbicMutationOptions => ({
        url: `${organizationBaseRoute}/${org_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: OrganizationQueries.ORG_PROFILE_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),
}

export function useOrganizationMutations<Key extends keyof typeof OrganizationMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = OrganizationMutations[key] as (
        params: Parameters<typeof OrganizationMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}
