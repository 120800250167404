import { Content } from "./content";

export module Order {
    export interface Order extends Pick<Order.Product, 'prod_id' | 'cour_id' | 'prod_name' | 
    'prod_teachers' | 'prod_students' | 'cour_name'> {
        order_id: number;
        order_ref: string;
        org_id: number;
        prod_id: number;
        order_by: number;
        order_date: string;
        order_expiry: string | null;

        order_status: string;
    }

    export interface Product {
        prod_id: number;
        cour_id: number;
        prod_name: string;
        prod_teachers: number | null;
        prod_students: number | null

        cour_name: string;
    }
}