import { Col, Form, Row, Modal, InputGroup } from "react-bootstrap";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import { BaseModalProps } from "../../../hooks/useModal";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  FetchOrganizationOptions,
  useAllOrganizations,
  useOrganizationMutations,
  useOrgProfileById,
} from "../../../api/organization.api";
import IconButton from "../../utils/IconButton";
import { CloseRounded, Upload } from "@mui/icons-material";
import UserAvatar from "../../Profile/UserAvatar";
import {
  BootstrapInput,
  BootstrapInputPhone,
  BootstrapRadio,
} from "../../utils/FormikBootstrapInputs";
import BootstrapSpinner from "../../utils/BootstrapSpinner";

interface UserAddForm {
  org_id: number;
  user_firstname: string;
  user_lastname: string;
  user_email: string;
  user_pic: File | null;
  user_mobileno: string;
  user_role: string;
  user_status: number | null;
}

export default function UserAddModal({ show, onClose }: BaseModalProps) {
  const { mutate: addUser, isLoading } = useOrganizationMutations("CREATEUSER");
  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { mutate: checkEmailAvailability } = useOrganizationMutations(
    "CHECK_EMAIL_AVAILABILITY"
  );

  const params = useMemo(() => {
    const params: FetchOrganizationOptions = {};

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.

    return params;
  }, []);

  const { data: _organization } = useAllOrganizations(params);
  const organization = _organization?.pages.find((p) => p.page === 1)?.result;

  //select courses dropdown
  const [selectedOrg, setSelectedOrg] = useState<number>(0);

  useEffect(() => {
    if (organization && organization.length !== 0)
      setSelectedOrg(organization[0].org_id);
  }, [organization]);

  const handleOrgChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrg(Number(e.target.value));
  };

  const { data: org } = useOrgProfileById(selectedOrg);

  // profile pic
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<{ file: File; url: string }>();

  // eslint-disable-next-line
  const [isReadingImage, setIsReadingImage] = useState(false);
  // eslint-disable-next-line
  const [imageReadError, setImageReadError] = useState<string>();

  const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return setImageReadError("Couldn't read image");
    }
    if (!file.type.startsWith("image")) {
      return setImageReadError("Invalid image file");
    }

    setIsReadingImage(true);

    readImage(file);

    /**
     * Return false in event handler, don't change the value of underlying input
     */
    return false;
  };

  const readImage = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setIsReadingImage(false);

      if (e.target?.result) {
        setImage({
          file,
          url: e.target.result as string,
        });
      }
    };
    reader.onerror = (err) => {
      console.error(err);
      setIsReadingImage(false);
    };
    reader.readAsDataURL(file);
  };

  const initialValues: UserAddForm = {
    org_id: 5,
    user_firstname: "",
    user_lastname: "",
    user_email: "",
    user_pic: null,
    user_mobileno: "",
    user_role: "",
    user_status: 1,
  };

  const validationSchema = yup.object().shape({
    user_role: yup.string().default(null).required("User role is required"),
    user_firstname: yup
      .string()
      .max(30, ({ max }) => `Maximum ${max} characters`)
      .required("Required"),
    user_lastname: yup
      .string()
      .max(30, ({ max }) => `Maximum ${max} characters`)
      .required("Required"),
    user_email: yup
      .string()
      .max(120, ({ max }) => `Maximum ${max} characters`)
      .required("Required"),
    user_mobileno: yup
      .string()
      .max(12, ({ max }) => `Maximum ${max} characters`),
  });

  const onSubmit = async (values: UserAddForm, { resetForm }: any) => {
    addUser(
      {
        ...values,
        user_pic: image?.file ?? null,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "U",
            alog_action: "A",
            alog_accessfrom: 0,
            alog_modulename: values.user_email,
            alog_details: "Add User",
            org_name: org?.org_name ?? "",
          });
          resetForm();
          onClose();
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "U",
            alog_action: "A",
            alog_accessfrom: 0,
            alog_modulename: values.user_email,
            alog_details: "Add User Failed.",
            org_name: org?.org_name ?? "",
          });
        },
      }
    );
  };

  const formikRef = useRef<
    FormikProps<
      Pick<
        UserAddForm,
        // | 'org_id'
        | "user_firstname"
        | "user_lastname"
        | "user_mobileno"
        | "user_email"
        | "user_role"
        | "user_status"
      >
    >
  >(null);

  useEffect(() => {
    // restore profiile pic preview if saved
    if (initialValues.user_pic !== null) {
      readImage(initialValues.user_pic);
    }
    // check email availability
    if (initialValues.user_email) {
      if (
        initialValues.user_email.length < 5 ||
        initialValues.user_email.length > 120
      ) {
        formikRef.current?.setFieldTouched("user_email", true);
      } else {
        checkEmailAvailability(
          { user_email: initialValues.user_email },
          {
            onError: (error) => {
              formikRef.current?.setFieldTouched("user_email", true);
              formikRef.current?.setFieldError("user_email", error.message);
            },
          }
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organization) return <BootstrapSpinner />;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldError,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            centered
            onHide={onClose}
            backdrop={isLoading ? "static" : undefined}
            keyboard={!isLoading}
            size="lg"
          >
            <Form onSubmit={handleSubmit}>
              <Modal.Header className="p-3">
                <Modal.Title className="fw-normal">
                  Add User 新增用户
                </Modal.Title>
                <IconButton
                  Icon={CloseRounded}
                  title="Close"
                  className="ms-auto"
                  disabled={isLoading}
                  onClick={onClose}
                />
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageSelect}
                />
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md="3">
                    <div>
                      <UserAvatar
                        roundedCircle
                        src={image?.url}
                        width="100"
                        height="100"
                        style={{ pointerEvents: "none" }}
                        className="ms-4 d-flex justify-content-center "
                      />
                      <div className="mt-3">
                        <IconButton
                          Icon={Upload}
                          transparent
                          className="text-black fw-bold"
                          label="UPLOAD 上传"
                          onClick={() => inputRef.current?.click()}
                          style={{ left: 0, right: 0 }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <Row className="mb-3 g-3">
                      <Form.Group as={Col} lg>
                        <BootstrapInput
                          id="user_firstname"
                          label="First Name 名"
                          placeholder="First Name"
                          aria-label="First Name"
                          disabled={isLoading}
                        />
                      </Form.Group>
                      <Form.Group as={Col} lg>
                        <BootstrapInput
                          id="user_lastname"
                          label="Last Name 姓"
                          placeholder="Last Name"
                          aria-label="Last Name"
                          disabled={isLoading}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                      <Form.Group>
                        <Form.Label className="fw-bold text-uppercase text-secondary small undefined form-label">
                          Select Instiution 选择课程
                        </Form.Label>
                        <InputGroup>
                          <Form.Select
                            value={selectedOrg}
                            onChange={(
                              e: React.ChangeEvent<HTMLSelectElement>
                            ) => {
                              handleOrgChange(e);
                              setFieldValue("org_id", Number(e.target.value));
                            }}
                          >
                            {organization.map((o) => (
                              <option key={o.org_id} value={o.org_id}>
                                {o.org_name}
                              </option>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                      <Form.Group as={Col} lg>
                        <BootstrapInput
                          id="user_email"
                          label="Email 电邮"
                          placeholder="Email"
                          aria-label="Email"
                          disabled={isLoading}
                          displayError={false}
                          onBlur={(e) => {
                            // default blur handler from Formik
                            handleBlur(e);

                            const user_email = e.target.value;

                            if (
                              !user_email ||
                              user_email.length < 5 ||
                              user_email.length > 120
                            )
                              return;

                            checkEmailAvailability(
                              { user_email },
                              {
                                onError: (error) => {
                                  setFieldError("user_email", error.message);
                                },
                              }
                            );
                          }}
                        />
                      </Form.Group>
                      {touched.user_email &&
                        errors.user_email !== undefined && (
                          <Row>
                            <div>
                              <span
                                style={{
                                  fontSize: "0.81rem",
                                  color: "#dc3545",
                                }}
                              >
                                {errors.user_email}
                              </span>
                            </div>
                          </Row>
                        )}
                    </Row>
                    <Row className="mb-3 g-3">
                      <Form.Group as={Col} lg>
                        <BootstrapInputPhone
                          id="user_mobileno"
                          label="Mobile No 手机号"
                          placeholder="Mobile No"
                          aria-label="Mobile No 手机号"
                          disabled={isLoading}
                          required={false}
                          value={values.user_mobileno}
                          onChange={(val) => {
                            setFieldValue("user_mobileno", val);
                          }}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-3 g-3">
                      <Form.Group as={Col} lg>
                        <BootstrapRadio
                          required={false}
                          name="user_role"
                          id="user_role"
                          className="d-inline-block me-5 small text-uppercase text-secondary"
                          label="User Role 用户角色"
                          labels={[
                            "System Admin 系统管制",
                            "Content Admin 课程管制",
                            "Coordinator 负责人",
                          ]}
                          values={["S", "C", "O"]}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <IconButton
                  iconHtmlColor="var(--primary)"
                  disabled={isLoading}
                  label="CANCEL 取消"
                  transparent
                  className="text-primary border-primary px-4 me-3"
                  onClick={() => onClose()}
                />
                <IconButton
                  disabled={isLoading}
                  type="submit"
                  label="SAVE 保存"
                  className="px-4"
                />
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
}
