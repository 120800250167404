import { forwardRef, HTMLAttributes } from "react";
import * as EBIC from "@ebic-bbrm/types";
import LessonsItem from "./LessonsItem";

export type LessonsDraggableItemProps = HTMLAttributes<HTMLDivElement> & {
  activeId: number;
  lessons: EBIC.Content.Lesson[];
  withOpacity?: boolean;
  isDragging?: boolean;
};

const LessonsDraggableItem = forwardRef<
  HTMLDivElement,
  LessonsDraggableItemProps
>(({ activeId, lessons }, ref) => {
  const lesson = lessons.find((u) => u.less_seqno === activeId);

  if (!lesson) return <></>;
  return (
    <div ref={ref}>
      <LessonsItem lesson={{ id: activeId, ...lesson }} />
    </div>
  );
});

export default LessonsDraggableItem;
