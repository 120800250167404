import { CloseRounded } from '@mui/icons-material';
import { useMemo, useState, useEffect } from 'react';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import IconButton from '../../utils/IconButton';
import * as yup from "yup";
import { useActivityLogMutations } from '../../../api/activitylog.api';
import * as EBIC from "@ebic-bbrm/types";
import { Formik } from 'formik';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import BootstrapSpinner from '../../utils/BootstrapSpinner';
import { useOrderById, useOrderMutations } from '../../../api/order.api';
import { useProducts } from '../../../api/products.api';
import { SearchUserOptions, useUserSearch } from '../../../api/user.api';
import { useOrgProfileById } from '../../../api/organization.api';
import { CompleteDateInput } from '../../SysAdmin/Announcements/AnnoEditModal';

interface OrgEditOrderProps {
    show: boolean;
    onClose: () => void;

    orderId: number;
    orgId: number;
}

function OrgEditOrder({ show, onClose, orderId, orgId }: OrgEditOrderProps) {

    const { data: selectedOrder, isLoading: isOrderLoading } = useOrderById(orderId);
    const { mutate: editOrder, isLoading: isEditLoading } = useOrderMutations("EDIT");

    const { mutate: addLog } = useActivityLogMutations("CREATE");
    const { data: organization } = useOrgProfileById(orgId);

    type orderEditForm = Required<
Pick<EBIC.Order.Order, "order_ref" | "org_id" | "order_expiry">
>

    //select products
    const { data: _prod } = useProducts();
    const prod = useMemo(() => {
        if (!_prod) return [];
        let prodArray = [];
        if (Array.isArray(_prod)) prodArray = _prod;
        else prodArray = [_prod];

        return prodArray;
        // eslint-disable-next-line
    }, [_prod]);


    const [selectedProduct, setSelectedProduct] = useState<number>(selectedOrder?.prod_id ?? 0);

    useEffect(() => {
        if (prod && prod.length !== 0)
            setSelectedProduct(selectedOrder?.prod_id ?? 0)
    }, [prod, selectedOrder?.prod_id])

    const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(Number(e.target.value));

    }

    //select user options
    const userParams = useMemo(() => {
        const params: SearchUserOptions = { member_of_organization: orgId };

        //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.


        return params;

    }, [orgId])

    const { data: _users } = useUserSearch(userParams)
    const users = _users?.pages.find((p) => p.page === 1)?.result


    //select users dropdown
    const [selectedUser, setSelectedUser] = useState<number>(0);

    useEffect(() => {
        if (users && users.length !== 0)
            setSelectedUser(selectedOrder?.order_by ?? 0)
    }, [_users, selectedOrder?.order_by, users])

    const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedUser(Number(e.target.value));

    }

    const initialValues: orderEditForm = {
        order_ref: selectedOrder?.order_ref ?? '',
        org_id: orgId,
        order_expiry: selectedOrder?.order_expiry ?? ''
    }

    const validationSchema = yup.object().shape({
        order_ref: yup
            .string()
            .required("Please enter an Order Reference")
            .max(30, "Maximum 30 characters"),
        order_expiry: yup
            .string()
            .required("Please key in the expiry date")
    })

    let expDate = "";
    if (selectedOrder?.order_expiry) {
        expDate = new Date(selectedOrder.order_expiry).toISOString().split("T")[0];
    }


    const handleEdit = async (values: orderEditForm) => {
        editOrder({
            order_id: orderId,
            prod_id: selectedProduct,
            order_by: selectedUser,
            ...values
        },
            {
                onSuccess: () => {
                    addLog(
                        {
                            alog_type: 'I',
                            alog_module: 'O',
                            alog_action: 'E',
                            alog_accessfrom: 0,
                            alog_modulename: values.order_ref,
                            alog_details: 'Edit Order',
                            org_name: organization?.org_name ?? null
                        }
                    )
                    onClose();
                },
                onError: () => {
                    addLog(
                        {
                            alog_type: 'E',
                            alog_module: 'O',
                            alog_action: 'E',
                            alog_accessfrom: 0,
                            alog_modulename: values.order_ref,
                            alog_details: 'Edit Order Failed.',
                            org_name: organization?.org_name ?? null
                        }
                    )
                    onClose();
                },
            }

        )
    }

    const isLoading = isOrderLoading || isEditLoading;
    if (!users || !prod) return <BootstrapSpinner />


    return (
        <Modal
            size="lg"
            show={show}
            centered
            onHide={onClose}
            backdrop={isLoading ? "static" : undefined}
            keyboard={!isLoading}
        >

            <Formik
                initialValues={initialValues}
                onSubmit={handleEdit}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, handleChange, setFieldValue, errors, touched }) => (

                    <Form onSubmit={handleSubmit}>

                        <Modal.Header className="p-3">
                            <Modal.Title className="fw-normal">
                                Edit Order 编辑订单
                            </Modal.Title>
                            <IconButton
                                Icon={CloseRounded}
                                title="Close"
                                className="ms-auto"
                                disabled={isLoading}
                                onClick={onClose}
                            />
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex flex-grow-1">
                                <div className="mb-2 d-flex flex-column flex-grow-1">
                                    <BootstrapInput
                                        id="order_ref"
                                        required={true}
                                        label="Order Reference 订单参考"
                                        placeholder="Order Ref"
                                        aria-label="Order Ref"
                                        disabled={isLoading}
                                    />
                                </div>
                            </div>
                            <Form.Group>
                                <Form.Label className="fw-bold text-uppercase small undefined form-label">
                                    Select a Product 选择产品
                                    <span className="text-danger">*</span>
                                </Form.Label>

                                <InputGroup>
                                    <Form.Select
                                        value={selectedProduct}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            handleProductChange(e);
                                            setFieldValue('prod_id', Number(e.target.value));
                                        }}>
                                        {prod.map((p) => (
                                            <option key={p.prod_id ?? 0} value={p.prod_id ?? 0}>
                                                {p.prod_name}
                                            </option>
                                        ))}
                                    </Form.Select>

                                </InputGroup>


                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="fw-bold text-uppercase small undefined form-label">
                                    Ordered by 订购人
                                    <span className="text-danger">*</span>
                                </Form.Label>

                                <InputGroup>
                                    <Form.Select
                                        value={selectedUser}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            handleUserChange(e);
                                            setFieldValue('order_by', Number(e.target.value));
                                        }}>
                                        {users.map((u) => (
                                            <option key={u.user_id ?? 0} value={u.user_id ?? 0}>
                                                {u.user_firstname + ' ' + u.user_lastname}
                                            </option>
                                        ))}
                                    </Form.Select>

                                </InputGroup>


                            </Form.Group>
                            <div>
                                <CompleteDateInput
                                    type="date"
                                    name="order_expiry"
                                    value={expDate}
                                    label="Expiry Date 结束日期"
                                    error={errors.order_expiry}
                                    touched={touched.order_expiry}
                                    handleChange={handleChange}
                                    required={true}
                                />
                            </div>



                        </Modal.Body>
                        <Modal.Footer>
                            <IconButton
                                iconHtmlColor="var(--primary)"
                                disabled={isLoading}
                                label="CANCEL 取消"
                                transparent
                                className="text-primary border-primary px-4 me-3"
                                onClick={() => onClose()}
                            />
                            <IconButton
                                disabled={isLoading}
                                type="submit"
                                label="SAVE 保存"
                                className="px-4"
                            />
                        </Modal.Footer>

                    </Form>

                )}

            </Formik>

        </Modal>
    )



}

export default OrgEditOrder;

