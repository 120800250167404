import {
  EditUnitInterface,
  useDownloadables,
  useUnitMutations,
} from "../../../api/units.api";
import { useEffect, useRef, useState } from "react";
import { AttachmentFile } from "../../../types/custom";
import * as yup from "yup";
import { extractFileSize, getFileWithUrl } from "../../utils/HelperFunctions";
import { Formik } from "formik";
import {
  Col,
  Form,
  Row,
  Image,
  Button,
  InputGroup,
  Card,
} from "react-bootstrap";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import { Link, useHistory, useParams } from "react-router-dom";
import imgPlaceHolder from "../../../assets/img_placeholder.jpg";
import IconButton from "../../utils/IconButton";
import { Add, Upload } from "@mui/icons-material";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import EmptyMessage from "../../utils/EmptyMessage";
import { useCourseById } from "../../../api/courses.api";
import ErrorPage from "./../../utils/ErrorPage";
import TeachingGuideModal from "./TeachingGuideModal";
import { useUnitById } from "./../../../api/units.api";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import useModal from "../../../hooks/useModal";
import DownloadablesModal from "./Downloadables/DownloadablesModal";
import DownloadableItem from "./Downloadables/DownloadableItem";
import { useActivityLogMutations } from "../../../api/activitylog.api";

export default function UnitEdit() {
  const { courseId } = useParams<{ courseId: string | undefined }>();
  const { data: course, error: courseError } = useCourseById(
    parseInt(courseId ?? "0"),
    courseId !== undefined
  );

  const { unitId } = useParams<{ unitId: string | undefined }>();
  const {
    data: unit,
    isLoading: unitLoading,
    error: unitError,
  } = useUnitById(parseInt(unitId ?? "0"), unitId !== undefined);

  const [showUploadModal, setShowUploadModal] = useState(false);

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const history = useHistory();
  const { mutate: editUnit, isLoading, error } = useUnitMutations("EDIT_UNIT");
  const {
    data: downloadables,
    isLoading: isDownloadLoading,
    error: downloadError,
  } = useDownloadables(
    { unit_id: parseInt(unitId ?? "0") },
    unitId !== undefined
  );

  const [downloadableModal, openDownloadable] = useModal(DownloadablesModal, {
    unitId: parseInt(unitId ?? "0"),
  });

  useEffect(() => {
    setFile(undefined);
  }, []);

  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  interface EditUnitForm
    extends Omit<
      EditUnitInterface,
      "unit_pic" | "unit_id" | "unit_guidedoc" | "unit_guidename"
    > {
    unit_guidedoc?: Blob;
    unit_guidename?: string;
  }

  const initialValues: EditUnitForm = {
    unit_name: unit?.unit_name ?? "",
    unit_videourl: unit?.unit_videourl ?? undefined,

    // Special case: Default to undefined, only modify if a file is passed into this value
    unit_guidedoc: undefined,
    unit_guidename: unit?.unit_guidename ?? undefined,
  };

  const validationSchema = yup.object().shape({
    unit_name: yup
      .string()
      .required("Unit name is required")
      .max(80, ({ max }) => `Unit name cannot be more than ${max} characters`),
    unit_guidedoc: yup.string(),
    unit_guidename: yup
      .string()
      .max(60, "Name must be less than 60 characters"),
    unit_videourl: yup
      .string()
      .max(128, "URL must be less than 128 characters")
      .matches(
        /(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9_-]+)/,
        "Enter a valid YouTube URL"
      ),
  });

  const onSubmit = (values: EditUnitForm) => {
    editUnit(
      {
        unit_id: unit?.unit_id ?? 0,
        unit_name: values.unit_name,
        unit_pic: file?.file,
        unit_guidedoc: values.unit_guidedoc ?? undefined,
        unit_guidename: values.unit_guidename ?? undefined,
        unit_videourl: values.unit_videourl ?? undefined,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: values.unit_name,
            alog_details: "Edit Unit",
            org_name: null,
          });

          history.push(
            createContentRoute(ContentRoutes.UNITS, {
              courseId: course?.cour_id ?? "0",
            })
          );
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: values.unit_name,
            alog_details: "Edit Unit Failed.",
            org_name: null,
          });
        },
      }
    );
  };

  if (courseError || unitError)
    return <ErrorPage message={courseError?.message || unitError?.message} />;

  if (unitLoading) return <BootstrapSpinner />;

  if (!course)
    return (
      <EmptyMessage message="Course not found. Unable to create unit in an unknown course." />
    );

  return (
    <div className="p-5">
      <h3
        className="text-uppercase mb-5"
        style={{ color: "var(--bs-gray-600)" }}
      >
        <Link
          to={createContentRoute(ContentRoutes.UNITS, {
            courseId: parseInt(courseId ?? "0"),
          })}
          className="linkUnderline"
        >
          {course?.cour_name}
        </Link>
        <span className="text-black"> &#xBB; </span>
        {unit?.unit_name}
      </h3>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col
                md={3}
                className="d-flex flex-column align-items-center justify-content-start"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      await getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    } // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                ></input>
                {file !== undefined ? (
                  <Image
                    src={file.url}
                    title={
                      file.file.name + " " + extractFileSize(file.file.size)
                    }
                    className={
                      "shadow-none rounded-circle border border-secondary "
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Image
                    src={unit?.unit_pic ?? imgPlaceHolder}
                    className={
                      "shadow-none rounded-circle border border-secondary"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                    title="Unit Picture"
                  />
                )}
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black mt-3 fw-bold"
                  title="Upload a unit photo"
                  label="UPLOAD 上传"
                  disabled={isLoading || isSubmitting}
                  onClick={() => inputRef.current?.click()}
                />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Unit Name 单元名
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <BootstrapInput
                          id="unit_name"
                          required
                          placeholder="中学系列S1A"
                          aria-label="Unit Name"
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-2">
                      <Form.Group as={Col}>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Teaching Guide 教学手册
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="Select a file"
                            readOnly
                            value={
                              values?.unit_guidename
                                ? values.unit_guidename.toString()
                                : ""
                            }
                          />
                          <Button
                            onClick={() => setShowUploadModal(true)}
                            disabled={isLoading || isSubmitting}
                            title={"Upload a guide 上载教学指引"}
                          >
                            <Upload />
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Training Video 培训视频
                        </Form.Label>
                        <BootstrapInput
                          id="unit_videourl"
                          placeholder="https://www.youtube.com/watch?v=example"
                          aria-label="Training Video"
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col md={4} className="mb-4">
                    <Row>
                      <Col>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Downloadables 可下载
                        </Form.Label>
                        <Card
                          className="shadow-sm p-3 mb-2"
                          style={{
                            height: "12rem",
                            width: "100%",
                            overflowY: "auto",
                            overflowX: "hidden",
                          }}
                        >
                          {downloadError ? (
                            <EmptyMessage
                              message={`Error fetching downloadables, ${downloadError.message}`}
                            />
                          ) : isDownloadLoading ? (
                            <BootstrapSpinner />
                          ) : !downloadables || downloadables.length === 0 ? (
                            <p className="text-muted">No downloadables yet.</p>
                          ) : (
                            downloadables.map((downloadable) => (
                              <DownloadableItem
                                key={downloadable.dnld_id}
                                downloadable={downloadable}
                              />
                            ))
                          )}
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          className="text-uppercase me-2"
                          title="Add File 加文件"
                          variant="outline-primary"
                          disabled={isLoading || isSubmitting}
                          style={{ float: "right" }}
                          onClick={openDownloadable}
                        >
                          <Add fontSize="small" /> Add File 加文件
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center">
                      <Button
                        className="text-uppercase me-2"
                        title="Cancel"
                        variant="outline-primary"
                        disabled={isLoading || isSubmitting}
                        onClick={() =>
                          history.push(
                            createContentRoute(ContentRoutes.UNITS, {
                              courseId: course.cour_id,
                            })
                          )
                        }
                      >
                        Cancel 取消
                      </Button>
                      <Button
                        className="text-uppercase"
                        title="Save"
                        type="submit"
                        disabled={isLoading || isSubmitting}
                      >
                        Save 保存
                      </Button>
                    </div>
                  </div>
                </Row>
                {error !== null ? (
                  <Row className="text-danger">{error?.message}</Row>
                ) : null}
              </Col>
            </Row>
            <TeachingGuideModal
              show={showUploadModal}
              onClose={() => setShowUploadModal(false)}
              setGuideDocValue={(file) => {
                setFieldValue("unit_guidedoc", file, true);
              }}
              setGuideNameValue={(name) => {
                setFieldValue("unit_guidename", name, true);
              }}
            />
            {downloadableModal}
          </Form>
        )}
      </Formik>
    </div>
  );
}
