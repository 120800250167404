import * as EBIC from "@ebic-bbrm/types";
import { Dropdown } from "react-bootstrap";
import { Column } from "react-table";
import { useMemo, useState } from "react";
import ReactTable from "../../utils/ReactTable";
import IconButton from "../../utils/IconButton";
import { formatDate } from '../../utils/HelperFunctions'
import { MoreVert } from "@mui/icons-material";
import { useOrderMutations } from "../../../api/order.api";
import DestructiveModal from "../../utils/DestructiveModal";
import OrgEditOrder from "./OrgEditOrder";
import { useEbicUser } from "../../../api/user.api";


interface OrgOrderTableInterface {
    orders: EBIC.Order.Order[];

    isLoading: boolean;
    orgId: number;
}

export const OrgOrderTable = ({
    orders,
    isLoading,
    orgId
}: OrgOrderTableInterface) => {

    const { mutate: deleteOrder } = useOrderMutations("DELETE");
    const { data: dbUser } = useEbicUser();

    const isSysAdmin = Boolean(dbUser?.user_role === "S")

    const orderColumns = useMemo<Column<EBIC.Order.Order>[]>(
        () => [
            {
                Header: "Date",
                accessor: "order_date",
                minWidth: 10,
                Cell: ({ value, row }) => {
                    return (
                        <>
                            {
                                `${value}`
                                    ? formatDate(`${value}`, "long") : " "
                            }

                        </>
                    );
                },
            },
            {
                Header: "Order Ref.",
                accessor: "order_ref",
                minWidth: 10,
                Cell: ({ value }) => {
                    return (
                        <>
                            {`${value}`}

                        </>
                    );
                },
            },
            {
                Header: "Course Name",
                accessor: "cour_id",
                minWidth: 10,
                Cell: ({ value, row }) => {
                    const order = row.original
                    return (
                        <>
                            {`${order.cour_name}`}

                        </>
                    );
                },
            },
            {
                Header: "Name",
                accessor: "prod_name",
                minWidth: 10,
                Cell: ({ value }) => {
                    return (
                        <>
                            {`${value}`}

                        </>
                    );
                },
            },
            {
                Header: "No. of Teachers",
                accessor: "prod_teachers",
                minWidth: 10,
                Cell: ({ value }) => {
                    return (
                        <>
                            {`${value}`}

                        </>
                    );
                },
            },
            {
                Header: "No. of Students",
                accessor: "prod_students",
                minWidth: 10,
                Cell: ({ value }) => {
                    return (
                        <>
                            {`${value}`}

                        </>
                    );
                },
            },
            {
                Header: "Status",
                accessor: "order_status",
                minWidth: 10,
                Cell: ({ value, row }) => {
                    return (
                        <>
                            {`${value}`}

                        </>
                    );
                },
            },
            {
                accessor: 'order_id',
                minWidth: 10,
                Cell: ({ value, row }) => {
                    const order = row.original;

                    const [showEditModal, setShowEditModal] = useState(false);
                    const [showDeleteModal, setShowDeleteModal] = useState(false)

                    return (
                        <>
                            {isSysAdmin && (
                                <>

                                    <Dropdown
                                        onToggle={(_, meta) => {
                                            meta.originalEvent?.stopPropagation();
                                            meta.originalEvent?.preventDefault();
                                        }}
                                        style={{ float: 'right' }}
                                        drop="start"
                                    >
                                        <Dropdown.Toggle as="span" bsPrefix=" " >
                                            <IconButton
                                                transparent
                                                border={false}
                                                title="More"
                                                Icon={MoreVert}
                                                iconHtmlColor="var(--bs-gray)"
                                            />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="p-0">
                                            <Dropdown.Item
                                                onClick={() => setShowEditModal(true)}
                                            >Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => setShowDeleteModal(true)}
                                            >Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <OrgEditOrder
                                        show={showEditModal}
                                        onClose={() => setShowEditModal(false)}
                                        orderId={order.order_id}
                                        orgId={order.org_id}
                                    />
                                    <DestructiveModal
                                        title="Delete Order 删除订单"
                                        description={
                                            "This Order will be permanently removed from the system. 此订单将从系统中永久删除。"
                                        }
                                        show={showDeleteModal}
                                        onClose={() => setShowDeleteModal(false)}
                                        onConfirm={() =>
                                            deleteOrder(
                                                { order_id: order.order_id })
                                        }
                                    />

                                </>

                            )}
                        </>
                    )
                }
            },


        ],
        [deleteOrder, isSysAdmin]
    )

    return (
        <>
            <ReactTable
                data={orders}
                columns={orderColumns}
                isLoading={isLoading}
                placeholderMessage={"There are no Orders yet. 还没有订单。"}
                pagination={true}
                Row={(props, data) => <tr {...props} />}
            ></ReactTable>
        </>
    );

}

