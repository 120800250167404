import { createRoot } from 'react-dom/client';
import App from './App';
import './App.scss';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import EbicQueryProvider from './api/EbicQueryProvider';

const domain = String(process.env.REACT_APP_AUTH0_DOMAIN);
const clientId = String(process.env.REACT_APP_AUTH0_CLIENT_ID);
const audience = String(process.env.REACT_APP_AUTH0_AUDIENCE);

const container = document.getElementById('root')
const root = createRoot(container!);

root.render(
  <Router>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      scope="openid profile email"
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <EbicQueryProvider>
        <App />
      </EbicQueryProvider>
    </Auth0Provider>

  </Router>
);