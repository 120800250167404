export interface Point {
	x: number;
	y: number;
}

export interface SignedURL {
    url: string;
    expiresIn: number;
}

export type CountriesKey = keyof typeof Countries;
export enum Countries {
	AF = "Afghanistan",
	AL = "Albania",
	DZ = "Algeria",
	AS = "American Samoa",
	AD = "Andorra",
	AI = "Anguilla",
	AG = "Antigua and Barbuda",
	AR = "Argentina",
	AM = "Armenia",
	AW = "Aruba",
	AU = "Australia",
	AT = "Austria",
	AZ = "Azerbaijan",
	BS = "Bahamas",
	BH = "Bahrain",
	BD = "Bangladesh",
	BB = "Barbados",
	BY = "Belarus",
	BE = "Belgium",
	BZ = "Belize",
	BJ = "Benin",
	BM = "Bermuda",
	BT = "Bhutan",
	BO = "Bolivia",
	BW = "Botswana",
	BV = "Bouvet Island",
	BR = "Brazil",
	IO = "British Indian Ocean Territory",
	BN = "Brunei Darussalam",
	BG = "Bulgaria",
	BF = "Burkina Faso",
	BI = "Burundi",
	KH = "Cambodia",
	CM = "Cameroon",
	CA = "Canada",
	CV = "Cape Verde",
	KY = "Cayman Islands",
	CF = "Central African Republic",
	TD = "Chad",
	CL = "Chile",
	CN = "China",
	CX = "Christmas Island",
	CC = "Cocos (Keeling) Islands",
	CO = "Colombia",
	KM = "Comoros",
	CG = "Congo Republic of the Democratic",
	CD = "Congo-Brazzaville",
	CK = "Cook Islands",
	CR = "Costa Rica",
	HR = "Croatia (Hrvatska)",
	CU = "Cuba",
	CY = "Cyprus",
	CZ = "Czech Republic",
	DK = "Denmark",
	DJ = "Djibouti",
	DM = "Dominica",
	DO = "Dominican Republic",
	TP = "East Timor",
	EC = "Ecuador",
	EG = "Egypt",
	SV = "El Salvador",
	GQ = "Equatorial Guinea",
	ER = "Eritrea",
	EE = "Estonia",
	ET = "Ethiopia",
	FK = "Falkland Islands (Malvinas)",
	FO = "Faroe Islands",
	FJ = "Fiji",
	FI = "Finland",
	FR = "France",
	GF = "French Guiana",
	PF = "French Polynesia",
	TF = "French Southern Territories",
	GA = "Gabon",
	GM = "Gambia",
	GE = "Georgia",
	DE = "Germany",
	GI = "Gibraltar",
	GR = "Greece",
	GL = "Greenland",
	GD = "Grenada",
	GP = "Guadeloupe",
	GU = "Guam",
	GT = "Guatemala",
	GN = "Guinea",
	GW = "Guinea-Bissau",
	GY = "Guyana",
	HT = "Haiti",
	HM = "Heard and Mc Donald Islands",
	HN = "Honduras",
	HK = "Hong Kong",
	HU = "Hungary",
	IS = "Iceland",
	IN = "India",
	ID = "Indonesia",
	IR = "Iran (Islamic Republic of)",
	IQ = "Iraq",
	IE = "Ireland",
	IL = "Israel",
	IT = "Italy",
	CI = "Ivory Coast",
	JM = "Jamaica",
	JP = "Japan",
	JE = "Jersey",
	JO = "Jordan",
	KZ = "Kazakhstan",
	KE = "Kenya",
	KI = "Kiribati",
	KP = "Korea North",
	KR = "Korea South",
	KW = "Kuwait",
	KG = "Kyrgyzstan",
	LA = "Lao PeopleÕs Democratic Republic",
	LV = "Latvia",
	LB = "Lebanon",
	LS = "Lesotho",
	LR = "Liberia",
	LY = "Libyan Arab Jamahiriya",
	LI = "Liechtenstein",
	LT = "Lithuania",
	LU = "Luxembourg",
	MO = "Macau",
	MK = "Macedonia",
	MG = "Madagascar",
	MW = "Malawi",
	MY = "Malaysia",
	MV = "Maldives",
	ML = "Mali",
	MT = "Malta",
	MH = "Marshall Islands",
	MQ = "Martinique",
	MR = "Mauritania",
	MU = "Mauritius",
	YT = "Mayotte",
	MX = "Mexico",
	FM = "Micronesia Federated States of",
	MD = "Moldova Republic of",
	MC = "Monaco",
	MN = "Mongolia",
	MS = "Montserrat",
	MA = "Morocco",
	MZ = "Mozambique",
	MM = "Myanmar",
	NA = "Namibia",
	NR = "Nauru",
	NP = "Nepal",
	NL = "Netherlands",
	AN = "Netherlands Antilles",
	NC = "New Caledonia",
	NZ = "New Zealand",
	NI = "Nicaragua",
	NE = "Niger",
	NG = "Nigeria",
	NU = "Niue",
	NF = "Norfolk Island",
	MP = "Northern Mariana Islands",
	NO = "Norway",
	OM = "Oman",
	PK = "Pakistan",
	PW = "Palau",
	PA = "Panama",
	PG = "Papua New Guinea",
	PY = "Paraguay",
	PE = "Peru",
	PH = "Philippines",
	PN = "Pitcairn",
	PL = "Poland",
	PT = "Portugal",
	PR = "Puerto Rico",
	QA = "Qatar",
	RE = "Reunion",
	RO = "Romania",
	RU = "Russian Federation",
	RW = "Rwanda",
	KN = "Saint Kitts",
	LC = "Saint Lucia",
	VC = "Saint Vincent Grenadines",
	WS = "Samoa",
	SM = "San Marino",
	ST = "Sao Tome and Principe",
	SA = "Saudi Arabia",
	SN = "Senegal",
	SC = "Seychelles",
	SL = "Sierra Leone",
	SG = "Singapore",
	SK = "Slovakia (Slovak Republic)",
	SI = "Slovenia",
	SB = "Solomon Islands",
	SO = "Somalia",
	ZA = "South Africa",
	GS = "South Georgia and the South Sandwich Islands",
	ES = "Spain",
	LK = "Sri Lanka",
	SD = "Sudan",
	SR = "Suriname",
	SJ = "Svalbard and Jan Mayen Islands",
	SZ = "Swaziland",
	SE = "Sweden",
	CH = "Switzerland",
	SY = "Syrian Arab Republic",
	TW = "Taiwan",
	TJ = "Tajikistan",
	TZ = "Tanzania",
	TH = "Thailand",
	TG = "Togo",
	TK = "Tokelau",
	TO = "Tonga",
	TT = "Trinidad and Tobago",
	TN = "Tunisia",
	TR = "Turkey",
	TM = "Turkmenistan",
	TC = "Turks and Caicos Islands",
	TV = "Tuvalu",
	UG = "Uganda",
	UA = "Ukraine",
	AE = "United Arab Emirates",
	GB = "United Kingdom",
	US = "United States",
	UM = "United States Minor Outlying Islands",
	UY = "Uruguay",
	UZ = "Uzbekistan",
	VU = "Vanuatu",
	VA = "Vatican City State (Holy See)",
	VE = "Venezuela",
	VN = "Vietnam",
	VG = "Virgin Islands (British)",
	VI = "Virgin Islands (US)",
	WF = "Wallis and Futuna Islands",
	EH = "Western Sahara",
	YE = "Yemen",
	ZM = "Zambia",
	ZW = "Zimbabwe"
}