import styles from "./landing.module.scss";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Row, Container, Image } from "react-bootstrap";
import { useEbicContacts } from "../../api/announcements.api";
import BootstrapSpinner from "../utils/BootstrapSpinner";

const Landing = () => {
  const {
    loginWithRedirect,
    // logout,
    user,
    isAuthenticated,
  } = useAuth0();

  const { data: contact, isLoading: contactLoading } = useEbicContacts();

  if (!isAuthenticated) {
    return (
      <>
        <Container>
          <Row>
            <div className="d-flex justify-content-center align-items-center py-3">
              <Image
                alt="img"
                src="/logo.png"
                width="45%"
                className="mx-3"
                style={{ objectFit: "contain", marginTop: "5%" }}
              />
            </div>
            <div className="d-flex py-3 justify-content-center align-items-center">
              <h2 style={{ maxWidth: "45%" }}>
                引导华文为第二语的一代遨游圣经世界、探索真善美、体验信仰、文化与人生。
              </h2>
            </div>
          </Row>
          <Row className="d-flex justify-content-center align-items-center py-3">
            <Button
              className={"lg " + styles.signUpBtn}
              style={{
                backgroundColor: "white",
                color: "#AA8066",
                borderColor: "transparent",
                margin: "1%",
              }}
              onClick={() => loginWithRedirect()}
            >
              START | 开启
            </Button>
          </Row>{" "}
          <Row className="text-center">
            {contactLoading ? <BootstrapSpinner /> : (
              <>
                <h3>COPYRIGHT© {new Date().getFullYear()} EBIC LTD</h3>
                <p>
                  {contact?.contact_address1}<br />
                  {contact?.contact_address2}<br />
                  <a href={`mailto:${contact?.contact_email ?? 'support@ebic.com.sg'}`}>
                    {contact?.contact_email ?? "support@ebic.com.sg"}
                  </a>
                </p>
              </>
            )}
          </Row>
        </Container>
      </>
    );
  }

  return (
    <div>
      <h1 style={{ color: "#0076ba", margin: 10 }}>
        Welcome <b> {user?.nickname}! </b>
      </h1>
    </div>
  );
};

export default Landing;
