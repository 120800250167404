import * as EBIC from "@ebic-bbrm/types";
import {
  useOrganizationMutations,
  useOrgProfileById,
} from "../../../api/organization.api";
import { useState, useEffect, useRef } from "react";
import UserAvatar from "../../Profile/UserAvatar";
import { CloseRounded, Upload } from "@mui/icons-material";
import { Form, Modal } from "react-bootstrap";
import IconButton from "../../utils/IconButton";
import { useUserDetails } from "../../../api/user.api";
import * as yup from "yup";
import { Formik } from "formik";
import {
  BootstrapInput,
  BootstrapInputPhone,
  BootstrapRadio,
} from "../../utils/FormikBootstrapInputs";
import { useActivityLogMutations } from "../../../api/activitylog.api";

interface UserEditModalProps {
  show: boolean;
  onClose: () => void;

  userId: number;
  orgId: number;
}

function UserEditModal({ show, onClose, userId, orgId }: UserEditModalProps) {
  const { data: selectedUser } = useUserDetails(userId);

  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: organization } = useOrgProfileById(orgId);

  const schema = yup.object().shape({
    user_firstname: yup
      .string()
      .required("Please enter your first name")
      .max(30, "Maximum 30 characters"),
    user_lastname: yup
      .string()
      .required("Please enter your last name")
      .max(30, "Maximum 30 characters"),
    user_mobileno: yup
      .string()
      .nullable()
      .typeError("Please enter a valid number")
      .max(12, "12 character limit"),
    user_role: yup.string().required(),
  });

  const {
    mutate: editUser,
    isLoading: isLoadingDetails,
    error: errorDetails,
  } = useOrganizationMutations("EDIT_USER");

  type OrgUserProfileForm = Required<
    Pick<
      EBIC.User.User,
      | "org_id"
      | "user_id"
      | "user_firstname"
      | "user_lastname"
      | "user_mobileno"
      | "user_role"
    >
  >;

  const handleEdit = async (values: OrgUserProfileForm) => {
    const editUserDetails = (imageUpdated: boolean) => {
      editUser(values, {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "U",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: selectedUser?.user_email ?? "",
            alog_details: "Edit User",
            org_name: organization?.org_name ?? null,
          });

          onClose();
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "U",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: selectedUser?.user_email ?? "",
            alog_details: "Edit User Failed",
            org_name: organization?.org_name ?? null,
          });
        },
      });
    };
    if (!image) {
      editUserDetails(false);
    } else {
      editPhoto(
        { org_id: orgId, user_id: userId, image: image.file },
        {
          onSuccess: () => editUserDetails(true),
        }
      );
    }
  };

  //Image states
  const {
    mutate: editPhoto,
    isLoading: isLoadingPhoto,
    error: errorPhoto,
  } = useOrganizationMutations("EDIT_USER_PHOTO");

  const [isReadingPhoto, setIsReadingPhoto] = useState(false);
  const [imageReadError, setImageReadError] = useState<string>();

  const [image, setImage] = useState<{ file: File; url: string }>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImage(undefined);
  }, [show]);

  const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return setImageReadError("Couldn't read image");
    }
    if (!file.type.startsWith("image")) {
      return setImageReadError("Invalid image file");
    }

    setIsReadingPhoto(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      setIsReadingPhoto(false);

      if (e.target?.result) {
        setImage({
          file,
          url: e.target.result as string,
        });
      }
    };
    reader.onerror = (err) => {
      console.error(err);
      setIsReadingPhoto(false);
    };
    reader.readAsDataURL(file);
    /**
     * Return false in event handler, don't change the value of underlying input
     */
    return false;
  };

  const isLoading = isLoadingPhoto || isLoadingDetails || isReadingPhoto;

  return (
    <Modal
      show={show}
      centered
      lg
      onHide={onClose}
      backdrop={isLoading ? "static" : undefined}
      keyboard={!isLoading}
    >
      <Modal.Header className="p-3">
        <Modal.Title className="fw-normal">
          Edit Profile 编辑个人资料
        </Modal.Title>
        <IconButton
          Icon={CloseRounded}
          title="Close"
          className="ms-auto"
          disabled={isLoading}
          onClick={onClose}
        />
        <input
          hidden
          ref={inputRef}
          type="file"
          accept="image/*"
          onChange={handleImageSelect}
        ></input>
      </Modal.Header>
      <Formik
        initialValues={{
          org_id: orgId,
          user_id: userId,
          user_firstname: selectedUser?.user_firstname ?? "",
          user_lastname: selectedUser?.user_lastname ?? "",
          user_mobileno: selectedUser?.user_mobileno ?? "",
          user_role: selectedUser?.user_role ?? "",
        }}
        onSubmit={handleEdit}
        validationSchema={schema}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="small px-4 py-3">
              <div className="mb-3 d-flex align-items-center">
                <UserAvatar
                  width={100}
                  height={100}
                  user={selectedUser}
                  className="me-4"
                  style={{ objectFit: "cover" }}
                  src={image?.url}
                />
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black fw-bold"
                  title="Change Profile Picture"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                />
              </div>
              <div className="mb-2">
                <BootstrapInput
                  id="user_firstname"
                  placeholder="First Name"
                  label="First Name 名"
                  required={false}
                />
              </div>
              <div className="mb-2">
                <BootstrapInput
                  id="user_lastname"
                  placeholder="Last Name"
                  label="Last Name 姓"
                  required={false}
                />
              </div>
              <div className="mb-2">
                <BootstrapInputPhone
                  id="user_mobileno"
                  placeholder="Phone Number"
                  label="Mobile No 手机号"
                  value={selectedUser?.user_mobileno ?? ""}
                  onChange={(val: any) => {
                    setFieldValue("user_mobileno", val ?? "");
                  }}
                  required={false}
                />
              </div>
              <div className="mb-2">
                <Form.Group>
                  <BootstrapRadio
                    required={false}
                    name="user_role"
                    id="user_role"
                    className="d-inline-block me-5 small text-uppercase text-secondary"
                    label="User Role 用户角色"
                    labels={[
                      "User 用户",
                      "System Admin 系统管制",
                      "Content Admin 课程管制",
                      "Coordinator 负责人",
                    ]}
                    values={["U", "S", "C", "O"]}
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {(imageReadError || errorPhoto || errorDetails) && (
                <p className="w-100 text-danger">
                  {imageReadError !== undefined
                    ? imageReadError
                    : `${
                        errorPhoto !== null
                          ? "There was an error updating your photo: "
                          : errorDetails !== null
                          ? "We updated your photo but there was an error updating your details: "
                          : false
                      }
                                                  ${
                                                    errorPhoto?.message ||
                                                    errorDetails?.message
                                                  }`}
                </p>
              )}
              <IconButton
                iconHtmlColor="var(--primary)"
                disabled={isLoading}
                label="CANCEL 取消"
                transparent
                className="text-primary border-primary px-4 me-3"
                onClick={() => onClose()}
              />
              <IconButton
                disabled={isLoading}
                type="submit"
                label="SAVE 保存"
                className="px-4"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default UserEditModal;
