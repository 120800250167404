import { CloseRounded } from "@mui/icons-material";
import * as EBIC from "@ebic-bbrm/types";
import { Col, Form, InputGroup, Modal } from "react-bootstrap";
import IconButton from "../../utils/IconButton";
import * as yup from "yup";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import {
  useAnnouncementsById,
  useAnnouncementsMutations,
} from "../../../api/announcements.api";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { Formik } from "formik";

interface AnnoEditModalProps {
  show: boolean;
  onClose: () => void;

  annoId: number;
}

function AnnoEditModal({ show, onClose, annoId }: AnnoEditModalProps) {
  const { mutate: editAnno, isLoading } = useAnnouncementsMutations("EDIT");
  const { data: selectedAnno } = useAnnouncementsById(annoId);

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const schema = yup.object().shape({
    anno_subject: yup
      .string()
      .required("Please enter a Subject")
      .max(100, "Maximum 80 characters"),
    anno_text: yup.string().required("Please enter more details"),
    anno_expire: yup
      .string()
      .required("Please key in the Announcement expiry date"),
  });

  type annoEditForm = Required<
    Pick<EBIC.Announcements, "anno_subject" | "anno_text" | "anno_expire">
  >;

  const handleEdit = async (values: annoEditForm) => {
    editAnno(
      {
        anno_id: annoId,
        ...values,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "A",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: values.anno_subject,
            alog_details: "Edit Announcement",
            org_name: null,
          });

          onClose();
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "A",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: values.anno_subject,
            alog_details: "Edit Announcement Failed.",
            org_name: null,
          });

          onClose();
        },
      }
    );
  };

  let expDate = "";
  if (selectedAnno?.anno_expire) {
    expDate = new Date(selectedAnno.anno_expire).toISOString().split("T")[0];
  }

  return (
    <Modal
      size="lg"
      show={show}
      centered
      onHide={onClose}
      backdrop={isLoading ? "static" : undefined}
      keyboard={!isLoading}
    >
      <Formik
        initialValues={{
          anno_subject: selectedAnno?.anno_subject ?? "",
          anno_text: selectedAnno?.anno_text ?? "",
          anno_expire: selectedAnno?.anno_expire ?? "",
        }}
        onSubmit={handleEdit}
        validationSchema={schema}
      >
        {({ handleSubmit, handleChange, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="p-3">
              <Modal.Title className="fw-normal">
                Edit {selectedAnno?.anno_subject ?? ""} Announcement 编辑公告
              </Modal.Title>
              <IconButton
                Icon={CloseRounded}
                title="Close"
                className="ms-auto"
                disabled={isLoading}
                onClick={onClose}
              />
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-grow-1">
                <div className="mb-2 d-flex flex-column flex-grow-1">
                  <BootstrapInput
                    id="anno_subject"
                    required={true}
                    label="Subject 主题"
                    placeholder="Subject"
                    aria-label="Subject"
                    disabled={isLoading}
                  />
                </div>
              </div>
              <div>
                <BootstrapInput
                  id="anno_text"
                  as="textarea"
                  rows="2"
                  label="Details 公告內容"
                  required={true}
                  placeholder="Text"
                  aria-label="Text"
                  disabled={isLoading}
                  className="col-xs-4 mb-2"
                />
              </div>
              <div>
                <CompleteDateInput
                  type="date"
                  name="anno_expire"
                  value={expDate}
                  label="Expiry Date 结束日期"
                  error={errors.anno_expire}
                  touched={touched.anno_expire}
                  handleChange={handleChange}
                  required={true}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <IconButton
                iconHtmlColor="var(--primary)"
                disabled={isLoading}
                label="CANCEL 取消"
                transparent
                className="text-primary border-primary px-4 me-3"
                onClick={() => onClose()}
              />
              <IconButton
                disabled={isLoading}
                type="submit"
                label="SAVE 保存"
                className="px-4"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}
interface CompleteDateInputProps {
  allowedValues?: string[] | number[];
  allowedLabels?: string[];
  type: "select" | "text" | "number" | "date";
  name?: string;
  value?: string | number;
  label?: string;
  required?: boolean;
  error?: string;
  touched?: boolean;
  handleChange: (ev: React.ChangeEvent<any>) => void;
  xs?: number;
  lg?: number;
}
export function CompleteDateInput({
  allowedValues,
  allowedLabels,
  type,
  name,
  value,
  label,
  required,
  error,
  touched,
  handleChange,
  xs,
  lg,
}: CompleteDateInputProps) {
  const formControl =
    type === "select" && allowedValues ? (
      <Form.Select
        aria-label={label}
        name={name}
        value={value}
        onChange={handleChange}
        isInvalid={touched && !!error}
      >
        <option value="">--</option>
        {allowedValues.map((val, index) => {
          return (
            <option key={index} value={val}>
              {(allowedLabels ?? allowedValues)?.[index]}
            </option>
          );
        })}
      </Form.Select>
    ) : (
      <Form.Control
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        isInvalid={touched && !!error}
      />
    );

  return (
    <Form.Group as={Col} lg={lg} xs={xs}>
      <Form.Label
        className="mb-2 text-uppercase small fw-bold"
        aria-label={label}
      >
        {label} {required && <span className="text-danger">*</span>}
      </Form.Label>
      <InputGroup hasValidation>
        {formControl}
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}

export default AnnoEditModal;
