import { CreateUnitInterface, useUnitMutations } from "../../../api/units.api";
import { useEffect, useRef, useState } from "react";
import { AttachmentFile } from "../../../types/custom";
import * as yup from "yup";
import { extractFileSize, getFileWithUrl } from "../../utils/HelperFunctions";
import { Formik } from "formik";
import {
  Col,
  Form,
  Row,
  Image,
  Button,
  InputGroup,
  Card,
} from "react-bootstrap";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import { Link, useHistory, useParams } from "react-router-dom";
import imgPlaceHolder from "../../../assets/img_placeholder.jpg";
import IconButton from "../../utils/IconButton";
import { Upload, Add } from "@mui/icons-material";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import EmptyMessage from "../../utils/EmptyMessage";
import { useCourseById } from "../../../api/courses.api";
import ErrorPage from "./../../utils/ErrorPage";
import TeachingGuideModal from "./TeachingGuideModal";
import {
  useDownloadableMutations,
  CreateDownloadableInterface,
} from "./../../../api/units.api";
import DownloadableItem from "./Downloadables/DownloadableItem";
import DownloadablesModal from "./Downloadables/DownloadablesModal";

export interface NewUnitDownloadableInterface
  extends Omit<CreateDownloadableInterface, "unit_id"> {
  dnld_id: number;
}

export default function UnitCreate() {
  const { courseId } = useParams<{ courseId: string | undefined }>();
  const { data: course, error: courseError } = useCourseById(
    parseInt(courseId ?? "0"),
    courseId !== undefined
  );

  const [showUploadModal, setShowUploadModal] = useState(false);

  const history = useHistory();
  const {
    mutate: createUnit,
    isLoading,
    error,
  } = useUnitMutations("CREATE_UNIT");

  const {
    mutate: createDownloadable,
    isLoading: downloadableLoading,
    error: downloadableError,
  } = useDownloadableMutations("CREATE_DOWNLOADABLE");

  const [downloadables, setDownloadables] = useState<
    NewUnitDownloadableInterface[]
  >([]);

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  useEffect(() => {
    setFile(undefined);
  }, []);

  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  interface CreateUnitForm
    extends Omit<
      CreateUnitInterface,
      "unit_pic" | "cour_id" | "unit_guidedoc" | "unit_guidename"
    > {
    unit_guidedoc?: Blob;
    unit_guidename?: string;
  }

  const initialValues: CreateUnitForm = {
    unit_name: "",
    unit_videourl: undefined,

    unit_guidedoc: undefined,
    unit_guidename: undefined,
  };

  const validationSchema = yup.object().shape({
    unit_name: yup
      .string()
      .required("Unit name is required")
      .max(80, ({ max }) => `Unit name cannot be more than ${max} characters`),
    unit_guidedoc: yup.string(),
    unit_guidename: yup
      .string()
      .max(60, "Name must be less than 60 characters"),
    unit_videourl: yup
      .string()
      .max(128, "URL must be less than 128 characters")
      .matches(
        /(?:https?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9_-]+)/,
        "Enter a valid YouTube URL"
      ),
  });

  const onSubmit = (values: CreateUnitForm) => {
    createUnit(
      {
        cour_id: parseInt(courseId ?? "0"),
        unit_name: values.unit_name,
        unit_pic: file?.file,
        unit_guidedoc: values.unit_guidedoc ?? undefined,
        unit_guidename: values.unit_guidename ?? undefined,
        unit_videourl: values.unit_videourl ?? undefined,
      },
      {
        onSuccess: async (data) => {
          if (Array.isArray(downloadables) && downloadables.length !== 0) {
            await Promise.all(
              downloadables.map(async (downloadable) => {
                return createDownloadable({
                  unit_id: data.response.data,
                  ...downloadable,
                });
              })
            ).then(() => {
              history.push(
                createContentRoute(ContentRoutes.UNITS, {
                  courseId: course?.cour_id ?? "0",
                })
              );
            });
          } else {
            history.push(
              createContentRoute(ContentRoutes.UNITS, {
                courseId: course?.cour_id ?? "0",
              })
            );
          }
        },
      }
    );
  };

  if (courseError) return <ErrorPage message={courseError?.message} />;

  if (!course)
    return (
      <EmptyMessage message="Course not found. Unable to create unit in an unknown course." />
    );

  return (
    <div className="p-5">
      <h3
        className="text-uppercase mb-5"
        style={{ color: "var(--bs-gray-600)" }}
      >
        <Link
          to={createContentRoute(ContentRoutes.UNITS, {
            courseId: parseInt(courseId ?? "0"),
          })}
          className="linkUnderline"
        >
          {course?.cour_name}
        </Link>
        <span className="text-black"> &#xBB; </span>
        Creating a unit 加新单元
      </h3>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col
                md={3}
                className="d-flex flex-column align-items-center justify-content-start"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      await getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    } // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                ></input>
                {file !== undefined ? (
                  <Image
                    src={file.url}
                    title={
                      file.file.name + " " + extractFileSize(file.file.size)
                    }
                    className={
                      "shadow-none rounded-circle border border-secondary "
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Image
                    src={imgPlaceHolder}
                    className={
                      "shadow-none rounded-circle border border-secondary"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                    title="Image placeholder"
                  />
                )}
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black mt-3 fw-bold"
                  title="Upload a unit photo"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                  disabled={isLoading || isSubmitting}
                />
              </Col>
              <Col>
                <Row>
                  <Col>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Unit Name 单元名
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <BootstrapInput
                          id="unit_name"
                          required
                          placeholder="中学系列S1A"
                          aria-label="Unit Name"
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Group>
                    </Row>
                    <Row className="mb-2">
                      <Form.Group as={Col}>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Teaching Guide 教学手册
                        </Form.Label>
                        <InputGroup>
                          <Form.Control
                            placeholder="Select a file"
                            readOnly
                            value={
                              values?.unit_guidename
                                ? values.unit_guidename.toString()
                                : ""
                            }
                          />
                          <Button
                            onClick={() => setShowUploadModal(true)}
                            title={"Upload a guide 上载教学指引"}
                            disabled={isLoading || isSubmitting}
                          >
                            <Upload />
                          </Button>
                        </InputGroup>
                      </Form.Group>
                    </Row>
                    <Row className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Training Video 培训视频
                        </Form.Label>
                        <BootstrapInput
                          id="unit_videourl"
                          placeholder="https://www.youtube.com/watch?v=example"
                          aria-label="Training Video"
                          disabled={isLoading || isSubmitting}
                        />
                      </Form.Group>
                    </Row>
                  </Col>
                  <Col md={4} className="mb-4">
                    <Row>
                      <Col>
                        <Form.Label className="mb-0 text-uppercase small fw-bold">
                          Downloadables 可下载
                        </Form.Label>
                        <Card
                          className="shadow-sm p-3 mb-2"
                          style={{ height: "12rem", overflowY: "auto" }}
                        >
                          {downloadables.length !== 0 ? (
                            Array.isArray(downloadables) ? (
                              downloadables.map((downloadable, index) => (
                                <DownloadableItem
                                  downloadable={downloadable}
                                  key={index}
                                  editDownloadable={(downloadable) => {
                                    const newDownloadableArray =
                                      downloadables.map((d) =>
                                        d.dnld_id === downloadable.dnld_id
                                          ? {
                                              ...downloadable,
                                            }
                                          : d
                                      );

                                    setDownloadables([...newDownloadableArray]);
                                  }}
                                  removeDownloadable={() => {
                                    const newDownloadableArray =
                                      downloadables.filter(
                                        (d) =>
                                          d.dnld_id !== downloadable.dnld_id
                                      );

                                    setDownloadables([...newDownloadableArray]);
                                  }}
                                />
                              ))
                            ) : (
                              <DownloadableItem
                                downloadable={downloadables}
                                editDownloadable={(downloadable) => {
                                  setDownloadables([downloadable]);
                                }}
                                removeDownloadable={() => {
                                  setDownloadables([]);
                                }}
                              />
                            )
                          ) : (
                            <p className="text-muted">No downloadables yet.</p>
                          )}
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          className="text-uppercase me-2"
                          title="Add File 加文件"
                          variant="outline-primary"
                          disabled={
                            isLoading || isSubmitting || downloadableLoading
                          }
                          style={{ float: "right" }}
                          onClick={() => {
                            setShowDownloadModal(true);
                          }}
                        >
                          <Add fontSize="small" /> Add File 加文件
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center">
                      <Button
                        className="text-uppercase me-2"
                        title="Cancel"
                        variant="outline-primary"
                        disabled={isLoading || isSubmitting}
                        onClick={() =>
                          history.push(
                            createContentRoute(ContentRoutes.UNITS, {
                              courseId: course.cour_id,
                            })
                          )
                        }
                      >
                        Cancel 取消
                      </Button>
                      <Button
                        className="text-uppercase"
                        title="Save"
                        type="submit"
                        disabled={isLoading || isSubmitting}
                      >
                        Save 保存
                      </Button>
                    </div>
                  </div>
                </Row>
                <Row className="text-danger">
                  {error?.message || downloadableError?.message}
                </Row>
              </Col>
            </Row>
            <TeachingGuideModal
              show={showUploadModal}
              onClose={() => setShowUploadModal(false)}
              setGuideDocValue={(file) => {
                setFieldValue("unit_guidedoc", file, true);
              }}
              setGuideNameValue={(name) => {
                setFieldValue("unit_guidename", name, true);
              }}
            />
            <DownloadablesModal
              show={showDownloadModal}
              onClose={() => setShowDownloadModal(false)}
              addDownloadable={(downloadable) => {
                const newDownloadableArray = downloadables;
                newDownloadableArray.push(downloadable);
                setDownloadables(newDownloadableArray);
              }}
              index={
                // If array is empty, index would be 0
                // Else, get the last element's dnld_id, and add 1 to it
                downloadables.length === 0
                  ? 0
                  : downloadables[downloadables.length - 1].dnld_id + 1
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
