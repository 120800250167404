import { CloseRounded, School, Upload } from "@mui/icons-material";
import { useMemo, useRef, useState, useEffect } from "react";
import * as EBIC from "@ebic-bbrm/types";
import {
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
} from "react-bootstrap";
import IconButton from "../../utils/IconButton";
import * as yup from "yup";
import {
  useClassesMutations,
  useClassMembersByClass,
  useClassesById,
} from "../../../api/classes.api";
import { Formik } from "formik";
import ClassAvatar from "./ClassAvatar";
import {
  BootstrapFormLabel,
  BootstrapInput,
} from "../../utils/FormikBootstrapInputs";
import ClassMembersTable from "./ClassMembersTable";
import classNames from "classnames";
import { Form as BootstrapForm } from "react-bootstrap";
import CustomToggle from "../../utils/CustomToggle";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { SearchUserOptions, useUserSearch } from "../../../api/user.api";
import { BaseModalProps } from "../../../hooks/useModal";
import { FetchCoursesOptions, useCourses } from "../../../api/courses.api";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useOrgProfileById } from "../../../api/organization.api";

interface classMemberForm {
  cmem_role: EBIC.Classes.ClassMemberKey;
  user_id: number;
}

interface EditClassModalProps extends BaseModalProps {
  classId: number | null;
  orgId: number;
  classMembers: EBIC.Classes.ClassMembers;
}

interface classEditForm {
  class_name: string;
  class_desc: string | null;
  class_status: number | null;
}

function ClassEditModal({
  show,
  onClose,
  classId,
  orgId,
  classMembers,
}: EditClassModalProps) {
  const { data: selectedClass } = useClassesById(classId ?? 0);
  const { data: getClassMembers } = useClassMembersByClass(
    classId ?? 0,
    classId !== -1
  );
  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const { data: organization } = useOrgProfileById(orgId);

  //course selection
  const params = useMemo(() => {
    const params: FetchCoursesOptions = {
      published: true,
      orderOrgId: orgId,
      orderExpiry: true,
    };

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.

    return params;
  }, [orgId]);

  const { data: course } = useCourses(params);
  const courses = course?.pages.find((p) => p.page === 1)?.result;

  //select courses dropdown
  const [selectedCourse, setSelectedCourse] = useState(0);

  useEffect(() => {
    if (courses && courses.length !== 0)
      setSelectedCourse(selectedClass?.cour_id ?? 0);
  }, [courses, selectedClass?.cour_id]);

  const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(Number(e.target.value));
  };

  //select user options
  const userParams = useMemo(() => {
    const params: SearchUserOptions = { member_of_organization: orgId };

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.

    return params;
  }, [orgId]);

  const { data: _users } = useUserSearch(userParams);
  const users = _users?.pages.find((p) => p.page === 1)?.result;

  //select users dropdown
  const [selectedUser, setSelectedUser] = useState<number>(0);

  useEffect(() => {
    if (users && users.length !== 0) setSelectedUser(users[0].user_id);
  }, [_users, users]);

  const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedUser(Number(e.target.value));
  };

  const schema = yup.object().shape({
    class_name: yup
      .string()
      .required("Please enter a class name")
      .max(20, "Maximum 80 characters"),
    class_desc: yup.string().max(200, "Maximum 200 characters"),
  });

  const classMembersDetails = useMemo(() => {
    if (!getClassMembers) return [];
    let membersArray = [];
    if (Array.isArray(getClassMembers)) membersArray = getClassMembers;
    else membersArray = [getClassMembers];

    return membersArray;
    // eslint-disable-next-line
  }, [getClassMembers, classMembers]);

  const { mutate: editClass, isLoading: isLoadingDetails } =
    useClassesMutations("EDIT");

  const initialEditValues: classEditForm = {
    class_name: selectedClass?.class_name ?? "",
    class_desc: selectedClass?.class_desc ?? "",
    class_status: 1,
  };

  const handleEdit = async (values: classEditForm) => {
    const editClassDetails = (imageUpdated: boolean) => {
      editClass(
        {
          org_id: orgId,
          class_id: classId ?? 0,
          cour_id: selectedCourse,
          ...values,
        },
        {
          onSuccess: () => {
            onClose();
            addLog({
              alog_type: "I",
              alog_module: "S",
              alog_action: "E",
              alog_accessfrom: 0,
              alog_modulename: values.class_name,
              alog_details: "Edit Class",
              org_name: organization?.org_name ?? null,
            });
          },
          onError: () => {
            addLog({
              alog_type: "E",
              alog_module: "S",
              alog_action: "E",
              alog_accessfrom: 0,
              alog_modulename: values.class_name,
              alog_details: "Edit Class Failed.",
              org_name: organization?.org_name ?? null,
            });
          },
        }
      );
    };
    if (!image) {
      editClassDetails(false);
    } else {
      editClassPhoto(
        { image: image.file, class_id: classId ?? 0, org_id: orgId },
        {
          onSuccess: () => editClassDetails(true),
        }
      );
    }
  };

  //Image states
  const { mutate: editClassPhoto, isLoading: isLoadingPhoto } =
    useClassesMutations("EDIT_PHOTO");
  const [isReadingPhoto, setIsReadingPhoto] = useState(false);

  // eslint-disable-next-line
  const [imageReadError, setImageReadError] = useState<string>();

  const [image, setImage] = useState<{ file: File; url: string }>();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImage(undefined);
  }, [show]);

  const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return setImageReadError("Couldn't read image");
    }
    if (!file.type.startsWith("image")) {
      return setImageReadError("Invalid image file");
    }

    setIsReadingPhoto(true);

    const reader = new FileReader();
    reader.onload = (e) => {
      setIsReadingPhoto(false);

      if (e.target?.result) {
        setImage({
          file,
          url: e.target.result as string,
        });
      }
    };
    reader.onerror = (err) => {
      console.error(err);
      setIsReadingPhoto(false);
    };
    reader.readAsDataURL(file);
    /**
     * Return false in event handler, don't change the value of underlying input
     */
    return false;
  };

  //adding classmembers
  const {
    mutate: addMember,
    isLoading: isLoadingMembers,
    error,
  } = useClassesMutations("ADD_MEMBER");

  const initialValues: classMemberForm = {
    cmem_role: "S",
    user_id: selectedUser ?? 0,
  };

  const onSubmit = (values: classMemberForm, { resetForm }: any) => {
    const form = {
      class_id: classId ?? 0,
      ...values,
    };

    addMember(form, {
      onSuccess: () => {
        addLog({
          alog_type: "I",
          alog_module: "S",
          alog_action: "A",
          alog_accessfrom: 0,
          alog_modulename: selectedClass?.class_name ?? "",
          alog_details: "Add Class members",
          org_name: organization?.org_name ?? null,
        });
        resetForm();
      },
      onError: () => {
        addLog({
          alog_type: "E",
          alog_module: "S",
          alog_action: "A",
          alog_accessfrom: 0,
          alog_modulename: selectedClass?.class_name ?? "",
          alog_details: "Add Class members Failed.",
          org_name: organization?.org_name ?? null,
        });
        alert(
          error?.message ?? "This user is already been added to this class."
        );
        resetForm();
      },
    });
  };

  const isLoading =
    isLoadingPhoto || isLoadingDetails || isReadingPhoto || isLoadingMembers;

  if (!courses || !users) return <BootstrapSpinner />;

  return (
    <Modal
      size="lg"
      show={show}
      centered
      onHide={onClose}
      backdrop={isLoading ? "static" : undefined}
      keyboard={!isLoading}
    >
      <Formik
        initialValues={initialEditValues}
        onSubmit={handleEdit}
        validationSchema={schema}
        enableReinitialize={true}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header className="p-3">
              <Modal.Title className="fw-normal">
                Edit Class Details 编辑班级详情
              </Modal.Title>
              <IconButton
                Icon={CloseRounded}
                title="Close"
                className="ms-auto"
                disabled={isLoading}
                onClick={onClose}
              />
              <input
                hidden
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={handleImageSelect}
              ></input>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg="3">
                  <div className="mt-4 d-flex flex-column align-items-center">
                    <ClassAvatar
                      width={150}
                      height={150}
                      classes={selectedClass}
                      style={{ objectFit: "cover" }}
                      src={image?.url}
                    />
                    <IconButton
                      Icon={Upload}
                      transparent
                      label="UPLOAD 上传"
                      onClick={() => inputRef.current?.click()}
                      className="text-black mt-2 fw-bold"
                      style={{ left: 0, right: 0 }}
                    />
                  </div>
                </Col>
                <Col lg="9">
                  <div className="d-flex flex-grow-1">
                    <div className="mb-2 d-flex flex-column flex-grow-1">
                      <BootstrapInput
                        id="class_name"
                        required
                        label="Class Name 班级名称"
                        placeholder="Class Name"
                        aria-label="Class Name"
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                  <div>
                    <BootstrapInput
                      id="class_desc"
                      as="textarea"
                      rows="3"
                      required={false}
                      label="Class Description 描述"
                      placeholder="Class Description"
                      aria-label="Class Description"
                      disabled={isLoading}
                      className="col-md-4"
                    />
                  </div>
                  <div className="mt-1 mb-3">
                    <Form.Group>
                      <Form.Label className="fw-bold text-uppercase text-secondary small undefined form-label">
                        Select Course 选择课程
                      </Form.Label>
                      <InputGroup>
                        <Form.Select
                          value={selectedCourse}
                          onChange={handleCourseChange}
                        >
                          {courses.map((c) => (
                            <option key={c.cour_id ?? 0} value={c.cour_id ?? 0}>
                              {c.cour_name}
                            </option>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <div className="d-flex justify-content-center align-content-center mb-2">
                <School /> &nbsp; - Teacher &nbsp;
              </div>
              <div>
                <ClassMembersTable
                  classMembers={classMembers}
                  classMembersDetails={classMembersDetails}
                  isLoading={isLoading}
                  // isTeacher={isOrgOwner}
                  classId={classId ?? 0}
                  orgId={orgId}
                />
              </div>
              <br />
              {selectedCourse !== 0 ? (
                <div>
                  <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {({
                      submitForm,
                      setFieldValue,
                      values,
                      dirty,
                      isSubmitting,
                    }) => (
                      <Form noValidate>
                        <Container>
                          <Row className="g-3">
                            <Col>
                              <Form.Group>
                                <Form.Label className="fw-bold text-uppercase small undefined form-label">
                                  Select a user 选择用户
                                  <span className="text-danger">*</span>
                                </Form.Label>

                                <InputGroup>
                                  <Form.Select
                                    value={selectedUser}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLSelectElement>
                                    ) => {
                                      handleUserChange(e);
                                      setFieldValue(
                                        "user_id",
                                        Number(e.target.value)
                                      );
                                    }}
                                  >
                                    {users.map((u) => (
                                      <option
                                        key={u.user_id ?? 0}
                                        value={u.user_id ?? 0}
                                      >
                                        {u.user_firstname +
                                          " " +
                                          u.user_lastname}
                                      </option>
                                    ))}
                                  </Form.Select>
                                </InputGroup>
                              </Form.Group>
                            </Col>
                            <Form.Group as={Col} lg>
                              <BootstrapFormLabel htmlFor="cmem_role">
                                Role 身份
                              </BootstrapFormLabel>
                              <ClassMemberRoleDropdown
                                values={values}
                                isSubmitting={isSubmitting}
                                setFieldValue={setFieldValue}
                              />
                            </Form.Group>
                            <Col xs={3} className="mt-auto">
                              <IconButton
                                disabled={isLoading}
                                onClick={() => submitForm()}
                                label="ADD 新增"
                                className="px-4"
                              />
                            </Col>
                          </Row>
                        </Container>
                      </Form>
                    )}
                  </Formik>
                </div>
              ) : (
                <div>Please select a course before adding class members.</div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <IconButton
                iconHtmlColor="var(--primary)"
                disabled={isLoading}
                label="CANCEL 取消"
                transparent
                className="text-primary border-primary px-4 me-3"
                onClick={() => onClose()}
              />
              <IconButton
                disabled={isLoading}
                type="submit"
                label="SAVE 保存"
                className="px-4"
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
      <div
        style={{
          background: "#000000",
          opacity: 0.5,
        }}
      ></div>
    </Modal>
  );
}

interface classMemberRoleDropdownInterface {
  values: classMemberForm;
  isSubmitting: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const classMemberAllowedKeys = Object.keys(
  EBIC.Classes.ClassMemberRole
) as EBIC.Classes.ClassMemberKey[];
export const classMemberAllowedLabels = Object.values(
  EBIC.Classes.ClassMemberRole
);

const ClassMemberRoleDropdown = ({
  values,
  isSubmitting,
  setFieldValue,
}: classMemberRoleDropdownInterface) => {
  return (
    <BootstrapForm.Group as={Col} lg>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix=" "
          as={CustomToggle}
          className={classNames("w-100", { "disabled-with-bg": isSubmitting })}
        >
          {values.cmem_role
            ? EBIC.Classes.ClassMemberRole[
                values.cmem_role as EBIC.Classes.ClassMemberKey
              ]
            : "-"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {classMemberAllowedKeys.map((i, index) => (
            <Dropdown.Item
              onClick={() =>
                setFieldValue("cmem_role", classMemberAllowedKeys[index])
              }
              key={i}
              value={classMemberAllowedKeys[index]}
            >
              {classMemberAllowedLabels[index]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </BootstrapForm.Group>
  );
};

export default ClassEditModal;
