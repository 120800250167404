import * as EBIC from "@ebic-bbrm/types";
import { HTMLAttributes } from "react";

interface AnnoContentProps extends HTMLAttributes<HTMLDivElement> {
  announcements: EBIC.Announcements;
}

const AnnouncementContent = ({ announcements }: AnnoContentProps) => {
  return (
    <>
      <b className="text-uppercase">{announcements.anno_subject}</b>
      <p style={{ whiteSpace: "pre-line" }}>{announcements.anno_text}</p>
    </>
  );
};

export default AnnouncementContent;
