import useAppTitle from "../../../hooks/useAppTitle";
import AllOrgList from "./AllOrgList";

const AllOrganizations = () => {
    useAppTitle("Institutions");

    return (
        <>
            <div className="p-5 pb-2">
                <AllOrgList />
            </div>
        </>

    );
}

export default AllOrganizations;