import { Point } from "./common";

export namespace Organization {
    export type ProfileStatusKey = keyof typeof ProfileStatus;
    export enum ProfileStatus {
        'PENDING' = 0,
        'ACTIVE' = 1,
        'SUSPENDED' = 2,
        'TERMINATED' = 3
    }

    export type TypeKey = keyof typeof Type;
    export enum Type {
        C = 'Church 教会',
        O = "Institution 机构"
    }

    export interface Profile {
        org_id: number;
        org_name: string;
        org_type: TypeKey;
        org_desc: string | null;
        org_pic: string | null;
        org_address: string;
        org_coord: Point | null;
        org_unitno: string;
        org_country: string | null;
        org_state: string | null;
        org_city: string | null;
        org_website: string | null;
        org_email: string;
        org_contact: string;
        org_contactno: string;

        org_signupdate: string;
        org_status: number | null;
        org_size: number;

    }


}

