import { useState, useRef, useMemo, useEffect } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import { useClassesMutations } from "../../../api/classes.api";
import { CloseRounded, Upload } from "@mui/icons-material";
import IconButton from "../../utils/IconButton";
import * as yup from "yup";
import ClassAvatar from "./ClassAvatar";
import { Formik } from "formik";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { useCourses, FetchCoursesOptions } from "../../../api/courses.api";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useOrgProfileById } from "../../../api/organization.api";

interface OrgAddClassModalProps {
  show: boolean;
  onClose: () => void;

  setClassId: (classId: number | null) => void;

  orgId: number;
}

interface AddClassForm {
  class_name: string;
  class_desc: string | null;
  class_status: number | null;
  cour_id: number;
}

function OrgClassAddModal({
  show,
  onClose,
  setClassId,
  orgId,
}: OrgAddClassModalProps) {
  const { mutate: addClass, isLoading: isLoadingDetails } =
    useClassesMutations("CREATE");

  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: organization } = useOrgProfileById(orgId);

  const params = useMemo(() => {
    const params: FetchCoursesOptions = {
      published: true,
      orderOrgId: orgId,
      orderExpiry: true,
    };

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.

    return params;
  }, [orgId]);

  const { data: course } = useCourses(params);
  const courses = course?.pages.find((p) => p.page === 1)?.result;

  //select courses dropdown
  const [selectedCourse, setSelectedCourse] = useState<number>(0);

  useEffect(() => {
    if (courses && courses.length !== 0) setSelectedCourse(courses[0].cour_id);
  }, [courses]);

  const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(Number(e.target.value));
  };

  const [image, setImage] = useState<{ file: File; url: string }>();

  // const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);
  // eslint-disable-next-line
  const [isReadingImage, setIsReadingImage] = useState(false);
  // eslint-disable-next-line
  const [imageReadError, setImageReadError] = useState<string>();

  const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return setImageReadError("Couldn't read image");
    }
    if (!file.type.startsWith("image")) {
      return setImageReadError("Invalid image file");
    }

    setIsReadingImage(true);

    readImage(file);

    /**
     * Return false in event handler, don't change the value of underlying input
     */
    return false;
  };

  const readImage = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setIsReadingImage(false);

      if (e.target?.result) {
        setImage({
          file,
          url: e.target.result as string,
        });
      }
    };
    reader.onerror = (err) => {
      console.error(err);
      setIsReadingImage(false);
    };
    reader.readAsDataURL(file);
  };

  const initialValues: AddClassForm = {
    class_name: "",
    class_desc: "",
    class_status: 1,
    cour_id: selectedCourse ?? 0,
  };

  const validationSchema = yup.object().shape({
    class_name: yup
      .string()
      .required("Please enter a class name")
      .max(80, "Maximum 80 characters"),
    class_desc: yup
      .string()
      .max(200, "Maximum 200 characters")
      .default(null)
      .nullable(),
  });

  const handleAdd = async (values: AddClassForm, { resetForm }: any) => {
    addClass(
      {
        ...values,
        org_id: orgId,
        cour_id: selectedCourse ?? 0,
        class_pic: image?.file ?? null,
      },
      {
        onSuccess: (data) => {
          addLog({
            alog_type: "I",
            alog_module: "S",
            alog_action: "A",
            alog_accessfrom: 0,
            alog_modulename: values.class_name,
            alog_details: "Add Class",
            org_name: organization?.org_name ?? null,
          });
          resetForm();
          setClassId(data.response.data);
          onClose();
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "S",
            alog_action: "A",
            alog_accessfrom: 0,
            alog_modulename: values.class_name,
            alog_details: "Add Class Failed.",
            org_name: organization?.org_name ?? null,
          });
        },
      }
    );
  };

  const isLoading = isLoadingDetails;

  if (!course || !courses) return <BootstrapSpinner />;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleAdd}
      >
        {({
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldError,
          setFieldValue,
        }) => (
          <Modal
            show={show}
            centered
            onHide={onClose}
            backdrop={isLoading ? "static" : undefined}
            keyboard={!isLoading}
          >
            <Modal.Header className="p-3">
              <Modal.Title className="fw-normal">
                Add new Class 添加班级
              </Modal.Title>
              <IconButton
                Icon={CloseRounded}
                title="Close"
                className="ms-auto"
                disabled={isLoading}
                onClick={onClose}
              />
              <input
                hidden
                ref={inputRef}
                type="file"
                accept="image/*"
                onChange={handleImageSelect}
              ></input>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
              <Modal.Body className="small px-4 py-3">
                <div className="mb-3 d-flex align-items-center">
                  <ClassAvatar
                    width={100}
                    height={100}
                    style={{ objectFit: "cover" }}
                    src={image?.url}
                  />
                  <IconButton
                    Icon={Upload}
                    transparent
                    className="text-black ms-3 fw-bold"
                    label="UPLOAD 上传"
                    onClick={() => inputRef.current?.click()}
                    // style={{ marginLeft: "5%" }}
                  />
                </div>
                <div className="d-flex flex-grow-1">
                  <div className="mb-2 d-flex flex-column flex-grow-1">
                    <BootstrapInput
                      id="class_name"
                      required
                      label=" Class Name 班级名称"
                      placeholder="Class Name"
                      aria-label="Class Name"
                      disabled={isLoading}
                    />
                  </div>
                </div>
                <div>
                  <BootstrapInput
                    id="class_desc"
                    as="textarea"
                    rows="2"
                    required={false}
                    label="Class Description 描述"
                    placeholder="Class Description"
                    aria-label="Class Description"
                    disabled={isLoading}
                    className="col-xs-4"
                  />
                </div>
                <div className="mt-4">
                  <Form.Group>
                    <Form.Label className="fw-bold text-uppercase text-secondary small undefined form-label">
                      Select Course 选择课程
                    </Form.Label>
                    <InputGroup>
                      <Form.Select
                        value={selectedCourse}
                        onChange={handleCourseChange}
                      >
                        {courses.map((c) => (
                          <option key={c.cour_id} value={c.cour_id}>
                            {c.cour_name}
                          </option>
                        ))}
                      </Form.Select>
                    </InputGroup>
                  </Form.Group>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <IconButton
                  iconHtmlColor="var(--primary)"
                  disabled={isLoading}
                  label="CANCEL 取消"
                  transparent
                  className="text-primary border-primary px-4 me-3"
                  onClick={() => onClose()}
                />
                <IconButton
                  disabled={isLoading}
                  type="submit"
                  label="SAVE 保存"
                  className="px-4"
                  // onClick={() => setShowEditModal()}
                />
              </Modal.Footer>
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
}

export default OrgClassAddModal;
