import { useState, useMemo, useCallback, useEffect } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useClassesById, useClassMembersByClass } from "../../api/classes.api";
import { useUnits } from "../../api/units.api";
import { ClassMemberItem } from "./ClassMemberItem";
import * as EBIC from "@ebic-bbrm/types";
import { useEbicUser } from "../../api/user.api";
import useQuery from "../../hooks/useQuery";
import ClassesUnitView from "./ClassesUnitView";
import BootstrapSpinner from "../utils/BootstrapSpinner";

interface ClassesSearchProps {
  classes: EBIC.Classes.ClassInfo;
}

export default function ClassesSearch({ classes }: ClassesSearchProps) {
  const { data: dbUser } = useEbicUser();
  const search = useQuery();

  //get details of selected class
  const [selectedClass, setSelectedClass] = useState<number>(0);

  const user_classId = dbUser?.classId?.split(",").map(Number);
  const user_className = dbUser?.class_name?.split(",").map(String);

  //get selected class_id, if class not selected, get current user in charge class
  const classId = useMemo(() => {
    if (selectedClass && selectedClass) return selectedClass;
    // return 0
    else if (dbUser) return user_classId ? user_classId[0] : 0;
    else return 0;
  }, [dbUser, selectedClass, user_classId]);

  const handleClassChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedClass(Number(e.target.value));
  };

  const { data: classMembers, isLoading: isMemberLoading } =
    useClassMembersByClass(classId ?? 0);

  const { data: selectedClasses } = useClassesById(classId ?? 0);

  const {
    data: allUnits,
    isLoading: isUnitLoading,
    fetchNextPage,
  } = useUnits(
    {
      cour_id: selectedClasses ? selectedClasses?.cour_id ?? 0 : 0,
      published: true,
    },
    selectedClasses?.cour_id !== undefined
  );

  const getCurrentPage = useCallback(
    (page: number) => {
      return allUnits?.pages.find((p) => p.page === page);
    },
    [allUnits]
  );

  const tab = useQuery().get("t");
  const page = parseInt(search.get("page") ?? "1");
  useEffect(() => {
    if (!getCurrentPage(page) && tab === "c") {
      //check if next page exists
      fetchNextPage({ pageParam: page });
    }
  }, [getCurrentPage, page, fetchNextPage, tab]);

  const _units = getCurrentPage(page)?.result;
  const unitItems = useMemo(() => {
    if (!_units) return [];

    return _units.map((unit) => {
      return {
        id: unit.unit_seqno,
        ...unit,
      };
    });
  }, [_units]);

  const isLoading = isUnitLoading || isMemberLoading;

  if (isLoading) return <BootstrapSpinner />;

  if (!classMembers || !user_classId || !user_className || !allUnits)
    return <>The System is unable to locate any classes for you.</>;

  return (
    <>
      <Row>
        <Col xs={3}>
          <Form.Select
            className="mx-2"
            style={{ width: "20rem" }}
            value={selectedClass}
            onChange={handleClassChange}
          >
            {user_classId.map((id, index) => (
              <option key={index} value={id}>
                {user_className[index]}
              </option>
            ))}
          </Form.Select>

          <Card className="mt-3 pb-3">
            {classMembers.map((classMembers) => (
              <div>
                <ClassMemberItem
                  classMember={classMembers}
                  key={classMembers.class_id}
                />
              </div>
            ))}
          </Card>
        </Col>
        <Col xs={9}>
          <Card>
            <div className="m-3">
              {unitItems.map((u) => (
                <ClassesUnitView units={u} key={u.id} />
              ))}
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}
