import { FormControl, InputGroup } from "react-bootstrap";
import IconButton from "../utils/IconButton";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import styles from "./ui.module.scss";

export interface SearchBarInterface {
    query: string;
    handleEnterPressed: () => void;
    setquery: (value: React.SetStateAction<string>) => void;
}

export default function SearchBar({ query, handleEnterPressed, setquery } : SearchBarInterface) {
    return (
        <InputGroup
            className='d-flex justify-content-end w-auto'
        >
            <div className={styles.searchContainer}>
                <FormControl
                    aria-label="Text input"
                    placeholder='What are you looking for?'
                    style={{minWidth: '18rem'}}
                    className="shadow"
                    value={query}
                    onKeyPress={(t) => t.key === 'Enter' ? handleEnterPressed() : null}
                    onChange={(e) => setquery(e.target.value)}
                />

                <IconButton
                    transparent
                    border={false}
                    Icon={SearchIcon}
                    iconHtmlColor="var(--black)"
                    iconStyles={{ width: 17, height: 17, overflow: 'visible' }}
                    onClick={() => handleEnterPressed()}
                />
            </div>
        </InputGroup>
    )
}