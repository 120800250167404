import { useMemo, useState } from "react";
import IconButton from "../../utils/IconButton";
import { Col } from "react-bootstrap";
import { AddCircle } from "@mui/icons-material";
import { useProducts } from "../../../api/products.api";
import useAppTitle from "../../../hooks/useAppTitle";
import { ProductsTable } from "./ProductsTable";
import ProductsAddModal from "./ProductsAddModal";


export const Products = () => {

    useAppTitle("Products")
    const { data: product, isLoading } = useProducts();

    const [showModal, setShowModal] = useState(false);


    const productDetails = useMemo(() => {
        if (!product) return [];
        let productArray = [];
        if (Array.isArray(product)) productArray = product;
        else productArray = [product];

        return productArray;
        // eslint-disable-next-line
    }, [product]);

    return (
        <>
            <div className="p-5 pb-2">

                <div className="d-flex justify-content-end align-items-end h-25 me-3 mb-1 ms-4">
                    <Col xs={12} />
                    <Col>
                        <IconButton
                            Icon={AddCircle}
                            title="Add A Class"
                            onClick={() => setShowModal(true)}
                            style={{
                                backgroundColor: "transparent",
                                color: "#aa8067",
                            }}
                        />
                    </Col>

                </div>

                <ProductsTable
                    Products={productDetails}
                    isLoading={isLoading}
                />
            </div>
            <ProductsAddModal
                show={showModal}
                onClose={() => setShowModal(false)}
            />

        </>
    )

}