import { Col, Row } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import EbicListCardAdd from "../../utils/EbicListAdd";
import { Pagination } from "@mui/material";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import { FetchUnitsOptions, useUnitMutations } from "../../../api/units.api";
import { useUnits } from "./../../../api/units.api";
import ErrorPage from "../../utils/ErrorPage";
import EmptyMessage from "./../../utils/EmptyMessage";
import { useCourseById } from "../../../api/courses.api";
import UnitsItem from "./UnitsItem";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  DragOverlay,
} from "@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import UnitsDraggableItem from "./UnitsDraggableItem";
import {
  PublishedWithChanges,
  StarRounded,
  Unpublished,
} from "@mui/icons-material";
import ToggleButtonGroup from "./../../ui/ToggleButtonGroup";
import UnitNormalItem from "./UnitsNormalItem";

export default function UnitsList() {
  const history = useHistory();
  const search = useQuery();
  const { courseId } = useParams<{ courseId: string | undefined }>();

  // Tabs
  const filterItems = [
    { label: "All 全部" as const, Icon: StarRounded },
    { label: "Published 已发布" as const, Icon: PublishedWithChanges },
    { label: "Drafts 草稿" as const, Icon: Unpublished },
  ];
  const [activeTab, setActiveTab] =
    useState<typeof filterItems[number]["label"]>("All 全部");

  const params = useMemo(() => {
    const params: FetchUnitsOptions = {};
    params.cour_id = parseInt(courseId ?? "0");

    if (activeTab === "Published 已发布") params.published = true;
    else if (activeTab === "Drafts 草稿") params.draft = true;

    return params;
  }, [courseId, activeTab]);

  const {
    data: course,
    isLoading: courseLoading,
    error: courseError,
  } = useCourseById(parseInt(courseId ?? "0"), courseId !== undefined);

  const {
    mutate: rearrangeUnit,
    isLoading: rearrangeLoading,
    error: rearrangeError,
  } = useUnitMutations("REARRANGE_UNIT");

  const {
    data: _units,
    isLoading,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage,
    error,
    refetch,
  } = useUnits(params);

  useEffect(() => {
    refetch();
  }, [params, refetch]);

  const handleChange = (event: any, value: number) => {
    search.set("page", value.toString());
    history.replace({
      search: search.toString(),
    });
    //document.documentElement.scrollTop = 0
    fetchNextPage({ pageParam: value });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCurrentPage = useCallback(
    (page: number) => {
      return _units?.pages.find((p) => p.page === page);
    },
    [_units]
  );

  const tab = useQuery().get("t");

  const page = parseInt(search.get("page") ?? "1");
  useEffect(() => {
    if (!getCurrentPage(page) && tab === "c") {
      //check if next page exists
      fetchNextPage({ pageParam: page });
    }
  }, [getCurrentPage, page, fetchNextPage, tab]);

  const units = getCurrentPage(page)?.result;

  const items = useMemo(() => {
    if (!units) return [];

    return units.map((unit) => {
      return {
        id: unit.unit_seqno,
        ...unit,
      };
    });
  }, [units]);

  const [activeDraggedId, setActiveDraggedId] = useState<number | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      if (rearrangeLoading) return;

      const id =
        typeof event.active.id === "number"
          ? event.active.id
          : parseInt(event.active.id);
      setActiveDraggedId(id);
    },
    [rearrangeLoading]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (rearrangeLoading) return;

      if (event.over && event.active.id !== event.over.id) {
        const sourceId =
          typeof event.active.id === "number"
            ? event.active.id
            : parseInt(event.active.id);

        const destId =
          typeof event.over.id === "number"
            ? event.over.id
            : parseInt(event.over.id);

        rearrangeUnit({
          sourceSeqNo: sourceId,
          destinationSeqNo: destId,
          courseId: parseInt(courseId ?? "0"),
        });
      }
    },
    [courseId, rearrangeUnit, rearrangeLoading]
  );

  const handleDragCancel = useCallback(() => {
    setActiveDraggedId(null);
  }, []);

  if (courseError !== null || error !== null || rearrangeError) {
    if (courseError?.status === 404)
      return (
        <EmptyMessage message="Course not found. Cannot retrieve units." />
      );
    else
      return (
        <ErrorPage
          message={
            courseError?.message || error?.message || rearrangeError?.message
          }
        />
      );
  }

  if (courseLoading) return <BootstrapSpinner />;

  return (
    <div className="p-5 pb-2">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3
          className="text-uppercase mb-3"
          style={{ color: "var(--bs-gray-600)" }}
        >
          <Link
            to={createContentRoute(ContentRoutes.COURSES)}
            className="linkUnderline"
          >
            {course?.cour_name}
          </Link>
        </h3>

        <ToggleButtonGroup
          items={filterItems.map((i) => ({
            ...i,
            active: activeTab === i.label,
          }))}
          onSelect={({ label }) => setActiveTab(label)}
        />
      </div>
      {isLoading || isFetchingNextPage || isRefetching || rearrangeLoading ? (
        <BootstrapSpinner />
      ) : activeTab === "Published 已发布" ? (
        <>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext items={items} strategy={rectSortingStrategy}>
              <Row className="gy-3 gy-lg-4" xs="1" md="3" lg="5">
                {items.map((unit, index) => {
                  return <UnitsItem key={index} unit={unit} />;
                })}
                <Col>
                  <EbicListCardAdd
                    handleClick={() => {
                      history.push(
                        createContentRoute(ContentRoutes.CREATEUNIT, {
                          courseId: courseId ?? "0",
                        })
                      );
                    }}
                    style={{ minHeight: "20rem" }}
                    title="Create a Unit 加新单元"
                    className="text-muted"
                    iconFontSize="large"
                  />
                </Col>
              </Row>
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
              {activeDraggedId ? (
                <UnitsDraggableItem
                  activeId={activeDraggedId}
                  isDragging
                  units={units ?? []}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
          {_units?.pages !== undefined && _units?.pages[0]?.total > 9 && (
            <div className="d-flex justify-content-center my-3">
              <Pagination
                count={Math.ceil(
                  _units?.pages[0]?.total / _units?.pages[0].limit
                )}
                page={page}
                onChange={handleChange}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <Row className="gy-3 gy-lg-4" xs="1" md="3" lg="5">
            {units &&
              units.map((unit, index) => {
                return <UnitNormalItem key={index} unit={unit} />;
              })}
            <Col>
              <EbicListCardAdd
                handleClick={() => {
                  history.push(
                    createContentRoute(ContentRoutes.CREATEUNIT, {
                      courseId: courseId ?? "0",
                    })
                  );
                }}
                style={{ minHeight: "20rem" }}
                title="Create a Unit 加新单元"
                className="text-muted"
                iconFontSize="large"
              />
            </Col>
          </Row>
          {_units?.pages !== undefined && _units?.pages[0]?.total > 9 && (
            <div className="d-flex justify-content-center my-3">
              <Pagination
                count={Math.ceil(
                  _units?.pages[0]?.total / _units?.pages[0].limit
                )}
                page={page}
                onChange={handleChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
