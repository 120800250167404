import { BaseModalProps } from "../../../../hooks/useModal";
import * as yup from "yup";
import {
  useDownloadableMutations,
  useUnitById,
} from "../../../../api/units.api";
import { Form, Modal } from "react-bootstrap";
import IconButton from "../../../utils/IconButton";
import { Close, CloseRounded, Done } from "@mui/icons-material";
import { Formik } from "formik";
import { BootstrapInput } from "../../../utils/FormikBootstrapInputs";
import { useMemo } from "react";
import { NewUnitDownloadableInterface } from "../UnitCreate";
import * as EBIC from "@ebic-bbrm/types";
import { useActivityLogMutations } from "../../../../api/activitylog.api";

interface NewUnitEditProps extends BaseModalProps {
  editDownloadable?: (downloadable: {
    dnld_id: number;
    dnld_name: string;
  }) => void;
  downloadable?: NewUnitDownloadableInterface;
}

interface OldUnitEditProps extends BaseModalProps {
  editDownloadable?: never;
  downloadable?: EBIC.Content.Downloadable;
}

type DownloadablesModalProps = NewUnitEditProps | OldUnitEditProps;

export default function DownloadableEditModal({
  show,
  onClose,
  downloadable,
  editDownloadable,
  ...props
}: DownloadablesModalProps) {
  const schema = yup.object().shape({
    dnld_name: yup
      .string()
      .required("Please enter a file name")
      .max(60, "Name must be less than 60 characters"),
  });

  type UploadForm = { dnld_name: string };

  const {
    mutate: edit,
    isLoading,
    error,
  } = useDownloadableMutations("EDIT_DOWNLOADABLE");

  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: unit } = useUnitById(
    (downloadable as EBIC.Content.Downloadable).unit_id
  );

  const onSubmit = (values: UploadForm) => {
    if (editDownloadable) {
      // Edit a downloadable using row.insertId in backend
      const newDownloadable = {
        dnld_id: downloadable?.dnld_id ?? 0,
        dnld_name: values.dnld_name.concat(format),
      };

      editDownloadable(newDownloadable);
      onClose();
    }

    edit(
      {
        dnld_id: downloadable?.dnld_id ?? 0,
        dnld_name: values.dnld_name.concat(format),
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: unit?.unit_name ?? "",
            alog_details: "Edit Downloadables",
            org_name: null,
          });

          onClose();
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: unit?.unit_name ?? "",
            alog_details: "Edit Downloadables Failed.",
            org_name: null,
          });
        },
      }
    );
  };

  const format = useMemo(() => {
    if (!downloadable?.dnld_name) return "";

    return `.${downloadable.dnld_name.slice(
      downloadable.dnld_name.lastIndexOf(".") + 1
    )}`;
  }, [downloadable]);

  return (
    <Modal
      {...props}
      centered
      show={show}
      backdrop={isLoading ? "static" : undefined}
      className="modal-layer-1"
      backdropClassName="modal-layer-1"
      keyboard={!isLoading}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>Edit Downloadable 修改文档</Modal.Title>
        <IconButton
          title="Close modal"
          Icon={CloseRounded}
          className="ms-auto"
          disabled={isLoading}
          onClick={onClose}
        />
      </Modal.Header>
      <Formik
        initialValues={{
          dnld_name: downloadable?.dnld_name
            ? downloadable.dnld_name.slice(
                0,
                downloadable.dnld_name.lastIndexOf(".")
              )
            : "",
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body className="small">
                {/* Inputs */}
                <div className="mb-2">
                  <BootstrapInput
                    id="dnld_name"
                    required
                    placeholder="file"
                    label="File Name 文件名"
                    inputGroupText={
                      !downloadable?.dnld_name ? undefined : format
                    }
                    inputGroupPosition="suffix"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="overflow-hidden">
                {error !== null && (
                  <p className="text-danger w-100 text-truncate">
                    {error.message}
                  </p>
                )}
                <IconButton
                  transparent
                  Icon={Close}
                  iconHtmlColor="var(--primary)"
                  className="px-4 me-3 border border-primary text-primary"
                  label="Cancel 取消"
                  disabled={isLoading}
                  onClick={onClose}
                />
                <IconButton
                  Icon={Done}
                  type="submit"
                  className="px-4"
                  disabled={isLoading}
                  label={!isLoading ? "Edit 修改" : "Editing... 修改中..."}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
