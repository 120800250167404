import { useAuth0 } from "@auth0/auth0-react";
import AnnouncementCard from "./AnnouncementCard";
import { useEbicUser } from "../../api/user.api";
import CheckUserModal from "../Header/checkExistingUser/CheckUserModal";
import useModal from "../../hooks/useModal";
import { useEffect } from "react";
import { useAllAnnouncements, useEbicContacts } from "../../api/announcements.api";
import BootstrapSpinner from "../utils/BootstrapSpinner";
import { AlternateEmail, LocationOn, WhatsApp } from "@mui/icons-material";

const Home = () => {
  const { isAuthenticated } = useAuth0();
  const { data: contact, isLoading: contactLoading } = useEbicContacts();

  const { data: dbUser, isLoading: isUserLoading, error } = useEbicUser();
  const { data: allAnno, isLoading: isAnnouncementLoading } =
    useAllAnnouncements();

  const [modal, openModal] = useModal(CheckUserModal);

  useEffect(() => {
    if (
      isAuthenticated === true &&
      dbUser === undefined &&
      !isUserLoading &&
      error?.status === 404
    ) {
      openModal();
    }
  }, [dbUser, isUserLoading, error, isAuthenticated, openModal]);

  if (dbUser === undefined && isAuthenticated === true) {
    return (
      <>
        <div>{modal}</div>
      </>
    );
  }

  if (!allAnno && isAnnouncementLoading) return <BootstrapSpinner />;

  return (
    <div>
      <h1 className="text-primary ms-1 p-5 pb-2">
        Welcome to <b>EBICUS</b> 欢迎来到<b>圣经天地用户系统</b>
      </h1>
      <AnnouncementCard announcements={allAnno ?? []} />
      <h2 className="ms-1 p-5 pb-2">CONTACT US 联系我们</h2>
      {(!contact || contactLoading) ? <BootstrapSpinner /> :
      (
        <div className="px-5">
          <p className="px-1">
            <strong>EBIC LTD</strong>
          </p>
          <div className="d-flex flex-row" title="Address 地址">
            <LocationOn color="disabled" />
            <p className="mx-1">
              {`${contact.contact_address1}, ${contact.contact_address2}`}
            </p>
          </div>
          <a
            className="d-flex flex-row"
            href={`mailto:${contact.contact_email}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
            title="Email 电邮"
          >
            <AlternateEmail color="disabled" />
            <p className="mx-1 btn-link">{contact.contact_email}</p>
          </a>
          <a
            className="d-flex flex-row"
            href={`https://wa.me/${contact.contact_phone}`}
            target="_blank"
            rel="noopener noreferrer"
            title="WhatsApp"
          >
            <WhatsApp color="disabled" />
            <p className="mx-1 btn-link">{`+${contact.contact_countrycode} ${contact.contact_phone.toString().slice(0, 4)} ${contact.contact_phone.toString().slice(4, 8)}`}</p>
          </a>
        </div>
      )}
    </div>
  );
};

export default Home;
