import { Prompt } from 'react-router-dom';

interface RouterPromptInterface {
    isBlocking: boolean;
}

const RouterPrompt = ({ isBlocking }: RouterPromptInterface) => {
    return (
        <Prompt
            when={isBlocking}
            message={(location) => 'You have unsaved changes. Are you sure you want to leave?'}
        />
    );
};

export default RouterPrompt;
