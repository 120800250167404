import { useOrgProfileCompeletion } from "./CheckUserModal";
import { Row, Button } from "react-bootstrap";

const CheckSubscription = () => {
  const { goNextPage, goPrevPage, isLoading } = useOrgProfileCompeletion();

  return (
    <>
      <Row>
        <h1 className="text-muted">
          Welcome to&nbsp;
          <span className="text-primary">EBICUS</span> 欢迎您来到
          <span className="text-primary fw-bold">圣经天地用户系统</span>。
        </h1>
        <p>
          Please press the Next button if you have been notified to register as
          an <strong>EBICUS coordinator</strong>.
          <br />
          若您收到通知，要注册成为 <strong>EBICUS 负责人</strong>
          ，请按“下一步”。
        </p>
      </Row>
      <div className="d-flex flex-wrap justify-content-end">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={() => {
            goPrevPage();
          }}
          disabled={isLoading}
        >
          CANCEL 取消
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => {
            goNextPage();
          }}
        >
          NEXT 下一步
        </Button>
      </div>
    </>
  );
};

export default CheckSubscription;
