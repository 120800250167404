import * as EBIC from "@ebic-bbrm/types";
import { useCallback, useEffect, useMemo, useState } from "react";
import ActivityLogTable from "./ActivityLogTable";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import useQuery from "../../../hooks/useQuery";
import {
  FetchALogOptions,
  useAllActivityLog,
} from "../../../api/activitylog.api";
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";
import { useHistory } from "react-router-dom";
import {
  ErrorRounded,
  InfoRounded,
  ShieldRounded,
  WarningRounded,
} from "@mui/icons-material";
import { Form, Col, Row, Button } from "react-bootstrap";
import { ExportToCsv } from "export-to-csv";
import { formatDate } from "../../utils/HelperFunctions";
import { Pagination } from "@mui/material";

type KeysWithType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
//Maps for item keys in URL search
type FilterKeys = "i" | "w" | "e" | "s";
/**
 * If undefined, we select `following_only` by default.
 * If null, we select public posts (no filter).
 */
const FILTER_MAP: Record<
  FilterKeys,
  KeysWithType<Required<FetchALogOptions>, boolean> | null
> = {
  i: "isInfo",
  w: "isWarning",
  e: "isError",
  s: "isSecurity",
} as const;

type SortKeys = "dt";
const SORT_MAP: Record<SortKeys, FetchALogOptions["sort"]> = {
  dt: "date",
} as const;

interface ActivityReportProps {
  ActivityLog: EBIC.ActivityLog.LogInfo;
}

function ActivityHeader() {
  const search = useQuery();
  const history = useHistory();

  const handleFilterChange = (filter: FilterKeys) => {
    search.set("f", filter);
    search.delete("page");
    history.replace({
      search: search.toString(),
    });
  };

  type Item = Omit<ToggleItem, "active" | "render">;

  let activeFilter = decodeURIComponent(search.get("f") || "") as FilterKeys;
  if (!(activeFilter in FILTER_MAP)) activeFilter = "i";

  const filterItems: Record<FilterKeys, Item> = {
    i: {
      Icon: InfoRounded,
      label: "Info 信息",
    },
    w: {
      Icon: WarningRounded,
      label: "Warning 警告",
    },
    e: {
      Icon: ErrorRounded,
      label: "Error 错误",
    },
    s: {
      Icon: ShieldRounded,
      label: "Security 保卫",
    },
  };

  return (
    <div className="mb-2 d-flex align-items-center justify-content-between flex-wrap">
      <ToggleButtonGroup
        items={Object.entries(filterItems).map(([key, item]) => ({
          ...item,
          key: key as FilterKeys,
          active: key === activeFilter,
        }))}
        onSelect={(item) => {
          handleFilterChange(item.key);
        }}
      />
    </div>
  );
}
const ActivityReports = ({ ActivityLog }: ActivityReportProps) => {
  const search = useQuery();
  const history = useHistory();

  // eslint-disable-next-line
  const [dateStart, setDateStart] = useState<string | undefined>(undefined);
  // eslint-disable-next-line
  const [dateEnd, setDateEnd] = useState<string | undefined>(undefined);

  const params = useMemo(() => {
    const params: FetchALogOptions = { dateStart, dateEnd };

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
    const query = decodeURIComponent(search.get("q") || "");

    if (query) params.query = query;

    const filterKey =
      FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
    //if null, no filter (all posts)
    if (filterKey !== null) {
      //set matching filter key to true;

      //if undefined, default to show `active` but only if not searching
      if (filterKey !== undefined || !query) {
        params[filterKey ?? "isInfo"] = true;
      }
    }

    //date filter
    const dateParams: FetchALogOptions = {
      dateStart: dateStart,
      dateEnd: dateEnd,
    };
    const dateQuery = decodeURIComponent(search.get("q") || "");

    if (dateQuery) dateParams.query = dateQuery;

    //sort
    const sortKey =
      SORT_MAP[decodeURIComponent(search.get("dt") || "") as SortKeys] ??
      "date";
    params.sort = sortKey;

    return params;
  }, [search, dateStart, dateEnd]);

  const {
    data: activityLog,
    isLoading,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useAllActivityLog(params);

  const handleChange = (event: any, value: number) => {
    search.set("page", value.toString());
    history.replace({
      search: search.toString(),
    });
    //document.documentElement.scrollTop = 0
    fetchNextPage({ pageParam: value });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCurrentPage = useCallback(
    (page: number) => {
      return activityLog?.pages.find((p) => p.page === page);
    },
    [activityLog]
  );

  const tab = useQuery().get("t");
  const page = parseInt(search.get("page") ?? "1");
  useEffect(() => {
    if (!getCurrentPage(page) && tab === "c") {
      //check if next page exists
      fetchNextPage({ pageParam: page });
    }
  }, [getCurrentPage, page, fetchNextPage, tab]);

  const _activityLog = getCurrentPage(page)?.result;

  //csv format
  const activities = useMemo(() => {
    const activities = _activityLog?.map((activity) => {
      return {
        Date: activity.alog_datetime
          ? formatDate(activity.alog_datetime, "short")
          : "",
        Organization: activity.org_name ? activity.org_name : "No Institution",
        User: activity.user_email ? activity.user_email : "No user",
        Module:
          activity.alog_module === "A"
            ? "Announcements"
            : activity.alog_module === "C"
            ? "Content"
            : activity.alog_module === "N"
            ? "Notes"
            : activity.alog_module === "O"
            ? "Institution"
            : activity.alog_module === "U"
            ? "Users"
            : activity.alog_module === "S"
            ? "Classes"
            : activity.alog_module === "P"
            ? "Products"
            : "",
        ModuleName: activity.alog_modulename ?? "No Module Name",
        Action:
          activity.alog_action === "A"
            ? "Add"
            : activity.alog_action === "E"
            ? "Edit"
            : activity.alog_action === "D"
            ? "Delete"
            : activity.alog_action === "V"
            ? "View"
            : activity.alog_action === "I"
            ? "Login"
            : activity.alog_action === "O"
            ? "Logout"
            : activity.alog_action === "S"
            ? "Suspend"
            : activity.alog_action === "T"
            ? "Terminate"
            : activity.alog_action === "P"
            ? "Activate/Publish"
            : activity.alog_action === "U"
            ? "Deactivate/Publish"
            : "",
        Details: activity.alog_details ?? "No Details",
        WebOrApp: activity.alog_accessfrom === 0 ? "Web" : "App",
      };
    });

    return activities;
  }, [_activityLog]);

  //csv
  const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    filename: "Activity_report",
    title: "Activity Report Details",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
    headers: [
      "Date",
      "Institution",
      "User",
      "Module",
      "Module Name",
      "Action",
      "Details",
      "W/A",
    ],
  };

  const csvExporter = new ExportToCsv(options);

  if (isLoading || !activityLog || isFetchingNextPage || isRefetching)
    return <BootstrapSpinner />;

  return (
    <>
      <Row>
        <div className="d-flex align-items-center justify-content-between mb-3 ">
          <ActivityHeader />

          <Form>
            <Row>
              <Col>
                <Button
                  onClick={() => {
                    csvExporter.generateCsv(activities);
                  }}
                  className="mt-4"
                >
                  Download CSV
                </Button>
              </Col>

              <Col>
                <Form.Group>
                  <Form.Label className="mb-0 text-uppercase small fw-bold">
                    Start Date 开始日期
                  </Form.Label>
                  <Form.Control
                    id="dateStart"
                    type="date"
                    value={dateStart}
                    disabled={isLoading}
                    onChange={(e) => setDateStart(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label className="mb-0 text-uppercase small fw-bold">
                    End Date 结束日期
                  </Form.Label>
                  <Form.Control
                    id="dateEnd"
                    type="date"
                    value={dateEnd}
                    disabled={isLoading}
                    onChange={(e) => setDateEnd(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
      <Row>
        <ActivityLogTable
          ActivityLog={ActivityLog}
          ActivityLogDetails={_activityLog ?? []}
          isLoading={isLoading}
        />
      </Row>
      {activityLog?.pages !== undefined &&
        activityLog?.pages[0]?.total > 15 && (
          <div className="d-flex justify-content-center my-3">
            <Pagination
              count={Math.ceil(
                activityLog.pages[0]?.total / activityLog?.pages[0].limit
              )}
              page={page}
              onChange={handleChange}
            />
          </div>
        )}
    </>
  );
};

export default ActivityReports;
