import * as EBIC from "@ebic-bbrm/types";
import ReactTable from "../../utils/ReactTable";
import { useMemo } from "react";
import { Column } from "react-table";
import { Container } from "react-bootstrap";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useTotalStudent, useMaxTeacher } from "../../../api/classes.api";

interface SubscriptionTableInterface {
  classes: EBIC.Classes.ClassInfo;
  classDetails: EBIC.Classes.ClassInfo[];
  isLoading: boolean;
}

const SubscriptionTable = ({
  classes,
  classDetails,
  isLoading,
}: SubscriptionTableInterface) => {
  //table columns
  const subscriptionColumns = useMemo<Column<EBIC.Classes.ClassInfo>[]>(
    () => [
      {
        Header: "Institution Name",
        accessor: "org_name",
        minWidth: 15,
        Cell: ({ value }) => {
          return <>{value ? `${value}` : ""}</>;
        },
      },
      {
        Header: "Class Name",
        accessor: "class_name",
        minWidth: 15,
        Cell: ({ value }) => {
          return <>{value ? `${value}` : ""}</>;
        },
      },
      {
        Header: "Course Name",
        accessor: "cour_name",
        minWidth: 15,
        Cell: ({ value }) => {
          return <>{value ? `${value}` : "No Selected Course"}</>;
        },
      },
      {
        Header: "No. of Teachers (Used/Subscribed)",
        accessor: "teacher_size",
        minWidth: 15,
        Cell: ({ value, row }) => {
          const sub = row.original;
          const { data: totalTeacher } = useMaxTeacher(sub.cour_id ?? 0);

          const maxTeacher = Number(sub.prod_teachers ?? 0);

          if (!totalTeacher) return <BootstrapSpinner />;
          const over = totalTeacher > maxTeacher;

          return (
            <>
              {over === true ? (
                <div className="text-danger">
                  {`${value ?? 0}/${maxTeacher}`}
                </div>
              ) : (
                <>{`${value ?? 0}/${maxTeacher}`}</>
              )}
            </>
          );
        },
      },
      {
        Header: "No. of Students (Used/Subscribed)",
        accessor: "student_size",
        minWidth: 15,
        Cell: ({ value, row }) => {
          const sub = row.original;
          const { data: totalStudent } = useTotalStudent(sub.cour_id ?? 0);
          const maxStudent = Number(sub.prod_students ?? 0);

          let totalStudent_ = totalStudent ?? 0;

          const over = totalStudent_ > maxStudent;

          return (
            <>
              {over === true ? (
                <div className="text-danger">
                  {`${value ?? 0}/${maxStudent}`}
                </div>
              ) : (
                <>{`${value ?? 0}/${maxStudent}`}</>
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [classes]
  );

  if (!classDetails) return <BootstrapSpinner />;

  return (
    <>
      <Container>
        <ReactTable
          data={classDetails}
          columns={subscriptionColumns}
          isLoading={isLoading}
          placeholderMessage={"There are no Subscriptions yet."}
          pagination={false}
          showRowCount={false}
          Row={(props, data) => <tr {...props} />}
          style={{ borderColor: "white" }}
        ></ReactTable>
      </Container>
    </>
  );
};

export default SubscriptionTable;
