import { MoreVert } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { Column } from "react-table";
import * as EBIC from "@ebic-bbrm/types";
import ReactTable from "../../utils/ReactTable";
import { Dropdown, Row } from "react-bootstrap";
import IconButton from "../../utils/IconButton";
import DestructiveModal from "../../utils/DestructiveModal";
import { useClassesMutations } from "../../../api/classes.api";
import ClassEditModal from "./ClassEditModal";
import { useOrgProfileById } from "../../../api/organization.api";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import useModal from "../../../hooks/useModal";

interface OrgClassesTableInterface {
  classes: EBIC.Classes.ClassInfo;
  classDetails: EBIC.Classes.ClassInfo[];
  classMembers: EBIC.Classes.ClassMembers;

  isLoading: boolean;
  isOrgOwner: boolean;
  isSysAdmin: boolean;
  orgId: number;
}
const OrgClassesTable = ({
  classes,
  classDetails,
  classMembers,
  isLoading,
  isOrgOwner,
  isSysAdmin,
  orgId,
}: OrgClassesTableInterface) => {
  // const classId = classes.class_id;

  const { data: organization } = useOrgProfileById(orgId);

  const { mutate: deleteClass } = useClassesMutations("DELETE");
  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const activeOrg = Boolean(organization?.org_status === 1 && isOrgOwner);

  // table columns
  const orgClassesColumns = useMemo<Column<EBIC.Classes.ClassInfo>[]>(
    () => [
      {
        Header: "Class Name 班级名称",
        accessor: "class_name",
        minWidth: 50,
        Cell: ({ value, row }) => {
          const classDetails = row.original;
          return <>{` ${classDetails.class_name}`}</>;
        },
      },
      {
        Header: "Description 描述",
        accessor: "class_desc",
        minWidth: 400,
        Cell: ({ value }) => (
          <>{value ? `${value}` : "This class does not have a description."}</>
        ),
      },
      {
        Header: "Teacher(s) 老师",
        accessor: "teacher_name",
        minWidth: 100,
        maxWidth: 100,
        Cell: ({ value }) => (
          <>{value ? `${value}` : "No Teachers in charge."}</>
        ),
      },
      {
        Header: "No. of members 会员人数",
        accessor: "class_size",
        minWidth: 50,
        Cell: ({ value, row }) => {
          const classDetails = row.original;
          return <>{`${classDetails.class_size}`}</>;
        },
      },

      {
        accessor: "class_status",
        minWidth: 10,
        disableSortBy: true,
        Cell: ({ row }) => {
          const classDetails = row.original;
          const [showEditModal, setShowEditModal] = useModal(ClassEditModal, {
            classId: classDetails.class_id,
            orgId: classDetails.org_id,
            classMembers: classMembers,
          });
          const [showDeleteModal, setShowDeleteModal] = useState(false);
          return !isSysAdmin && !activeOrg ? (
            <></>
          ) : (
            <>
              <Dropdown
                onToggle={(_, meta) => {
                  meta.originalEvent?.stopPropagation();
                  meta.originalEvent?.preventDefault();
                }}
                style={{ float: "right" }}
                drop="start"
              >
                <Dropdown.Toggle as="span" bsPrefix=" ">
                  <IconButton
                    transparent
                    border={false}
                    title="More"
                    Icon={MoreVert}
                    iconHtmlColor="var(--bs-gray)"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0">
                  <Dropdown.Item onClick={() => setShowEditModal()}>
                    Edit
                  </Dropdown.Item>

                  <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {showEditModal}
              <DestructiveModal
                title="Delete Class 删除类"
                description={
                  "This class will be permanently removed from the system. 此类将从系统中永久删除。"
                }
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={() =>
                  deleteClass(
                    {
                      class_id: classDetails.class_id,
                      org_id: orgId,
                    },
                    {
                      onSuccess: () => {
                        addLog({
                          alog_type: "I",
                          alog_module: "S",
                          alog_action: "D",
                          alog_accessfrom: 0,
                          alog_modulename: classDetails.class_name,
                          alog_details: "Delete Class",
                          org_name: organization?.org_name ?? null,
                        });
                      },
                      onError: () => {
                        addLog({
                          alog_type: "E",
                          alog_module: "S",
                          alog_action: "D",
                          alog_accessfrom: 0,
                          alog_modulename: classDetails.class_name,
                          alog_details: "Delete Class Failed.",
                          org_name: organization?.org_name ?? null,
                        });
                      },
                    }
                  )
                }
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [classes]
  );

  return (
    <Row>
      <ReactTable
        data={classDetails}
        columns={orgClassesColumns}
        isLoading={isLoading}
        placeholderMessage={"There are no classes yet. 没有课程。"}
        pagination={true}
        Row={(props, data) => <tr {...props} />}
      ></ReactTable>
    </Row>
  );
};

export default OrgClassesTable;
