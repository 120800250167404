import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FetchCoursesOptions, useCourses } from "./../../../api/courses.api";
import { useCallback, useEffect, useMemo, useState } from "react";
import useQuery from "../../../hooks/useQuery";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import CoursesItem from "./CoursesItem";
import EbicListCardAdd from "../../utils/EbicListAdd";
import { Pagination } from "@mui/material";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import {
  PublishedWithChanges,
  StarRounded,
  Unpublished,
} from "@mui/icons-material";
import ToggleButtonGroup from "./../../ui/ToggleButtonGroup";

export default function CoursesList() {
  const history = useHistory();
  const search = useQuery();

  // Tabs
  const items = [
    { label: "All 全部" as const, Icon: StarRounded },
    { label: "Published 已发布" as const, Icon: PublishedWithChanges },
    { label: "Drafts 草稿" as const, Icon: Unpublished },
  ];
  const [activeTab, setActiveTab] =
    useState<typeof items[number]["label"]>("All 全部");

  const params = useMemo(() => {
    const params: FetchCoursesOptions = {};

    if (activeTab === "Published 已发布") params.published = true;
    else if (activeTab === "Drafts 草稿") params.draft = true;

    return params;
  }, [activeTab]);

  const {
    data: _courses,
    isLoading,
    isRefetching,
    fetchNextPage,
    isFetchingNextPage,
  } = useCourses(params);

  // useEffect(() => {
  //   refetch();
  // }, [ params, refetch ]);

  const handleChange = (event: any, value: number) => {
    search.set("page", value.toString());
    history.replace({
      search: search.toString(),
    });
    //document.documentElement.scrollTop = 0
    fetchNextPage({ pageParam: value });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getCurrentPage = useCallback(
    (page: number) => {
      return _courses?.pages.find((p) => p.page === page);
    },
    [_courses]
  );

  const tab = useQuery().get("t");

  const page = parseInt(search.get("page") ?? "1");
  useEffect(() => {
    if (!getCurrentPage(page) && tab === "c") {
      //check if next page exists
      fetchNextPage({ pageParam: page });
    }
  }, [getCurrentPage, page, fetchNextPage, tab]);

  const courses = getCurrentPage(page)?.result;

  return (
    <div className="p-5 pb-2">
      <div className="d-flex justify-content-end align-items-center mb-3">
        <ToggleButtonGroup
          items={items.map((i) => ({ ...i, active: activeTab === i.label }))}
          onSelect={({ label }) => setActiveTab(label)}
        />
      </div>
      {isLoading || isFetchingNextPage || isRefetching ? (
        <BootstrapSpinner />
      ) : (
        <>
          <Row className="gy-3 gy-lg-4" xs="1" md="3" lg="5">
            {courses?.map((course, index) => {
              return (
                <Col key={index} style={{ minHeight: "20rem" }}>
                  <CoursesItem course={course} />
                </Col>
              );
            })}
            <Col>
              <EbicListCardAdd
                handleClick={() => {
                  history.push(createContentRoute(ContentRoutes.CREATECOURSE));
                }}
                style={{ minHeight: "20rem" }}
                title="Create a Course 加新课程"
                className="text-muted"
                iconFontSize="large"
              />
            </Col>
          </Row>
          {_courses?.pages !== undefined && _courses?.pages[0]?.total > 9 && (
            <div className="d-flex justify-content-center my-3">
              <Pagination
                count={Math.ceil(
                  _courses?.pages[0]?.total / _courses?.pages[0].limit
                )}
                page={page}
                onChange={handleChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
