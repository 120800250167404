import { FetchOrderOptions, useAllOrders } from "../../../api/order.api";
import { useMemo } from "react";
import useQuery from "../../../hooks/useQuery";
import { useHistory } from "react-router-dom";
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";
import { Col } from "react-bootstrap";
import { PlayCircle, StopCircle } from "@mui/icons-material";
import { OrgOrderTable } from "./OrgOrderTable";


//Maps for item keys in URL search
type SortKeys = "n" | "o";
const SORT_MAP: Record<SortKeys, FetchOrderOptions["sort"]> = {
    n: "Newest",
    o: "Oldest",

} as const;

type KeysWithType<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];
//Maps for item keys in URL search
type FilterKeys = 'a' | 'i';
/**
 * If undefined, we select `active_only` by default.
 * If null, we select public posts (no filter).
 */
const FILTER_MAP: Record<FilterKeys, KeysWithType<Required<FetchOrderOptions>, boolean> | null> = {
    a: "active",
    i: "inactive",

} as const;

interface OrgOrderProps {
    orgId: number;
}


export const OrgOrder = ({ orgId }: OrgOrderProps) => {
    const search = useQuery();

    const params = useMemo(() => {
        const params: FetchOrderOptions = { orgId: orgId };

        //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.query = query;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
        //if null, no filter (all posts)
        if (filterKey !== null) {
            //set matching filter key to true;

            //if undefined, default to show `active` but only if not searching
            if (filterKey !== undefined || !query) {
                params[filterKey ?? 'active'] = true;
            }
        }

        const sortKey =
            SORT_MAP[decodeURIComponent(search.get("s") || "") as SortKeys] ??
            "Newest";

        params.sort = sortKey;

        return params;
    }, [orgId, search])

    const { order: orders, query } = useAllOrders(params);
    const { isLoading } = query;


    const orderDetails = useMemo(() => {
        if (!orders) return [];
        let orderArray = [];
        if (Array.isArray(orders)) orderArray = orders;
        else orderArray = [orders];

        return orderArray;
        // eslint-disable-next-line
    }, [orders]);

    return (
        <>
            <div>
                <Col xs={12} className="mb-2">
                    <OrderHeader />
                </Col>

                <OrgOrderTable
                    orders={orderDetails}
                    orgId={orgId}
                    isLoading={isLoading}
                />

            </div>
        </>
    )

}

function OrderHeader() {
    const history = useHistory();
    const search = useQuery();

    type Item = Omit<ToggleItem, 'active' | 'render'>;

    //filter
    const handleFilterChange = (filter: FilterKeys) => {
        search.set('f', filter);
        history.replace({
            search: search.toString()
        })
    }

    let activeFilter = decodeURIComponent(search.get('f') || "") as FilterKeys;
    if (!(activeFilter in FILTER_MAP)) activeFilter = 'a';

    const filterItems: Record<FilterKeys, Item> = {
        a: {
            Icon: PlayCircle,
            label: "Active 在线",
        },
        i: {
            Icon: StopCircle,
            label: "Inactive 已下线"
        },

    }

    return (
        <>
            <div className="d-flex flex-wrap align-items-center justify-content-between">

                <Col xs={2} className="ms-8 p-0">
                    <div className="d-flex flex-wrap align-items-center justify-content-between me-0 p-0">
                        <ToggleButtonGroup
                            items={Object.entries(filterItems).map(([key, item]) => ({
                                ...item,
                                key: key as FilterKeys,
                                active: key === activeFilter
                            }))}
                            onSelect={(item) => {
                                handleFilterChange(item.key)
                            }}
                        />
                    </div>
                </Col>


            </div>
        </>
    )

}