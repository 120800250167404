export namespace ActivityLog {
    export type ALogTypeKey = keyof typeof ALogType;
    export enum ALogType {
        I = 'Info',
        W = 'Warning',
        E = 'Error',
        S = 'Security'
    }

    export type ALogModuleKey = keyof typeof ALogModule;
    export enum ALogModule {
        O = 'Organization',
        C = 'Content',
        A = 'Announcements',
        N = 'Notes',
        U = 'Users',
        S = 'Classes',
        P = 'Products'
    }

    export type ALogActionKey = keyof typeof ALogAction;
    export enum ALogAction {
        A = 'Add',
        E = 'Edit',
        D = 'Delete',
        V = 'View',
        I = 'Login',
        O = 'Logout',
        S = 'Suspend',
        T = 'Terminate',
        P = 'Activate/Publish',
        U = 'Deactivate/Unpublish'
    }

    export type ALogAccessKey = keyof typeof ALogAccess;
    export enum ALogAccess {
        'Web' = 0,
        'App' = 1

    }

    export interface LogInfo {
        alog_id: number;
        alog_datetime: string;
        alog_type: ALogTypeKey;
        alog_module: ALogModuleKey;
        alog_action: ALogActionKey;
        alog_accessfrom: 0 | 1;

        //to be removed
        alog_courseid: number | null;
        alog_unitid: number | null;
        alog_lessonid: number | null;
        alog_annoid: number | null;

        alog_modulename: string | null;
        alog_details: string | null;

        total_users: number | null

        user_email: string;
        org_name: string | null;



    }
}