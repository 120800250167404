
import * as EBIC from "@ebic-bbrm/types";
import useEbicAPI, {
    EbicMutationOptions,
    useEbicMutation,
    useEbicPaginatedQuery,
} from './useEbicAPI';

export interface FetchALogOptions {
    limit?: number;
    query?: string;
    sort?: "date";

    isInfo?: boolean;
    isWarning?: boolean;
    isError?: boolean;
    isSecurity?: boolean;

    dateStart?: string;
    dateEnd?: string;
}


export const ALogQueries = {

    ACTIVITYLOG: ['activitylog', 'info'] as const,

    ALLACTIVITIES: (params: FetchALogOptions) => ['activitylog', 'info', params] as const,

    ACTIVITYLOGBYUSER: (userId: number) => ['activitylog', 'info', userId] as const,
}

export const activityLogBaseRoute = '/api/activitylog'

export function useAllActivityLog(params: FetchALogOptions = {}, enabled = true) {

    const { sort = "date", limit = 15, ...rest } = params;

    const options = {
        sort, limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.ActivityLog.LogInfo[]>(
        ALogQueries.ALLACTIVITIES(options),
        `${activityLogBaseRoute}/all?${searchParams.toString()}`,
        true,
        { enabled }
    );

}

export function useActivityLog() {
    return useEbicAPI<EBIC.ActivityLog.LogInfo>(ALogQueries.ACTIVITYLOG, `${activityLogBaseRoute}`, true, {
        refetchOnMount: 'always',
    })
}

export function initialiseActivityLogObject(alog: EBIC.ActivityLog.LogInfo) {
    const requiredFields = [
        'alog_id',
        'alog_datetime',
        'alog_module',
        'alog_action',
        'alog_accessfrom',
        'alog_modulename',
        'alog_details',
        'user_email',
        'org_name'

    ];
    const originalActivityLog: EBIC.ActivityLog.LogInfo = requiredFields.reduce(
        (accumulator, currentValue) => {
            return {
                ...accumulator,
                ...{
                    [currentValue]: alog[currentValue as keyof typeof alog],
                },
            };
        },
        {} as EBIC.ActivityLog.LogInfo
    );

    return originalActivityLog;
}

interface CreateActivityLog {
    alog_type: string;
    alog_module: string;
    alog_action: string;
    alog_accessfrom: number;

    alog_modulename: string | null;
    alog_details: string | null;
    
    org_name: string | null
}

const ActivityLogMutations = {
    CREATE: ({
        ...alog
    }: CreateActivityLog): EbicMutationOptions => ({
        url: `${activityLogBaseRoute}`,
        method: 'POST',
        requestBody: { ...alog },
        updater: [{
            queryKey: ALogQueries.ACTIVITYLOG,
            action: 'refetch'
        },
        {
            queryKey: ALogQueries.ALLACTIVITIES({}),
            action: 'refetch'
        },
        ]
    })
}


export function useActivityLogMutations<Key extends keyof typeof ActivityLogMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ActivityLogMutations[key] as (
        params: Parameters<typeof ActivityLogMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}