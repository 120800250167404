// Import Worker and viewer
import { Worker, Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// Import styles of default layout plugin
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Modal } from "react-bootstrap";
import { useTeachingGuide } from "../../api/units.api";
import {
  toolbarPlugin,
  ToolbarSlot,
  TransformToolbarSlot,
} from "@react-pdf-viewer/toolbar";

interface TeachingGuideViewerProps {
  unitid: number;
  guidename: string;

  show: boolean;
  onClose: () => void;
}

const TeachingGuideViewer = ({
  unitid,
  guidename,
  show,
  onClose,
}: TeachingGuideViewerProps) => {
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    // These slots will be empty
    Download: () => <></>,
    Print: () => <></>,
    Open: () => <></>,
  });

  const { data: guidedoc } = useTeachingGuide(unitid);

  return (
    <>
      <Modal
        show={show}
        onHide={onClose}
        backdrop={undefined}
        centered
        size="xl"
        dialogClassName="modal-80w"
      >
        {guidedoc && <Modal.Header>{guidename}</Modal.Header>}
        <Modal.Body>
          {/* View PDF */}
          <div className="viewer">
            {/* render this if we have a pdf file */}
            {guidedoc && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                <div
                  className="rpv-core__viewer"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    display: "flex",
                    flexDirection: "column",
                    height: "80vh",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      backgroundColor: "#eeeeee",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      padding: "0.25rem",
                      position: "fixed",
                      transform: "translate(-50%, 0)",
                      zIndex: 1,
                      left: "50%",
                      top: "6rem",
                    }}
                  >
                    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      overflow: "hidden",
                    }}
                  >
                    <Viewer
                      fileUrl={guidedoc}
                      plugins={[toolbarPluginInstance]}
                    />
                  </div>
                </div>
              </Worker>
            )}

            {/* render this if we have guidedoc state null   */}
            {!guidedoc && (
              <>
                <div className="text-center d-flex justify-content-center align-content-center">
                  There is no Teaching Guide available.
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TeachingGuideViewer;
