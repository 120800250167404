import * as EBIC from "@ebic-bbrm/types"
import { ALogQueries } from "./activitylog.api";
import useEbicAPI, { EbicMutationOptions, useEbicMutation, useEbicPaginatedQuery } from './useEbicAPI';

export interface SearchClassesOptions {
    query?: string;
    limit?: number;

    org_id?: number;
    all?: boolean;

}

export const ClassesQueries = {

    CLASSES: ['classes', 'info'] as const,
    CLASS_SEARCH: (params: SearchClassesOptions) => ['classes', 'info', params] as const,

    CLASSESBYID: (classId: number) => ['classes', 'info', classId] as const,

    CLASSESBYORGID: (organizationId: number) => ['classes', 'info', 'users', organizationId, 'count'] as const,

    CLASSMEMBERS: (classId: number) => ['classes', 'users', classId] as const,
    CLASSMEMBERSBYID: (userId: number) => ['classes', 'users', userId] as const,
    TEACHERCOUNT: (courId: number) => ['classes', courId, 'teachers', 'count'] as const,
    STUDENTCOUNT: (courId: number) => ['classes', courId, 'students', 'count'] as const,

}

export const classesBaseRoute = '/api/classes';


export function useOrgClasses(organizationId: number, enabled = true) {
    return useEbicAPI<EBIC.Classes.ClassInfo>(
        ClassesQueries.CLASSESBYORGID(organizationId),
        `${classesBaseRoute}/organization/${organizationId}`,
        true,
        { enabled }
    )
}

export function useClassesById(classId: number, enabled = true) {
    return useEbicAPI<EBIC.Classes.ClassInfo>(
        ClassesQueries.CLASSESBYID(classId),
        `${classesBaseRoute}/${classId}`,
        true,
        { enabled }
    )
}

export function useClassMembersByClass(classId: number, enabled = true) {
    return useEbicAPI<EBIC.Classes.ClassMembers[]>(
        ClassesQueries.CLASSMEMBERS(classId),
        `${classesBaseRoute}/classmembers/${classId}`,
        true,
        { enabled }
    )
}

export function useMaxTeacher(courId: number, enabled = true) {
    return useEbicAPI<number>(
        ClassesQueries.TEACHERCOUNT(courId),
        `${classesBaseRoute}/maxTeacher/${courId}`,
        true,
        { enabled }
    )
}

export function useTotalStudent(courId: number, enabled = true) {
    return useEbicAPI<number>(
        ClassesQueries.STUDENTCOUNT(courId),
        `${classesBaseRoute}/totalStudent/${courId}`,
        true,
        { enabled }
    )
}

export function useClassMembersById(userId: number, classId: number, enabled = true) {
    return useEbicAPI<EBIC.Classes.ClassInfo>(
        ClassesQueries.CLASSMEMBERSBYID(userId),
        `${classesBaseRoute}/members/${classId}/${userId}`,
        true,
        { enabled }
    )
}


export function initialiseClassesObject(classes: EBIC.Classes.ClassInfo) {
    const requiredFields = [
        'org_id',
        'cour_id',
        'class_name',
        'class_desc',
        'class_pic',
        'class_status'

    ];
    const originalClass: EBIC.Classes.ClassInfo = requiredFields.reduce(
        (accumulator, currentValue) => {
            return {
                ...accumulator,
                ...{
                    [currentValue]: classes
                    [currentValue as keyof typeof classes],
                },
            };
        },
        {} as EBIC.Classes.ClassInfo
    );

    return originalClass;
}

export function useClassesSearch(params: SearchClassesOptions = {}, enabled = true) {

   const { limit = 15, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.Classes.ClassInfo[]>(
        ClassesQueries.CLASS_SEARCH(options),
        `${classesBaseRoute}/allClasses?${searchParams.toString()}`,
        true,
        { enabled }
    );


}

interface ClassForm {
    class_id: number;
    org_id: number;
    cour_id: number | null;

    class_name: string;
    class_desc: string | null;
    class_status: number | null;
}

interface EditClassPhoto {
    org_id: number;
    class_id: number;
    image: File;
}

type DeleteClassForm = Pick<
    EBIC.Classes.ClassInfo,
    | 'class_id'
    | 'org_id'
>


type TCreateClasses = Pick<
    EBIC.Classes.ClassInfo,
    | 'org_id'
    | 'cour_id'
    | 'class_name'
    | 'class_desc'
    | 'class_status'
> & {
    class_pic: File | null;
};

interface ClassMembersForm extends Pick<EBIC.Classes.ClassMembers, 'class_id' | 'user_id'> {
    cmem_role: EBIC.Classes.ClassMemberKey
}


const ClassesMutations = {
    CREATE: ({ org_id, cour_id, ...body }: TCreateClasses): EbicMutationOptions => ({
        url: `${classesBaseRoute}/${org_id}`,
        method: 'POST',
        requestBody: { org_id, cour_id, ...body },
        useFormData: true,
        updater: [
            {
                queryKey: ClassesQueries.CLASSESBYORGID(org_id),
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),
    EDIT: ({ org_id, class_id, ...classes }: ClassForm): EbicMutationOptions => ({
        url: `${classesBaseRoute}/${org_id}/${class_id}`,
        method: 'PUT',
        requestBody: classes,
        updater: [
            {
                queryKey: ClassesQueries.CLASSESBYORGID(org_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASSESBYID(class_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ],

    }),

    EDIT_PHOTO: ({ class_id, org_id, image }: EditClassPhoto): EbicMutationOptions => ({
        url: `${classesBaseRoute}/photo/${org_id}/${class_id}`,
        method: 'PUT',
        requestBody: {
            class_pic: image,
        },
        updater: {
            queryKey: ClassesQueries.CLASSESBYORGID(org_id),
            action: 'refetch'
        },
        useFormData: true,
    }),

    DELETE: ({ org_id, class_id }: DeleteClassForm): EbicMutationOptions => ({
        url: `${classesBaseRoute}/${org_id}/${class_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: ClassesQueries.CLASSESBYORGID(org_id),
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    ADD_MEMBER: ({ class_id, user_id, ...body }: ClassMembersForm): EbicMutationOptions => ({
        url: `${classesBaseRoute}/members/${class_id}/${user_id}`,
        method: 'POST',
        requestBody: body,
        updater: [
            {
                queryKey: ClassesQueries.CLASSMEMBERS(class_id),
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.TEACHERCOUNT(class_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.STUDENTCOUNT(class_id),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    REMOVE_MEMBER: ({ class_id, user_id }: { class_id: number, user_id: number }): EbicMutationOptions => ({
        url: `${classesBaseRoute}/member/${class_id}/${user_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: ClassesQueries.CLASSMEMBERS(class_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASS_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
             {
                queryKey: ClassesQueries.TEACHERCOUNT(class_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.STUDENTCOUNT(class_id),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

}

export function useClassesMutations<Key extends keyof typeof ClassesMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = ClassesMutations[key] as (
        params: Parameters<typeof ClassesMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}
