import Profile from "./components/Profile/Profile";
import Home from "./components/Home/Home";
import ContentManagement from "./components/ContentManagement/ContentManagement";
import Organizations from "./components/Organizations/Organizations";
import Classes from "./components/Classes/Classes";
import { Switch, Route } from "react-router-dom";
import * as EBIC from "@ebic-bbrm/types";
import AllUsersList from "./components/SysAdmin/Users/AllUsersList";

export enum AllRoutes {
  HOME = "/home",
  PROFILE = "/profile",
  CONTENT = "/content",
  ORGANIZATIONS = "/organizations",
  CLASSES = "/classes",
  USERS = "/users"
}

type TArgs =
  | [AllRoutes]
  | [AllRoutes, { [param: string]: string | number }];

export function createAllRoute(...args: TArgs) {
  const [path, params] = args;

  if (params === undefined) {
    return path;
  }
  //replace all params with the params passed
  return Object.entries(params).reduce(
    (previousValue: string, [param, value]) => {
      return previousValue.replace(`:${param}`, "" + value);
    },
    path
  );
}

interface routeProps {
  classes: EBIC.Classes.ClassInfo;
  classMembers: EBIC.Classes.ClassMembers;

}

const Routes = ({ classes, classMembers }: routeProps) => {


  return (
    <Switch>
      <Route path={AllRoutes.HOME}>
        <Home/>
      </Route>
      <Route path={AllRoutes.PROFILE}>
        <Profile />
      </Route>
      <Route path={AllRoutes.CONTENT}>
        <ContentManagement />
      </Route>
      <Route path={AllRoutes.ORGANIZATIONS}>
        <Organizations
          classes={classes}
          classMembers={classMembers} />
      </Route>
      <Route path={AllRoutes.CLASSES}>
        <Classes 
        classes={classes}
        />
      </Route>
      <Route path={AllRoutes.USERS}>
        <AllUsersList />
      </Route>


    </Switch>

  );
}

export default Routes;