/* eslint-disable react-hooks/rules-of-hooks */
import { OrgForm, useOrgProfileCompeletion } from "./CheckUserModal";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useOrganizationMutations } from "../../../api/organization.api";
import * as yup from "yup";
import { Formik } from "formik";
import {
  BootstrapInput,
  BootstrapInputPhone,
} from "../../utils/FormikBootstrapInputs";

const OrgProfileDetailsCreation = () => {
  const {
    orgForm,
    setOrgForm,
    goNextPage,
    goPrevPage,
    isLoading: isGlobalLoading,
  } = useOrgProfileCompeletion();

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutate: createOrg,
    isLoading: isCreateLoading,
    error: createError,
  } = useOrganizationMutations("CREATE_ORG_AND_USER");

  const initialValues: Pick<
    OrgForm,
    "org_contact" | "org_contactno" | "org_email"
  > = {
    org_contact: orgForm.org_contact,
    org_contactno: orgForm.org_contactno,
    org_email: orgForm.org_email,
  };

  const validationSchema = yup.object({
    org_contact: yup
      .string()
      .max(120, ({ max }) => `Maximum ${max} characters`)
      .required("Contact Person is required"),
    org_contactno: yup
      .string()
      .max(12, ({ max }) => `Maximum ${max} characters`)
      .required("Contact Number is required"),
    org_email: yup
      .string()
      .max(120, ({ max }) => `Maximum ${max} characters`)
      .email("invalid email")
      .required("Email is required"),
  });

  const onSubmit = (
    values: Pick<OrgForm, "org_contact" | "org_contactno" | "org_email">
  ) => {
    const newOrgForm = {
      ...orgForm,
      ...values,
    };

    setOrgForm(newOrgForm);

    goNextPage();
  };

  const isLoading = isGlobalLoading || isCreateLoading;

  return (
    <>
      <Row>
        <h1 className="text-muted">
          Setup the&nbsp;
          <span className="text-primary">Coordinator Details</span> 设置您的
          <span className="text-primary">负责人详情</span>
        </h1>
      </Row>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, isValid, setFieldValue, handleSubmit }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg="6">
                <BootstrapInput
                  id="org_contact"
                  label="EBICUS Coordinator (Name & Title) 负责人(姓名与称谓)"
                  placeholder="Coordinator"
                  aria-label="Coordinator"
                  disabled={isLoading}
                  required={true}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 g-3">
              <Form.Group as={Col} lg={6}>
                <BootstrapInputPhone
                  id="org_contactno"
                  label="EBICUS Coordinator Contact Number 联系电话"
                  placeholder="Coordinator Contact Number"
                  aria-label="Coordinator Contact Number"
                  disabled={isLoading}
                  required={true}
                  value={values.org_contactno}
                  onChange={(val) => {
                    setFieldValue("org_contactno", val);
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <BootstrapInput
                  id="org_email"
                  label="EBICUS Coordinator Email Address 电邮"
                  placeholder="email@address.com"
                  aria-label="Coordinator Email"
                  disabled={isLoading}
                  required={true}
                />
              </Form.Group>
            </Row>

            <div className="d-flex flex-wrap justify-content-end mt-3">
              <Button
                variant="outline-primary"
                className="me-2"
                onClick={() => {
                  setOrgForm((prev) => ({
                    ...prev,
                    ...values,
                  }));
                  goPrevPage();
                }}
                disabled={isLoading}
              >
                BACK 上一步
              </Button>
              <Button type="submit" disabled={!isValid || isLoading}>
                NEXT 下一步
              </Button>
            </div>
            {createError !== null && (
              <p className="w-100 text-danger">{createError.message}</p>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrgProfileDetailsCreation;
