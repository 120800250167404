import { Navbar, Container, Nav, NavLink, Dropdown } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink as RouterNavLink, useLocation } from "react-router-dom";
import {
  HouseOutlined,
  AccountTreeOutlined,
  DriveFileRenameOutlineOutlined,
  WidgetsOutlined,
  Logout,
  AccountCircleOutlined,
  House,
  AccountTree,
  DriveFileRenameOutline,
  Widgets,
  PeopleAlt,
  Campaign,
  Flag,
  Inventory,
} from "@mui/icons-material";
import { useEbicUser } from "../../api/user.api";
import UserAvatar from "../Profile/UserAvatar";
import { AllRoutes } from "../../Routes";
import styles from "./navibar.module.scss";
import { useState } from "react";
import { OrgRoutes } from "../Organizations/Organizations";
import { useActivityLogMutations } from "../../api/activitylog.api";
// import { useActivityLogMutations } from '../../api/activitylog.api';

const Navibar = () => {
  const { isAuthenticated } = useAuth0();

  let location = useLocation();
  const currentUrl = "/" + location.pathname.split("/")[1];

  const { data: dbUser, isLoading: isUserLoading } = useEbicUser();

  // const { mutate: addLog } = useActivityLogMutations("CREATE");

  // // login activity log
  // useEffect(() => {
  //   if (isAuthenticated === true) {
  //     addLog(
  //       {
  //         alog_type: 'S',
  //         alog_module: 'O',
  //         alog_action: 'I',
  //         alog_accessfrom: 0,
  //         alog_modulename: '',
  //         alog_details: 'User Login',
  //         org_name: dbUser?.org_name?? null
  //       }
  //     )

  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isAuthenticated])

  //nav bar props
  const [expanded, setExpanded] = useState(false);
  const hideNavbar = () => {
    setTimeout(() => {
      setExpanded(false);
    }, 10);
  };

  if (!isAuthenticated && dbUser === undefined && !isUserLoading) {
    return <></>;
  }
  const navItemsSysAdmin = [
    {
      to: AllRoutes.HOME,
      Icon: currentUrl === AllRoutes.HOME ? House : HouseOutlined,
      name: "HOME 首页  ",
      disabled: false,
    },
    {
      to: AllRoutes.ORGANIZATIONS,
      Icon:
        currentUrl === AllRoutes.ORGANIZATIONS
          ? AccountTree
          : AccountTreeOutlined,
      name: "INSTITUTION 机构",
      disabled: false,
    },
    {
      to: AllRoutes.CONTENT,
      Icon:
        currentUrl === AllRoutes.CONTENT
          ? DriveFileRenameOutline
          : DriveFileRenameOutlineOutlined,
      name: "CONTENT 内容",
      disabled: false,
    },
    {
      to: AllRoutes.CLASSES,
      Icon: currentUrl === AllRoutes.CLASSES ? Widgets : WidgetsOutlined,
      name: "CLASSES 班级",
      disabled: false,
    },
  ];

  const navItemsUsers = [
    {
      to: AllRoutes.HOME,
      Icon: currentUrl === AllRoutes.HOME ? House : HouseOutlined,
      name: "HOME 首页",
      disabled: false,
    },
    {
      to: OrgRoutes.OWNORG,
      Icon: currentUrl === OrgRoutes.OWNORG ? AccountTree : AccountTreeOutlined,
      name: "INSTITUTION 机构",
      disabled: false,
    },
    {
      to: AllRoutes.CONTENT,
      Icon:
        currentUrl === AllRoutes.CONTENT
          ? DriveFileRenameOutline
          : DriveFileRenameOutlineOutlined,
      name: "CONTENT 内容",
      disabled: true,
    },
    {
      to: AllRoutes.CLASSES,
      Icon: currentUrl === AllRoutes.CLASSES ? Widgets : WidgetsOutlined,
      name: "CLASSES 班级",
      disabled: false,
    },
  ];

  const navItemsContentAdmin = [
    {
      to: AllRoutes.HOME,
      Icon: currentUrl === AllRoutes.HOME ? House : HouseOutlined,
      name: "HOME 首页",
      disabled: false,
    },
    {
      to: OrgRoutes.OWNORG,
      Icon: currentUrl === OrgRoutes.OWNORG ? AccountTree : AccountTreeOutlined,
      name: "INSTITUTION 机构",
      disabled: true,
    },
    {
      to: AllRoutes.CONTENT,
      Icon:
        currentUrl === AllRoutes.CONTENT
          ? DriveFileRenameOutline
          : DriveFileRenameOutlineOutlined,
      name: "CONTENT 内容",
      disabled: false,
    },
    {
      to: AllRoutes.CLASSES,
      Icon: currentUrl === AllRoutes.CLASSES ? Widgets : WidgetsOutlined,
      name: "CLASSES 班级",
      disabled: true,
    },
  ];

  const navItemsCoordinator = [
    {
      to: AllRoutes.HOME,
      Icon: currentUrl === AllRoutes.HOME ? House : HouseOutlined,
      name: "HOME 首页",
      disabled: false,
    },
    {
      to: OrgRoutes.OWNORG,
      Icon: currentUrl === OrgRoutes.OWNORG ? AccountTree : AccountTreeOutlined,
      name: "INSTITUTION 机构",
      disabled: false,
    },
    {
      to: AllRoutes.CLASSES,
      Icon: currentUrl === AllRoutes.CLASSES ? Widgets : WidgetsOutlined,
      name: "CLASSES 班级",
      disabled: false,
    },
  ];

  return (
    <>
      <Navbar
        style={{ height: "var(--navbar-height)" }}
        expand="lg"
        bg="white"
        variant="light"
        expanded={expanded}
        onToggle={(v) => setExpanded(v)}
      >
        <Container fluid className="navbar-wrapper h-100 bg-white">
          <div className="d-flex flex-column flex-lg-row flex-grow-1">
            <Navbar.Brand className="ms-3 ms-lg-4 h-100 p-0" href="/home">
              <img
                alt="ebiclogo"
                src="/logo.png"
                width="130"
                className="logo-name flex-shrink-0 user-select-none"
                draggable={false}
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              className="me-2"
            />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="bg-white px-2 pe-lg-3"
            >
              <div className="d-flex flex-column flex-lg-row flex-grow-1">
                <Nav className="me-auto">
                  {dbUser?.user_role === "S" &&
                    navItemsSysAdmin.map(
                      ({ to, Icon, name, disabled }, idx) => (
                        <NavLink
                          key={idx}
                          as={RouterNavLink}
                          to={to}
                          activeClassName="active"
                          className={"mx-1 " + styles.navItem}
                          style={{
                            pointerEvents: disabled ? "none" : undefined,
                            opacity: disabled ? "0.5" : undefined,
                          }}
                          onClick={() => hideNavbar()}
                        >
                          <div className="d-flex align-items-center text-uppercase">
                            <Icon fontSize="small" />
                            {name && <span className="ms-1">{name}</span>}
                          </div>
                        </NavLink>
                      )
                    )}
                  {dbUser?.user_role === "U" &&
                    navItemsUsers.map(({ to, Icon, name, disabled }, idx) => (
                      <NavLink
                        key={idx}
                        as={RouterNavLink}
                        to={to}
                        activeClassName="active"
                        className={"mx-1 " + styles.navItem}
                        style={{
                          pointerEvents: disabled ? "none" : undefined,
                          opacity: disabled ? "0.5" : undefined,
                        }}
                        onClick={() => hideNavbar()}
                      >
                        <div className="d-flex align-items-center text-uppercase">
                          <Icon fontSize="small" />
                          {name && <span className="ms-1">{name}</span>}
                        </div>
                      </NavLink>
                    ))}
                  {dbUser?.user_role === "O" &&
                    navItemsCoordinator.map(
                      ({ to, Icon, name, disabled }, idx) => (
                        <NavLink
                          key={idx}
                          as={RouterNavLink}
                          to={to}
                          activeClassName="active"
                          className={"mx-1 " + styles.navItem}
                          style={{
                            pointerEvents: disabled ? "none" : undefined,
                            opacity: disabled ? "0.5" : undefined,
                          }}
                          onClick={() => hideNavbar()}
                        >
                          <div className="d-flex align-items-center text-uppercase">
                            <Icon fontSize="small" />
                            {name && <span className="ms-1">{name}</span>}
                          </div>
                        </NavLink>
                      )
                    )}
                  {dbUser?.user_role === "C" &&
                    navItemsContentAdmin.map(
                      ({ to, Icon, name, disabled }, idx) => (
                        <NavLink
                          key={idx}
                          as={RouterNavLink}
                          to={to}
                          activeClassName="active"
                          className={"mx-1 " + styles.navItem}
                          style={{
                            pointerEvents: disabled ? "none" : undefined,
                            opacity: disabled ? "0.5" : undefined,
                          }}
                          onClick={() => hideNavbar()}
                        >
                          <div className="d-flex align-items-center text-uppercase">
                            <Icon fontSize="small" />
                            {name && <span className="ms-1">{name}</span>}
                          </div>
                        </NavLink>
                      )
                    )}
                </Nav>
              </div>

              <Nav>
                <UserDropdown />
              </Nav>
            </Navbar.Collapse>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default Navibar;

const UserDropdown = () => {
  const { logout } = useAuth0();

  const { data: dbUser } = useEbicUser();
  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const links = [["Profile 档案", AccountCircleOutlined, "/profile"]] as const;

  const links2 = [
    ["Users 用户", PeopleAlt, "/users"],
    ["Accouncements 公告", Campaign, "/announcements"],
    ["Products 产品", Inventory, "/products"],
    ["Reports 报告", Flag, "/reports"],
  ] as const;

  return (
    <Dropdown align="end" className="mx-2">
      <Dropdown.Toggle as={NavLink} className="d-flex align-items-center p-2">
        <UserAvatar
          user={dbUser}
          width={25}
          height={25}
          className="shadow-none"
        />
        <span className="ms-1">
          {dbUser?.user_firstname + " " + dbUser?.user_lastname}
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {links.map(([label, Icon, url], idx) => (
          <Dropdown.Item
            key={idx}
            as={RouterNavLink}
            to={url}
            className="d-flex align-items-center nav-link py-2"
          >
            <Icon fontSize="small" className="me-2" />
            {label}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        {dbUser?.user_role === "S" && (
          <>
            {links2.map(([label, Icon, url], idx) => (
              <Dropdown.Item
                key={idx}
                as={RouterNavLink}
                to={url}
                className="d-flex align-items-center nav-link py-2"
              >
                <Icon fontSize="small" className="me-2" />
                {label}
              </Dropdown.Item>
            ))}

            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item
          onClick={() => {
            addLog(
              {
                alog_type: "S",
                alog_module: "O",
                alog_action: "O",
                alog_accessfrom: 0,
                alog_modulename: "",
                alog_details: "User Logout",
                org_name: null,
              },
              {
                onSuccess: () => {
                  logout({ returnTo: window.location.origin });
                },
              }
            );
          }}
          className="py-2 nav-link"
        >
          <Logout fontSize="small" className="me-2" />
          Logout 登出
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
