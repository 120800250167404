import * as EBIC from "@ebic-bbrm/types";
import { FetchAnnouncementsOptions, useMainAnnouncements } from "../../../api/announcements.api";
import { useMemo, useState } from "react";
import AnnouncementsTable from "./AnnouncementsTable";
import IconButton from "../../utils/IconButton";
import { AddCircle, PlayCircle, StopCircle } from "@mui/icons-material";
import { Col } from "react-bootstrap";
import AddAnnouncementModal from "./AddAnnouncementModal";
import { useEbicUser } from "../../../api/user.api";
import useAppTitle from "../../../hooks/useAppTitle";
import useQuery from "../../../hooks/useQuery";
import { useHistory } from "react-router-dom";
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";

interface AnnouncementsProps {
    Announcements: EBIC.Announcements
}

//Maps for item keys in URL search
type SortKeys = "n" | "o";
const SORT_MAP: Record<SortKeys, FetchAnnouncementsOptions["sort"]> = {
    n: "Newest",
    o: "Oldest",

} as const;

type KeysWithType<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];
//Maps for item keys in URL search
type FilterKeys = 'a' | 'i';
/**
 * If undefined, we select `active_only` by default.
 * If null, we select public posts (no filter).
 */
const FILTER_MAP: Record<FilterKeys, KeysWithType<Required<FetchAnnouncementsOptions>, boolean> | null> = {
    a: "active",
    i: "inactive",

} as const;


const AllAnnouncements = ({ Announcements }: AnnouncementsProps) => {
    useAppTitle("Announcements");

    const search = useQuery();

    const params = useMemo(() => {
        const params: FetchAnnouncementsOptions = {};

        //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
        const query = decodeURIComponent(search.get("q") || "");

        if (query) params.query = query;

        const filterKey = FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
        //if null, no filter (all posts)
        if (filterKey !== null) {
            //set matching filter key to true;

            //if undefined, default to show `active` but only if not searching
            if (filterKey !== undefined || !query) {
                params[filterKey ?? 'active'] = true;
            }
        }

        const sortKey =
            SORT_MAP[decodeURIComponent(search.get("s") || "") as SortKeys] ??
            "Newest";

        params.sort = sortKey;

        return params;
    }, [search])

    const { anno: allAnno, query } = useMainAnnouncements(params);
    const { isLoading } = query;
    const { data: dbUser } = useEbicUser();

    const [showModal, setShowModal] = useState(false);

    const onCreateAnnouncementModalOpen = () => {
        setShowModal(true);
    };
    const onCreateAnnouncementModalClose = () => {
        setShowModal(false);
    };

    const AnnouncementsDetails = useMemo(() => {
        if (!allAnno) return [];
        let annoArray = [];
        if (Array.isArray(allAnno)) annoArray = allAnno;
        else annoArray = [allAnno];

        return annoArray;
        // eslint-disable-next-line
    }, [allAnno, Announcements]);

    return (

        <div className="p-5 pb-2">

            <div className="d-flex justify-content-end align-items-end h-25 me-3 mb-1 ms-4">
                <Col xs={12}>
                    <AnnoHeader />
                </Col>
                <Col>
                    <IconButton
                        Icon={AddCircle}
                        title="Add A Class"
                        onClick={() => onCreateAnnouncementModalOpen()}
                        style={{
                            backgroundColor: "transparent",
                            color: "#aa8067",
                        }}
                    />
                </Col>

            </div>


                <AnnouncementsTable
                    Announcements={Announcements}
                    AnnouncementsDetails={AnnouncementsDetails}
                    isLoading={isLoading}
                    
                />

            <AddAnnouncementModal
                show={showModal}
                onClose={onCreateAnnouncementModalClose}
                userId={dbUser?.user_id ?? 0}
            />


        </div>

    );
}

function AnnoHeader() {

    const history = useHistory();
    const search = useQuery();

    type Item = Omit<ToggleItem, 'active' | 'render'>;

    //filter
    const handleFilterChange = (filter: FilterKeys) => {
        search.set('f', filter);
        history.replace({
            search: search.toString()
        })
    }

    let activeFilter = decodeURIComponent(search.get('f') || "") as FilterKeys;
    if (!(activeFilter in FILTER_MAP)) activeFilter = 'a';

    const filterItems: Record<FilterKeys, Item> = {
        a: {
            Icon: PlayCircle,
            label: "Active 在线",
        },
        i: {
            Icon: StopCircle,
            label: "Inactive 已下线"
        },

    }


    return (
        <>
            <div className="d-flex flex-wrap align-items-center justify-content-between">

                <Col xs={2} className="ms-8 p-0">
                    <div className="d-flex flex-wrap align-items-center justify-content-between me-0 p-0">
                        <ToggleButtonGroup
                            items={Object.entries(filterItems).map(([key, item]) => ({
                                ...item,
                                key: key as FilterKeys,
                                active: key === activeFilter
                            }))}
                            onSelect={(item) => {
                                handleFilterChange(item.key)
                            }}
                        />
                    </div>
                </Col>


            </div>
        </>
    )

}

export default AllAnnouncements;