import { Redirect, Route, Switch } from "react-router-dom";
import { useEbicUser } from "./../../api/user.api";
import useAppTitle from "./../../hooks/useAppTitle";
import CourseCreate from "./Courses/CourseCreate";
import CourseEdit from "./Courses/CourseEdit";
import CoursesList from "./Courses/CoursesList";
import UnitsList from "./Units/UnitsList";
import UnitEdit from "./Units/UnitEdit";
import UnitCreate from "./Units/UnitCreate";
import LessonsList from "./Lessons/LessonsList";
import LessonCreate from "./Lessons/LessonCreate";
import LessonEdit from "./Lessons/LessonEdit";
import BootstrapSpinner from "../utils/BootstrapSpinner";
import ErrorPage from "../utils/ErrorPage";

const ContentManagement = () => {
  const { data: dbUser, isLoading: isUserLoading, error } = useEbicUser();

  if (!dbUser || isUserLoading) return <BootstrapSpinner />;

  return (
    <>
      {error !== null ? (
        <ErrorPage message={error.message} />
      ) : dbUser.user_role === "S" || dbUser.user_role === "C" ? (
        <Content />
      ) : (
        <p>You are not authorized to view this page.</p>
      )}
    </>
  );
};

export enum ContentRoutes {
  COURSES = "/content/courses",
  CREATECOURSE = "/content/courses/form",
  EDITCOURSE = "/content/courses/form/:courseId",

  UNITS = "/content/courses/:courseId/units",
  CREATEUNIT = "/content/courses/:courseId/units/form",
  EDITUNIT = "/content/courses/:courseId/units/form/:unitId",

  LESSONS = "/content/courses/:courseId/units/:unitId/lessons",
  CREATELESSON = "/content/courses/:courseId/units/:unitId/lessons/form",
  EDITLESSON = "/content/courses/:courseId/units/:unitId/lessons/form/:lessonId",
}

type TArgs =
  | [ContentRoutes]
  | [ContentRoutes, { [param: string]: string | number }];

export function createContentRoute(...args: TArgs) {
  const [path, params] = args;

  if (params === undefined) {
    return path;
  }
  //replace all params with the params passed
  return Object.entries(params).reduce(
    (previousValue: string, [param, value]) => {
      return previousValue.replace(`:${param}`, "" + value);
    },
    path
  );
}

const Content = () => {
  useAppTitle("Content");
  return (
    <Switch>
      <Route path={ContentRoutes.COURSES} exact={true}>
        <CoursesList />
      </Route>
      <Route path={ContentRoutes.EDITCOURSE}>
        <CourseEdit />
      </Route>
      <Route path={ContentRoutes.CREATECOURSE}>
        <CourseCreate />
      </Route>

      <Route path={ContentRoutes.UNITS} exact={true}>
        <UnitsList />
      </Route>
      <Route path={ContentRoutes.EDITUNIT} exact={true}>
        <UnitEdit />
      </Route>
      <Route path={ContentRoutes.CREATEUNIT}>
        <UnitCreate />
      </Route>

      <Route path={ContentRoutes.LESSONS} exact={true}>
        <LessonsList />
      </Route>
      <Route path={ContentRoutes.EDITLESSON}>
        <LessonEdit />
      </Route>
      <Route path={ContentRoutes.CREATELESSON}>
        <LessonCreate />
      </Route>

      {/* Redirect if no match */}
      <Redirect to={ContentRoutes.COURSES} />
    </Switch>
  );
};

export default ContentManagement;
