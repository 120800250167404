import { useRef, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import * as yup from "yup";
import { BaseModalProps } from "../../../../hooks/useModal";
import FileThumbnailPlaceholder from "../../../utils/FileThumbnailPlaceholder";
import {
  extractFileExtension,
  extractFileSize,
} from "../../../utils/HelperFunctions";
import IconButton from "../../../utils/IconButton";
import { Close, CloseRounded, Upload } from "@mui/icons-material";
import { Formik } from "formik";
import cloudUploadIcon from "../../../../assets/icons/cloud-upload.png";
import classNames from "classnames";
import styles from "../../cardstyles.module.css";
import {
  useDownloadableMutations,
  useUnitById,
} from "./../../../../api/units.api";
import { extractFileNameFromPath } from "./../../../utils/HelperFunctions";
import { allowedFileTypes } from "../TeachingGuideModal";
import { NewUnitDownloadableInterface } from "../UnitCreate";
import ErrorPage from "../../../utils/ErrorPage";
import { useActivityLogMutations } from "../../../../api/activitylog.api";

// Two different interfaces depending on whether creating from New Unit or from the edit page of created lesson
interface NewUnitCreateProps extends BaseModalProps {
  addDownloadable?: (downloadable: NewUnitDownloadableInterface) => void; // Add downloadable to downloadable array
  index?: number; // Current downloadable index to be passed in as the 'dnld_id'
  unitId?: never;
}

interface OldUnitCreateProps extends BaseModalProps {
  unitId?: number; // Create downloadable in existing lesson
  index?: never;
  addDownloadable?: never;
}

type DownloadablesModalProps = NewUnitCreateProps | OldUnitCreateProps;

export default function DownloadablesModal({
  show,
  onClose,
  unitId,
  index,
  addDownloadable,
  ...props
}: DownloadablesModalProps) {
  const handleClose = async () => {
    // Reset files and thumbnail
    setThumb(undefined);
    setFile([]);
    onClose();
  };

  const {
    mutate: createDownloadable,
    isLoading,
    error,
    progress,
  } = useDownloadableMutations("CREATE_DOWNLOADABLE");

  const { data: unit } = useUnitById(unitId ?? 0, unitId !== undefined);

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const validationSchema = yup.object().shape({
    dnld_name: yup.string().max(60, "Name must be less than 60 characters"),
    dnld_type: yup
      .string()
      .oneOf(allowedFileTypes, (obj) => `'${obj.value}' files aren't allowed.`),
  });

  // file form element
  const fileInput = useRef<HTMLInputElement>(null);
  const fileRef = useRef<File | null>(null);

  const [thumb, setThumb] = useState<JSX.Element>();

  const setFile = (files: File[]) => {
    if (files[0]) {
      fileRef.current = files[0];

      if (files[0].type.startsWith("image")) {
        //read image and set thumb
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            setThumb(
              <>
                <img
                  alt="thumbnail"
                  className="mb-2"
                  src={reader.result}
                  width={100}
                  height={100}
                />
                <span className="fw-bold">{files[0].name}</span>
              </>
            );
          }
        };
        reader.readAsDataURL(files[0]);
      } else {
        setThumb(
          <>
            <FileThumbnailPlaceholder
              className="mb-2"
              format={extractFileExtension(files[0].name) || "UNK"}
            />
            <span className="fw-bold">{files[0].name}</span>
          </>
        );
      }

      return files[0];
    }
    setThumb(undefined);
  };

  const [showDropOverlay, setShowDropOverlay] = useState(false);

  type UploadForm = {
    dnld_file?: string;
    dnld_name: string;
    dnld_size: number;
    dnld_type: string;
  };

  const initialValues: UploadForm = {
    dnld_file: undefined,
    dnld_name: "",
    dnld_size: 0,
    dnld_type: "",
  };

  const onSubmit = (values: UploadForm) => {
    const file = fileRef.current;

    if (file) {
      if (unitId) {
        // Create a downloadable using existing unitId
        createDownloadable(
          {
            unit_id: unitId,
            dnld_file: file,
            dnld_name: !values.dnld_name
              ? file.name
              : values.dnld_name.endsWith(`.${values.dnld_type}`)
              ? values.dnld_name
              : values.dnld_name.concat(`.${values.dnld_type}`),
          },
          {
            onSuccess: () => {
              addLog({
                alog_type: "I",
                alog_module: "C",
                alog_action: "A",
                alog_accessfrom: 0,
                alog_modulename: unit?.unit_name ?? "",
                alog_details: "Add Downloadables",
                org_name: null,
              });

              handleClose();
            },
            onError: () => {
              addLog({
                alog_type: "E",
                alog_module: "C",
                alog_action: "A",
                alog_accessfrom: 0,
                alog_modulename: unit?.unit_name ?? "",
                alog_details: "Add Downloadables Failed.",
                org_name: null,
              });

              handleClose();
            },
          }
        );
      } else if (addDownloadable) {
        // Create a downloadable using row.insertId in backend
        const newDownloadable = {
          dnld_id: index ?? 0,
          dnld_file: file,
          dnld_name: !values.dnld_name
            ? file.name
            : values.dnld_name.endsWith(`.${values.dnld_type}`)
            ? values.dnld_name
            : values.dnld_name.concat(`.${values.dnld_type}`),
        };

        addDownloadable(newDownloadable);
        handleClose();
      }
    }
  };

  if (!unitId && addDownloadable === undefined)
    return (
      <ErrorPage message="Unit not found. Unable to create a downloadable in an unknown unit." />
    );

  return (
    <Modal
      {...props}
      show={show}
      onHide={() => {
        handleClose();
      }}
      keyboard={!isLoading}
      backdrop={isLoading ? "static" : undefined}
      className="modal-layer-1"
      backdropClassName="modal-layer-1"
      centered
    >
      <Modal.Header>
        <Modal.Title>Upload a Document 上载文档</Modal.Title>
        <IconButton
          title="Close modal"
          Icon={CloseRounded}
          className="ms-auto"
          onClick={() => handleClose()}
        />
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          setFieldValue,
          handleSubmit,
          handleChange,
          errors,
          values,
          touched,
        }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Container className="small">
                  {/* Upload Area */}
                  <div
                    style={{ border: "1px dashed" }}
                    className={classNames({
                      "mt-2 mb-3 p-3 rounded border-secondary d-flex flex-column align-items-center justify-content-center":
                        true,
                      [styles.dropZone]: showDropOverlay,
                    })}
                    onDragEnter={(e) => {
                      if (e.dataTransfer.types.includes("Files")) {
                        setShowDropOverlay(true);
                      }
                    }}
                    onDragLeave={() => setShowDropOverlay(false)}
                    onDragOver={(e) => {
                      e.preventDefault();
                      if (e.dataTransfer.types.includes("Files")) {
                        e.dataTransfer.dropEffect = "copy";
                      }
                    }}
                    onDrop={(ev) => {
                      // Prevent default behaviour (Prevent file from being opened)
                      ev.preventDefault();
                      setShowDropOverlay(false);

                      const file =
                        Array.from(ev.dataTransfer.items)
                          .find((i) => i.kind === "file")
                          ?.getAsFile() || ev.dataTransfer.files.item(0);

                      if (file) {
                        setFile([file]);
                        setFieldValue("dnld_size", file.size ?? 0, true);
                        const extension = extractFileExtension(file.name ?? "");
                        setFieldValue("dnld_type", extension, true);
                        setFieldValue("dnld_name", file.name ?? "", true);
                      }
                    }}
                  >
                    <input
                      onChange={(ev) => {
                        const file = setFile(
                          Array.from(ev.currentTarget.files ?? [])
                        );
                        if (file) {
                          setFieldValue("dnld_size", file.size ?? 0, true);
                          const extension = extractFileExtension(
                            file.name ?? ""
                          );
                          setFieldValue("dnld_type", extension, true);
                          setFieldValue("dnld_name", file.name ?? "", true);
                        }
                        return false;
                      }}
                      ref={fileInput}
                      className="d-none"
                      type="file"
                      accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf,video/mp4,video/x-m4v,video/*,.3gp,.aac,.flac,.m4a,.ogg,.wav,.wma"
                    ></input>

                    <div className="mt-2 d-flex flex-column align-items-center">
                      {thumb ?? (
                        <img
                          alt=""
                          src={cloudUploadIcon}
                          draggable={false}
                          width={60}
                          className="mt-2"
                        ></img>
                      )}
                    </div>

                    <p style={{ fontSize: "large" }} className="m-0">
                      Drag &amp; Drop your file here or
                    </p>
                    <p style={{ fontSize: "large" }} className="mb-3">
                      拖放您的文件或
                    </p>
                    <IconButton
                      title="Browse for files 浏览文件"
                      label="Browse file 浏览文件"
                      onClick={() => fileInput.current?.click()}
                    />
                  </div>
                  {/* Inputs */}
                  <Row className="my-2">
                    <Form.Group as={Col}>
                      <Form.Label className="fw-bold">
                        File Name 文件名
                      </Form.Label>
                      <Form.Control
                        onChange={handleChange}
                        name="dnld_name"
                        value={values.dnld_name}
                        isInvalid={
                          touched.dnld_file &&
                          (!!errors.dnld_file ||
                            !!errors.dnld_size ||
                            !!errors.dnld_type)
                        }
                        type="text"
                        placeholder="File Name 文件名"
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.dnld_file ||
                          errors.dnld_size ||
                          errors.dnld_type}
                      </Form.Control.Feedback>
                      <Form.Label className="mt-1 text-muted">
                        {values.dnld_size && values.dnld_file ? (
                          <>
                            {extractFileNameFromPath(values.dnld_file)}
                            <span style={{ whiteSpace: "nowrap" }}>
                              {" "}
                              ({extractFileSize(values.dnld_size)})
                            </span>
                          </>
                        ) : (
                          "25 MB Limit"
                        )}
                      </Form.Label>
                    </Form.Group>
                  </Row>
                </Container>
              </Modal.Body>
              <Modal.Footer className="overflow-hidden">
                <p className="text-danger w-100 text-truncate">
                  {error?.message ||
                    errors.dnld_file ||
                    errors.dnld_name ||
                    errors.dnld_size ||
                    errors.dnld_type}
                </p>
                <IconButton
                  transparent
                  Icon={Close}
                  iconHtmlColor="var(--primary)"
                  className="px-4 me-3 border border-primary text-primary"
                  label="Cancel 取消"
                  onClick={() => handleClose()}
                />
                <IconButton
                  Icon={Upload}
                  type="submit"
                  className="px-4"
                  label={"Upload 上传"}
                />
                {isLoading && (
                  <div
                    className="bg-primary m-0"
                    style={{
                      position: "absolute",
                      left: "0",
                      bottom: "0",
                      width: `${progress}%`,
                      height: "5px",
                    }}
                  ></div>
                )}
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
