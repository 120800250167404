export module Content {
    export interface Course {
        cour_id: number;
        cour_name: string;
        cour_synopsis: string;
        cour_desc: string;
        cour_pic: string | null;
        cour_published: 0 | 1;

        cour_size: number;

    }

    export interface Unit {
        unit_id: number;
        cour_id: number;
        unit_seqno: number;
        cour_name: string;
        unit_name: string;
        unit_pic: string | null;
        unit_guidedoc: string | null;
        unit_guidename: string | null;
        unit_videourl: string | null;
        unit_published: 0 | 1;
    }

    export interface Lesson {
        less_id: number;
        unit_id: number;
        less_seqno: number;
        less_name: string;
        less_passage: string;
        less_passageCSL: string;
        less_pic: string | null;
        less_published: 0 | 1;

        cour_id: number;
    }

    export interface Downloadable {
        dnld_id: number;
        unit_id: number;
        dnld_file: string | null;
        dnld_name: string | null;
        dnld_date: string;
    }

    export interface Passage {
        pass_id: number;
        less_id: number;
        pass_seqno: number;
        pass_ref: string;
        pass_refCSL: string;
        pass_text: string;
        pass_textCSL: string;
        pass_audiofile: string | null;
        pass_audioname: string | null;
    }

    export interface Keyword {
        keyw_id: number;
        pass_id: number;
        keyw_seqno: number;
        pass_refCSL: string;
        keyw_text: string;
        keyw_textCSL: string;
        keyw_audiofile: string | null;
        keyw_audioname: string | null;
        keyw_explanation: string | null;
    }

    export interface Note {
        note_id: number;
        user_id: number;
        pass_id: number;
        note_text: string;
        note_private: 0 | 1;
        note_datetime?: string;
    }

    
}
