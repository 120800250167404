import * as EBIC from "@ebic-bbrm/types";
import { forwardRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useEbicUser } from "../../api/user.api";
import UserAvatar from "../Profile/UserAvatar";
import LineClampText from "../utils/LineClampText";

interface ClassMemberItemProps {
  classMember: EBIC.Classes.ClassMembers;
}

export const ClassMemberItem = forwardRef<HTMLDivElement, ClassMemberItemProps>(
  ({ classMember }, ref) => {

    const { data: dbUser } = useEbicUser();


    return (
      <div
        ref={ref}
        className="d-flex flex-column align-items-center p-3 ms-3 me-3 mt-2 mb-1 bg-light shadow rounded-4"
      >
        <div className="d-flex flex-row align-items-center align-self-stretch">
          <UserAvatar
            user={classMember}
            style={{
              aspectRatio: "1 / 1",
              width: "min(20%, 100px)",
              height: "auto",
            }}
            className="me-2 flex-shrink-0"
          />

          <div className="d-flex flex-column flex-grow-1">
            <Row>
              <Col xs={11} className="pe-0">
                <div className=" d-flex flex-row align-items-bottom fw-bold w-100 p-0">
                  <LineClampText
                    className="fw-bold m-0"
                    maxLines={1}>
                    {classMember?.user_id !== dbUser?.user_id
                      ? classMember?.user_firstname
                      : 'You'
                    }
                  </LineClampText>
                </div>
              </Col>

              <div className="text-normal mt-0">
                <LineClampText
                  className="fw-normal m-0"
                  maxLines={1}>
                  {classMember?.user_email !== null && (
                    <small>
                      <u>
                        {classMember?.user_email ?? ""}
                      </u>
                    </small>
                  )}
                </LineClampText>
              </div>
              <div className="text-normal mt-0">
                <LineClampText
                  className="fw-normal m-0"
                  maxLines={1}>
                  {classMember?.user_mobileno !== null && (
                    <small>
                      {classMember?.user_mobileno ?? ""}
                    </small>
                  )}
                </LineClampText>

              </div>
            </Row>

          </div>
        </div>

      </div>
    );


  })