import { BaseModalProps } from "../../../../hooks/useModal";
import * as yup from "yup";
import {
  usePassageMutations,
  CreatePassageInterface,
} from "./../../../../api/lessons.api";
import ErrorPage from "../../../utils/ErrorPage";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import {
  extractFileExtension,
  formCloseHandler,
} from "../../../utils/HelperFunctions";
import IconButton from "./../../../utils/IconButton";
import { CloseRounded, Upload } from "@mui/icons-material";
import { BootstrapInput } from "../../../utils/FormikBootstrapInputs";
import { NewLessonPassageInterface } from "../LessonCreate";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// Two different interfaces depending on whether creating from New Lesson or from the edit page of created lesson
interface NewLessonCreateProps extends BaseModalProps {
  addPassage?: (passage: NewLessonPassageInterface) => void; // Add passage to passage array
  index?: number; // Current passage index to be passed in as the 'pass_id'
  lessonId?: never;
}

interface OldLessonCreateProps extends BaseModalProps {
  lessonId?: number; // Create passage in existing lesson
  index?: number; // Last passage's seqno + 1
  addPassage?: never;
}

type PassageCreateModalProps = NewLessonCreateProps | OldLessonCreateProps;

export const allowedAudioTypes = [
  "3gp",
  "aac",
  "flac",
  "mp3",
  "m4a",
  "ogg",
  "wav",
  "wma",
];

export default function PassageCreateModal({
  show,
  onClose,
  addPassage,
  index,
  lessonId,
  ...props
}: PassageCreateModalProps) {
  // file form element
  const fileInput = useRef<HTMLInputElement>(null);
  const fileRef = useRef<File | null>(null);

  const {
    mutate: createPassage,
    isLoading,
    error,
    progress,
    reset,
  } = usePassageMutations("CREATE_PASSAGE");

  useEffect(() => {
    reset();
  }, [show, reset]);

  const [isFocus, setIsFocus] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const [isFocusCSL, setIsFocusCSL] = useState(false);
  const [isClickedCSL, setIsClickedCSL] = useState(false);
  const [editorStateCSL, setEditorStateCSL] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    const state = EditorState.createEmpty();
    setEditorState(state);

    const stateCSL = EditorState.createEmpty();

    setEditorStateCSL(stateCSL);
    setIsClicked(false);
    setIsClickedCSL(false);
  }, [onClose]);

  interface CreatePassageForm
    extends Omit<
      CreatePassageInterface,
      "less_id" | "pass_audiofile" | "pass_audioname" | "pass_seqno"
    > {
    pass_audiofile?: Blob;
    pass_audioname?: string;
    pass_audiotype?: string;
  }

  const initialValues: CreatePassageForm = {
    pass_ref: "",
    pass_refCSL: "",
    pass_text: "",
    pass_textCSL: "",
    pass_audiofile: undefined,
    pass_audioname: undefined,
    pass_audiotype: undefined,
  };

  const validationSchema = yup.object().shape({
    pass_ref: yup
      .string()
      .required("Passage reference is required")
      .max(
        60,
        ({ max }) => `Passage reference cannot be more than ${max} characters`
      ),
    pass_refCSL: yup
      .string()
      .required("Chinese passage reference is required")
      .max(
        60,
        ({ max }) =>
          `Chinese passage reference cannot be more than ${max} characters`
      ),
    pass_text: yup
      .string()
      .required("Passage text is required")
      .trim()
      .min(8, "Passage text is required")
      .nullable(),
    pass_textCSL: yup
      .string()
      .required("Chinese passage text is required")
      .trim()
      .min(8, "Chinese passage text is required")
      .nullable(),
    pass_audiofile: yup.string(),
    pass_audioname: yup
      .string()
      .max(60, ({ max }) => `Name cannot be more than ${max} characters`),
    pass_audiotype: yup
      .string()
      .oneOf(
        allowedAudioTypes,
        (obj) => `'${obj.value}' files aren't allowed.`
      ),
  });

  const onSubmit = (values: CreatePassageForm, { resetForm }: any) => {
    const file = fileRef.current;
    if (lessonId) {
      // Create a passage 加新经文 using existing lesson ID
      createPassage(
        {
          less_id: lessonId,
          pass_seqno: index ?? 0,
          pass_ref: values.pass_ref,
          pass_refCSL: values.pass_refCSL,
          pass_text: values.pass_text,
          pass_textCSL: values.pass_textCSL,
          pass_audiofile: file ?? undefined,
          pass_audioname: values.pass_audioname ?? undefined,
        },
        {
          onSuccess: () => {
            resetForm();
            onClose();
          },
        }
      );
    } else if (addPassage) {
      // Create a passage 加新经文 using row.insertId in backend
      const newPassage = {
        pass_id: index ?? 0,
        pass_ref: values.pass_ref,
        pass_refCSL: values.pass_refCSL,
        pass_text: values.pass_text,
        pass_textCSL: values.pass_textCSL,
        pass_audiofile: file ?? undefined,
        pass_audioname: values.pass_audioname ?? undefined,
      };
      addPassage(newPassage);
      resetForm();
      onClose();
    }
  };

  if (!lessonId && addPassage === undefined)
    return (
      <ErrorPage message="Lesson not found. Unable to create passage in an unknown passage." />
    );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        values,
        errors,
        resetForm,
      }) => {
        const setFieldValuePassageEN = () => {
          let text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

          // Remove beginning p tag
          // if (text.startsWith("<p>")) {
          //   text = text.slice("<p>".length);
          // } else if (text.startsWith('<p style="text-align:start;">')) {
          //   text = text.slice('<p style="text-align:start;">'.length);
          // } else if (text.startsWith('<p style="text-align:left;">')) {
          //   text = text.slice('<p style="text-align:left;">'.length);
          // }
          // // Remove end p tag
          // text = text.substring(0, text.length - 5);

          setFieldValue("pass_text", text);
        };

        const setFieldValuePassageCSL = () => {
          let text = draftToHtml(
            convertToRaw(editorStateCSL.getCurrentContent())
          );

          // if (text.startsWith("<p>")) {
          //   text = text.slice("<p>".length);
          // } else if (text.startsWith('<p style="text-align:start;">')) {
          //   text = text.slice('<p style="text-align:start;">'.length);
          // } else if (text.startsWith('<p style="text-align:left;">')) {
          //   text = text.slice('<p style="text-align:left;">'.length);
          // }

          // text = text.substring(0, text.length - 5);
          setFieldValue("pass_textCSL", text);
        };

        const setFile = (files: File[]) => {
          if (files[0]) {
            fileRef.current = files[0];
            const file = fileRef.current;

            if (file) {
              setFieldValue("pass_audiofile", file, true);
              setFieldValue("pass_audioname", file.name, true);
              const extension = extractFileExtension(file.name ?? "");
              setFieldValue("pass_audiotype", extension, true);
            }
            return files[0];
          }
        };

        const handleClose = () => {
          // Reset files
          setFile([]);
          if (error !== null) error.message = "";
          resetForm();
          onClose();
        };

        return (
          <Modal
            {...props}
            centered
            show={show}
            onHide={() => {
              formCloseHandler(handleClose, dirty);
            }}
            keyboard={!isLoading}
            backdrop={isLoading ? "static" : undefined}
            className="modal-layer-1"
            backdropClassName="modal-layer-1"
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Create a Passage 加新经文</Modal.Title>
              <IconButton
                title="Close modal"
                Icon={CloseRounded}
                className="ms-auto"
                onClick={() => {
                  formCloseHandler(handleClose, dirty);
                }}
              />
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <input
                  onChange={(ev) => {
                    const file = setFile(
                      Array.from(ev.currentTarget.files ?? [])
                    );
                    if (file) {
                      setFieldValue("pass_audiofile", file, true);
                      const extension = extractFileExtension(file.name ?? "");
                      setFieldValue("pass_audiotype", extension, true);
                      setFieldValue("pass_audioname", file.name ?? "", true);
                    }
                    return false;
                  }}
                  ref={fileInput}
                  className="d-none"
                  type="file"
                  accept="audio/*"
                ></input>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Reference 引用 (EN)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="pass_ref"
                      required
                      placeholder="Matt 1:1-2, 6"
                      aria-label="Reference (EN)"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Reference 引用 (中文)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="pass_refCSL"
                      required
                      placeholder="⻢太福⾳ 1:1-2, 6"
                      aria-label="Reference (中文)"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Text 经⽂ (EN)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Editor
                      onFocus={() => {
                        setFieldValuePassageEN();
                        setIsFocus(true);
                        setIsClicked(true);
                      }}
                      onBlur={() => {
                        setFieldValuePassageEN();
                        setIsFocus(false);
                      }}
                      placeholder="The book of the genealogy of Jesus Christ, the son of David, the son of Abraham. Abraham was the father of Isaac, and Isaac the father of Jacob, and Jacob the father of Judah and his brothers."
                      wrapperClassName="card"
                      toolbarClassName="rounded border-0 border-bottom mb-0"
                      editorClassName="card-body pt-0"
                      editorStyle={{
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        maxHeight: "7.5rem",
                      }}
                      editorState={editorState}
                      onEditorStateChange={(e) => setEditorState(e)}
                      toolbar={{
                        options: ["inline", "history"],
                      }}
                    />
                    {errors.pass_text &&
                      isFocus === false &&
                      isClicked === true && (
                        <span className="invalid-feedback d-block">
                          {errors.pass_text}
                        </span>
                      )}
                    {/* <BootstrapInput
                      id="pass_text"
                      as="textarea"
                      style={{ minHeight: "5rem" }}
                      placeholder="The book of the genealogy of Jesus Christ, the son of David, the son of Abraham. Abraham was the father of Isaac, and Isaac the father of Jacob, and Jacob the father of Judah and his brothers."
                      aria-label="Passage Text"
                      disabled={isLoading || isSubmitting}
                    /> */}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Text 经⽂ (中文)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Editor
                      onFocus={() => {
                        setFieldValuePassageCSL();
                        setIsFocusCSL(true);
                        setIsClickedCSL(true);
                      }}
                      onBlur={() => {
                        setFieldValuePassageCSL();
                        setIsFocusCSL(false);
                      }}
                      placeholder="亚伯拉罕的裔、⼤卫的⼦孙耶稣基督的家谱: 亚伯拉罕⽣以撒，以撒⽣雅各，雅各⽣犹⼤和他的兄弟。"
                      wrapperClassName="card"
                      toolbarClassName="rounded border-0 border-bottom mb-0"
                      editorClassName="card-body pt-0"
                      editorStyle={{
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        maxHeight: "7.5rem",
                      }}
                      editorState={editorStateCSL}
                      onEditorStateChange={(e) => setEditorStateCSL(e)}
                      toolbar={{
                        options: ["inline", "history"],
                      }}
                    />
                    {errors.pass_textCSL &&
                      isFocusCSL === false &&
                      isClickedCSL === true && (
                        <span className="invalid-feedback d-block">
                          {errors.pass_textCSL}
                        </span>
                      )}

                    {/* <BootstrapInput
                      id="pass_textCSL"
                      as="textarea"
                      style={{ minHeight: "5rem" }}
                      placeholder="亚伯拉罕的裔、⼤卫的⼦孙耶稣基督的家谱: 亚伯拉罕⽣以撒，以撒⽣雅各，雅各⽣犹⼤和他的兄弟。"
                      aria-label="Chinese Passage Text"
                      disabled={isLoading || isSubmitting}
                    /> */}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Audio File ⾳频⽂件
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Upload an audio file 上传音频文件"
                        readOnly
                        value={
                          values.pass_audioname
                            ? values.pass_audioname.toString()
                            : ""
                        }
                      />
                      <Button
                        onClick={() => fileInput.current?.click()}
                        title={"Upload an audio file 上传音频文件"}
                        disabled={isLoading || isSubmitting}
                      >
                        <Upload />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer className="overflow-hidden">
                <p className="text-danger w-100 text-truncate">
                  {error?.message ||
                    errors.pass_audiofile ||
                    errors.pass_audioname ||
                    errors.pass_audiotype}
                </p>
                <div className="d-flex align-items-center">
                  <Button
                    variant="outline-primary"
                    className="px-4 me-3 border border-primary text-primary"
                    title="Cancel"
                    disabled={isLoading || isSubmitting}
                    onClick={() => formCloseHandler(handleClose, dirty)}
                  >
                    Cancel 取消
                  </Button>
                  <Button
                    className="text-uppercase"
                    title="Save"
                    type="submit"
                    disabled={isLoading || isSubmitting}
                  >
                    Save 保存
                  </Button>
                </div>
                {isLoading && (
                  <div
                    className="bg-primary m-0"
                    style={{
                      position: "absolute",
                      left: "0",
                      bottom: "0",
                      width: `${progress}%`,
                      height: "5px",
                    }}
                  ></div>
                )}
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
