import classNames from "classnames";
import { useState } from "react";
import { Image } from "react-bootstrap";
import * as EBIC from "@ebic-bbrm/types";
import socialStyles from "../Profile/social.module.css";

interface OrgAvatarProps extends React.HTMLAttributes<HTMLImageElement> {
  width: number;
  height?: number;

  clickable?: boolean;
  Organization: Pick<EBIC.Organization.Profile, "org_pic"> &
    Partial<Pick<EBIC.Organization.Profile, "org_id" | "org_name">>;

  /**
   * If `src` is passed in props, it will always take precedence, unless there's an error
   */
  src?: string;
}
export default function OrgAvatar({
  width,
  height,
  clickable = false,
  Organization,
  style,
  className,
  src: srcFromProps,
  ...props
}: OrgAvatarProps) {
  const [isError, setIsError] = useState(false);

  let ele: JSX.Element;
  if (
    isError ||
    (Organization.org_pic === null && srcFromProps === undefined)
  ) {
    ele = (
      <div
        {...props}
        className={classNames(
          className,
          clickable && socialStyles.userAvatar,
          "rounded-circle fw-bold d-flex align-items-center justify-content-center text-light"
        )}
        style={{
          ...style,
          width,
          height,
          fontSize: width / 2,
          backgroundColor: "#00a3f0",
          filter: `hue-rotate(${((Organization.org_id ?? 0) % 10) * 45}deg)`,
          userSelect: "none",
        }}
      >
        {Organization.org_name?.charAt(0).toUpperCase() || "W"}
      </div>
    );
  } else {
    ele = (
      <Image
        width={width}
        height={height}
        onError={() => setIsError(true)}
        className={classNames(
          className,
          clickable && socialStyles.userAvatar,
          "rounded-circle"
        )}
        src={srcFromProps ?? Organization.org_pic!}
        style={{
          objectFit: "cover",
          ...style,
        }}
        {...props}
      />
    );
  }

  return ele;
}
