import classNames from "classnames";
import React, { useState } from "react";
import { Card, Image } from "react-bootstrap";
import OrgProfileSetupCompany from "./OrgProfileSetupCompany";
import businessOwnerIcon from "../../assets/icons/organizations-busines-owner.png";

export type IProfileType = "C" | "S";

const OrgProfileSetup = () => {
  const [profileType, setProfileType] = useState<IProfileType>();

  return (
    <div>
      {profileType === undefined ? (
        <>
          <h3>I am a...</h3>

          <div className="d-flex">
            <CardItem
              title="Institution In Charge"
              iconSrc={businessOwnerIcon}
              titleText="Institution In Charge"
              descriptionText="I would like to create an Institution."
              handleClick={() => setProfileType("C")}
            />
          </div>
        </>
      ) : (
        <>
          <Card body>
            {profileType === "C" && (
              <OrgProfileSetupCompany setProfileType={setProfileType} />
            )}
          </Card>
        </>
      )}
    </div>
  );
};

interface CardItemProps extends React.HTMLAttributes<HTMLButtonElement> {
  iconSrc: string;
  titleText: string;
  descriptionText: string;
  handleClick: () => void;
  className?: string;
}

const CardItem = ({
  iconSrc,
  titleText,
  descriptionText,
  handleClick,
  className,
  ...props
}: CardItemProps) => {
  return (
    <Card
      as="button"
      className={classNames("card-clickable", className && className)}
      onClick={handleClick}
      {...props}
    >
      <Card.Body>
        <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
          <Image className="mb-3" src={iconSrc} width={80} height={80} />
          <h6 className="mb-0 ms-1">{titleText}</h6>
        </div>

        <span className="text-muted">{descriptionText}</span>
      </Card.Body>
    </Card>
  );
};

export default OrgProfileSetup;
