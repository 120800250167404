import {
  EditCourseInterface,
  useCourseMutations,
} from "../../../api/courses.api";
import { useEffect, useRef, useState } from "react";
import { AttachmentFile } from "../../../types/custom";
import * as yup from "yup";
import { extractFileSize, getFileWithUrl } from "../../utils/HelperFunctions";
import { Formik } from "formik";
import { Col, Form, Row, Image, Button } from "react-bootstrap";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import { Link, useHistory, useParams } from "react-router-dom";
import imgPlaceHolder from "../../../assets/img_placeholder.jpg";
import IconButton from "../../utils/IconButton";
import { Upload } from "@mui/icons-material";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import { useCourseById } from "./../../../api/courses.api";
import ErrorPage from "../../utils/ErrorPage";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useActivityLogMutations } from "../../../api/activitylog.api";

export default function CourseEdit() {
  const { courseId } = useParams<{ courseId: string | undefined }>();

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const {
    data: course,
    isLoading: courseLoading,
    error: courseError,
  } = useCourseById(parseInt(courseId ?? "0"), courseId !== undefined);

  const history = useHistory();
  const {
    mutate: editCourse,
    isLoading,
    error,
  } = useCourseMutations("EDIT_COURSE");

  useEffect(() => {
    setFile(undefined);
  }, []);

  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  interface EditCourseForm
    extends Omit<EditCourseInterface, "cour_id" | "cour_pic"> {}

  const initialValues: EditCourseForm = {
    cour_name: course?.cour_name ?? "",
    cour_synopsis: course?.cour_synopsis ?? "",
    cour_desc: course?.cour_desc ?? "",
  };

  const validationSchema = yup.object().shape({
    cour_name: yup
      .string()
      .required("Course name is required")
      .max(
        80,
        ({ max }) => `Course name cannot be more than ${max} characters`
      ),
    cour_synopsis: yup
      .string()
      .required("Course synopsis is required")
      .max(
        120,
        ({ max }) => `Course synopsis cannot be more than ${max} characters`
      ),
    cour_desc: yup
      .string()
      .required("Course description is required")
      .max(
        200,
        ({ max }) => `Course description cannot be more than ${max} characters`
      ),
  });

  const onSubmit = (values: EditCourseForm) => {
    editCourse(
      {
        cour_id: course?.cour_id ?? 0,
        cour_name: values.cour_name,
        cour_synopsis: values.cour_synopsis,
        cour_desc: values.cour_desc,
        cour_pic: file?.file,
      },
      {
        onSuccess: () => {
          history.push(createContentRoute(ContentRoutes.COURSES));
          addLog({
            alog_type: "I",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: values.cour_name ?? "",
            alog_details: "Edit Courses",
            org_name: null,
          });
        },
        onError: () => {
          history.push(createContentRoute(ContentRoutes.COURSES));
          addLog({
            alog_type: "E",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: values.cour_name ?? "",
            alog_details: "Edit Courses Failed.",
            org_name: null,
          });
        },
      }
    );
  };

  if (courseError) return <ErrorPage message={courseError.message} />;

  if (courseLoading) return <BootstrapSpinner />;

  return (
    <div className="p-5">
      <h3
        className="text-uppercase mb-5"
        style={{ color: "var(--bs-gray-600)" }}
      >
        <Link
          to={createContentRoute(ContentRoutes.COURSES)}
          className="linkUnderline"
        >
          Courses 课程
        </Link>
        <span className="text-black"> &#xBB; </span>
        {course?.cour_name}
      </h3>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit, setFieldValue, errors, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col
                md={3}
                className="d-flex flex-column align-items-center justify-content-start"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      await getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    } // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                ></input>
                {file !== undefined ? (
                  <Image
                    src={file.url}
                    title={
                      file.file.name + " " + extractFileSize(file.file.size)
                    }
                    className={
                      "shadow-none rounded-circle border border-secondary "
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Image
                    src={course?.cour_pic ?? imgPlaceHolder}
                    className={
                      "shadow-none rounded-circle border border-secondary"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                    title="Course Picture"
                  />
                )}
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black mt-3 fw-bold"
                  title="Upload a course photo"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                />
              </Col>
              <Col>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Course Name 课程名
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="cour_name"
                      required
                      placeholder="中学系列S1A"
                      aria-label="Course Name"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Course Summary 课程总结
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="cour_synopsis"
                      required
                      placeholder="Summary"
                      aria-label="Course Summary"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Course Description 课程描述
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="cour_desc"
                      required
                      as="textarea"
                      style={{ minHeight: "120px" }}
                      placeholder="Description"
                      aria-label="Course Description"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center">
                      <Button
                        className="text-uppercase me-2"
                        title="Cancel"
                        variant="outline-primary"
                        onClick={() =>
                          history.push(
                            createContentRoute(ContentRoutes.COURSES)
                          )
                        }
                      >
                        Cancel 取消
                      </Button>
                      <Button
                        className="text-uppercase"
                        title="Save"
                        type="submit"
                      >
                        Save 保存
                      </Button>
                    </div>
                  </div>
                </Row>
                {error !== null ? (
                  <Row className="text-danger">{error?.message}</Row>
                ) : null}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}
