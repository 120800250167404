import { Link } from 'react-router-dom';
import { serverErrorObject } from './HelperFunctions';
import IconButton from './IconButton';

interface ErrorPageInterface {
  status?: number;
  message?: string;
  showLink?: boolean;
  handleRetry?: () => void;
}

const ErrorPage = ({status = serverErrorObject.status, message = serverErrorObject.message, showLink = false, handleRetry}: ErrorPageInterface) => {
  return (
    <div className="page-wrap d-flex flex-row align-items-center">
      <div className="container">
        <div className="row justify-content-center my-3">
          <div className="col-md-12 text-center">
            <h1>Whoops!</h1>
            <div className="lead">{message} (Code: {status})</div>
            {
              handleRetry !== undefined && (
                <IconButton
                  label="Retry"
                  onClick={handleRetry}
                  className="mx-auto my-4"
                />
              )
            }
            {showLink ? (
              <Link to="/" className="btn btn-link">
                Back to Home
              </Link>
            ) : undefined}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
