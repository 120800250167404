import {
  orgAllowedKeys,
  OrganizationTypeDropdown,
  OrgForm,
  useOrgProfileCompeletion,
} from "./CheckUserModal";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useState, useRef } from "react";
import * as yup from "yup";
import { AttachmentFile } from "../../../types/custom";
import { Formik, FormikProps } from "formik";
import OrgAvatar from "../../Organizations/OrgAvatar";
import IconButton from "../../utils/IconButton";
import { getFileWithUrl } from "../../utils/HelperFunctions";
import { Upload } from "@mui/icons-material";
import {
  BootstrapInput,
  BootstrapSelectCountry,
} from "../../utils/FormikBootstrapInputs";
import { RegionDropdown } from "react-country-region-selector";

const OrgProfileCreation = () => {
  const { orgForm, setOrgForm, goNextPage, goPrevPage, isLoading } =
    useOrgProfileCompeletion();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [imageReadError, setImageReadError] = useState<string>();
  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  const initialValues: Pick<
    OrgForm,
    | "org_name"
    | "org_type"
    | "org_desc"
    | "org_address"
    | "org_coord"
    | "org_unitno"
    | "org_country"
    | "org_state"
    | "org_city"
    | "org_website"
  > = {
    org_name: orgForm.org_name,
    org_type: "C",
    org_desc: orgForm.org_desc,
    org_address: orgForm.org_address,
    org_coord: orgForm.org_coord,
    org_unitno: orgForm.org_unitno,
    org_country: orgForm.org_country,
    org_state: orgForm.org_state,
    org_city: orgForm.org_city,
    org_website: orgForm.org_website,
  };

  const validationSchema = yup.object({
    org_name: yup
      .string()
      .required("Institution Name is required")
      .max(120, ({ max }) => `Maximum ${max} characters`),
    org_desc: yup
      .string()
      .max(512, ({ max }) => `Maximum ${max} characters`)
      .nullable(),
    org_type: yup
      .string()
      .oneOf(orgAllowedKeys)
      .default("C")
      .required("Institution type is required"),

    org_website: yup
      .string()
      .max(120, ({ max }) => `Maximum ${max} characters`)
      .url("invalid url")
      .nullable(),
    org_country: yup.string().required("Country is required"),
    org_address: yup
      .string()
      .max(256, ({ max }) => `Maximum ${max} characters`)
      .required("Address is required"),
    org_state: yup
      .string()
      .max(80, ({ max }) => `Maximum ${max} characters`)
      .nullable(),
    org_city: yup
      .string()
      .max(80, ({ max }) => `Maximum ${max} characters`)
      .nullable(),
    org_unitno: yup
      .string()
      .max(10, ({ max }) => `Maxmium ${max} characters`)
      .nullable(),
  });

  const onSubmit = (
    values: Pick<
      OrgForm,
      | "org_name"
      | "org_type"
      | "org_desc"
      | "org_address"
      | "org_coord"
      | "org_unitno"
      | "org_country"
      | "org_state"
      | "org_city"
      | "org_website"
    >
  ) => {
    setOrgForm((prev) => ({
      ...prev,
      ...values,
      org_pic: file?.file ?? null,
    }));

    goNextPage();
  };

  const formikRef =
    useRef<
      FormikProps<
        Pick<
          OrgForm,
          | "org_name"
          | "org_type"
          | "org_desc"
          | "org_address"
          | "org_coord"
          | "org_unitno"
          | "org_country"
          | "org_state"
          | "org_city"
          | "org_website"
        >
      >
    >(null);

  return (
    <>
      <Row>
        <h1 className="text-muted mb-3">
          Please provide&nbsp;
          <span className="text-primary">Institutional Information</span>{" "}
          请提供机构的资料
        </h1>
      </Row>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldError,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row className="justify-content-md-center mt-1">
              <Col
                xs="12"
                lg="3"
                xl="2"
                className="d-flex flex-column align-items-center mb-3 mb-lg-0"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    }
                    // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                />
                <OrgAvatar
                  Organization={{
                    org_id: -1,
                    org_name: "Institution Profile",
                    org_pic: null,
                  }}
                  width={140}
                  height={140}
                  src={file?.url}
                  className="mb-3 shadow-sm"
                />

                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black mt-3 fw-bold"
                  type="button"
                  title="Upload Profile Image"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                  disabled={isLoading}
                  style={{
                    width: 150,
                    maxWidth: "100%",
                    fontSize: "12px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </Col>
              <Col xs="12" lg="9" xl="10">
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <Form.Label
                      className="mb-0 text-uppercase small fw-bold"
                      htmlFor="org_type"
                    >
                      Type 类型 <span className="text-danger">*</span>
                    </Form.Label>
                    <OrganizationTypeDropdown
                      values={values.org_type}
                      isSubmitting={isSubmitting}
                      setFieldValue={setFieldValue}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Institution Name 机构名称
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="org_name"
                      placeholder="Name"
                      required={true}
                      aria-label="Institution Name"
                      disabled={isLoading}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Description 描述
                    </Form.Label>
                    <BootstrapInput
                      id="org_desc"
                      as="textarea"
                      rows="2"
                      placeholder="Institution Profile Description"
                      aria-label="Institution Profile Description"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>

                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg="9">
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Address 地址
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="org_address"
                      placeholder="Address 地址"
                      aria-label="Address 地址"
                      disabled={isLoading}
                      required={true}
                    />
                  </Form.Group>

                  <Form.Group as={Col} lg="3">
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      {" "}
                    </Form.Label>
                    <BootstrapInput
                      id="org_unitno"
                      placeholder="Unit No. 门牌号 (#01-10)"
                      aria-label="Unit No"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <BootstrapSelectCountry
                      required={true}
                      id="org_country"
                      disabled={isLoading}
                    />
                    <Form.Control.Feedback>
                      {errors.org_country}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <RegionDropdown
                      blankOptionLabel="Select a country first 请先选择国家"
                      defaultOptionLabel="Select a region 选择地区"
                      name="org_state"
                      valueType="short"
                      countryValueType="short"
                      country={values.org_country ?? ""}
                      value={values.org_state ?? ""}
                      onChange={(val: string) =>
                        setFieldValue("org_state", val)
                      }
                      disabled={isLoading}
                      classes="form-select"
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="org_city"
                      placeholder="City 城市"
                      aria-label="City 城市"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Institution Website 网站
                    </Form.Label>
                    <BootstrapInput
                      id="org_website"
                      placeholder="https://example.com"
                      aria-label="Institution Website"
                      disabled={isLoading}
                      required={false}
                    />
                  </Form.Group>
                </Row>
              </Col>
              <div className="d-flex flex-wrap justify-content-end">
                <Button
                  variant="outline-primary"
                  className="me-2"
                  onClick={() => {
                    // save current page data
                    setOrgForm((prev) => ({
                      ...prev,
                      ...values,
                      org_pic: file?.file ?? null,
                    }));

                    goPrevPage();
                  }}
                  disabled={isLoading}
                >
                  BACK 上一步
                </Button>
                <Button type="submit" disabled={isLoading}>
                  NEXT 下一步
                </Button>
              </div>
              {imageReadError !== undefined && (
                <p className="w-100 text-danger">{imageReadError}</p>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default OrgProfileCreation;
