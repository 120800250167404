import {
  EditLessonInterface,
  useKeywordMutations,
  useLessonById,
  useLessonMutations,
  usePassageMutations,
  usePassages,
} from "../../../api/lessons.api";
import { useEffect, useMemo, useRef, useState } from "react";
import { AttachmentFile } from "../../../types/custom";
import * as yup from "yup";
import { extractFileSize, getFileWithUrl } from "../../utils/HelperFunctions";
import { Formik } from "formik";
import { Col, Form, Row, Image, Button, InputGroup } from "react-bootstrap";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import { Link, useHistory, useParams } from "react-router-dom";
import imgPlaceHolder from "../../../assets/img_placeholder.jpg";
import IconButton from "../../utils/IconButton";
import { Upload, Add } from "@mui/icons-material";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import EmptyMessage from "../../utils/EmptyMessage";
import { useUnitById } from "../../../api/units.api";
import ErrorPage from "../../utils/ErrorPage";
import EbicCard from "../../utils/EbicCard";
import PassageCreateModal from "./Passages/PassageCreateModal";
import PassageItem from "./Passages/PassageItem";
import BootstrapSpinner from "../../utils/BootstrapSpinner";
import { useKeywords } from "./../../../api/lessons.api";
import KeywordItem from "./Keywords/KeywordItem";
import KeywordCreateModal from "./Keywords/KeywordCreateModal";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useActivityLogMutations } from "../../../api/activitylog.api";

export default function LessonEdit() {
  const { unitId } = useParams<{ unitId: string | undefined }>();
  const { data: unit, error: unitError } = useUnitById(
    parseInt(unitId ?? "0"),
    unitId !== undefined
  );

  const { lessonId } = useParams<{ lessonId: string | undefined }>();
  const {
    data: lesson,
    isLoading: lessonLoading,
    error: lessonError,
  } = useLessonById(parseInt(lessonId ?? "0"), lessonId !== undefined);

  const {
    data: passages,
    isLoading: passagesLoading,
    error: passagesError,
  } = usePassages(
    { less_id: parseInt(lessonId ?? "0") },
    lessonId !== undefined
  );

  // Next seqno for the next passage
  const passageSeqNo = useMemo(() => {
    if (!passages || !passages[passages.length - 1]) return 0;

    return passages[passages.length - 1].pass_seqno + 1;
  }, [passages]);

  const { mutate: rearrangePassage, isLoading: rearrangePassageLoading } =
    usePassageMutations("REARRANGE_PASSAGE");

  function handleOnDragEndPassage(result: any) {
    if (!result.destination) return;
    rearrangePassage({
      sourceSeqNo: result.source.index,
      destinationSeqNo: result.destination.index,
      lessonId: parseInt(result.destination.droppableId),
    });
  }

  const {
    data: keywords,
    isLoading: keywordsLoading,
    error: keywordsError,
  } = useKeywords(
    { less_id: parseInt(lessonId ?? "0") },
    lessonId !== undefined
  );

  const keywordSeqNo = useMemo(() => {
    if (!keywords || !keywords[keywords.length - 1]) return 0;

    return keywords[keywords.length - 1].keyw_seqno + 1;
  }, [keywords]);

  const { mutate: rearrangeKeyword, isLoading: rearrangeKeywordLoading } =
    useKeywordMutations("REARRANGE_KEYWORD");

  function handleOnDragEndKeyword(result: any) {
    if (!result.destination) return;
    rearrangeKeyword({
      sourceSeqNo: result.source.index,
      destinationSeqNo: result.destination.index,
      lessonId: parseInt(lessonId ?? "0"),
    });
  }

  const [showPassageCreateModal, setShowPassageCreateModal] = useState(false);
  const [showKeywordCreateModal, setShowKeywordCreateModal] = useState(false);

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const history = useHistory();
  const {
    mutate: editLesson,
    isLoading,
    error,
  } = useLessonMutations("EDIT_LESSON");

  useEffect(() => {
    setFile(undefined);
  }, []);

  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  interface EditLessonForm
    extends Omit<EditLessonInterface, "less_pic" | "less_id"> {}

  const initialValues: EditLessonForm = {
    less_name: lesson?.less_name ?? "",
    less_passage: lesson?.less_passage ?? "",
    less_passageCSL: lesson?.less_passageCSL ?? "",
  };

  const validationSchema = yup.object().shape({
    less_name: yup
      .string()
      .required("Lesson name is required")
      .max(
        80,
        ({ max }) => `Lesson name cannot be more than ${max} characters`
      ),
    less_passage: yup
      .string()
      .required("Lesson passage is required")
      .max(
        100,
        ({ max }) => `Lesson passage cannot be more than ${max} characters`
      ),
    less_passageCSL: yup
      .string()
      .required("Chinese lesson passage is required")
      .max(
        100,
        ({ max }) =>
          `Chinese lesson passage cannot be more than ${max} characters`
      ),
  });

  const onSubmit = (values: EditLessonForm) => {
    editLesson(
      {
        less_id: lesson?.less_id ?? 0,
        less_name: values.less_name,
        less_passage: values.less_passage,
        less_passageCSL: values.less_passageCSL,
        less_pic: file?.file,
      },
      {
        onSuccess: () => {
          history.push(
            createContentRoute(ContentRoutes.LESSONS, {
              courseId: unit?.cour_id ?? "0",
              unitId: unit?.unit_id ?? "0",
            })
          );
          addLog({
            alog_type: "I",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: lesson?.less_name ?? "",
            alog_details: "Edit Lessons",
            org_name: null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "C",
            alog_action: "E",
            alog_accessfrom: 0,
            alog_modulename: lesson?.less_name ?? "",
            alog_details: "Edit Lessons Failed.",
            org_name: null,
          });
        },
      }
    );
  };

  if (unitError || lessonError)
    return <ErrorPage message={unitError?.message || lessonError?.message} />;
  if (lessonLoading) return <BootstrapSpinner />;

  if (!lesson)
    return (
      <EmptyMessage message="Lesson not found. Unable to edit an unknown lesson." />
    );

  return (
    <div className="p-5">
      <h3
        className="text-uppercase mb-4"
        style={{ color: "var(--bs-gray-600)" }}
      >
        <Link
          to={createContentRoute(ContentRoutes.UNITS, {
            courseId: unit?.cour_id.toString() ?? "0",
          })}
          className="linkUnderline"
        >
          {unit?.cour_name}
        </Link>
        <span className="text-black"> &#xBB; </span>
        <Link
          to={createContentRoute(ContentRoutes.LESSONS, {
            courseId: unit?.cour_id.toString() ?? "0",
            unitId: parseInt(unitId ?? "0"),
          })}
          className="linkUnderline"
        >
          {unit?.unit_name}
        </Link>
        <span className="text-black"> &#xBB; </span>
        {lesson?.less_name}
      </h3>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col
                md={3}
                className="d-flex flex-column align-items-center justify-content-start"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      await getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    } // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                ></input>
                {file !== undefined ? (
                  <Image
                    src={file.url}
                    title={
                      file.file.name + " " + extractFileSize(file.file.size)
                    }
                    className={
                      "shadow-none rounded-circle border border-secondary "
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Image
                    src={lesson.less_pic ?? imgPlaceHolder}
                    className={
                      "shadow-none rounded-circle border border-secondary"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                    title="Image"
                  />
                )}
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black mt-3 fw-bold"
                  title="Upload a lesson photo"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                />
              </Col>
              <Col>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Lesson Name 课名
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="less_name"
                      required
                      placeholder="God's Perfect Plan 上帝奇妙的计划"
                      aria-label="Lesson Name"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Passage 圣经经文
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <InputGroup>
                          <BootstrapInput
                            id="less_passage"
                            required
                            placeholder="Matt 1:1-2, 6, 11-12a, 16-24"
                            aria-label="English Passage"
                            aria-describedby="passageEN"
                            disabled={isLoading || isSubmitting}
                            displayError={false}
                          />
                          <InputGroup.Text id="passageEN">EN</InputGroup.Text>
                          {errors?.less_passage && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.less_passage}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Col>
                      <Col>
                        <InputGroup>
                          <BootstrapInput
                            id="less_passageCSL"
                            required
                            placeholder="⻢太福⾳1:1-2, 6, 11-12a, 16-24"
                            aria-label="Chinese Passage"
                            aria-describedby="passageCN"
                            disabled={isLoading || isSubmitting}
                            displayError={false}
                          />
                          <InputGroup.Text id="passageCN">中</InputGroup.Text>
                          {errors?.less_passageCSL && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.less_passageCSL}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <EbicCard
                      items={{ title: "Passages 经文" }}
                      cardProps={() => ({
                        style: {
                          height: "12rem",
                          overflowY: "auto",
                        },
                      })}
                      rightSide={() => {
                        return (
                          <IconButton
                            disabled={
                              isLoading ||
                              passagesLoading ||
                              isSubmitting ||
                              rearrangePassageLoading
                            }
                            Icon={Add}
                            onClick={() => setShowPassageCreateModal(true)}
                          />
                        );
                      }}
                    >
                      {passagesError ? (
                        <EmptyMessage
                          message={`Error fetching passages, ${passagesError.message}`}
                        />
                      ) : passagesLoading || rearrangePassageLoading ? (
                        <BootstrapSpinner />
                      ) : !passages || passages.length === 0 ? (
                        <p className="text-muted">No passages yet.</p>
                      ) : (
                        <DragDropContext onDragEnd={handleOnDragEndPassage}>
                          <Droppable
                            droppableId={passages[0].less_id.toString()}
                          >
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {passages.map((passage) => (
                                  <Draggable
                                    key={passage.pass_id}
                                    draggableId={passage.pass_seqno.toString()}
                                    index={passage.pass_seqno}
                                  >
                                    {(provided) => (
                                      <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <PassageItem
                                          key={passage.pass_id}
                                          passage={passage}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    </EbicCard>
                  </Col>
                  <Col>
                    <EbicCard
                      items={{ title: "Keywords ⽣词" }}
                      cardProps={() => ({
                        style: {
                          height: "12rem",
                          overflowY: "auto",
                        },
                      })}
                      rightSide={() => {
                        return (
                          <IconButton
                            disabled={
                              isLoading ||
                              isSubmitting ||
                              keywordsLoading ||
                              passagesLoading ||
                              rearrangeKeywordLoading
                            }
                            Icon={Add}
                            onClick={() => setShowKeywordCreateModal(true)}
                          />
                        );
                      }}
                    >
                      {keywordsError ? (
                        <EmptyMessage
                          message={`Error fetching keywords, ${keywordsError.message}`}
                        />
                      ) : passagesLoading ||
                        keywordsLoading ||
                        rearrangeKeywordLoading ? (
                        <BootstrapSpinner />
                      ) : !keywords || keywords.length === 0 ? (
                        <p className="text-muted">No keywords yet.</p>
                      ) : (
                        <DragDropContext onDragEnd={handleOnDragEndKeyword}>
                          <Droppable droppableId={lessonId ?? "0"}>
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                {keywords.map((keyword) => (
                                  <Draggable
                                    key={keyword.keyw_id}
                                    draggableId={keyword.keyw_seqno.toString()}
                                    index={keyword.keyw_seqno}
                                  >
                                    {(provided) => (
                                      <div
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <KeywordItem
                                          key={keyword.keyw_id}
                                          passages={passages ?? []}
                                          keyword={keyword}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      )}
                    </EbicCard>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center">
                      <Button
                        className="text-uppercase me-2"
                        title="Cancel"
                        variant="outline-primary"
                        disabled={isLoading || isSubmitting}
                        onClick={() =>
                          history.push(
                            createContentRoute(ContentRoutes.LESSONS, {
                              courseId: unit?.cour_id ?? 0,
                              unitId: unit?.unit_id ?? 0,
                            })
                          )
                        }
                      >
                        Cancel 取消
                      </Button>
                      <Button
                        className="text-uppercase"
                        title="Save"
                        type="submit"
                        disabled={isLoading || isSubmitting}
                      >
                        Save 保存
                      </Button>
                    </div>
                  </div>
                </Row>
                {error !== null ? (
                  <Row className="text-danger">{error?.message}</Row>
                ) : null}
              </Col>
            </Row>
            <PassageCreateModal
              show={showPassageCreateModal}
              onClose={() => setShowPassageCreateModal(false)}
              lessonId={lesson.less_id}
              index={passageSeqNo}
            />
            <KeywordCreateModal
              show={showKeywordCreateModal}
              onClose={() => setShowKeywordCreateModal(false)}
              passages={passages ?? []}
              index={keywordSeqNo}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
