import { Card, Dropdown, Image } from "react-bootstrap";
import styles from "../cardstyles.module.css";
import ebicPlaceholder from "../../../assets/img_placeholder.jpg";
import { useHistory } from "react-router-dom";
import { HTMLAttributes } from "react";
import * as EBIC from "@ebic-bbrm/types";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import IconButton from "../../utils/IconButton";
import { DeleteOutline, EditOutlined, MoreHoriz } from "@mui/icons-material";
import { useCourseMutations } from "./../../../api/courses.api";
import DestructiveModal from "../../utils/DestructiveModal";
import useModal from "../../../hooks/useModal";
import LineClampText from "../../utils/LineClampText";
import { useActivityLogMutations } from "../../../api/activitylog.api";

interface CourseItemProps extends HTMLAttributes<HTMLDivElement> {
  course: EBIC.Content.Course;
}

export default function CoursesItem({ course }: CourseItemProps) {
  const history = useHistory();
  const { mutate: deleteCourse, error } = useCourseMutations("DELETE_COURSE");
  const { mutate: publishCourse } = useCourseMutations("PUBLISH_COURSE");

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const [deleteModal, openDelete] = useModal(DestructiveModal, {
    title: "Delete course 删除课程",
    description: (
      <>
        <p>
          Delete the {course.cour_name} course? All units and lessons under this
          course would be deleted as well!
        </p>
        <p>删除《{course.cour_name}》课程? 课程里的所有单元和课也将被删除!</p>
      </>
    ),

    onConfirm: async () => {
      await deleteCourse(course.cour_id, {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "C",
            alog_action: "D",
            alog_accessfrom: 0,
            alog_modulename: course.cour_name ?? "",
            alog_details: "Delete Courses",
            org_name: null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "C",
            alog_action: "D",
            alog_accessfrom: 0,
            alog_modulename: course.cour_name ?? "",
            alog_details: "Delete Courses Failed.",
            org_name: null,
          });
          alert(error?.message);
        },
      });
    },
    confirmBtnText: "Delete 删除",
  });

  const [publishModal, openPublishModal] = useModal(DestructiveModal, {
    title: "Publish Course 公布课程",
    description: (
      <>
        <p>
          Publish the {course.cour_name} course? The course will be publicly
          available for viewing in the mobile app.
        </p>
        <p>公布《{course.cour_name}》课程? 课程将在手机应用程序公开。</p>
      </>
    ),
    onConfirm: async () => {
      await publishCourse({ cour_id: course.cour_id, cour_published: 1 });
    },
    confirmBtnText: "Publish 公布",
  });

  const [unpublishModal, openUnpublishModal] = useModal(DestructiveModal, {
    title: "Unpublish Course 取消公布课程",
    description: (
      <>
        <p>
          Unpublish the {course.cour_name} course? The course will no longer be
          publicly available for viewing in the mobile app.
        </p>
        <p>
          取消公布《{course.cour_name}》课程? 课程将不再在手机应用程序公开。
        </p>
      </>
    ),
    onConfirm: async () => {
      await publishCourse({ cour_id: course.cour_id, cour_published: 0 });
    },
    confirmBtnText: "Unpublish 取消公布",
  });

  return (
    <>
      <Card className={"border-0 shadow h-100 " + styles.card}>
        <div className={"card-img-top " + styles.cardImageContainer}>
          <Card.Img
            className={"cursor-pointer " + styles.cardImage}
            as={Image}
            src={course.cour_pic ?? ebicPlaceholder}
            onClick={() =>
              history.push(
                createContentRoute(ContentRoutes.UNITS, {
                  courseId: course.cour_id,
                })
              )
            }
            variant="top"
          />
        </div>
        <Card.Body
          className="p-3 cursor-pointer"
          onClick={() =>
            history.push(
              createContentRoute(ContentRoutes.UNITS, {
                courseId: course.cour_id,
              })
            )
          }
        >
          <LineClampText
            className={"fw-bold mb-3 " + styles.linkUnderline}
            maxLines={1}
          >
            {course.cour_name}
          </LineClampText>
          <LineClampText className="card-text small" maxLines={4}>
            {course.cour_desc ?? "No description."}
          </LineClampText>
        </Card.Body>
        <Card.Footer className="d-flex p-0">
          <IconButton
            transparent
            border={false}
            Icon={EditOutlined}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              history.push(
                createContentRoute(ContentRoutes.EDITCOURSE, {
                  courseId: course.cour_id,
                })
              );
              ev.stopPropagation();
            }}
            title={"Edit 修改"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomLeftRadius: 12,
            }}
          />
          <div className="vr my-1" />
          <Dropdown
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
            onToggle={(_, meta) => {
              meta.originalEvent?.stopPropagation();
              meta.originalEvent?.preventDefault();
            }}
          >
            <Dropdown.Toggle as="span" bsPrefix=" ">
              <IconButton
                transparent
                border={false}
                Icon={MoreHoriz}
                iconHtmlColor="var(--primary)"
                title={"Status"}
                className="py-3"
                style={{ width: "100%", borderRadius: 0 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {course.cour_published === 1 ? (
                <Dropdown.Item onClick={openUnpublishModal}>
                  Unpublish 取消公布
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={openPublishModal}>
                  Publish 公布
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <div className="vr my-1" />
          <IconButton
            transparent
            border={false}
            Icon={DeleteOutline}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              openDelete();
              ev.stopPropagation();
            }}
            title={"Delete"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
          />
          {deleteModal}
          {publishModal}
          {unpublishModal}
        </Card.Footer>
      </Card>
    </>
  );
}
