import * as EBIC from "@ebic-bbrm/types";
import ReactTable from "../../utils/ReactTable";
import { useMemo } from "react";
import { Column } from "react-table";
import { Container } from "react-bootstrap";
import { formatDate } from "../../utils/HelperFunctions";

interface ActivityLogTableInterface {
  ActivityLog: EBIC.ActivityLog.LogInfo;
  ActivityLogDetails: EBIC.ActivityLog.LogInfo[];

  isLoading: boolean;
}

const ActivityLogTable = ({
  ActivityLog,
  ActivityLogDetails,
  isLoading,
}: ActivityLogTableInterface) => {
  //table columns
  const activityLogColumns = useMemo<Column<EBIC.ActivityLog.LogInfo>[]>(
    () => [
      {
        Header: "Date",
        accessor: "alog_datetime",
        minWidth: 5,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>{`${value}` ? formatDate(`${value}`, "short") : ""}</div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">
                  {`${value}` ? formatDate(`${value}`, "long") : ""}
                </div>
              )}
              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">
                  {`${value}` ? formatDate(`${value}`, "long") : ""}
                </div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">
                  {`${value}` ? formatDate(`${value}`, "long") : ""}
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Institution",
        accessor: "org_name",
        minWidth: 20,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>{value ? `${value}` : "No Institution"}</div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">
                  {value ? `${value}` : "No Institution"}
                </div>
              )}
              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">
                  {value ? `${value}` : "No Institution"}
                </div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">
                  {value ? `${value}` : "No Institution"}
                </div>
              )}
            </>
          );
        },
      },

      {
        Header: "User",
        accessor: "user_email",
        minWidth: 7,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>{` ${value}` ? `${value}` : "No User"}</div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">
                  {` ${value}` ? `${value}` : "No User"}
                </div>
              )}
              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">
                  {` ${value}` ? `${value}` : "No User"}
                </div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">
                  {` ${value}` ? `${value}` : "No User"}
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Module",
        accessor: "alog_module",
        minWidth: 10,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>
                  {
                    EBIC.ActivityLog.ALogModule[
                      `${ActivityLogDetails.alog_module}`
                    ] as EBIC.ActivityLog.ALogModule
                  }
                </div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">
                  {
                    EBIC.ActivityLog.ALogModule[
                      `${ActivityLogDetails.alog_module}`
                    ] as EBIC.ActivityLog.ALogModule
                  }
                </div>
              )}
              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">
                  {
                    EBIC.ActivityLog.ALogModule[
                      `${ActivityLogDetails.alog_module}`
                    ] as EBIC.ActivityLog.ALogModule
                  }
                </div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">
                  {
                    EBIC.ActivityLog.ALogModule[
                      `${ActivityLogDetails.alog_module}`
                    ] as EBIC.ActivityLog.ALogModule
                  }
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Module Name",
        accessor: "alog_modulename",
        minWidth: 30,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>{value ?? "No Module Name"}</div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">{value ?? "No Module Name"}</div>
              )}

              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">{value ?? "No Module Name"}</div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">{value ?? "No Module Name"}</div>
              )}
            </>
          );
        },
      },

      {
        Header: "Action",
        accessor: "alog_action",
        minWidth: 5,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>
                  {
                    EBIC.ActivityLog.ALogAction[
                      `${ActivityLogDetails.alog_action}`
                    ] as EBIC.ActivityLog.ALogAction
                  }
                </div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">
                  {
                    EBIC.ActivityLog.ALogAction[
                      `${ActivityLogDetails.alog_action}`
                    ] as EBIC.ActivityLog.ALogAction
                  }
                </div>
              )}
              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">
                  {
                    EBIC.ActivityLog.ALogAction[
                      `${ActivityLogDetails.alog_action}`
                    ] as EBIC.ActivityLog.ALogAction
                  }
                </div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">
                  {
                    EBIC.ActivityLog.ALogAction[
                      `${ActivityLogDetails.alog_action}`
                    ] as EBIC.ActivityLog.ALogAction
                  }
                </div>
              )}
            </>
          );
        },
      },
      {
        Header: "Details",
        accessor: "alog_details",
        minWidth: 30,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>{value ?? "No Details"}</div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">{value ?? "No Details"}</div>
              )}

              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">{value ?? "No Details"}</div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">{value ?? "No Details"}</div>
              )}
            </>
          );
        },
      },

      {
        Header: "W/A",
        accessor: "alog_accessfrom",
        minWidth: 10,
        Cell: ({ value, row }) => {
          const ActivityLogDetails = row.original;
          return (
            <>
              {ActivityLogDetails.alog_type === "I" && (
                <div>{value === 0 ? "Web" : "App"}</div>
              )}
              {ActivityLogDetails.alog_type === "W" && (
                <div className="text-warning">
                  {value === 0 ? "Web" : "App"}
                </div>
              )}
              {ActivityLogDetails.alog_type === "E" && (
                <div className="text-danger">{value === 0 ? "Web" : "App"}</div>
              )}
              {ActivityLogDetails.alog_type === "S" && (
                <div className="text-info">{value === 0 ? "Web" : "App"}</div>
              )}
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [ActivityLog]
  );

  return (
    <Container>
      <ReactTable
        data={ActivityLogDetails}
        columns={activityLogColumns}
        isLoading={isLoading}
        placeholderMessage={"There are no Reports yet."}
        pagination={false}
        showRowCount={false}
        Row={(props, data) => <tr {...props} />}
        style={{ borderColor: "white" }}
      ></ReactTable>
    </Container>
  );
};

export default ActivityLogTable;
