import * as EBIC from "@ebic-bbrm/types";
import { HTMLAttributes } from "react";
import { Card, Dropdown, Image } from "react-bootstrap";
import styles from "./asset.module.css";
import ebicPlaceholder from "../../../assets/img_placeholder.jpg";
import LineClampText from "../../utils/LineClampText";
import IconButton from "../../utils/IconButton";
import { EditOutlined, DeleteOutline, MoreHoriz } from "@mui/icons-material";
import useModal from "../../../hooks/useModal";
import UserDeleteModal from "./UserDeleteModal";
import UserEditModal from "./UserEditModal";
import { useUserMutations } from "../../../api/user.api";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { useOrgProfileById } from "../../../api/organization.api";

interface UserItemProps extends HTMLAttributes<HTMLDivElement> {
  users: EBIC.User.User;
}

export default function UserItem({ users }: UserItemProps) {
  const { mutate: editStatus } = useUserMutations("EDIT_STATUS");

  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: organization } = useOrgProfileById(users.org_id);

  const [editUserModal, openUserEdit] = useModal(UserEditModal, {
    orgId: users.org_id,
    userId: users.user_id,
  });
  const [deleteModal, openDelete] = useModal(UserDeleteModal, {
    users: users,
  });

  const onActive = () => {
    editStatus(
      {
        user_id: users.user_id,
        user_status: 1,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "U",
            alog_action: "P",
            alog_accessfrom: 0,
            alog_modulename: users.user_email,
            alog_details: "Activate User Account",
            org_name: organization?.org_name ?? null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "U",
            alog_action: "P",
            alog_accessfrom: 0,
            alog_modulename: users.user_email,
            alog_details: "Activate User Account Failed",
            org_name: organization?.org_name ?? null,
          });
        },
      }
    );
  };

  const onInactive = () => {
    editStatus(
      {
        user_id: users.user_id,
        user_status: 0,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "U",
            alog_action: "U",
            alog_accessfrom: 0,
            alog_modulename: users.user_email,
            alog_details: "Deactivate User Account",
            org_name: organization?.org_name ?? null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "U",
            alog_action: "U",
            alog_accessfrom: 0,
            alog_modulename: users.user_email,
            alog_details: "Deactivate User Account Failed.",
            org_name: organization?.org_name ?? null,
          });
        },
      }
    );
  };

  return (
    <>
      <Card className={"border-0 shadow h-100 " + styles.userListCard}>
        <div className={"card-img-top " + styles.userListCardImageContainer}>
          <Card.Img
            className={"cursor-pointer " + styles.userListCardImage}
            as={Image}
            onClick={(ev) => {
              openUserEdit();
              ev.stopPropagation();
            }}
            src={users.user_pic ?? ebicPlaceholder}
            variant="top" // needed to have proper corner radius
          />
        </div>
        <Card.Body className="p-3">
          <LineClampText
            maxLines={1}
            title={users.user_firstname}
            className={"fw-bold m-0 "}
          >
            {users.user_firstname + " " + users.user_lastname}
          </LineClampText>
          <LineClampText
            maxLines={1}
            title={users.user_email}
            className="text-muted m-0"
            style={{ fontSize: "0.81rem" }}
          >
            {users.user_email}
          </LineClampText>
          <LineClampText
            maxLines={1}
            title={users.org_name}
            className="text-muted m-0"
            style={{ fontSize: "0.81rem" }}
          >
            {users.org_name}
          </LineClampText>
          <LineClampText
            maxLines={1}
            title={String(users.isTeacher)}
            className="text-muted m-0"
            style={{ fontSize: "0.81rem" }}
          >
            {users.isTeacher === 1 ? "Teacher" : ""}
          </LineClampText>
        </Card.Body>
        <Card.Footer className="d-flex p-0">
          <IconButton
            transparent
            border={false}
            Icon={EditOutlined}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              openUserEdit();
              ev.stopPropagation();
            }}
            title={"Edit"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomLeftRadius: 12,
            }}
          />
          <div className="vr my-1" />
          <Dropdown
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
            onToggle={(_, meta) => {
              meta.originalEvent?.stopPropagation();
              meta.originalEvent?.preventDefault();
            }}
          >
            <Dropdown.Toggle as="span" bsPrefix=" ">
              <IconButton
                transparent
                border={false}
                Icon={MoreHoriz}
                iconHtmlColor="var(--primary)"
                title={"Status"}
                className="py-3"
                style={{ width: "100%", borderRadius: 0 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {users.user_status === 0 && (
                <Dropdown.Item
                  onClick={(ev) => {
                    onActive();
                    ev.stopPropagation();
                  }}
                >
                  Active 在线
                </Dropdown.Item>
              )}
              {users.user_status === 1 && (
                <Dropdown.Item
                  onClick={(ev) => {
                    onInactive();
                    ev.stopPropagation();
                  }}
                >
                  Deactivate 离线
                </Dropdown.Item>
              )}


            </Dropdown.Menu>
          </Dropdown>
          <div className="vr my-1" />
          <IconButton
            transparent
            border={false}
            Icon={DeleteOutline}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              openDelete();
              ev.stopPropagation();
            }}
            title={"Delete"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
          />
        </Card.Footer>
        {editUserModal}
        {deleteModal}
      </Card>
    </>
  );
}
