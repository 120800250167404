import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { Modal, Form } from "react-bootstrap";
import { CloseRounded } from '@mui/icons-material';
import IconButton from '../../utils/IconButton';
import * as yup from "yup";
import { Formik } from 'formik';
import { useAnnouncementsMutations } from '../../../api/announcements.api';
import { useActivityLogMutations } from '../../../api/activitylog.api';


interface AddAnnouncementModalProps {
    show: boolean;
    onClose: () => void;

    userId: number;
}

interface AddAnnouncementForm {
    anno_subject: string;
    anno_text: string;
    anno_expire: string;
}

function AddAnnouncementModal({ show, onClose, userId }: AddAnnouncementModalProps) {

    const { mutate: addAnno, isLoading } = useAnnouncementsMutations('CREATE');

     const { mutate: addLog } = useActivityLogMutations("CREATE");


    const initialValues: AddAnnouncementForm = {

        anno_subject: "",
        anno_text: "",
        anno_expire: ""
    };

    const validationSchema = yup.object().shape({
        anno_subject: yup
            .string()
            .required("Please enter a Subject")
            .max(100, "Maximum 100 characters"),
        anno_text: yup
            .string()
            .required("Please enter more details"),
        anno_expire: yup
            .string()
            .required("Please key in the Announcement expiry date")

    })

    const handleAdd = async (values: AddAnnouncementForm, { resetForm }: any) => {
        addAnno(
            {
                user_id: userId,
                ...values,

            },
            {
                onSuccess: () => {
                    addLog(
                        {
                            alog_type: 'I',
                            alog_module: 'A',
                            alog_action: 'A',
                            alog_accessfrom: 0,
                            alog_modulename: values.anno_subject,
                            alog_details: 'Add Announcement',
                            org_name: null
                        }
                    )
                    resetForm();
                    onClose();
                },
                onError: () => {
                    addLog(
                        {
                            alog_type: 'E',
                            alog_module: 'A',
                            alog_action: 'A',
                            alog_accessfrom: 0,
                            alog_modulename: values.anno_subject,
                            alog_details: 'Add Announcement Failed.',
                            org_name: null
                        }
                    )
                  
                },


            }
        );
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleAdd}
            >
                {({ touched, errors, values, handleBlur, handleSubmit, setFieldError, setFieldValue }) => (
                    <Modal
                        show={show}
                        centered
                        onHide={onClose}
                        backdrop={isLoading ? 'static' : undefined}
                        keyboard={!isLoading}
                    >
                        <Modal.Header className="p-3">
                            <Modal.Title className="fw-normal">
                                Add new Announcement 添加新公告
                            </Modal.Title>
                            <IconButton
                                Icon={CloseRounded}
                                title="Close"
                                className="ms-auto"
                                disabled={isLoading}
                                onClick={onClose}
                            />
                        </Modal.Header>
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="small px-4 py-3">
                                <div className="d-flex flex-grow-1">
                                    <div className="mb-2 d-flex flex-column flex-grow-1">
                                        <BootstrapInput
                                            id="anno_subject"
                                            required={true}
                                            label="Subject 主题"
                                            placeholder="Subject"
                                            aria-label="Subject"
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <BootstrapInput
                                        id="anno_text"
                                        as="textarea"
                                        rows="2"
                                        required={true}
                                        label="Details 公告內容"
                                        placeholder="Text"
                                        aria-label="Text"
                                        disabled={isLoading}
                                        className="col-xs-4 mb-2"
                                    />
                                </div>
                                <div>

                                    <BootstrapInput
                                        id="anno_expire"
                                        type="date"
                                        placeholder="Expire Date "
                                        aria-label="Expire Date"
                                        disabled={isLoading}
                                        required={true}
                                        label="Expiry Date 结束日期"
                                        className="col-xs-4"

                                    />
                                </div>


                            </Modal.Body>
                            <Modal.Footer>

                                <IconButton
                                    iconHtmlColor="var(--primary)"
                                    disabled={isLoading}
                                    label="CANCEL 取消"
                                    transparent
                                    className="text-primary border-primary px-4 me-3"
                                    onClick={() => onClose()}
                                />
                                <IconButton
                                    disabled={isLoading}
                                    type="submit"
                                    label="SAVE 保存"
                                    className="px-4"
                                />
                            </Modal.Footer>

                        </Form>

                    </Modal>
                )}
            </Formik>

        </>

    )


}

export default AddAnnouncementModal;