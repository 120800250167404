import * as EBIC from "@ebic-bbrm/types";
import { Row, Col, Image } from "react-bootstrap";
import { useDownloadables } from "../../api/units.api";
import { useDownloadWithPostToken } from "../../api/useEbicAPI";
import imgPlaceHolder from "../../assets/img_placeholder.jpg";
import useModal from "../../hooks/useModal";
import BootstrapSpinner from "../utils/BootstrapSpinner";
import IconButton from "../utils/IconButton";
import TeachingGuideViewer from "./TeachingGuideViewer";

interface ClassesUnitViewInterface {
  units: EBIC.Content.Unit;
}

const ClassesUnitView = ({ units }: ClassesUnitViewInterface) => {
  const { data: downloadables, isLoading: isDownloadLoading } =
    useDownloadables({ unit_id: units.unit_id }, units.unit_id !== undefined);

  const download = useDownloadWithPostToken();

  const [teachingGuideModal, openTeachingGuideModal] = useModal(
    TeachingGuideViewer,
    {
      unitid: units.unit_id,
      guidename: units.unit_guidename ?? "",
    }
  );

  if (downloadables === undefined || isDownloadLoading)
    return <BootstrapSpinner />;

  const dl = downloadables.filter((d) => (d.unit_id = units.unit_id));

  return (
    <>
      <Row className="mb-2">
        <Col lg={3}>
          <Image
            src={units?.unit_pic ?? imgPlaceHolder}
            className={"shadow-none rounded-circle border border-secondary"}
            style={{
              width: "75%",
              maxWidth: "75%",
              aspectRatio: "1/1",
              objectFit: "cover",
            }}
            title="Unit Picture"
          />
        </Col>
        <Col>
          <h2> {units.unit_name} </h2>
          <div className="d-flex flex-row mb-3">
            {units.unit_guidedoc && (
              <>
                <IconButton
                  className="text-white fw-bold me-2"
                  title="Upload a unit photo"
                  label="TEACHING GUIDE 教学手册  "
                  style={{
                    borderRadius: "25px",
                    backgroundColor: "#0075ba",
                  }}
                  onClick={() =>
                    units.unit_guidedoc ? openTeachingGuideModal() : undefined
                  }
                />
                {teachingGuideModal}
              </>
            )}
            {units.unit_videourl && (
              <IconButton
                className="text-white fw-bold text-uppercase"
                label="Training Video 培训视频"
                style={{
                  borderRadius: "25px",
                  backgroundColor: "var(--primary)",
                }}
                href={
                  units.unit_videourl.startsWith("http")
                    ? units.unit_videourl
                    : "https://" + units.unit_videourl
                }
                target="_blank"
                rel="noopener noreferrer"
              />
            )}
          </div>
          <Row>
            <p className="text-muted fw-bold m-0">DOWNLOADABLES 下载</p>
            <div>
              {downloadables.length === 0
                ? `No Downloadables available.`
                : dl.map((dl, index) => (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      onClick={() => download("DOWNLOADABLES", dl?.dnld_id)}
                      className="cursor-pointer"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <p className="m-0">{dl.dnld_name}</p>
                    </a>
                  ))}
            </div>
          </Row>
        </Col>
        <div className="w-100 align-self-stretch d-flex flex-column text-break" />
        <hr
          style={{ width: "96%" }}
          className="align-self-center d-flex justify-content-center m-4 me-5"
        />
      </Row>
    </>
  );
};

export default ClassesUnitView;
