import { Modal, Row } from "react-bootstrap";
import { BaseModalProps } from "../../../hooks/useModal";
import * as EBIC from "@ebic-bbrm/types";
import IconButton from "../../utils/IconButton";
import { CloseRounded } from "@mui/icons-material";
import { useUserMutations } from "../../../api/user.api";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { useOrgProfileById } from "../../../api/organization.api";

interface UserDeleteProps extends BaseModalProps {
  users: EBIC.User.User;
}

export default function UserDeleteModal({
  show,
  onClose,
  users,
}: UserDeleteProps) {
  const { mutate: deleteUser, isLoading, error } = useUserMutations("DELETE");
  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: organization } = useOrgProfileById(users.org_id);

  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop={isLoading ? "static" : undefined}
      keyboard={!isLoading}
    >
      <Modal.Header>
        <Modal.Title>
          Delete User <i>{users.user_firstname}</i> 删除用户
        </Modal.Title>
        <IconButton
          title="Close modal"
          Icon={CloseRounded}
          className="ms-auto"
          disabled={isLoading}
          onClick={onClose}
        />
      </Modal.Header>
      <Modal.Body>
        <p>This will permanently remove this user from the database. </p>
        <p>这将从数据库中永久删除此用户。</p>
      </Modal.Body>
      <Modal.Footer>
        {error !== null ? (
          <Row className="text-danger">{error?.message}</Row>
        ) : null}

        <IconButton
          iconHtmlColor="var(--danger)"
          title="Cancel"
          label="CANCEL 取消"
          onClick={onClose}
          disabled={isLoading}
          className="bg-transparent border-danger text-danger"
        />
        <IconButton
          title="Delete"
          label="DELETE 删除"
          disabled={isLoading}
          className="bg-danger text-light"
          onClick={() => {
            deleteUser(
              {
                user_id: users.user_id,
                org_id: users.org_id,
              },
              {
                onSuccess: () => {
                  addLog({
                    alog_type: "I",
                    alog_module: "U",
                    alog_action: "D",
                    alog_accessfrom: 0,
                    alog_modulename: users.user_email,
                    alog_details: "Delete User",
                    org_name: organization?.org_name ?? null,
                  });
                },
                onError: () => {
                  addLog({
                    alog_type: "E",
                    alog_module: "U",
                    alog_action: "D",
                    alog_accessfrom: 0,
                    alog_modulename: users.user_email,
                    alog_details: "Delete User Failed.",
                    org_name: organization?.org_name ?? null,
                  });
                },
              }
            );
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}
