import { Row, Col } from "react-bootstrap";
import IconButton from "../../../utils/IconButton";
import styles from "./downloadable.module.css";
import { Delete } from "@mui/icons-material";
import * as EBIC from "@ebic-bbrm/types";
import useModal from "../../../../hooks/useModal";
import DestructiveModal from "../../../utils/DestructiveModal";
import DownloadableEditModal from "./DowonloadableEditModal";
import { useMemo } from "react";
import {
  useDownloadableMutations,
  useUnitById,
} from "../../../../api/units.api";
import { NewUnitDownloadableInterface } from "../UnitCreate";
import { useActivityLogMutations } from "../../../../api/activitylog.api";

interface OldUnitDownloadableItemProps {
  downloadable: EBIC.Content.Downloadable;
  editDownloadable?: never;
  removeDownloadable?: never;
}

interface NewUnitDownloadableItemProps {
  downloadable: NewUnitDownloadableInterface;
  editDownloadable?: (downloadable: NewUnitDownloadableInterface) => void;
  removeDownloadable?: () => void;
}

type DownloadableItemProps =
  | NewUnitDownloadableItemProps
  | OldUnitDownloadableItemProps;

export default function DownloadableItem({
  downloadable,
  editDownloadable,
  removeDownloadable,
}: DownloadableItemProps) {
  const { mutate: deleteDownloadable } = useDownloadableMutations(
    "DELETE_DOWNLOADABLE"
  );

  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: unit } = useUnitById(
    (downloadable as EBIC.Content.Downloadable).unit_id
  );

  const [deleteModal, openDelete] = useModal(DestructiveModal, {
    title: "Delete downloadable 删除文件",
    description: (
      <>
        <p>Delete the {downloadable.dnld_name} downloadable?</p>
        <p>删除《{downloadable.dnld_name}》文件? </p>
      </>
    ),
    onConfirm: () => {
      if (removeDownloadable !== undefined) {
        removeDownloadable();
      } else if ("dnld_id" in downloadable) {
        deleteDownloadable(downloadable.dnld_id, {
          onSuccess: () => {
            addLog({
              alog_type: "I",
              alog_module: "C",
              alog_action: "D",
              alog_accessfrom: 0,
              alog_modulename: unit?.unit_name ?? "",
              alog_details: "Delete Downloadable",
              org_name: null,
            });
          },
          onError: () => {
            addLog({
              alog_type: "E",
              alog_module: "C",
              alog_action: "D",
              alog_accessfrom: 0,
              alog_modulename: unit?.unit_name ?? "",
              alog_details: "Delete Downloadable Failed.",
              org_name: null,
            });
          },
        });
      }
    },
    confirmBtnText: "Delete 删除",
  });

  const props = useMemo(() => {
    if ("unit_id" in downloadable && editDownloadable === undefined) {
      return {
        downloadable: downloadable,
      };
    } else if (downloadable) {
      return {
        downloadable: downloadable,
        editDownloadable: editDownloadable,
      };
    }
  }, [downloadable, editDownloadable]);

  const [editModal, openEdit] = useModal(DownloadableEditModal, props);

  return (
    <Row
      className={"p-1 " + styles.itemBody}
      title={downloadable.dnld_name ?? ""}
    >
      <Col className="d-flex justify-content-between align-items-center">
        <div onClick={openEdit}>
          <p className="m-0 linkUnderline">{downloadable.dnld_name}</p>
        </div>
        <IconButton
          style={{ float: "right" }}
          Icon={Delete}
          transparent
          className={styles.dropdown}
          onClick={openDelete}
        />
      </Col>
      {editModal}
      {deleteModal}
    </Row>
  );
}
