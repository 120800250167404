import Spinner, { SpinnerProps } from 'react-bootstrap/Spinner';

const BootstrapSpinner = ({ animation = 'border', ...props }: Partial<Omit<SpinnerProps, 'role'>>) => {
  return (
    <div className="d-flex justify-content-center align-items-center p-3">
      <Spinner animation={animation} variant="primary" role="status" {...props}>
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default BootstrapSpinner;
