import { Row } from "react-bootstrap";


const CheckSubscription = () => {

    return (
        <>
            <Row>
                <h1 className="text-muted">
                    Thank You for your time at&nbsp;
                    <span className="text-primary">EBICUS</span>
                </h1>
                <p>
                    Your account and organization is now being checked by the admins, you will be notified via EMAIL when your organization has been successfully registered.
                </p>

            </Row>
        </>


    );
}

export default CheckSubscription;