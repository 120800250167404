import { SvgIconComponent } from "@mui/icons-material";
import { Card, CardProps } from "react-bootstrap";
import { NavLink, NavLinkProps, matchPath, useLocation } from "react-router-dom";
import classNames from "classnames";
import styles from "./utils.module.css";
import { HTMLAttributes } from "react";
import IconButton from "./IconButton";
import { useCallback } from "react";

interface SidebarProps extends CardProps {
    /** [ Icon, title, props ] */
    tabs: readonly (readonly [
        |   SvgIconComponent
        |   React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }>
        |   undefined,
        string,
        (
            | NavLinkProps
            | HTMLAttributes<HTMLButtonElement> & {
                active: boolean,
            }
        ) & {
            tabs?: readonly (readonly[
                SvgIconComponent,
                string,
                | NavLinkProps
                | HTMLAttributes<HTMLButtonElement> & {
                    active: boolean
                }
            ])[];
        }
    ] | "separator")[];

    beforeChildren?: React.ReactNode;
    afterChildren?: React.ReactNode;
}

const SEPARATOR_HEIGHT = 10;
const TAB_HEIGHT = 35;

export default function Sidebar({ tabs, beforeChildren, afterChildren, className, ...props } : SidebarProps) {

    const location = useLocation();

    const isPathMatch = useCallback((to: Pick<NavLinkProps, 'to'>['to'], exact?: boolean, strict?: boolean) => {
        let tabPath = to;

        //if to prop is function, call it to get path
        if (typeof tabPath === "function") {
            tabPath = tabPath(location);
        }

        const pathStr = typeof tabPath === "string" ? tabPath : tabPath.pathname;

        return matchPath(location.pathname, {
            path: pathStr,
            exact,
            strict
        }) !== null;
    }, [location])

    //height from top for left border
    let height = 0;
    for (let i = 0; i < tabs.length; i++) {
        const tab = tabs[i];
        if (tab === "separator") {
            //height of separator
            height += SEPARATOR_HEIGHT;
            continue;
        }

        if (
            ("to" in tab[2] && isPathMatch(tab[2].to, tab[2].exact, tab[2].strict)) ||
            ("active" in tab[2] && tab[2].active)
        ) {
            const subIdx = tab[2].tabs?.findIndex(t =>
                (
                    ('to' in t[2] && isPathMatch(t[2].to, t[2].exact, t[2].strict)) ||
                    ('active' in t[2] && t[2].active === true)
                )
            )
            if (subIdx !== undefined && subIdx !== -1) {
                height += (subIdx + 1) * TAB_HEIGHT;
            }
            break;
        }
        //height of a tab
        height += TAB_HEIGHT;
        //if last tab & no match, set to -1
        if (i === tabs.length - 1) {
            height = -1;
        }
    }

    return (
        <Card
            className={classNames("shadow rounded-4", className)}
            {...props}
        >
            <Card.Body className="py-2 px-0 d-flex flex-column" style={{ marginLeft: '2px' }}>
                {beforeChildren}
                <div className="position-relative">
                    {
                        tabs.map((tab, idx) => {
                            if (tab === 'separator') {
                                return (
                                    <div
                                        key={idx}
                                        className="d-flex align-items-center mx-2"
                                        style={{ height: SEPARATOR_HEIGHT }}
                                    >
                                        <hr className="w-100" />
                                    </div>
                                )
                            }
                            return (
                                <SidebarItem
                                    key={idx + tab[1]}
                                    isPathMatch={isPathMatch}
                                    tab={tab}
                                />
                            )
                        })
                    }
                    {/* Border */}
                    {
                        height !== -1 && (
                            <div
                                style={{
                                    height: TAB_HEIGHT,
                                    transition: "transform 200ms ease-in-out",
                                    top: '0px',
                                    left: '-2px',
                                    transform: `translateY(${height}px)`
                                }}
                                className="position-absolute border border-2 border-primary rounded-pill bg-primary"
                            ></div>
                        )
                    }
                </div>
                {afterChildren}
            </Card.Body>
        </Card>
    )
}

interface SidebarItemProps {
    tab: readonly [
        |   SvgIconComponent
        |   React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
            title?: string | undefined;
        }>
        |   undefined,
        string,
        (
            | NavLinkProps
            | HTMLAttributes<HTMLButtonElement> & {
                active: boolean,
            }
        ) & {
            tabs?: readonly (readonly[
                SvgIconComponent,
                string,
                | NavLinkProps
                | HTMLAttributes<HTMLButtonElement> & {
                    active: boolean
                }
            ])[];
        }
    ],
    isPathMatch: (to: Pick<NavLinkProps, 'to'>['to'], exact?: boolean, strict?: boolean) => boolean;
}

function SidebarItem({ tab, isPathMatch } : SidebarItemProps) {


    const [ Icon, title, { className, style, tabs: subTabs, ...props } ] = tab;

    

    const isActive = (
        ('active' in props && props.active) ||
        ('to' in props && isPathMatch(props.to, props.exact, props.strict))
    );

    let item;
    if ("to" in props) {
        item = (
            <NavLink
            className={classNames("text-decoration-none bg-transparent d-flex align-items-center px-3 py-1" + styles.sidebaritem, isActive ? (isActive + 'fw-bold' + styles.active) : '' )}
                style={{
                    height: TAB_HEIGHT,
                    ...style
                }}
                {...props}
            >
                {
                    props.children ?? (
                        <div className="d-flex align-items-center">
                            { Icon !== undefined && <Icon className="me-2" fontSize="small"></Icon> }
                            <span style={{ textOverflow: 'ellipsis' }} className="d-block overflow-hidden text-nowrap">
                                {title}
                            </span>
                        </div>
                    )
                }
            </NavLink>
        )
    }
    else {
        item = (
            <IconButton
                className={classNames({
                    [styles.sidebarItem]: true,
                    "px-3 py-2 bg-transparent": true,
                    "fw-bold": props.active,
                    [styles.active]: props.active,
                    [typeof className === 'function' ? className(isActive) : (className ?? '')]: true,
                })}
                label={title}
                title={title}
                Icon={Icon}
                style={{
                    height: TAB_HEIGHT,
                    ...style
                }}
                {...props}
            />
        )
    }

    return (
        <>
            {item}
            {
                isActive &&
                subTabs !== undefined &&
                subTabs.length > 0 &&
                subTabs.map((tab, idx) => (
                    <SidebarItem
                        key={idx + tab[1]}
                        isPathMatch={isPathMatch}
                        tab={[
                            tab[0],
                            tab[1],
                            {
                                ...tab[2],
                                className: classNames(typeof tab[2].className === 'function' ? tab[2].className(isActive) : tab[2].className, 'ms-3')
                            }
                        ]}
                    />
                ))
            }
        </>
    )
}