import useEbicAPI, { EbicMutationOptions, useEbicMutation, useEbicPaginatedQuery } from "./useEbicAPI";
import * as EBIC from "@ebic-bbrm/types";
import { ALogQueries } from "./activitylog.api";

export const lessonQueries = {
    LESSON: (lessonId: number) => ['content', 'lessons', lessonId] as const,
    LESSONS: (params: FetchLessonsOptions) => ['content', 'lessons', params] as const,
    PASSAGE: (passageId: number) => ['content', 'lessons', 'passages', passageId] as const,
    PASSAGES: (params: FetchPassagesOptions) => ['content', 'lessons', 'passages', params] as const,

    KEYWORD: (keywordId: number) => ['content', 'lessons', 'keywords', keywordId] as const,
    KEYWORDS: (params: FetchKeywordsOptions) => ['content', 'lessons', 'keywords', params] as const
}

export const lessonBaseRoute = `/api/lessons`;

export function useLessonById(lessonId: number, enabled = true) {
    return useEbicAPI<EBIC.Content.Lesson>(
        lessonQueries.LESSON(lessonId),
        `${lessonBaseRoute}/${lessonId}`,
        true,
        {
            enabled,
            retry: (_, error) => error.status !== 401
        }
    )
}

export interface FetchLessonsOptions {
    sort?: "alphabetical" | "newest";
    search?: string;
    unit_id?: number;
    published?: boolean;
    draft?: boolean;

    limit?: number;
}

export function useLessons(params: FetchLessonsOptions = {}, enabled = true) {
    // Set defaults
    const { limit = 9, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.Content.Lesson[]>(
        lessonQueries.LESSONS(options),
        `${lessonBaseRoute}/all?${searchParams.toString()}`,
        true,
        { enabled }
    )
}

export interface CreateLessonInterface {
    unit_id: number;
    less_name: string;
    less_passage: string;
    less_passageCSL: string;
    less_pic: File | undefined;
}

export interface EditLessonInterface extends Omit<CreateLessonInterface, 'unit_id'> {
    less_id: number;
}

export interface PublishLessonInterface {
    less_id: number;
    less_published: 0 | 1;
}

interface RearrangeLessonInterface {
    sourceSeqNo: number;
    destinationSeqNo: number;
    unitId: number;
}

const LessonMutations = {
    CREATE_LESSON: (lesson: CreateLessonInterface): EbicMutationOptions => ({
        url: `${lessonBaseRoute}`,
        method: 'POST',
        requestBody: lesson,
        useFormData: true,
        updater: [
            {
                queryKey: lessonQueries.LESSONS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    EDIT_LESSON: ({ less_id, ...lesson }: EditLessonInterface): EbicMutationOptions => ({
        url: `${lessonBaseRoute}/${less_id}`,
        method: 'PUT',
        requestBody: lesson,
        useFormData: true,
        updater: [
            {
                queryKey: lessonQueries.LESSONS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: lessonQueries.LESSON(less_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },]
    }),

    PUBLISH_LESSON: ({ less_id, less_published }: PublishLessonInterface): EbicMutationOptions => ({
        url: `${lessonBaseRoute}/${less_id}/publish`,
        method: 'PUT',
        requestBody: { less_published },
        updater: [
            {
                queryKey: lessonQueries.LESSONS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: lessonQueries.LESSON(less_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    REARRANGE_LESSON: ({ sourceSeqNo, ...lesson }: RearrangeLessonInterface) => ({
        url: `${lessonBaseRoute}/rearrange/${sourceSeqNo}`,
        method: 'PUT',
        requestBody: lesson,
        updater: {
            queryKey: lessonQueries.LESSONS({}),
            partialMatch: true,
            action: 'refetch'
        }
    }),

    DELETE_LESSON: (less_id: number): EbicMutationOptions => ({
        url: `${lessonBaseRoute}/${less_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: lessonQueries.LESSONS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]

    })
}

export function useLessonMutations<Key extends keyof typeof LessonMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = LessonMutations[key] as (
        params: Parameters<typeof LessonMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}

export const passageBaseRoute = `/api/lessons/passages`;

export function usePassageById(passageId: number, enabled = true) {
    return useEbicAPI<EBIC.Content.Passage>(
        lessonQueries.PASSAGE(passageId),
        `${passageBaseRoute}/${passageId}`,
        true,
        {
            enabled,
            retry: (_, error) => error.status !== 401
        }
    )
}

export interface FetchPassagesOptions {
    sort?: "alphabetical" | "newest";
    search?: string;
    less_id?: number;

    limit?: number;
}

export function usePassages(params: FetchPassagesOptions = {}, enabled = true) {

    const searchParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicAPI<EBIC.Content.Passage[]>(
        lessonQueries.PASSAGES(params),
        `${passageBaseRoute}/all?${searchParams.toString()}`,
        true,
        { enabled, refetchOnMount: 'always' }
    )
}

export interface CreatePassageInterface {
    less_id: number;
    pass_seqno: number;
    pass_ref: string;
    pass_refCSL: string;
    pass_text: string;
    pass_textCSL: string;
    pass_audiofile: File | undefined;
    pass_audioname: string | undefined;
}

export interface EditPassageInterface extends Omit<CreatePassageInterface, 'less_id' | 'pass_seqno'> {
    pass_id: number;
}

interface RearrangePassageInterface {
    sourceSeqNo: number;
    destinationSeqNo: number;
    lessonId: number;
}

const PassageMutations = {
    CREATE_PASSAGE: (passage: CreatePassageInterface): EbicMutationOptions => ({
        url: `${passageBaseRoute}`,
        method: 'POST',
        requestBody: passage,
        useFormData: true,
        updater: [
            {
                queryKey: lessonQueries.PASSAGES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    EDIT_PASSAGE: ({ pass_id, ...passage }: EditPassageInterface): EbicMutationOptions => ({
        url: `${passageBaseRoute}/${pass_id}`,
        method: 'PUT',
        requestBody: passage,
        useFormData: true,
        updater: [
            {
                queryKey: lessonQueries.PASSAGES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: lessonQueries.PASSAGE(pass_id),
                action: 'refetch'
            },
            {
                queryKey: lessonQueries.KEYWORDS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    REARRANGE_PASSAGE: ({ sourceSeqNo, ...passage }: RearrangePassageInterface) => ({
        url: `${passageBaseRoute}/rearrange/${sourceSeqNo}`,
        method: 'PUT',
        requestBody: passage,
        updater: {
            queryKey: lessonQueries.PASSAGES({}),
            partialMatch: true,
            action: 'refetch'
        }
    }),

    DELETE_PASSAGE: (pass_id: number): EbicMutationOptions => ({
        url: `${passageBaseRoute}/${pass_id}`,
        method: 'DELETE',
        updater: [{
            queryKey: lessonQueries.PASSAGES({}),
            partialMatch: true,
            action: 'refetch'
        },
        {
            queryKey: lessonQueries.KEYWORDS({}),
            partialMatch: true,
            action: 'refetch'
        },
        {
            queryKey: ALogQueries.ALLACTIVITIES({}),
            partialMatch: true,
            action: 'refetch'
        },
        ]
    })
}

export function usePassageMutations<Key extends keyof typeof PassageMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = PassageMutations[key] as (
        params: Parameters<typeof PassageMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}

export const keywordBaseRoute = `/api/lessons/keywords`;

export function useKeywordById(keywordId: number, enabled = true) {
    return useEbicAPI<EBIC.Content.Keyword>(
        lessonQueries.KEYWORD(keywordId),
        `${keywordBaseRoute}/${keywordId}`,
        true,
        {
            enabled,
            retry: (_, error) => error.status !== 401
        }
    )
}

export interface FetchKeywordsOptions {
    sort?: "alphabetical" | "newest";
    search?: string;
    less_id?: number;

    limit?: number;
}

export function useKeywords(params: FetchKeywordsOptions = {}, enabled = true) {

    const searchParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicAPI<EBIC.Content.Keyword[]>(
        lessonQueries.KEYWORDS(params),
        `${keywordBaseRoute}/all?${searchParams.toString()}`,
        true,
        { enabled, refetchOnMount: 'always' }
    )
}

export interface CreateKeywordInterface {
    pass_id: number;
    keyw_seqno: number;
    keyw_text: string | undefined;
    keyw_textCSL: string;
    keyw_audiofile: File | undefined;
    keyw_audioname: string | undefined;
    keyw_explanation: string | undefined;
}

export interface EditKeywordInterface extends Omit<CreateKeywordInterface, 'keyw_seqno'> {
    keyw_id: number;
}

interface RearrangeKeywordInterface {
    sourceSeqNo: number;
    destinationSeqNo: number;
    lessonId: number;
}

const KeywordMutations = {
    CREATE_KEYWORD: (keyword: CreateKeywordInterface): EbicMutationOptions => ({
        url: `${keywordBaseRoute}`,
        method: 'POST',
        requestBody: keyword,
        useFormData: true,
        updater: [
            {
                queryKey: lessonQueries.KEYWORDS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    // For new lessons, find the passage ID from pass_ref given
    CREATE_KEYWORD_FINDPASS: (keyword: Omit<CreateKeywordInterface, 'pass_id'> & { pass_refCSL: string }): EbicMutationOptions => ({
        url: `${keywordBaseRoute}/find`,
        method: 'POST',
        requestBody: keyword,
        useFormData: true,
        updater: [
            {
                queryKey: lessonQueries.KEYWORDS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    REARRANGE_KEYWORD: ({ sourceSeqNo, ...keyword }: RearrangeKeywordInterface) => ({
        url: `${keywordBaseRoute}/rearrange/${sourceSeqNo}`,
        method: 'PUT',
        requestBody: keyword,
        updater: {
            queryKey: lessonQueries.KEYWORDS({}),
            partialMatch: true,
            action: 'refetch'
        }
    }),

    EDIT_KEYWORD: ({ keyw_id, ...keyword }: EditKeywordInterface): EbicMutationOptions => ({
        url: `${keywordBaseRoute}/${keyw_id}`,
        method: 'PUT',
        requestBody: keyword,
        useFormData: true,
        updater: [
            {
                queryKey: lessonQueries.KEYWORDS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: lessonQueries.KEYWORD(keyw_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    DELETE_KEYWORD: (keyw_id: number): EbicMutationOptions => ({
        url: `${keywordBaseRoute}/${keyw_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: lessonQueries.KEYWORDS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    })
}

export function useKeywordMutations<Key extends keyof typeof KeywordMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = KeywordMutations[key] as (
        params: Parameters<typeof KeywordMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}