import * as EBIC from "@ebic-bbrm/types";
import { Row } from "react-bootstrap";
import { Column } from "react-table";
import { RemoveCircleRounded, School } from "@mui/icons-material";
import { useMemo } from "react";
import { useClassesById, useClassesMutations } from "../../../api/classes.api";

import ReactTable from "../../utils/ReactTable";
import IconButton from "../../utils/IconButton";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { useOrgProfileById } from "../../../api/organization.api";

interface ClassMembersTableInterface {
  classMembers: EBIC.Classes.ClassMembers;
  classMembersDetails: EBIC.Classes.ClassMembers[];

  isLoading: boolean;
  classId: number;
  orgId: number;
}
const ClassMembersTable = ({
  classMembers,
  classMembersDetails,
  isLoading,
  classId,
  orgId,
}: ClassMembersTableInterface) => {
  const { mutate: removeMember } = useClassesMutations("REMOVE_MEMBER");
  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: organization } = useOrgProfileById(orgId);
  const { data: classes } = useClassesById(classId);

  const classMembersColumns = useMemo<Column<EBIC.Classes.ClassMembers>[]>(
    () => [
      {
        Header: "",
        accessor: "cmem_role",
        minWidth: 10,
        Cell: ({ value, row }) => {
          return <>{`${value}` === "T" ? <School /> : ""}</>;
        },
      },
      {
        Header: "Name 姓名",
        accessor: "user_firstname",
        minWidth: 50,
        Cell: ({ value, row }) => {
          const classMembersDetails = row.original;
          return (
            <>
              {` ${classMembersDetails.user_firstname}` +
                ` ${classMembersDetails.user_lastname}`}
            </>
          );
        },
      },
      {
        Header: "Email 电邮",
        accessor: "user_email",
        minWidth: 100,
        Cell: ({ value, row }) => {
          return <>{` ${value}`}</>;
        },
      },
      {
        Header: "Mobile No 手机号",
        accessor: "user_mobileno",
        minWidth: 80,
        Cell: ({ value, row }) => {
          return <>{value ? `${value}` : "No Mobile Number."}</>;
        },
      },
      {
        Header: "-",
        accessor: "user_lastlogin",
        minWidth: 10,
        Cell: ({ row }) => {
          const classMembersDetails = row.original;
          return (
            <>
              <IconButton
                Icon={RemoveCircleRounded}
                title="Remove Class Members"
                onClick={() =>
                  removeMember(
                    {
                      class_id: classId,
                      user_id: classMembersDetails.user_id,
                    },
                    {
                      onSuccess: () => {
                        addLog({
                          alog_type: "I",
                          alog_module: "S",
                          alog_action: "D",
                          alog_accessfrom: 0,
                          alog_modulename: classes?.class_name ?? "",
                          alog_details: "Delete Class member",
                          org_name: organization?.org_name ?? null,
                        });
                      },
                      onError: () => {
                        addLog({
                          alog_type: "E",
                          alog_module: "S",
                          alog_action: "D",
                          alog_accessfrom: 0,
                          alog_modulename: classes?.class_name ?? "",
                          alog_details: "Delete Class member Failed.",
                          org_name: organization?.org_name ?? null,
                        });
                      },
                    }
                  )
                }
                style={{ backgroundColor: "transparent", color: "grey" }}
              />
            </>
          );
        },
      },
    ],
    // eslint-disable-next-line
    [classMembers]
  );

  return (
    <Row>
      <ReactTable
        data={classMembersDetails}
        columns={classMembersColumns}
        isLoading={isLoading}
        placeholderMessage={"There are no members yet. 没有成员。"}
        pagination={true}
        Row={(props, data) => <tr {...props} />}
      ></ReactTable>
    </Row>
  );
};

export default ClassMembersTable;
