export namespace User {

    export interface User extends Required<UserInfo> {
        
        user_email: string;
        user_mobileno: string | null;
        user_role: string;
        user_status: number | null;

        isTeacher: 0 | 1;
        classId: string | null;
        class_name: string | null;
        org_name: string;


    }
    export interface UserInfo {
        user_firstname: string;
        user_lastname: string;
        
        org_id: number;
        user_id: number;
        user_pic: string | null;

        user_signup?: string;
        user_lastlogin?: string | null;
    }

}