/* eslint-disable react-hooks/rules-of-hooks */
import { Modal, Container, Col } from "react-bootstrap";
import { BaseModalProps } from "../../../hooks/useModal";
import { createContext, useCallback, useContext, useState } from "react";
import * as EBIC from "@ebic-bbrm/types";
import CheckSubscription from "./CheckSubscription";
import RefuseSubscription from "./RefuseSubscription";
import OrgProfileCreation from "./OrgProfileCreation";
import { Dropdown } from "react-bootstrap";
import { Image, Form as BootstrapForm } from "react-bootstrap";
import classNames from "classnames";
import {
  ChurchOutlined,
  SchoolOutlined,
  VolunteerActivismOutlined,
  PublicOutlined,
  GroupsOutlined,
  GroupOutlined,
} from "@mui/icons-material";
import CustomToggle from "../../utils/CustomToggle";
import OrgProfileDetailsCreation from "./OrgProfileDetailsCreation";
import CreateOrgOwner from "./CreateOrgOwner";
import FinalCreationPage from "./FinalCreationPage";

export interface OrgForm {
  org_name: string;
  org_type: EBIC.Organization.TypeKey;
  org_desc: string | null;
  org_pic: File | null;
  org_address: string;
  org_coord: string | null;
  org_unitno: string;
  org_country: string | null;
  org_state: string | null;
  org_city: string | null;
  org_website: string | null;
  org_email: string;
  org_contact: string;
  org_contactno: string;
}

export interface OrgOwnerForm {
  user_firstname: string;
  user_lastname: string;
  user_mobileno: string;
  user_pic: File | null;
  user_role: string;
}

interface IOrgProfileCompeletionContext {
  orgForm: OrgForm;
  setOrgForm: React.Dispatch<
    React.SetStateAction<IOrgProfileCompeletionContext["orgForm"]>
  >;

  orgOwnerForm: OrgOwnerForm;
  setOrgOwnerForm: React.Dispatch<
    React.SetStateAction<IOrgProfileCompeletionContext["orgOwnerForm"]>
  >;

  isLoading: boolean;
  setIsLoading: React.Dispatch<
    React.SetStateAction<IOrgProfileCompeletionContext["isLoading"]>
  >;

  goPrevPage: (target?: number) => void;
  goNextPage: (target?: number) => void;

  onClose: () => void;
}

const OrgProfileCompeletionContext = createContext<
  IOrgProfileCompeletionContext | undefined
>(undefined);
OrgProfileCompeletionContext.displayName = "OrgProfileCompeletionContext";

export function useOrgProfileCompeletion() {
  const context = useContext(OrgProfileCompeletionContext);

  if (context === undefined) {
    throw new Error(
      "Context error: `useOrgProfileCompeletion` used outside of provider!"
    );
  }
  return context;
}

const CheckUserModal = ({ show, onClose }: BaseModalProps) => {
  const [orgForm, setOrgForm] = useState<OrgForm>({
    org_name: "",
    org_type: "C",
    org_desc: "",
    org_pic: null,
    org_address: "",
    org_coord: null,
    org_unitno: "",
    org_country: "",
    org_state: "",
    org_city: "",
    org_website: "",
    org_email: "",
    org_contact: "",
    org_contactno: "",
  });

  const [orgOwnerForm, setOrgOwnerForm] = useState<OrgOwnerForm>({
    user_firstname: "",
    user_lastname: "",
    user_mobileno: "",
    user_pic: null,
    user_role: "U",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);

  const pages = [
    [RefuseSubscription],
    [CheckSubscription],
    [OrgProfileCreation],
    [OrgProfileDetailsCreation],
    [CreateOrgOwner],
    [FinalCreationPage],
  ];

  const goPrevPage = useCallback((target?: number) => {
    if (target === undefined) {
      setActivePage((p) => Math.max(p - 1, 0));
    } else {
      setActivePage(Math.max(target, 0));
    }
  }, []);

  const goNextPage = useCallback(
    (target?: number) => {
      if (target === undefined) {
        setActivePage((p) => Math.min(p + 1, pages.length - 1));
      } else {
        setActivePage((p) => Math.min(target, pages.length - 1));
      }
    },
    [pages.length]
  );

  const SelectedPage = pages[activePage][0];

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="xl"
      backdrop="static"
      fullscreen={true}
      keyboard={false}
      animation={false}
      centered
    >
      <Modal.Body
        className="p-3 p-lg-5 d-flex justify-content-center align-items-center py-3"
        style={{ display: "inline-block" }}
      >
        <Container>
          <div className="d-flex justify-content-left align-items-left h-100">
            <Image
              alt="img"
              src="/logo.png"
              width="300"
              // className="mx-3"
              style={{
                objectFit: "contain",
                marginLeft: 0,
              }}
            />
          </div>
          <div className="d-flex justify-content-center align-items-center py-3">
            <Container className="d-flex flex-column justify-content-center h-100">
              <OrgProfileCompeletionContext.Provider
                value={{
                  orgForm,
                  setOrgForm,
                  orgOwnerForm,
                  setOrgOwnerForm,
                  isLoading,
                  setIsLoading,
                  goPrevPage,
                  goNextPage,
                  onClose,
                }}
              >
                <SelectedPage />
              </OrgProfileCompeletionContext.Provider>
            </Container>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export const OrgTypeIcon = ({
  org,
}: {
  org: Pick<EBIC.Organization.Profile, "org_type">;
}) => {
  const typeIconConfig = {
    C: ChurchOutlined,
    E: SchoolOutlined,
    P: PublicOutlined,
    A: VolunteerActivismOutlined,
    M: GroupsOutlined,
    O: GroupOutlined,
  } as const;

  if (!org.org_type) return <></>;

  const TypeIcon = typeIconConfig[org.org_type];

  return <TypeIcon />;
};

export const orgAllowedKeys = Object.keys(
  EBIC.Organization.Type
) as EBIC.Organization.TypeKey[];
export const orgAllowedLabels = Object.values(EBIC.Organization.Type);
const orgAllowedIcons = orgAllowedKeys.map((i, index) => (
  <OrgTypeIcon key={i} org={{ org_type: orgAllowedKeys[index] }} />
));

interface OrganizationTypeDropdownInterface {
  values: OrgForm["org_type"];
  isSubmitting: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
}

export const OrganizationTypeDropdown = ({
  values,
  isSubmitting,
  setFieldValue,
}: OrganizationTypeDropdownInterface) => {
  return (
    <BootstrapForm.Group as={Col} lg>
      <Dropdown>
        <Dropdown.Toggle
          bsPrefix=" "
          as={CustomToggle}
          className={classNames("w-100", { "disabled-with-bg": isSubmitting })}
        >
          {
            orgAllowedIcons[
              orgAllowedKeys.indexOf(values as EBIC.Organization.TypeKey)
            ]
          }
          {values
            ? EBIC.Organization.Type[values as EBIC.Organization.TypeKey]
            : "-"}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {orgAllowedKeys.map((i, index) => (
            <Dropdown.Item
              onClick={() => setFieldValue("org_type", orgAllowedKeys[index])}
              key={i}
              value={orgAllowedKeys[index]}
            >
              {orgAllowedLabels[index]}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </BootstrapForm.Group>
  );
};

export default CheckUserModal;
