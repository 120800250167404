import { useOrgProfileCompeletion } from "./CheckUserModal";
import { Row, Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const RefuseSubscription = () => {
  const { isLoading } = useOrgProfileCompeletion();

  const { logout } = useAuth0();

  return (
    <>
      <Row>
        <h1 className="text-muted">
          Welcome to&nbsp;
          <span className="text-primary">EBICUS</span> 欢迎您来到
          <span className="text-primary fw-bold">圣经天地用户系统</span>
        </h1>
        <p>
          Thank you for your interest. EBICUS is only available for subscribed
          users only. Please do feel free to contact us if you have any
          questions about the system.
          <br />
          感谢您的关注。圣经天地用户系统仅适用于订阅用户。如果您对系统有任何疑问，请随时与我们联系。
        </p>
      </Row>
      <div className="d-flex flex-wrap justify-content-end">
        <Button
          variant="outline-primary"
          onClick={() => {
            logout({ returnTo: window.location.origin });
          }}
          disabled={isLoading}
        >
          OK 好
        </Button>
      </div>
    </>
  );
};

export default RefuseSubscription;
