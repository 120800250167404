import { BaseModalProps } from "../../../../hooks/useModal";
import * as yup from "yup";
import * as EBIC from "@ebic-bbrm/types";
import {
  usePassageMutations,
  EditPassageInterface,
  useLessonById,
} from "./../../../../api/lessons.api";
import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import {
  extractFileExtension,
  formCloseHandler,
} from "../../../utils/HelperFunctions";
import IconButton from "./../../../utils/IconButton";
import { CloseRounded, Upload } from "@mui/icons-material";
import { BootstrapInput } from "../../../utils/FormikBootstrapInputs";
import { NewLessonPassageInterface } from "../LessonCreate";
import { useActivityLogMutations } from "../../../../api/activitylog.api";
import { allowedAudioTypes } from "./PassageCreateModal";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

interface NewLessonEditProps extends BaseModalProps {
  editPassage?: (passage: NewLessonPassageInterface) => void;
  passage?: NewLessonPassageInterface;
}

interface OldLessonEditProps extends BaseModalProps {
  editPassage?: never;
  passage?: EBIC.Content.Passage;
}

type PassageEditModalProps = NewLessonEditProps | OldLessonEditProps;

export default function PassageEditModal({
  show,
  onClose,
  editPassage,
  passage,
  ...props
}: PassageEditModalProps) {
  // file form element
  const fileInput = useRef<HTMLInputElement>(null);
  const fileRef = useRef<File | null>(null);

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const {
    mutate: dbEditPassage,
    isLoading,
    error,
    progress,
    reset,
  } = usePassageMutations("EDIT_PASSAGE");

  const { data: lesson } = useLessonById(
    (passage as EBIC.Content.Passage).less_id
  );

  const [isFocus, setIsFocus] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );

  const [isFocusCSL, setIsFocusCSL] = useState(false);
  const [isClickedCSL, setIsClickedCSL] = useState(false);
  const [editorStateCSL, setEditorStateCSL] = useState<EditorState>(
    EditorState.createEmpty()
  );

  useEffect(() => {
    reset();
    setIsClicked(false);
    setIsClickedCSL(false);
  }, [show, reset]);

  useEffect(() => {
    if (passage?.pass_text) {
      const state = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(passage?.pass_text ?? "").contentBlocks
        )
      );
      setEditorState(state);
    }

    if (passage?.pass_textCSL) {
      const state = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(passage?.pass_textCSL ?? "").contentBlocks
        )
      );
      setEditorStateCSL(state);
    }
  }, [passage?.pass_text, passage?.pass_textCSL, onClose]);

  interface EditPassageForm
    extends Omit<
      EditPassageInterface,
      "pass_id" | "less_id" | "pass_audiofile" | "pass_audioname"
    > {
    pass_audiofile?: Blob;
    pass_audioname?: string;
    pass_audiotype?: string;
  }

  const initialValues: EditPassageForm = {
    pass_ref: passage?.pass_ref ?? "",
    pass_refCSL: passage?.pass_refCSL ?? "",
    pass_text: passage?.pass_text ?? "",
    pass_textCSL: passage?.pass_textCSL ?? "",
    pass_audiofile: undefined,
    pass_audioname: passage?.pass_audioname ?? undefined,
    pass_audiotype: undefined,
  };

  const validationSchema = yup.object().shape({
    pass_ref: yup
      .string()
      .required("Passage reference is required")
      .max(
        60,
        ({ max }) => `Passage reference cannot be more than ${max} characters`
      ),
    pass_refCSL: yup
      .string()
      .required("Chinese passage reference is required")
      .max(
        60,
        ({ max }) =>
          `Chinese passage reference cannot be more than ${max} characters`
      ),
    pass_text: yup
      .string()
      .required("Passage text is required")
      .trim()
      .min(8, "Passage text is required")
      .nullable(),
    pass_textCSL: yup
      .string()
      .required("Chinese passage text is required")
      .trim()
      .min(8, "Chinese passage text is required")
      .nullable(),
    pass_audiofile: yup.string(),
    pass_audioname: yup
      .string()
      .max(60, ({ max }) => `Name cannot be more than ${max} characters`),
    pass_audiotype: yup
      .string()
      .oneOf(
        allowedAudioTypes,
        (obj) => `'${obj.value}' files aren't allowed.`
      ),
  });

  const onSubmit = (values: EditPassageForm, { resetForm }: any) => {
    const file = fileRef.current;
    if (editPassage) {
      // Edit a passage using row.insertId in backend
      const newPassage = {
        pass_id: passage?.pass_id ?? 0,
        pass_ref: values.pass_ref,
        pass_refCSL: values.pass_refCSL,
        pass_text: values.pass_text,
        pass_textCSL: values.pass_textCSL,
        pass_audiofile: file ?? undefined,
        pass_audioname: values.pass_audioname ?? undefined,
      };
      editPassage(newPassage);
      resetForm();
      onClose();
    } else {
      // Edit a passage in db
      dbEditPassage(
        {
          pass_id: passage?.pass_id ?? 0,
          pass_ref: values.pass_ref,
          pass_refCSL: values.pass_refCSL,
          pass_text: values.pass_text,
          pass_textCSL: values.pass_textCSL,
          pass_audiofile: file ?? undefined,
          pass_audioname: values.pass_audioname ?? undefined,
        },
        {
          onSuccess: () => {
            addLog({
              alog_type: "I",
              alog_module: "C",
              alog_action: "E",
              alog_accessfrom: 0,
              alog_modulename: lesson ? lesson.less_name : "",
              alog_details: "Edit Passage",
              org_name: null,
            });
            resetForm();
            onClose();
          },
          onError: () => {
            addLog({
              alog_type: "E",
              alog_module: "C",
              alog_action: "E",
              alog_accessfrom: 0,
              alog_modulename: lesson ? lesson.less_name : "",
              alog_details: "Edit Passage Failed.",
              org_name: null,
            });

            resetForm();
            onClose();
          },
        }
      );
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleSubmit,
        isSubmitting,
        dirty,
        values,
        errors,
        resetForm,
      }) => {
        const setFieldValuePassageEN = () => {
          let text = draftToHtml(convertToRaw(editorState.getCurrentContent()));

          // // Remove beginning p tag
          // if (text.startsWith("<p>")) {
          //   text = text.slice("<p>".length);
          // } else if (text.startsWith('<p style="text-align:start;">')) {
          //   text = text.slice('<p style="text-align:start;">'.length);
          // } else if (text.startsWith('<p style="text-align:left;">')) {
          //   text = text.slice('<p style="text-align:left;">'.length);
          // }
          // // Remove end p tag
          // text = text.substring(0, text.length - 5);

          setFieldValue("pass_text", text);
        };

        const setFieldValuePassageCSL = () => {
          let text = draftToHtml(
            convertToRaw(editorStateCSL.getCurrentContent())
          );

          // if (text.startsWith("<p>")) {
          //   text = text.slice("<p>".length);
          // } else if (text.startsWith('<p style="text-align:start;">')) {
          //   text = text.slice('<p style="text-align:start;">'.length);
          // } else if (text.startsWith('<p style="text-align:left;">')) {
          //   text = text.slice('<p style="text-align:left;">'.length);
          // }

          // text = text.substring(0, text.length - 5);
          setFieldValue("pass_textCSL", text);
        };

        const setFile = (files: File[]) => {
          if (files[0]) {
            fileRef.current = files[0];
            const file = fileRef.current;

            if (file) {
              setFieldValue("pass_audiofile", file, true);
              setFieldValue("pass_audioname", file.name, true);
              const extension = extractFileExtension(file.name ?? "");
              setFieldValue("pass_audiotype", extension, true);
            }
            return files[0];
          }
        };

        const handleClose = () => {
          // Reset files
          setFile([]);
          if (error !== null) error.message = "";
          resetForm();
          onClose();
        };

        return (
          <Modal
            {...props}
            centered
            show={show}
            onHide={() => {
              formCloseHandler(handleClose, dirty);
            }}
            keyboard={!isLoading}
            backdrop={isLoading ? "static" : undefined}
            className="modal-layer-1"
            backdropClassName="modal-layer-1"
            size="lg"
          >
            <Modal.Header>
              <Modal.Title>Edit a Passage 修改经文</Modal.Title>
              <IconButton
                title="Close modal"
                Icon={CloseRounded}
                className="ms-auto"
                onClick={() => {
                  formCloseHandler(handleClose, dirty);
                }}
              />
            </Modal.Header>
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body className="position-relative">
                <input
                  onChange={(ev) => {
                    const file = setFile(
                      Array.from(ev.currentTarget.files ?? [])
                    );
                    if (file) {
                      setFieldValue("pass_audiofile", file ?? 0, true);
                      const extension = extractFileExtension(file.name ?? "");
                      setFieldValue("pass_audiotype", extension, true);
                      setFieldValue("pass_audioname", file.name ?? "", true);
                    }
                    return false;
                  }}
                  ref={fileInput}
                  className="d-none"
                  type="file"
                  accept="audio/*"
                ></input>

                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Reference 经文出处 (EN)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="pass_ref"
                      required
                      placeholder="Matt 1:1-2, 6"
                      aria-label="Reference (EN)"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Reference 经文出处 (中文)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="pass_refCSL"
                      required
                      placeholder="⻢太福⾳ 1:1-2, 6"
                      aria-label="Reference (中文)"
                      disabled={isLoading || isSubmitting}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Text 经⽂ (EN)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Editor
                      onFocus={() => {
                        setFieldValuePassageEN();
                        setIsFocus(true);
                        setIsClicked(true);
                      }}
                      onBlur={() => {
                        setFieldValuePassageEN();
                        setIsFocus(false);
                      }}
                      placeholder="The book of the genealogy of Jesus Christ, the son of David, the son of Abraham. Abraham was the father of Isaac, and Isaac the father of Jacob, and Jacob the father of Judah and his brothers."
                      wrapperClassName="card"
                      toolbarClassName="rounded border-0 border-bottom mb-0"
                      editorClassName="card-body pt-0"
                      editorStyle={{
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        maxHeight: "7.5rem",
                      }}
                      editorState={editorState}
                      onEditorStateChange={(e) => setEditorState(e)}
                      toolbar={{
                        options: ["inline", "history"],
                      }}
                    />
                    {errors.pass_text &&
                      isFocus === false &&
                      isClicked === true && (
                        <span className="invalid-feedback d-block">
                          {errors.pass_text}
                        </span>
                      )}
                    {/* <BootstrapInput
                      id="pass_text"
                      as="textarea"
                      style={{ minHeight: "5rem" }}
                      placeholder="The book of the genealogy of Jesus Christ, the son of David, the son of Abraham. Abraham was the father of Isaac, and Isaac the father of Jacob, and Jacob the father of Judah and his brothers."
                      aria-label="Passage Text"
                      disabled={isLoading || isSubmitting}
                    /> */}
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Text 经文 (中文)
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Editor
                      onFocus={() => {
                        setFieldValuePassageCSL();
                        setIsFocusCSL(true);
                        setIsClickedCSL(true);
                      }}
                      onBlur={() => {
                        setFieldValuePassageCSL();
                        setIsFocusCSL(false);
                      }}
                      placeholder="亚伯拉罕的裔、⼤卫的⼦孙耶稣基督的家谱: 亚伯拉罕⽣以撒，以撒⽣雅各，雅各⽣犹⼤和他的兄弟。"
                      wrapperClassName="card"
                      toolbarClassName="rounded border-0 border-bottom mb-0"
                      editorClassName="card-body pt-0"
                      editorStyle={{
                        paddingLeft: "0.75rem",
                        paddingRight: "0.75rem",
                        maxHeight: "7.5rem",
                      }}
                      editorState={editorStateCSL}
                      onEditorStateChange={(e) => setEditorStateCSL(e)}
                      toolbar={{
                        options: ["inline", "history"],
                      }}
                    />
                    {errors.pass_textCSL &&
                      isFocusCSL === false &&
                      isClickedCSL === true && (
                        <span className="invalid-feedback d-block">
                          {errors.pass_textCSL}
                        </span>
                      )}
                    {/* <BootstrapInput
                      id="pass_textCSL"
                      as="textarea"
                      style={{ minHeight: "5rem" }}
                      placeholder="亚伯拉罕的裔、⼤卫的⼦孙耶稣基督的家谱: 亚伯拉罕⽣以撒，以撒⽣雅各，雅各⽣犹⼤和他的兄弟。"
                      aria-label="Chinese Passage Text"
                      disabled={isLoading || isSubmitting}
                    /> */}
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Audio File ⾳频⽂件
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Upload an audio file 上传音频文件"
                        readOnly
                        value={
                          values.pass_audioname
                            ? values.pass_audioname.toString()
                            : ""
                        }
                      />
                      <Button
                        onClick={() => fileInput.current?.click()}
                        title={"Upload an audio file 上传音频文件"}
                        disabled={isLoading || isSubmitting}
                      >
                        <Upload />
                      </Button>
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer className="overflow-hidden">
                <p className="text-danger w-100 text-truncate">
                  {error?.message ||
                    errors.pass_audiofile ||
                    errors.pass_audioname ||
                    errors.pass_audiotype}
                </p>
                <div className="d-flex align-items-center">
                  <Button
                    variant="outline-primary"
                    className="px-4 me-3 border border-primary text-primary"
                    title="Cancel"
                    disabled={isLoading || isSubmitting}
                    onClick={() => formCloseHandler(handleClose, dirty)}
                  >
                    Cancel 取消
                  </Button>
                  <Button
                    className="text-uppercase"
                    title="Save"
                    type="submit"
                    disabled={isLoading || isSubmitting}
                  >
                    Save 保存
                  </Button>
                </div>
                {isLoading && (
                  <div
                    className="bg-primary m-0"
                    style={{
                      position: "absolute",
                      left: "0",
                      bottom: "0",
                      width: `${progress}%`,
                      height: "5px",
                    }}
                  ></div>
                )}
              </Modal.Footer>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
}
