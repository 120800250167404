import {
  CreateKeywordInterface,
  CreateLessonInterface,
  CreatePassageInterface,
  useKeywordMutations,
  useLessonMutations,
} from "../../../api/lessons.api";
import { useEffect, useRef, useState } from "react";
import { AttachmentFile } from "../../../types/custom";
import * as yup from "yup";
import { extractFileSize, getFileWithUrl } from "../../utils/HelperFunctions";
import { Formik } from "formik";
import { Col, Form, Row, Image, Button, InputGroup } from "react-bootstrap";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import { Link, useHistory, useParams } from "react-router-dom";
import imgPlaceHolder from "../../../assets/img_placeholder.jpg";
import IconButton from "../../utils/IconButton";
import { Upload, Add } from "@mui/icons-material";
import { BootstrapInput } from "../../utils/FormikBootstrapInputs";
import EmptyMessage from "../../utils/EmptyMessage";
import { useUnitById } from "../../../api/units.api";
import ErrorPage from "../../utils/ErrorPage";
import EbicCard from "../../utils/EbicCard";
import PassageCreateModal from "./Passages/PassageCreateModal";
import PassageItem from "./Passages/PassageItem";
import { usePassageMutations } from "./../../../api/lessons.api";
import KeywordCreateModal from "./Keywords/KeywordCreateModal";
import KeywordItem from "./Keywords/KeywordItem";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useActivityLogMutations } from "../../../api/activitylog.api";

export interface NewLessonPassageInterface
  extends Omit<CreatePassageInterface, "less_id" | "pass_seqno"> {
  pass_id: number;
}

export interface NewLessonKeywordInterface
  extends Omit<CreateKeywordInterface, "keyw_seqno"> {
  keyw_id: number;
  pass_refCSL: string;
}

export default function LessonCreate() {
  const { unitId } = useParams<{ unitId: string | undefined }>();
  const { data: unit, error: unitError } = useUnitById(
    parseInt(unitId ?? "0"),
    unitId !== undefined
  );

  const [showPassageCreateModal, setShowPassageCreateModal] = useState(false);
  const [showKeywordCreateModal, setShowKeywordCreateModal] = useState(false);

  const history = useHistory();
  const {
    mutate: createLesson,
    isLoading,
    error,
  } = useLessonMutations("CREATE_LESSON");

  const {
    mutate: createPassage,
    isLoading: isPassageLoading,
    error: passageError,
  } = usePassageMutations("CREATE_PASSAGE");

  const {
    mutate: createKeyword,
    isLoading: isKeywordLoading,
    error: keywordError,
  } = useKeywordMutations("CREATE_KEYWORD_FINDPASS");

  function handleOnDragEndPassage(result: any) {
    if (!result.destination) return;

    const newPassagesArray = passages; // Duplicate passages array
    const [reorderedItem] = newPassagesArray.splice(result.source.index, 1); // Remove original source from array of passages, then get the source and assign it to reordered item
    newPassagesArray.splice(result.destination.index, 0, reorderedItem); // Inject source item back into array at its destination index

    setPassages(newPassagesArray);
  }

  function handleOnDragEndKeyword(result: any) {
    if (!result.destination) return;

    const newKeywordsArray = keywords; // Duplicate keywords array
    const [reorderedItem] = newKeywordsArray.splice(result.source.index, 1); // Remove original source from array of keywords, then get the source and assign it to reordered item
    newKeywordsArray.splice(result.destination.index, 0, reorderedItem); // Inject source item back into array at its destination index

    setKeywords(newKeywordsArray);
  }

  useEffect(() => {
    setFile(undefined);
  }, []);

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const [file, setFile] = useState<AttachmentFile>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [passages, setPassages] = useState<NewLessonPassageInterface[]>([]);
  const [keywords, setKeywords] = useState<NewLessonKeywordInterface[]>([]);

  interface CreateLessonForm
    extends Omit<CreateLessonInterface, "less_pic" | "unit_id"> {}

  const initialValues: CreateLessonForm = {
    less_name: "",
    less_passage: "",
    less_passageCSL: "",
  };

  const validationSchema = yup.object().shape({
    less_name: yup
      .string()
      .required("Lesson name is required")
      .max(
        80,
        ({ max }) => `Lesson name cannot be more than ${max} characters`
      ),
    less_passage: yup
      .string()
      .required("Lesson passage is required")
      .max(
        100,
        ({ max }) => `Lesson passage cannot be more than ${max} characters`
      ),
    less_passageCSL: yup
      .string()
      .required("Chinese lesson passage is required")
      .max(
        100,
        ({ max }) =>
          `Chinese lesson passage cannot be more than ${max} characters`
      ),
  });

  const onSubmit = (values: CreateLessonForm) => {
    createLesson(
      {
        unit_id: parseInt(unitId ?? "0"),
        less_name: values.less_name,
        less_passage: values.less_passage,
        less_passageCSL: values.less_passageCSL,
        less_pic: file?.file,
      },
      {
        onSuccess: async (data) => {
          if (Array.isArray(passages) && passages.length !== 0) {
            await Promise.all(
              passages.map(async (passage, index) => {
                return createPassage(
                  {
                    pass_seqno: index,
                    less_id: data.response.data.lessonId,
                    ...passage,
                  },
                  {
                    onSuccess: () => {
                      addLog({
                        alog_type: "I",
                        alog_module: "C",
                        alog_action: "A",
                        alog_accessfrom: 0,
                        alog_modulename: values.less_name ?? "",
                        alog_details: "Add Lessons",
                        org_name: null,
                      });
                    },
                    onError: () => {
                      addLog({
                        alog_type: "E",
                        alog_module: "C",
                        alog_action: "A",
                        alog_accessfrom: 0,
                        alog_modulename: values.less_name ?? "",
                        alog_details: "Add Lessons Failed.",
                        org_name: null,
                      });
                    },
                  }
                );
              })
            ).then(async () => {
              if (Array.isArray(keywords) && keywords.length !== 0) {
                await Promise.all(
                  keywords.map(async (keyword, index) => {
                    return createKeyword(
                      {
                        keyw_seqno: index,
                        ...keyword,
                      },
                      {
                        onSuccess: () => {
                          addLog({
                            alog_type: "I",
                            alog_module: "C",
                            alog_action: "A",
                            alog_accessfrom: 0,
                            alog_modulename: values.less_name ?? "",
                            alog_details: "Add Keywords",
                            org_name: null,
                          });
                        },
                        onError: () => {
                          addLog({
                            alog_type: "E",
                            alog_module: "C",
                            alog_action: "A",
                            alog_accessfrom: 0,
                            alog_modulename: values.less_name ?? "",
                            alog_details: "Add Keywords Failed.",
                            org_name: null,
                          });
                        },
                      }
                    );
                  })
                ).then(() => {
                  history.push(
                    createContentRoute(ContentRoutes.LESSONS, {
                      courseId: unit?.cour_id ?? "0",
                      unitId: unit?.unit_id ?? "0",
                    })
                  );
                });
              } else {
                history.push(
                  createContentRoute(ContentRoutes.LESSONS, {
                    courseId: unit?.cour_id ?? "0",
                    unitId: unit?.unit_id ?? "0",
                  })
                );
              }
            });
          } else {
            history.push(
              createContentRoute(ContentRoutes.LESSONS, {
                courseId: unit?.cour_id ?? "0",
                unitId: unit?.unit_id ?? "0",
              })
            );
          }
        },
      }
    );
  };

  if (unitError) return <ErrorPage message={unitError?.message} />;

  if (!unit)
    return (
      <EmptyMessage message="Unit not found. Unable to create lesson in an unknown unit." />
    );

  return (
    <div className="p-5">
      <h3
        className="text-uppercase mb-4"
        style={{ color: "var(--bs-gray-600)" }}
      >
        <Link
          to={createContentRoute(ContentRoutes.UNITS, {
            courseId: unit?.cour_id.toString() ?? "0",
          })}
          className="linkUnderline"
        >
          {unit?.cour_name}
        </Link>
        <span className="text-black"> &#xBB; </span>
        <Link
          to={createContentRoute(ContentRoutes.LESSONS, {
            courseId: unit?.cour_id.toString() ?? "0",
            unitId: parseInt(unitId ?? "0"),
          })}
          className="linkUnderline"
        >
          {unit?.unit_name}
        </Link>
        <span className="text-black"> &#xBB; </span>
        Creating a lesson 加新课
      </h3>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col
                md={3}
                className="d-flex flex-column align-items-center justify-content-start"
              >
                <input
                  hidden
                  ref={inputRef}
                  type="file"
                  accept="image/*"
                  onChange={async (e) => {
                    if (e.target.files?.[0]) {
                      await getFileWithUrl(e.target.files[0]).then(setFile);
                    } else {
                      setFile(undefined);
                    } // reset value to allow reading the same file
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                ></input>
                {file !== undefined ? (
                  <Image
                    src={file.url}
                    title={
                      file.file.name + " " + extractFileSize(file.file.size)
                    }
                    className={
                      "shadow-none rounded-circle border border-secondary "
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Image
                    src={imgPlaceHolder}
                    className={
                      "shadow-none rounded-circle border border-secondary"
                    }
                    style={{
                      width: "100%",
                      maxWidth: "100%",
                      aspectRatio: "1/1",
                      objectFit: "cover",
                    }}
                    title="Image placeholder"
                  />
                )}
                <IconButton
                  Icon={Upload}
                  transparent
                  className="text-black mt-3 fw-bold"
                  title="Upload a lesson photo"
                  label="UPLOAD 上传"
                  onClick={() => inputRef.current?.click()}
                />
              </Col>
              <Col>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Lesson Name 课名
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <BootstrapInput
                      id="less_name"
                      required
                      placeholder="God's Perfect Plan 上帝奇妙的计划"
                      aria-label="Lesson Name"
                      disabled={
                        isLoading ||
                        isPassageLoading ||
                        isSubmitting ||
                        isKeywordLoading
                      }
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-2">
                  <Form.Group as={Col}>
                    <Form.Label className="mb-0 text-uppercase small fw-bold">
                      Passage 圣经经文
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Row>
                      <Col>
                        <InputGroup>
                          <BootstrapInput
                            id="less_passage"
                            required
                            placeholder="Matt 1:1-2, 6, 11-12a, 16-24"
                            aria-label="English Passage"
                            aria-describedby="passageEN"
                            disabled={
                              isLoading ||
                              isPassageLoading ||
                              isSubmitting ||
                              isKeywordLoading
                            }
                            displayError={false}
                          />
                          <InputGroup.Text id="passageEN">EN</InputGroup.Text>
                          {errors?.less_passage && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.less_passage}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Col>
                      <Col>
                        <InputGroup>
                          <BootstrapInput
                            id="less_passageCSL"
                            required
                            placeholder="⻢太福⾳1:1-2, 6, 11-12a, 16-24"
                            aria-label="Chinese Passage"
                            aria-describedby="passageCN"
                            disabled={
                              isLoading ||
                              isPassageLoading ||
                              isSubmitting ||
                              isKeywordLoading
                            }
                            displayError={false}
                          />
                          <InputGroup.Text id="passageCN">中</InputGroup.Text>
                          {errors?.less_passageCSL && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.less_passageCSL}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Col>
                    </Row>
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <EbicCard
                      items={{ title: "Passages 经文" }}
                      cardProps={() => ({
                        style: {
                          height: "12rem",
                          overflowY: "auto",
                        },
                      })}
                      rightSide={() => {
                        return (
                          <IconButton
                            disabled={
                              isLoading ||
                              isPassageLoading ||
                              isSubmitting ||
                              isKeywordLoading
                            }
                            Icon={Add}
                            onClick={() => setShowPassageCreateModal(true)}
                          />
                        );
                      }}
                    >
                      {passages.length !== 0 ? (
                        Array.isArray(passages) ? (
                          <DragDropContext onDragEnd={handleOnDragEndPassage}>
                            <Droppable droppableId="passages">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {passages.map((passage, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={index.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                        >
                                          <PassageItem
                                            passage={passage}
                                            key={index}
                                            editPassage={(passage) => {
                                              const newPassagesArray =
                                                passages.map((p) =>
                                                  p.pass_id === passage.pass_id
                                                    ? {
                                                        ...passage,
                                                        pass_audiofile:
                                                          passage.pass_audiofile ??
                                                          p.pass_audiofile,
                                                        pass_audioname:
                                                          passage.pass_audioname ??
                                                          p.pass_audioname,
                                                      }
                                                    : p
                                                );
                                              setPassages([
                                                ...newPassagesArray,
                                              ]);
                                            }}
                                            removePassage={() => {
                                              // Delete keywords with this passage as well
                                              const newKeywordsArray =
                                                keywords.filter(
                                                  (k) =>
                                                    k.pass_id !==
                                                    passage.pass_id
                                                );
                                              setKeywords([
                                                ...newKeywordsArray,
                                              ]);

                                              const newPassagesArray =
                                                passages.filter(
                                                  (p) =>
                                                    p.pass_id !==
                                                    passage.pass_id
                                                );
                                              setPassages([
                                                ...newPassagesArray,
                                              ]);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        ) : (
                          <PassageItem
                            passage={passages}
                            editPassage={(passage) => {
                              setPassages([passage]);
                            }}
                            removePassage={() => {
                              setPassages([]);
                              setKeywords([]);
                            }}
                          />
                        )
                      ) : (
                        <p className="text-muted">No passages yet.</p>
                      )}
                    </EbicCard>
                  </Col>
                  <Col>
                    <EbicCard
                      items={{ title: "Keywords ⽣词" }}
                      cardProps={() => ({
                        style: {
                          height: "12rem",
                          overflowY: "auto",
                        },
                      })}
                      rightSide={() => {
                        return (
                          <IconButton
                            disabled={
                              isLoading ||
                              isPassageLoading ||
                              isSubmitting ||
                              isKeywordLoading
                            }
                            Icon={Add}
                            onClick={() => setShowKeywordCreateModal(true)}
                          />
                        );
                      }}
                    >
                      {keywords.length !== 0 ? (
                        Array.isArray(keywords) ? (
                          <DragDropContext onDragEnd={handleOnDragEndKeyword}>
                            <Droppable droppableId="keywords">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {keywords.map((keyword, index) => (
                                    <Draggable
                                      key={index}
                                      draggableId={index.toString()}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          ref={provided.innerRef}
                                        >
                                          <KeywordItem
                                            keyword={keyword}
                                            passages={passages}
                                            key={index}
                                            editKeyword={(keyword) => {
                                              const newKeywordsArray =
                                                keywords.map((k) =>
                                                  k.keyw_id === keyword.keyw_id
                                                    ? {
                                                        ...keyword,
                                                        keyw_audiofile:
                                                          keyword.keyw_audiofile ??
                                                          k.keyw_audiofile,
                                                        keyw_audioname:
                                                          keyword.keyw_audioname ??
                                                          k.keyw_audioname,
                                                      }
                                                    : k
                                                );
                                              setKeywords([
                                                ...newKeywordsArray,
                                              ]);
                                            }}
                                            removeKeyword={() => {
                                              const newKeywordsArray =
                                                keywords.filter(
                                                  (k) =>
                                                    k.keyw_id !==
                                                    keyword.keyw_id
                                                );
                                              setKeywords([
                                                ...newKeywordsArray,
                                              ]);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        ) : (
                          <KeywordItem
                            keyword={keywords}
                            passages={passages}
                            editKeyword={(keyword) => {
                              setKeywords([keyword]);
                            }}
                            removeKeyword={() => {
                              setKeywords([]);
                            }}
                          />
                        )
                      ) : (
                        <p className="text-muted">No keywords yet.</p>
                      )}
                    </EbicCard>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="d-flex align-items-center">
                      <Button
                        className="text-uppercase me-2"
                        title="Cancel"
                        variant="outline-primary"
                        disabled={
                          isLoading ||
                          isPassageLoading ||
                          isSubmitting ||
                          isKeywordLoading
                        }
                        onClick={() =>
                          history.push(
                            createContentRoute(ContentRoutes.LESSONS, {
                              courseId: unit.cour_id,
                              unitId: unit.unit_id,
                            })
                          )
                        }
                      >
                        Cancel 取消
                      </Button>
                      <Button
                        className="text-uppercase"
                        title="Save"
                        type="submit"
                        disabled={
                          isLoading ||
                          isPassageLoading ||
                          isSubmitting ||
                          isKeywordLoading
                        }
                      >
                        Save 保存
                      </Button>
                    </div>
                  </div>
                </Row>
                {error !== null ||
                passageError !== null ||
                keywordError !== null ? (
                  <Row className="text-danger">
                    {error?.message ||
                      passageError?.message ||
                      keywordError?.message}
                  </Row>
                ) : null}
              </Col>
            </Row>
            <PassageCreateModal
              show={showPassageCreateModal}
              onClose={() => setShowPassageCreateModal(false)}
              addPassage={(passage) => {
                const newPassagesArray = passages;
                newPassagesArray.push(passage);
                setPassages(newPassagesArray);
              }}
              index={
                // If array is empty, index would be 0
                // Else, get the last element's pass_id, and add 1 to it
                passages.length === 0
                  ? 0
                  : passages[passages.length - 1].pass_id + 1
              }
            />
            <KeywordCreateModal
              show={showKeywordCreateModal}
              onClose={() => setShowKeywordCreateModal(false)}
              addKeyword={(keyword) => {
                const newKeywordsArray = keywords;
                newKeywordsArray.push(keyword);
                setKeywords(newKeywordsArray);
              }}
              passages={passages}
              index={
                // If array is empty, index would be 0
                // Else, get the last element's keyw_id, and add 1 to it
                keywords.length === 0
                  ? 0
                  : keywords[keywords.length - 1].keyw_id + 1
              }
            />
          </Form>
        )}
      </Formik>
    </div>
  );
}
