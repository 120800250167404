import { Card, Col, Image, Dropdown } from "react-bootstrap";
import styles from "../cardstyles.module.css";
import ebicPlaceholder from "../../../assets/img_placeholder.jpg";
import { useHistory } from "react-router-dom";
import { HTMLAttributes } from "react";
import * as EBIC from "@ebic-bbrm/types";
import { ContentRoutes, createContentRoute } from "../ContentManagement";
import IconButton from "../../utils/IconButton";
import { DeleteOutline, EditOutlined, MoreHoriz } from "@mui/icons-material";
import { useUnitMutations } from "../../../api/units.api";
import DestructiveModal from "../../utils/DestructiveModal";
import useModal from "../../../hooks/useModal";
import LineClampText from "../../utils/LineClampText";
import { useActivityLogMutations } from "../../../api/activitylog.api";

interface UnitNormalItemProps extends HTMLAttributes<HTMLDivElement> {
  unit: EBIC.Content.Unit;
}

export default function UnitNormalItem({ unit }: UnitNormalItemProps) {
  const history = useHistory();
  const { mutate: deleteUnit } = useUnitMutations("DELETE_UNIT");
  const { mutate: publishUnit } = useUnitMutations("PUBLISH_UNIT");

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const [deleteModal, openDelete] = useModal(DestructiveModal, {
    title: "Delete unit 删除单元",
    description: (
      <>
        <p>
          Delete the {unit.unit_name} unit? All lessons under this unit would be
          deleted as well!
        </p>
        <p>删除《{unit.unit_name}》单元? 单元里的所有课也将被删除!</p>
      </>
    ),
    onConfirm: async () => {
      await deleteUnit(unit.unit_id, {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "C",
            alog_action: "D",
            alog_accessfrom: 0,
            alog_modulename: unit.unit_name,
            alog_details: "Delete Unit",
            org_name: null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "C",
            alog_action: "D",
            alog_accessfrom: 0,
            alog_modulename: unit.unit_name,
            alog_details: "Delete Unit Failed.",
            org_name: null,
          });
        },
      });
    },
    confirmBtnText: "Delete 删除",
  });

  const [publishModal, openPublishModal] = useModal(DestructiveModal, {
    title: "Publish Unit 公布单元",
    description: (
      <>
        <p>
          Publish the {unit.unit_name} unit? The unit will be publicly available
          for viewing in the mobile app.
        </p>
        <p>公布《{unit.unit_name}》单元? 单元将在手机应用程序公开。</p>
      </>
    ),
    onConfirm: async () => {
      await publishUnit({ unit_id: unit.unit_id, unit_published: 1 });
    },
    confirmBtnText: "Publish 公布",
  });

  const [unpublishModal, openUnpublishModal] = useModal(DestructiveModal, {
    title: "Unpublish Unit 取消公布单元",
    description: (
      <>
        <p>
          Unpublish the {unit.unit_name} unit? The unit will no longer be
          publicly available for viewing in the mobile app.
        </p>
        <p>取消公布《{unit.unit_name}》单元? 单元将不再在手机应用程序公开。</p>
      </>
    ),
    onConfirm: async () => {
      await publishUnit({ unit_id: unit.unit_id, unit_published: 0 });
    },
    confirmBtnText: "Unpublish 取消公布",
  });

  return (
    <Col style={{ minHeight: "20rem" }}>
      <Card className={"border-0 shadow h-100 " + styles.card}>
        <div className={"card-img-top " + styles.cardImageContainer}>
          <Card.Img
            className={"cursor-pointer " + styles.cardImage}
            as={Image}
            src={unit.unit_pic ?? ebicPlaceholder}
            onClick={() =>
              history.push(
                createContentRoute(ContentRoutes.LESSONS, {
                  courseId: unit.cour_id,
                  unitId: unit.unit_id,
                })
              )
            }
            variant="top"
          />
        </div>
        <Card.Body
          className="p-3 cursor-pointer"
          onClick={() =>
            history.push(
              createContentRoute(ContentRoutes.LESSONS, {
                courseId: unit.cour_id,
                unitId: unit.unit_id,
              })
            )
          }
        >
          <LineClampText
            className={"fw-bold mb-3 " + styles.linkUnderline}
            maxLines={1}
          >
            {unit.unit_name}
          </LineClampText>
        </Card.Body>
        <Card.Footer className="d-flex p-0">
          <IconButton
            transparent
            border={false}
            Icon={EditOutlined}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              history.push(
                createContentRoute(ContentRoutes.EDITUNIT, {
                  courseId: unit.cour_id,
                  unitId: unit.unit_id,
                })
              );
              ev.stopPropagation();
            }}
            title={"Edit 修改"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomLeftRadius: 12,
            }}
          />
          <div className="vr my-1" />
          <Dropdown
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
            onToggle={(_, meta) => {
              meta.originalEvent?.stopPropagation();
              meta.originalEvent?.preventDefault();
            }}
          >
            <Dropdown.Toggle as="span" bsPrefix=" ">
              <IconButton
                transparent
                border={false}
                Icon={MoreHoriz}
                iconHtmlColor="var(--primary)"
                title={"Status"}
                className="py-3"
                style={{ width: "100%", borderRadius: 0 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {unit.unit_published === 1 ? (
                <Dropdown.Item onClick={openUnpublishModal}>
                  Unpublish 取消公布
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={openPublishModal}>
                  Publish 公布
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <div className="vr my-1" />
          <IconButton
            transparent
            border={false}
            Icon={DeleteOutline}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              openDelete();
              ev.stopPropagation();
            }}
            title={"Delete 删除"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
          />
          {deleteModal}
          {publishModal}
          {unpublishModal}
        </Card.Footer>
      </Card>
    </Col>
  );
}
