import { useRef, useState } from "react";
import { Container, Form, Modal } from "react-bootstrap";
import * as yup from "yup";
import { BaseModalProps } from "../../../hooks/useModal";
import FileThumbnailPlaceholder from "../../utils/FileThumbnailPlaceholder";
import { extractFileExtension } from "../../utils/HelperFunctions";
import IconButton from "../../utils/IconButton";
import { Close, CloseRounded, Upload } from "@mui/icons-material";
import { Formik } from "formik";
import cloudUploadIcon from "../../../assets/icons/cloud-upload.png";
import classNames from "classnames";
import styles from "../cardstyles.module.css";

export const allowedFileTypes = [
  "pdf",
  "doc",
  "docx",
  "odt",
  "xls",
  "xlsx",
  "ods",
  "ppt",
  "pptx",
  "txt",
  "rtf",
  "bmp",
  "heic",
  "jpg",
  "jpeg",
  "png",
  "raw",
  "svg",
  "tiff",
  "webp",
  "psd",
  "ai",
  "cdr",
  "dwg",
  "dxf",
  "gif",
  "mp4",
  "mov",
  "avi",
  "flv",
  "mkv",
  "webm",
  "3gp",
  "aac",
  "flac",
  "m4a",
  "mp3",
  "ogg",
  "wav",
  "wma",
];

interface TeachingGuideModalProps extends BaseModalProps {
  setGuideDocValue: (file: File | null) => void;
  setGuideNameValue: (name: string) => void;
}

export default function TeachingGuideModal({
  show,
  onClose,
  setGuideDocValue,
  setGuideNameValue,
  ...props
}: TeachingGuideModalProps) {
  const handleClose = async () => {
    // Reset files and thumbnail
    setThumb(undefined);
    setFile([]);
    onClose();
  };

  const validationSchema = yup.object().shape({
    unit_guidedoc: yup.string(),
    unit_guidename: yup
      .string()
      .max(60, "Name must be less than 60 characters"),
    unit_guidetype: yup
      .string()
      .oneOf(["pdf"], (obj) => `'${obj.value}' files aren't allowed.`),
  });

  // file form element
  const fileInput = useRef<HTMLInputElement>(null);
  const fileRef = useRef<File | null>(null);

  const [thumb, setThumb] = useState<JSX.Element>();

  const setFile = (files: File[]) => {
    if (files[0]) {
      fileRef.current = files[0];

      if (files[0].type.startsWith("image")) {
        //read image and set thumb
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === "string") {
            setThumb(
              <>
                <img
                  alt="thumbnail"
                  className="mb-2"
                  src={reader.result}
                  width={100}
                  height={100}
                />
                <span className="fw-bold">{files[0].name}</span>
              </>
            );
          }
        };
        reader.readAsDataURL(files[0]);
      } else {
        setThumb(
          <>
            <FileThumbnailPlaceholder
              className="mb-2"
              format={extractFileExtension(files[0].name) || "UNK"}
            />
            <span className="fw-bold">{files[0].name}</span>
          </>
        );
      }

      return files[0];
    }
    setThumb(undefined);
  };

  const [showDropOverlay, setShowDropOverlay] = useState(false);

  type UploadForm = {
    unit_guidedoc?: string;
    unit_guidename?: string;
    unit_guidesize?: number;
    unit_guidetype?: string;
  };

  const initialValues: UploadForm = {
    unit_guidedoc: undefined,
    unit_guidename: "",
    unit_guidesize: 0,
    unit_guidetype: "",
  };

  const onSubmit = (values: UploadForm) => {
    const file = fileRef.current;

    if (file) {
      setGuideDocValue(file);
      setGuideNameValue(file.name);

      handleClose();
    }
  };

  return (
    <Modal
      {...props}
      show={show}
      onHide={() => {
        handleClose();
      }}
      className="modal-layer-1"
      backdropClassName="modal-layer-1"
      centered
    >
      <Modal.Header>
        <Modal.Title>Upload a Teaching Guide 上载教学手册</Modal.Title>
        <IconButton
          title="Close modal"
          Icon={CloseRounded}
          className="ms-auto"
          onClick={() => handleClose()}
        />
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, handleSubmit, errors }) => {
          return (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Body>
                <Container className="small">
                  {/* Upload Area */}
                  <div
                    style={{ border: "1px dashed" }}
                    className={classNames({
                      "mt-2 mb-3 p-3 rounded border-secondary d-flex flex-column align-items-center justify-content-center":
                        true,
                      [styles.dropZone]: showDropOverlay,
                    })}
                    onDragEnter={(e) => {
                      if (e.dataTransfer.types.includes("Files")) {
                        setShowDropOverlay(true);
                      }
                    }}
                    onDragLeave={() => setShowDropOverlay(false)}
                    onDragOver={(e) => {
                      e.preventDefault();
                      if (e.dataTransfer.types.includes("Files")) {
                        e.dataTransfer.dropEffect = "copy";
                      }
                    }}
                    onDrop={(ev) => {
                      // Prevent default behaviour (Prevent file from being opened)
                      ev.preventDefault();
                      setShowDropOverlay(false);

                      const file =
                        Array.from(ev.dataTransfer.items)
                          .find((i) => i.kind === "file")
                          ?.getAsFile() || ev.dataTransfer.files.item(0);

                      if (file) {
                        setFile([file]);
                        setFieldValue("unit_guidesize", file.size ?? 0, true);
                        const extension = extractFileExtension(file.name ?? "");
                        setFieldValue("unit_guidetype", extension, true);
                        setFieldValue("unit_guidename", file.name ?? "", true);
                      }
                    }}
                  >
                    <input
                      onChange={(ev) => {
                        const file = setFile(
                          Array.from(ev.currentTarget.files ?? [])
                        );
                        if (file) {
                          setFieldValue("unit_guidesize", file.size ?? 0, true);
                          const extension = extractFileExtension(
                            file.name ?? ""
                          );
                          setFieldValue("unit_guidetype", extension, true);
                          setFieldValue(
                            "unit_guidename",
                            file.name ?? "",
                            true
                          );
                        }
                        return false;
                      }}
                      ref={fileInput}
                      className="d-none"
                      type="file"
                      accept=".pdf"
                    ></input>

                    <div className="mt-2 d-flex flex-column align-items-center">
                      {thumb ?? (
                        <img
                          alt=""
                          src={cloudUploadIcon}
                          draggable={false}
                          width={60}
                          className="mt-2"
                        ></img>
                      )}
                    </div>

                    <p style={{ fontSize: "large" }} className="m-0">
                      Drag &amp; Drop your file here or
                    </p>
                    <p style={{ fontSize: "large" }} className="mb-3">
                      拖放您的文件或
                    </p>
                    <IconButton
                      title="Browse for files 浏览文件"
                      label="Browse file 浏览文件"
                      onClick={() => fileInput.current?.click()}
                    />
                  </div>
                </Container>
              </Modal.Body>
              <Modal.Footer className="overflow-hidden">
                <p className="text-danger w-100 text-truncate">
                  {errors.unit_guidedoc ||
                    errors.unit_guidename ||
                    errors.unit_guidesize ||
                    errors.unit_guidetype}
                </p>
                <IconButton
                  transparent
                  Icon={Close}
                  iconHtmlColor="var(--primary)"
                  className="px-4 me-3 border border-primary text-primary"
                  label="Cancel 取消"
                  onClick={() => handleClose()}
                />
                <IconButton
                  Icon={Upload}
                  type="submit"
                  className="px-4"
                  label={"Upload 上传"}
                />
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
}
