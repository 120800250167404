import useEbicAPI, { EbicMutationOptions, useEbicMutation, useEbicPaginatedQuery } from "./useEbicAPI";
import * as EBIC from "@ebic-bbrm/types";
import { ALogQueries } from "./activitylog.api";

export const unitQueries = {
    UNIT: (unitId: number) => ['content', 'units', unitId] as const,
    UNITS: (params: FetchUnitsOptions) => ['content', 'units', params] as const,

    DOWNLOADABLE: (downloadId: number) => ['content', 'units', 'downloadables', downloadId] as const,
    DOWNLOADABLES: (params: FetchDownloadablesOptions) => ['content', 'units', 'downloadables', params] as const
}

export const unitBaseRoute = `/api/units`;

export function useUnitById(unitId: number, enabled = true) {
    return useEbicAPI<EBIC.Content.Unit>(
        unitQueries.UNIT(unitId),
        `${unitBaseRoute}/${unitId}`,
        true,
        {
            enabled,
            retry: (_, error) => error.status !== 401
        }
    )
}

export interface FetchUnitsOptions {
    sort?: "alphabetical" | "newest";
    search?: string;
    cour_id?: number;
    published?: boolean;
    draft?: boolean;

    limit?: number;
}

export function useUnits(params: FetchUnitsOptions = {}, enabled = true) {
    // Set defaults
    const { limit = 9, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.Content.Unit[]>(
        unitQueries.UNITS(options),
        `${unitBaseRoute}/all?${searchParams.toString()}`,
        true,
        { enabled }
    )
}

export function useTeachingGuide(unit_id: number) {
    return useEbicAPI<string>(unitQueries.UNIT(unit_id),
        `${unitBaseRoute}/view/${unit_id}`,
        true,
        {
            cacheTime: 60 * 1000, //cache for only 1 min
        }
    );
}



export interface CreateUnitInterface {
    cour_id: number;
    unit_name: string;
    unit_pic: File | undefined;

    unit_guidedoc: Blob | undefined;
    unit_guidename: string | undefined;
    unit_videourl: string | undefined;
}

export interface EditUnitInterface extends Omit<CreateUnitInterface, 'cour_id' | 'unit_seqno'> {
    unit_id: number;
}

interface RearrangeUnitInterface {
    sourceSeqNo: number;
    destinationSeqNo: number;
    courseId: number;
}

export interface PublishUnitInterface {
    unit_id: number;
    unit_published: 0 | 1;
}

const UnitMutations = {
    CREATE_UNIT: (unit: CreateUnitInterface): EbicMutationOptions => ({
        url: `${unitBaseRoute}`,
        method: 'POST',
        requestBody: unit,
        useFormData: true,
        updater: [
            {
                queryKey: unitQueries.UNITS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                action: 'refetch'
            }
        ]
    }),

    EDIT_UNIT: ({ unit_id, ...unit }: EditUnitInterface): EbicMutationOptions => ({
        url: `${unitBaseRoute}/${unit_id}`,
        method: 'PUT',
        requestBody: unit,
        useFormData: true,
        updater: [
            {
                queryKey: unitQueries.UNITS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: unitQueries.UNIT(unit_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    PUBLISH_UNIT: ({ unit_id, unit_published }: PublishUnitInterface): EbicMutationOptions => ({
        url: `${unitBaseRoute}/${unit_id}/publish`,
        method: 'PUT',
        requestBody: { unit_published },
        updater: [
            {
                queryKey: unitQueries.UNITS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: unitQueries.UNIT(unit_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    REARRANGE_UNIT: ({ sourceSeqNo, ...unit }: RearrangeUnitInterface) => ({
        url: `${unitBaseRoute}/rearrange/${sourceSeqNo}`,
        method: 'PUT',
        requestBody: unit,
        updater: {
            queryKey: unitQueries.UNITS({}),
            partialMatch: true,
            action: 'refetch'
        }
    }),

    DELETE_UNIT: (unit_id: number): EbicMutationOptions => ({
        url: `${unitBaseRoute}/${unit_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: unitQueries.UNITS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),


}

export function useUnitMutations<Key extends keyof typeof UnitMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = UnitMutations[key] as (
        params: Parameters<typeof UnitMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}

export const downloadableBaseRoute = `/api/units/downloadables`;

export function useDownloadableById(downloadId: number, enabled = true) {
    return useEbicAPI<EBIC.Content.Downloadable>(
        unitQueries.DOWNLOADABLE(downloadId),
        `${downloadableBaseRoute}/${downloadId}`,
        true,
        {
            enabled,
            retry: (_, error) => error.status !== 401
        }
    )
}

export function useDownloadableLink(dnld_id: number) {
    return useEbicAPI<string>(unitQueries.DOWNLOADABLE(dnld_id),
        `${downloadableBaseRoute}/downloaditem/${dnld_id}`,
        true,
        {
            cacheTime: 60 * 1000, //cache for only 1 min
        }
    );
}


export interface FetchDownloadablesOptions {
    sort?: "alphabetical" | "newest";
    search?: string;
    unit_id?: number;

    limit?: number;
}

export function useDownloadables(params: FetchDownloadablesOptions = {}, enabled = true) {

    const searchParams = new URLSearchParams();
    for (const key in params) {
        const value = params[key as keyof typeof params]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicAPI<EBIC.Content.Downloadable[]>(
        unitQueries.DOWNLOADABLES(params),
        `${downloadableBaseRoute}/all?${searchParams.toString()}`,
        true,
        { enabled, refetchOnMount: 'always' }
    )
}

export interface CreateDownloadableInterface {
    unit_id: number;
    dnld_file: Blob | undefined;
    dnld_name: string | undefined;
}

export interface EditDownloadableInterface {
    dnld_id: number;
    dnld_name: string | undefined;
}

const DownloadableMutations = {
    CREATE_DOWNLOADABLE: (downloadable: CreateDownloadableInterface): EbicMutationOptions => ({
        url: `${downloadableBaseRoute}`,
        method: 'POST',
        requestBody: downloadable,
        useFormData: true,
        updater: [
            {
                queryKey: unitQueries.DOWNLOADABLES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    EDIT_DOWNLOADABLE: ({ dnld_id, ...downloadable }: EditDownloadableInterface): EbicMutationOptions => ({
        url: `${downloadableBaseRoute}/${dnld_id}`,
        method: 'PUT',
        requestBody: downloadable,
        updater: [
            {
                queryKey: unitQueries.DOWNLOADABLES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: unitQueries.DOWNLOADABLE(dnld_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    DELETE_DOWNLOADABLE: (dnld_id: number): EbicMutationOptions => ({
        url: `${downloadableBaseRoute}/${dnld_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: unitQueries.DOWNLOADABLES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),

    DOWNLOAD: (dnld_id: number): EbicMutationOptions => ({
        url: `${downloadableBaseRoute}/downloaditem/${dnld_id}`,
        method: 'POST'
    }),



}

export function useDownloadableMutations<Key extends keyof typeof DownloadableMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = DownloadableMutations[key] as (
        params: Parameters<typeof DownloadableMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}