import * as EBIC from "@ebic-bbrm/types";
import { HTMLAttributes, useState } from "react";
import { Card, Image } from "react-bootstrap";
import styles from "../asset.module.css";
import ebicPlaceholder from "../../../assets/img_placeholder.jpg";
import LineClampText from "../../utils/LineClampText";
import {
  Groups,
  School,
  EditOutlined,
  DeleteOutline,
  AutoStories,
} from "@mui/icons-material";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { useClassesMutations } from "../../../api/classes.api";
import { useEbicUser } from "../../../api/user.api";
import IconButton from "../../utils/IconButton";
import DestructiveModal from "../../utils/DestructiveModal";
import ClassEditModal from "./ClassEditModal";
import useModal from "../../../hooks/useModal";
import { useCourseById } from "../../../api/courses.api";
import { useOrgProfileById } from "../../../api/organization.api";

interface OrgClassesItemProps extends HTMLAttributes<HTMLDivElement> {
  classes: EBIC.Classes.ClassInfo;
  classMembers: EBIC.Classes.ClassMembers;

  activeOrg: boolean;
}

const OrgClassesItem = ({
  classes,
  classMembers,
  activeOrg,
}: OrgClassesItemProps) => {
  // const [showEditModal, setShowEditModal] = useState(false)
  const [showEditModal, setShowEditModal] = useModal(ClassEditModal, {
    classId: classes.class_id,
    orgId: classes.org_id,
    classMembers: classMembers,
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data: course } = useCourseById(classes.cour_id ?? 0);
  const { mutate: deleteClass, error } = useClassesMutations("DELETE");
  const { mutate: addLog } = useActivityLogMutations("CREATE");
  const { data: dbUser } = useEbicUser();
  const { data: organization } = useOrgProfileById(classes.org_id);

  const isSysAdmin = Boolean(dbUser?.user_role === "S");

  return (
    <>
      <Card className={"border-0 shadow h-100 " + styles.organizationListCard}>
        <div
          className={
            "card-img-top " + styles.organizationListCardImageContainer
          }
        >
          <Card.Img
            className={"cursor-pointer " + styles.organizationListCardImage}
            as={Image}
            onClick={() =>
              activeOrg
                ? setShowEditModal()
                : isSysAdmin
                ? setShowEditModal()
                : undefined
            }
            src={classes.class_pic ?? ebicPlaceholder}
            variant="top" // needed to have proper corner radius
          />
        </div>
        <Card.Body className="p-3">
          <LineClampText
            maxLines={1}
            title={classes.class_name}
            className={"fw-bold m-0 "}
          >
            {classes.class_name}
          </LineClampText>
          <LineClampText
            maxLines={1}
            title={classes.teacher_name}
            className="text-capitalized text-muted mb-0"
            style={{ fontSize: "0.81rem" }}
          >
            <School fontSize="small" />{" "}
            {classes.teacher_name ?? "No Teachers assigned yet."}
          </LineClampText>
          <LineClampText
            maxLines={1}
            title={`classes.class_size`}
            className="text-capitalized text-muted mb-0"
            style={{ fontSize: "0.81rem" }}
          >
            <Groups fontSize="small" /> {classes.class_size}
          </LineClampText>
          <LineClampText
            maxLines={1}
            title={`course.cour_name`}
            className="text-capitalized text-muted mb-0"
            style={{ fontSize: "0.81rem" }}
          >
            <AutoStories fontSize="small" /> {course?.cour_name ?? "No Courses"}
          </LineClampText>
          <LineClampText
            maxLines={4}
            title={classes.class_desc ?? "No description available."}
            className={
              "card-text small mt-3 " +
              styles.organizationListCardDescription +
              " mb-0"
            }
          >
            {classes.class_desc ?? "No description available."}
          </LineClampText>
        </Card.Body>
        {activeOrg || isSysAdmin ? (
          <Card.Footer className="d-flex p-0">
            <IconButton
              transparent
              border={false}
              Icon={EditOutlined}
              iconHtmlColor="var(--primary)"
              onClick={() => setShowEditModal()}
              title={"Edit"}
              className="py-3"
              style={{
                width: "calc(50% - 0.5px)",
                borderRadius: 0,
                borderBottomLeftRadius: 12,
              }}
            />
            <div className="vr my-1" />
            <IconButton
              transparent
              border={false}
              Icon={DeleteOutline}
              iconHtmlColor="var(--primary)"
              onClick={() => setShowDeleteModal(true)}
              title={"Delete"}
              className="py-3"
              style={{
                width: "calc(50% - 0.5px)",
                borderRadius: 0,
                borderBottomRightRadius: 12,
              }}
            />
          </Card.Footer>
        ) : (
          <></>
        )}
        {showEditModal}
        <DestructiveModal
          title="Delete Class 删除类"
          description={
            "This class will be permanently removed from the system. 此类将从系统中永久删除。"
          }
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() =>
            deleteClass(
              {
                class_id: classes.class_id,
                org_id: classes.org_id,
              },
              {
                onSuccess: () => {
                  addLog({
                    alog_type: "I",
                    alog_module: "S",
                    alog_action: "D",
                    alog_accessfrom: 0,
                    alog_modulename: classes.class_name,
                    alog_details: "Delete Class",
                    org_name: organization?.org_name ?? null,
                  });
                },
                onError: () => {
                  addLog({
                    alog_type: "E",
                    alog_module: "S",
                    alog_action: "D",
                    alog_accessfrom: 0,
                    alog_modulename: classes.class_name,
                    alog_details: "Delete Class Failed.",
                    org_name: organization?.org_name ?? null,
                  });
                  alert(error?.message);
                },
              }
            )
          }
        />
      </Card>
    </>
  );
};

export default OrgClassesItem;
