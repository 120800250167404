import { OrgOwnerForm, useOrgProfileCompeletion } from "./CheckUserModal";
import { Row, Col, Button, Form } from "react-bootstrap";
import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Formik, FormikProps } from "formik";
import UserAvatar from "../../Profile/UserAvatar";
import {
  BootstrapInput,
  BootstrapInputPhone,
} from "../../utils/FormikBootstrapInputs";
import { useAuth0 } from "@auth0/auth0-react";
import { useOrganizationMutations } from "../../../api/organization.api";
import IconButton from "../../utils/IconButton";
import { Upload } from "@mui/icons-material";

const CreateOrgOwner = () => {
  const {
    orgForm,
    orgOwnerForm,
    setOrgOwnerForm,
    goNextPage,
    goPrevPage,
    isLoading,
  } = useOrgProfileCompeletion();

  const { mutate: create } = useOrganizationMutations("CREATE_ORG_AND_USER");

  const { user } = useAuth0();

  const initialValues: Pick<
    OrgOwnerForm,
    "user_firstname" | "user_lastname" | "user_mobileno" | "user_role"
  > = {
    user_firstname: orgOwnerForm.user_firstname,
    user_lastname: orgOwnerForm.user_lastname,
    user_mobileno: orgOwnerForm.user_mobileno,
    user_role: "U",
  };

  const validationSchema = yup.object({
    user_firstname: yup
      .string()
      .required("First Name is required")
      .max(30, ({ max }) => `Maximum ${max} characters`),
    user_lastname: yup
      .string()
      .required("Last Name is required")
      .max(30, ({ max }) => `Maximum ${max} characters`),
    user_mobileno: yup
      .string()
      .max(12, ({ max }) => `Maximum ${max} characters`),
  });

  // profile pic
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<{ file: File; url: string }>();
  const [isReadingImage, setIsReadingImage] = useState(false);
  const [imageReadError, setImageReadError] = useState<string>();

  const handleImageSelect = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return setImageReadError("Couldn't read image");
    }
    if (!file.type.startsWith("image")) {
      return setImageReadError("Invalid image file");
    }

    setIsReadingImage(true);

    readImage(file);

    /**
     * Return false in event handler, don't change the value of underlying input
     */
    return false;
  };

  const readImage = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setIsReadingImage(false);

      if (e.target?.result) {
        setImage({
          file,
          url: e.target.result as string,
        });
      }
    };
    reader.onerror = (err) => {
      console.error(err);
      setIsReadingImage(false);
    };
    reader.readAsDataURL(file);
  };

  const onSubmit = (
    values: Pick<
      OrgOwnerForm,
      "user_firstname" | "user_lastname" | "user_mobileno" | "user_role"
    >
    // values2: OwnerForm
  ) => {
    const orgUserForm = {
      ...orgOwnerForm,
      ...values,
    };

    create(
      {
        ...orgUserForm,
        ...orgForm,
        user_pic: image?.file ?? null,
      },
      {
        onSuccess: () => {
          goNextPage();
        },
      }
    );
  };

  const formikRef =
    useRef<
      FormikProps<
        Pick<
          OrgOwnerForm,
          "user_firstname" | "user_lastname" | "user_mobileno" | "user_role"
        >
      >
    >(null);

  useEffect(() => {
    // restore profiile pic preview if saved
    if (orgOwnerForm.user_pic !== null) {
      readImage(orgOwnerForm.user_pic);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <h1 className="text-muted">
          Setup Your&nbsp;
          <span className="text-primary">Profile</span> 设定个人
          <span className="text-primary">资料</span>
        </h1>
        <p>
          As the person subscribing you will be appointed automatically as the
          administrator for your institution. Tell us more about yourself so
          that we can get in touch with you for verification.
          <br />
          您将被指定为您机构的系统负责人。请提供您的资料以方便我们与您联系。
        </p>
      </Row>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          touched,
          errors,
          values,
          handleBlur,
          handleSubmit,
          setFieldError,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col md="3">
                <div className="d-flex flex-column align-items-center">
                  {user?.picture === undefined ? (
                    <div
                      className="skeleton-box rounded-circle"
                      style={{ width: 100, height: 100 }}
                    ></div>
                  ) : (
                    <UserAvatar
                      roundedCircle
                      src={image?.url ?? user.picture}
                      width="140"
                      height="140"
                      style={{ pointerEvents: "none" }}
                    />
                  )}

                  <div className="mt-3 d-flex justify-content-center align-items-center">
                    <IconButton
                      Icon={Upload}
                      transparent
                      className="text-black mt-3 fw-bold"
                      type="button"
                      title="Upload Profile Image"
                      label="UPLOAD 上传"
                      onClick={() => inputRef.current?.click()}
                      disabled={isReadingImage || isLoading}
                      style={{
                        width: 150,
                        maxWidth: "100%",
                        fontSize: "12px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />

                    {/* {image?.url !== undefined && (
                                            <IconButton

                                                className="ms-2 mt-3 fw-bold"
                                                title="Remove"
                                                label="REMOVE 消除"
                                                onClick={() => {
                                                    if (inputRef.current !== null) {
                                                        inputRef.current.value = "";
                                                        setImage(undefined);
                                                    }
                                                }}
                                                disabled={image?.url === undefined || isLoading}
                                            />

                                        )} */}
                  </div>

                  <input
                    hidden
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleImageSelect}
                  />
                </div>
              </Col>
              <Col>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="user_firstname"
                      label="First Name 名"
                      placeholder="First Name"
                      aria-label="First Name"
                      disabled={isLoading}
                    />
                  </Form.Group>
                  <Form.Group as={Col} lg>
                    <BootstrapInput
                      id="user_lastname"
                      label="Last Name 姓"
                      placeholder="Last Name"
                      aria-label="Last Name"
                      disabled={isLoading}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3 g-3">
                  <Form.Group as={Col} lg>
                    <BootstrapInputPhone
                      id="user_mobileno"
                      label="Mobile No 手机号"
                      placeholder="Mobile No"
                      aria-label="Mobile No 手机号"
                      disabled={isLoading}
                      required={false}
                      value={values.user_mobileno}
                      onChange={(val) => {
                        setFieldValue("user_mobileno", val);
                      }}
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <div className="d-flex flex-wrap justify-content-end">
              <Button
                variant="outline-primary"
                className="me-2"
                disabled={isLoading}
                onClick={() => {
                  // save current page data
                  setOrgOwnerForm((prev) => ({
                    ...prev,
                    ...values,
                    user_pic: image?.file ?? null,
                  }));

                  goPrevPage();
                }}
              >
                BACK 上一步
              </Button>
              <Button type="submit" disabled={isLoading}>
                CONFIRM SUBSCRIPTION 确认
              </Button>

              {imageReadError !== undefined && (
                <p className="w-100 text-danger">{imageReadError}</p>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateOrgOwner;
