import * as EBIC from "@ebic-bbrm/types"
import { useMemo } from "react";
import { ALogQueries } from "./activitylog.api";
import useEbicAPI, { EbicMutationOptions, useEbicMutation, useEbicPaginatedQuery } from './useEbicAPI';

export interface FetchAnnouncementsOptions {
    query?: string;
    sort?: "Newest" | "Oldest";
    limit?: number;

    active?: boolean;
    inactive?: boolean;
}

export const AnnouncementsQueries = {
    ANNOUNCEMENTS: ['anno', 'details'] as const,

    ANNOUNCEMENTSBYID: (announcementsId: number) => ['anno', 'details', announcementsId] as const,

    MAINANNOUNCEMENT: (params: FetchAnnouncementsOptions) => ['anno', 'details', params] as const,
}

export const announcementsBaseRoute = '/api/announcements'

export function useMainAnnouncements(params: FetchAnnouncementsOptions = {}, enabled = true) {

    const { limit = 20, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const sortParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof params]
        if (value !== undefined) {
            sortParams.set(key, String(value))
        }
    }

    const query = useEbicPaginatedQuery<EBIC.Announcements[]>(
        AnnouncementsQueries.MAINANNOUNCEMENT(params),
        `/api/announcements/sort?${sortParams.toString()}`
    );

    const anno = useMemo(() => {
        const data = query.data?.pages.flatMap((d) => d.result)
        //remove duplicates
        return data?.filter((d, i) => data.findIndex(d2 => d.anno_id === d2.anno_id) === i)
            .map((n) => ({
                ...n,
            }))

    }, [query.data?.pages])

    return {
        anno, query
    }

}

export function useAllAnnouncements() {
    return useEbicAPI<EBIC.Announcements[]>(
        AnnouncementsQueries.ANNOUNCEMENTS,
        `${announcementsBaseRoute}`,
        true,
        {
            refetchOnMount: 'always'
        }
    )
}

export function useAnnouncementsById(announcementsId: number, enabled = true) {
    return useEbicAPI<EBIC.Announcements>(
        AnnouncementsQueries.ANNOUNCEMENTSBYID(announcementsId),
        `${announcementsBaseRoute}/${announcementsId}`,
        true,
        { enabled }
    );
}

export function initialiseAnnouncementsObject(anno: EBIC.Announcements) {
    const requiredFields = [
        'anno_id',
        'anno_subject',
        'anno_text',
        'anno_expire',
        'user_id'
    ];
    const originalAnnouncements: EBIC.Announcements = requiredFields.reduce(
        (accumulator, currentValue) => {
            return {
                ...accumulator,
                ...{
                    [currentValue]: anno[currentValue as keyof typeof anno],
                },
            };
        },
        {} as EBIC.Announcements
    );

    return originalAnnouncements;
}

export function useEbicContacts() {
    return useEbicAPI<EBIC.Contact>(
        ['ebic', 'contact'] as const,
        `/api/contact`,
        false
    )
}


interface TCreateAnnouncement {
    anno_subject: string;
    anno_text: string;
    anno_expire: string;
    user_id: number;
}

type EditAnnouncementForm = Pick<
    EBIC.Announcements,
    | 'anno_subject'
    | 'anno_text'
    | 'anno_expire'
    | 'anno_id'
>

const AnnouncementsMutations = {
    CREATE: ({ user_id, ...announcement }: TCreateAnnouncement): EbicMutationOptions => ({
        url: `${announcementsBaseRoute}`,
        method: 'POST',
        requestBody: { user_id, ...announcement },
        updater: [
            {
                queryKey: AnnouncementsQueries.ANNOUNCEMENTS,
                action: 'refetch'
            },
            {
                queryKey: AnnouncementsQueries.MAINANNOUNCEMENT({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),
    EDIT: ({ anno_id, ...announcement }: EditAnnouncementForm): EbicMutationOptions => ({
        url: `${announcementsBaseRoute}/${anno_id}`,
        method: 'PUT',
        requestBody: announcement,
        updater: [
            {

                queryKey: AnnouncementsQueries.ANNOUNCEMENTSBYID(anno_id),
                action: (prev?: EBIC.Announcements) => {
                    if (prev !== undefined) {
                        prev.anno_subject = announcement.anno_subject;
                        prev.anno_text = announcement.anno_text;
                        prev.anno_expire = announcement.anno_expire;

                    }
                    return prev;
                },
            },
            {

                queryKey: AnnouncementsQueries.ANNOUNCEMENTS,
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: AnnouncementsQueries.MAINANNOUNCEMENT({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),
    DELETE: ({ anno_id }: { anno_id: number }): EbicMutationOptions => ({
        url: `${announcementsBaseRoute}/${anno_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: AnnouncementsQueries.ANNOUNCEMENTS,
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: AnnouncementsQueries.MAINANNOUNCEMENT({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),
}

export function useAnnouncementsMutations<Key extends keyof typeof AnnouncementsMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = AnnouncementsMutations[key] as (
        params: Parameters<typeof AnnouncementsMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}