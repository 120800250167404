/* eslint-disable no-mixed-operators */
import * as EBIC from "@ebic-bbrm/types";
import {
  useOrganizationUsers,
  useOrgProfileById,
} from "../../../api/organization.api";
import styles from "../organization.module.css";
import { formatDateByTimeDifference } from "../../utils/HelperFunctions";
import { useState, forwardRef, useEffect } from "react";
import UserAvatar from "../../Profile/UserAvatar";
import { MoreVert } from "@mui/icons-material";
import { Col, Row, Dropdown } from "react-bootstrap";
import IconButton from "../../utils/IconButton";
import { useEbicUser, useUserMutations } from "../../../api/user.api";
import DestructiveModal from "../../utils/DestructiveModal";
import OrgUserEditModal from "./OrgEditUsers";
import LineClampText from "../../utils/LineClampText";
import BootstrapSpinner from "../../utils/BootstrapSpinner";

interface OrgProfileProps {
  orgId: number;
  isSysAdmin: boolean;
  isOrgOwner: boolean;
}

const OrgUsers = ({ orgId, isSysAdmin, isOrgOwner }: OrgProfileProps) => {
  // const { id } = useParams<{ id?: string }>();
  const { data: orgUsers } = useOrganizationUsers(orgId);

  return (
    <>
      {orgUsers?.map((orgUser) => (
        <OrgHeader
          orgUser={orgUser}
          isSysAdmin={isSysAdmin}
          isOrgOwner={isOrgOwner}
        />
      ))}
    </>
  );
};

interface ProfileHeaderProps {
  orgUser: EBIC.User.User;
  isSysAdmin: boolean;
  isOrgOwner: boolean;
}

export const OrgHeader = forwardRef<HTMLDivElement, ProfileHeaderProps>(
  ({ orgUser, isSysAdmin, isOrgOwner }, ref) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const { mutate: deleteUser } = useUserMutations("DELETE");
    const { data: dbUser } = useEbicUser();

    useEffect(() => {
      //reset when url param changes
      setShowEditModal(false);
    }, [orgUser.user_id]);

    const { data: orgProfile } = useOrgProfileById(orgUser?.org_id);

    if (!orgUser) return <BootstrapSpinner />;

    return (
      <div
        ref={ref}
        className="d-flex flex-column align-items-center p-4 mb-2 bg-light shadow rounded-4"
      >
        <div className="d-flex flex-row align-items-center align-self-stretch">
          <UserAvatar
            user={orgUser}
            style={{
              aspectRatio: "1 / 1",
              width: "min(20%, 100px)",
              height: "auto",
            }}
            className="me-2 flex-shrink-0"
          />

          <div className="d-flex flex-column flex-grow-1">
            <Row>
              <Col xs={11} className="pe-0">
                <div className=" d-flex flex-row align-items-bottom fw-bold w-100 p-0">
                  <LineClampText className="fw-normal m-0" maxLines={1}>
                    {orgUser?.user_id !== dbUser?.user_id
                      ? orgUser?.user_firstname
                      : "You"}
                  </LineClampText>
                </div>
              </Col>
              <Col xs={1} className="p-0 m-0">
                {((orgProfile?.org_status === 1 && isOrgOwner) ||
                  isSysAdmin) && (
                  <Dropdown
                    onToggle={(_, meta) => {
                      meta.originalEvent?.stopPropagation();
                      meta.originalEvent?.preventDefault();
                    }}
                    // style={{ alignSelf: "flex-end" }}
                  >
                    <Dropdown.Toggle as="span" bsPrefix=" ">
                      <IconButton
                        transparent
                        border={false}
                        title="More"
                        Icon={MoreVert}
                        className="m-0"
                        iconHtmlColor="var(--bs-gray)"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-0">
                      <Dropdown.Item onClick={() => setShowEditModal(true)}>
                        Edit
                      </Dropdown.Item>
                      {/* removes delete button from organization owner */}
                      {!((orgUser?.user_role === "O" || orgUser?.user_role === "S") && orgProfile?.org_id === orgUser?.org_id) && (
                        <Dropdown.Item onClick={() => setShowDeleteModal(true)}>
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </Col>
              <div className="text-muted mt-0">
                {orgUser?.user_lastlogin !== null && (
                  <small>
                    Last seen:{" "}
                    {formatDateByTimeDifference(orgUser?.user_lastlogin ?? "")}
                  </small>
                )}
              </div>
            </Row>
          </div>
          {(orgUser?.user_role === "O") && (
            <div
              style={{
                position: "relative",
                bottom: "-25px",
                right: "-15px",
              }}
            >
              <UserBadge role={"Owner"} />
            </div>
          )}
           {(orgUser?.user_role === "S") && (
            <div
              style={{
                position: "relative",
                bottom: "-25px",
                right: "-15px",
              }}
            >
              <UserBadge role={"SysAdmin"} />
            </div>
          )}
        </div>

        <DestructiveModal
          title="Delete user 删除用户"
          description={
            "This user will no longer be part of your organization. 该用户将不再属于您的机构。"
          }
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={() =>
            deleteUser({
              user_id: orgUser.user_id,
              org_id: orgUser.org_id,
            })
          }
        />
        <OrgUserEditModal
          show={showEditModal}
          onClose={() => setShowEditModal(false)}
          userId={orgUser.user_id}
          orgId={orgUser.org_id}
        />
      </div>
    );
  }
);

export function UserBadge({ role }: { role: "SysAdmin" | "Owner" }) {
  return (
    <div
      className={
        styles.badge +
        " d-flex align-items-center justify-content-center text-center fw-semibold"
      }
      title={role}
      style={{
        ["--badge-color" as any]:
          role === "Owner" ? "var(--primary)" : "var(--bs-gray-500)",
        boxShadow: role === "Owner" ? "0 0 5px 0px var(--primary)" : undefined,
      }}
    >
      {role.charAt(0)}
    </div>
  );
}

export default OrgUsers;
