import { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

export default function EbicQueryProvider({ children } : { children: ReactNode }) {

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: Infinity
            }
        }
    })

    return (
        <QueryClientProvider client={queryClient} contextSharing={true}>
            {children}
        </QueryClientProvider>
    )
}