import { CloseRounded } from '@mui/icons-material';
import { useMemo, useState, useEffect } from 'react';
import { Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import IconButton from '../../utils/IconButton';
import * as yup from "yup";
import { useProductsById, useProductsMutations } from '../../../api/products.api';
import { useActivityLogMutations } from '../../../api/activitylog.api';
import { FetchCoursesOptions, useCourses } from '../../../api/courses.api';
import { Formik } from 'formik';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import BootstrapSpinner from '../../utils/BootstrapSpinner';



interface productEditForm {
    prod_name: string;
    prod_teachers: number | null;
    prod_students: number | null;

}


interface ProductsEditModalProps {
    show: boolean;
    onClose: () => void;
  
    prodId: number;
  }

function ProductsEditModal({ show, onClose, prodId }: ProductsEditModalProps) {

    const { data: selectedProduct, isLoading: isLoadingDetails } = useProductsById(prodId);


    const { mutate: editProduct, isLoading: isLoadingEdit } = useProductsMutations("EDIT")

    const { mutate: addLog } = useActivityLogMutations("CREATE");

    //select courses dropdown
    const params = useMemo(() => {
        const params: FetchCoursesOptions = { published: true };

        //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.


        return params;

    }, [])

    const { data: course, isLoading: isLoadingCourse } = useCourses(params)
    const courses = course?.pages.find((p) => p.page === 1)?.result

    const [selectedCourse, setSelectedCourse] = useState<number>(selectedProduct?.cour_id ?? 0);

    useEffect(() => {
        if (courses && courses.length !== 0)
            setSelectedCourse(selectedProduct?.cour_id ?? 0)
    }, [courses, selectedProduct?.cour_id])

    const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCourse(Number(e.target.value));

    }

    const initialValues: productEditForm = {
        prod_name: selectedProduct?.prod_name ?? "",
        prod_teachers: selectedProduct?.prod_teachers ?? 0,
        prod_students: selectedProduct?.prod_students ?? 0
    }

    const validationSchema = yup.object().shape({
        prod_name: yup
            .string()
            .required("Please enter Product Name")
            .max(120, "Maximum 120 characters"),
        prod_teachers: yup
            .number()
            .max(999, "Maximum 999 value"),
        prod_students: yup
            .number()
            .max(999, "Maximum 999 value"),


    })


    const handleEdit = async (values: productEditForm, { resetForm }: any) => {
        editProduct({
            prod_id: prodId,
            cour_id: selectedCourse,
            ...values
        },
            {
                onSuccess: () => {
                    addLog(
                        {
                            alog_type: 'I',
                            alog_module: 'P',
                            alog_action: 'E',
                            alog_accessfrom: 0,
                            alog_modulename: values.prod_name,
                            alog_details: 'Edit Product',
                            org_name: null
                        }
                    )
                    resetForm();
                    onClose();
                },
                onError: () => {
                    addLog(
                        {
                            alog_type: 'E',
                            alog_module: 'P',
                            alog_action: 'E',
                            alog_accessfrom: 0,
                            alog_modulename: values.prod_name,
                            alog_details: 'Edit Product Failed.',
                            org_name: null
                        }
                    )

                },
            })
    }

    const isLoading = isLoadingEdit || isLoadingDetails || isLoadingCourse;


    if (!courses || isLoading) return <BootstrapSpinner />

    return (
        <>
            <Modal
                size="lg"
                show={show}
                centered
                onHide={onClose}
                backdrop={isLoading ? 'static' : undefined}
                keyboard={!isLoading}
            >
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleEdit}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, setFieldValue, values }) => (
                        <Form onSubmit={handleSubmit}>
                            <Modal.Header className="p-3">
                                <Modal.Title className="fw-normal">
                                    Edit Product Details 编辑产品详情
                                </Modal.Title>
                                <IconButton
                                    Icon={CloseRounded}
                                    title="Close"
                                    className="ms-auto"
                                    disabled={isLoading}
                                    onClick={onClose}
                                />


                            </Modal.Header>
                            <Modal.Body className="small px-4 py-3">


                                <div className="d-flex flex-grow-1">
                                    <div className="mb-2 d-flex flex-column flex-grow-1">
                                        <BootstrapInput
                                            id="prod_name"
                                            required={true}
                                            label="Name 产品名称"
                                            placeholder="Product Name"
                                            aria-label="Product Name"
                                            disabled={isLoading}
                                        />

                                    </div>
                                </div>
                                <div>
                                    <Form.Group>
                                        <Form.Label className="fw-bold text-uppercase text-secondary small undefined form-label">
                                            Select Course 选择课程
                                        </Form.Label>
                                        <InputGroup>
                                            <Form.Select
                                                value={selectedCourse}
                                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    handleCourseChange(e);
                                                    setFieldValue('cour_id', Number(e.target.value));
                                                }}>
                                                {courses.map((c) => (
                                                    <option key={c.cour_id} value={c.cour_id}>
                                                        {c.cour_name}
                                                    </option>
                                                ))}
                                            </Form.Select>

                                        </InputGroup>
                                    </Form.Group>

                                </div>
                                <div>
                                    <Row>
                                        <Col lg={6}>
                                            <BootstrapInput
                                                id="prod_teachers"
                                                required={true}
                                                label="Maximum no. of Teachers 产品名称"
                                                placeholder="totalTeacher"
                                                aria-label="totalTeacher"
                                                disabled={isLoading}
                                            />
                                        </Col>

                                        <Col lg={6}>
                                            <BootstrapInput
                                                id="prod_students"
                                                required={true}
                                                label="Maximum no. of Students 产品名称"
                                                placeholder="totalStudents"
                                                aria-label="totalStudents"
                                                disabled={isLoading}
                                            />
                                        </Col>
                                    </Row>


                                </div>




                            </Modal.Body>

                            <Modal.Footer>

                                <IconButton
                                    iconHtmlColor="var(--primary)"
                                    disabled={isLoading}
                                    label="CANCEL 取消"
                                    transparent
                                    className="text-primary border-primary px-4 me-3"
                                    onClick={() => onClose()}
                                />
                                <IconButton
                                    disabled={isLoading}
                                    type="submit"
                                    label="SAVE 保存"
                                    className="px-4"
                                />
                            </Modal.Footer>
                        </Form>

                    )}

                </Formik>

            </Modal>
        </>
    )
}

export default ProductsEditModal;