import Landing from "./components/Landing/Landing";
import Profile from "./components/Profile/Profile";
import Home from "./components/Home/Home";
import ContentManagement from "./components/ContentManagement/ContentManagement";
import Organizations from "./components/Organizations/Organizations";
import Classes from "./components/Classes/Classes";

import { Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Navibar from "./components/Header/Navibar";
import AllUsersList from "./components/SysAdmin/Users/AllUsersList";
import Reports from "./components/SysAdmin/Reports/Reports";
import AllAnnouncements from "./components/SysAdmin/Announcements/AllAnnouncements";
import { Products } from "./components/SysAdmin/Products/Products";

function App() {

  return (
    <div>
      <Navibar />
      <Switch>
        <AuthenticatedRoute exact path="/" ReplaceWith={Landing}>
            <Redirect to="/home" />
        </AuthenticatedRoute>
        <AuthenticatedRoute path="/home" component={Home} />
        <AuthenticatedRoute path="/profile" component={Profile} />
        <AuthenticatedRoute path="/content" component={ContentManagement} />
        <AuthenticatedRoute path="/organizations" component={Organizations} />
        <AuthenticatedRoute path="/classes" component={Classes} />
        <AuthenticatedRoute path="/users" component={AllUsersList} />
        <AuthenticatedRoute path="/reports" component={Reports} />
        <AuthenticatedRoute path="/announcements" component={AllAnnouncements} />
        <AuthenticatedRoute path="/products" component={Products} />


        <AuthenticatedRoute path="*">
          <Redirect to="/" />
        </AuthenticatedRoute>
      </Switch>
    </div>
  );
}

function AuthenticatedRoute({
  children,
  component,
  ReplaceWith,
  ...props
}: RouteProps & { ReplaceWith?: React.FC }) {
  const { isAuthenticated } = useAuth0();

  const replaceWith = ReplaceWith ? (
    <ReplaceWith></ReplaceWith>
  ) : (
    <Redirect to="/"></Redirect>
  );
  const comp = isAuthenticated ? component : ReplaceWith;

  return (
    <Route component={children ? undefined : comp} {...props}>
      {isAuthenticated ? children : replaceWith}
    </Route>
  );
}

export default App;
