import classNames from "classnames";
import React from "react";
import styles from "./utils.module.css";

interface LineClampTextProps extends React.HTMLAttributes<HTMLParagraphElement> {
    maxLines: number;
}

export default function LineClampText({ maxLines, className, style, ...props } : LineClampTextProps) {
    return (
        <p
            className={classNames(styles.lineClamp, className)}
            style={{
                lineClamp: maxLines,
                WebkitLineClamp: maxLines,
                ...style
            }}
            {...props}
        />
    )
}