import { Table } from "react-bootstrap";

interface TableSkeletonProps {
  columns: string[];
}

const TableSkeleton = ({ columns }: TableSkeletonProps) => {
  return (
    <>
      <Table borderless striped hover responsive>
        <thead className="border-bottom border-dark">
          <tr className="text-secondary">
            {columns.map((name, index) => (
              <th key={index}>{name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <TableBodySkeleton cellCount={columns.length} />
        </tbody>
      </Table>
    </>
  );
};

export const TableBodySkeleton = ({ cellCount }: { cellCount: number }) => {
  return (
    <>
      {Array(2)
        .fill(null)
        .map((v, i) => (
          <tr key={i}>
            {Array(cellCount)
              .fill(null)
              .map((v, j) => (
                <td key={j}>
                  <span className="skeleton-box w-100"></span>
                </td>
              ))}
          </tr>
        ))}
    </>
  );
};

export default TableSkeleton;
