import {
  ContactPage,
  ManageAccounts,
  People,
  PersonOff,
  Badge,
} from "@mui/icons-material";
import { useMemo, useState, useEffect, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FetchUserOptions, useAllUsers } from "../../../api/user.api";
import useQuery from "../../../hooks/useQuery";
import ToggleButtonGroup, { ToggleItem } from "../../ui/ToggleButtonGroup";
import SearchBar from "../../ui/SearchBar";
import EmptyMessage from "../../utils/EmptyMessage";
import UserItem from "./UserItem";
import EbicListCardAdd from "../../utils/EbicListAdd";
import useModal from "../../../hooks/useModal";
import UserAddModal from "./UserAddModal";
import useAppTitle from "../../../hooks/useAppTitle";

type KeysWithType<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];
//Maps for item keys in URL search
type FilterKeys = "a" | "i" | "s" | "c" | "o";
/**
 * If undefined, we select `active_only` by default.
 * If null, we select public posts (no filter).
 */
const FILTER_MAP: Record<
  FilterKeys,
  KeysWithType<Required<FetchUserOptions>, boolean> | null
> = {
  a: "active",
  i: "inactive",
  s: "sysadmin",
  c: "contentadmin",
  o: "coordinator",
} as const;

function AllUsersHeader() {
  const search = useQuery();
  const history = useHistory();

  const handleFilterChange = (filter: FilterKeys) => {
    search.set("f", filter);
    history.replace({
      search: search.toString(),
    });
  };

  type Item = Omit<ToggleItem, "active" | "render">;

  let activeFilter = decodeURIComponent(search.get("f") || "") as FilterKeys;
  if (!(activeFilter in FILTER_MAP)) activeFilter = "a";

  const filterItems: Record<FilterKeys, Item> = {
    s: {
      Icon: ManageAccounts,
      label: "System Admin 系统管制",
    },
    c: {
      Icon: ContactPage,
      label: "Content Admin 课程管制",
    },
    o: {
      Icon: Badge,
      label: "Coordinator 负责人",
    },
    a: {
      Icon: People,
      label: "Users 用户",
    },
    i: {
      Icon: PersonOff,
      label: "Inactive 暂止用户",
    },
  };

  return (
    <div className="mb-2 d-flex align-items-center justify-content-between flex-wrap">
      <ToggleButtonGroup
        items={Object.entries(filterItems).map(([key, item]) => ({
          ...item,
          key: key as FilterKeys,
          active: key === activeFilter,
        }))}
        onSelect={(item) => {
          handleFilterChange(item.key);
        }}
      />
    </div>
  );
}

export default function AllUsersList({
  emptyMessage,
}: {
  emptyMessage?: string;
}) {
  useAppTitle("User Management");

  const search = useQuery();
  const history = useHistory();

  const [modal, open] = useModal(UserAddModal);

  const params = useMemo(() => {
    const params: FetchUserOptions = {};

    //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.
    const query = decodeURIComponent(search.get("q") || "");

    if (query) params.query = query;

    const filterKey =
      FILTER_MAP[decodeURIComponent(search.get("f") || "") as FilterKeys];
    //if null, no filter (all posts)
    if (filterKey !== null) {
      //set matching filter key to true;

      //if undefined, default to show `active` but only if not searching
      if (filterKey !== undefined || !query) {
        params[filterKey ?? "active"] = true;
      }
    }
    return params;
  }, [search]);

  const {
    data: allUsers,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useAllUsers(params);

  const users = useMemo(() => {
    return allUsers?.pages.flatMap((o) => o.result);
  }, [allUsers]);

  //Special case: refetch when navigating to active page
  useEffect(() => {
    if (params.active) {
      refetch();
    }
  }, [params.active, refetch]);

  const handleFetchNextSpinnerMount = useCallback(
    (node: HTMLDivElement | null) => {
      if (!node) return;

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0,
      };

      const observer = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting === true && !isLoading && hasNextPage) {
          fetchNextPage();
        }
      }, options);

      observer.observe(node);
    },
    [isLoading, hasNextPage, fetchNextPage]
  );

  //Search
  const [query, setquery] = useState<string>("");

  const handleEnterPressed = () => {
    if (query !== "") {
      search.set("q", query);
      history.replace({
        search: search.toString(),
      });
    } else {
      history.replace("/users");
    }
  };
  //end of search

  let content: JSX.Element;

  if (users === undefined) {
    content = <UserSkeleton />;
  } else if (users.length === 0) {
    content = (
      <>
        {params.active === true && (
          <EmptyMessage
            className="fs-5"
            message={
              emptyMessage ?? "There are no Users created yet. 还没有创建用户。"
            }
          />
        )}

        {(params.sysadmin === true ||
          params.contentadmin === true ||
          params.coordinator === true) && (
          <Row className="gy-3 gy-lg-4" xs="1" lg="5">
            <Col>
              <EbicListCardAdd
                title="Add Users"
                style={{ minHeight: "23rem" }}
                handleClick={open}
                text="Add User"
              />
              {modal}
            </Col>
          </Row>
        )}
      </>
    );
  } else {
    content = (
      <>
        <Row className="gy-3 gy-lg-4" xs="1" lg="5">
          {users.map((o) => (
            <Col key={o.user_id} style={{ minHeight: "23rem" }}>
              <UserItem users={o} />
            </Col>
          ))}
          ,
          {(params.sysadmin === true ||
            params.contentadmin === true ||
            params.coordinator === true) && (
            <Row className="gy-2 gy-lg-3">
              <Col>
                <EbicListCardAdd
                  title="Add Users"
                  style={{ minHeight: "23rem" }}
                  handleClick={open}
                  text="Add User"
                />
                {modal}
              </Col>
            </Row>
          )}
          <div
            hidden={isLoading || !hasNextPage}
            ref={handleFetchNextSpinnerMount}
          >
            <UserSkeleton />
          </div>
        </Row>
      </>
    );
  }

  return (
    <div className="p-5 pb-2">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <AllUsersHeader />

        <SearchBar
          query={query}
          handleEnterPressed={handleEnterPressed}
          setquery={setquery}
        />
      </div>

      {content}
    </div>
  );
}

export function UserSkeleton() {
  return (
    <>
      {Array<null>(3)
        .fill(null)
        .map((_, idx) => (
          <div
            key={idx}
            className="shadow-sm rounded-4 mb-2 d-flex justify-content-between align-items-center bg-light p-3"
          >
            <div
              className="rounded-circle skeleton-box me-2"
              style={{ width: 50, height: 50 }}
            ></div>
            <div className="flex-grow-1 align-self-stretch py-1 d-flex flex-column justify-content-between">
              <div className="skeleton-box" style={{ width: "60%" }}></div>
              <div
                className="skeleton-box"
                style={{ width: "30%", height: 14 }}
              ></div>
            </div>
          </div>
        ))}
    </>
  );
}
