import classNames from 'classnames';
import React from 'react';

interface CustomToggleInterface extends React.HTMLAttributes<HTMLDivElement> {
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const CustomToggle = React.forwardRef<HTMLDivElement, CustomToggleInterface>(({ onClick, className, children }, ref) => (
    <div
      className={classNames("form-select", className && className)}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
));

export default CustomToggle;