import * as EBIC from "@ebic-bbrm/types";
import ReactTable from '../../utils/ReactTable';
import { useMemo, useState } from 'react';
import { Column } from "react-table";
import { Dropdown } from 'react-bootstrap';
import { MoreVert } from "@mui/icons-material";
import IconButton from "../../utils/IconButton";
import ProductsEditModal from "./ProductsEditModal";
import { useProductsMutations } from "../../../api/products.api";
import DestructiveModal from "../../utils/DestructiveModal";


interface ProductsTableInterface {
    Products: EBIC.Order.Product[];

    isLoading: boolean;
}

export const ProductsTable = ({
    Products,
    isLoading
}: ProductsTableInterface) => {

    const { mutate: deleteProduct } = useProductsMutations("DELETE");
    //table columns
    const ProductsColumns = useMemo<Column<EBIC.Order.Product>[]>(
        () => [
            {
                Header: 'Name',
                accessor: 'prod_name',
                minWidth: 10,
                Cell: ({ value, row }) => {
                    return (
                        <>

                            {
                                `${value}`
                            }
                        </>
                    )
                }
            },
            {
                Header: 'Course',
                accessor: 'cour_name',
                minWidth: 10,
                Cell: ({ value, row }) => {
                    return (
                        <>

                            {
                                `${value}`
                            }
                        </>
                    )
                }
            },
            {
                Header: 'No. of Teachers',
                accessor: 'prod_teachers',
                minWidth: 10,
                Cell: ({ value, row }) => {
                    return (
                        <>

                            {
                                `${value}`
                            }
                        </>
                    )
                }
            },
            {
                Header: 'No. of students',
                accessor: 'prod_students',
                minWidth: 10,
                Cell: ({ value, row }) => {
                    return (
                        <>

                            {
                                `${value}`
                            }
                        </>
                    )
                }
            },
            {
                accessor: 'prod_id',
                minWidth: 10,
                Cell: ({ value, row }) => {
                    const prod = row.original;

                    const [showEditModal, setShowEditModal] = useState(false)
                    const [showDeleteModal, setShowDeleteModal] = useState(false)

                    return (

                        <>

                            <Dropdown
                                onToggle={(_, meta) => {
                                    meta.originalEvent?.stopPropagation();
                                    meta.originalEvent?.preventDefault();
                                }}
                                style={{ float: 'right' }}
                                drop="start"
                            >
                                <Dropdown.Toggle as="span" bsPrefix=" " >
                                    <IconButton
                                        transparent
                                        border={false}
                                        title="More"
                                        Icon={MoreVert}
                                        iconHtmlColor="var(--bs-gray)"
                                    />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="p-0">
                                    <Dropdown.Item
                                        onClick={() => setShowEditModal(true)}
                                    >Edit
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => setShowDeleteModal(true)}
                                    >Delete
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <ProductsEditModal
                                show={showEditModal}
                                onClose={() => setShowEditModal(false)}
                                prodId={prod.prod_id}
                            />
                            <DestructiveModal
                                title="Delete Product 删除产品"
                                description={"This Product will be permanently removed from the system. 该产品将从系统中永久删除。"}
                                show={showDeleteModal}
                                onClose={() => setShowDeleteModal(false)}
                                onConfirm={() =>
                                    deleteProduct({ prod_id: prod.prod_id })
                                }
                            />

                        </>
                    )
                }
            },
        ],
        [deleteProduct]
    )

    return (
        <>
            <ReactTable
                data={Products}
                columns={ProductsColumns}
                isLoading={isLoading}
                placeholderMessage={"There are no Products yet. 还没有订单。"}
                pagination={true}
                Row={(props, data) => <tr {...props} />}
            ></ReactTable>
        </>
    );
}