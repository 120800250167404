import styles from "./utils.module.css";
import classNames from "classnames";
import fileIcon from "../../assets/icons/file.png";

export interface ThumbnailProps {
  format: string;
  id: number;
}

export default function FileThumbnailPlaceholder({
  format,
  ...props
}: Pick<ThumbnailProps, "format"> & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div {...props} className={classNames(props.className, styles.placeholder)}>
      <img
        className={styles.placeholderImage}
        src={fileIcon}
        draggable={false}
        alt=""
      ></img>
      <div>{format.toUpperCase()}</div>
    </div>
  );
}
