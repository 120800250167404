
import * as EBIC from "@ebic-bbrm/types";
import useEbicAPI, {
    EbicMutationOptions,
    useEbicMutation,
    useEbicPaginatedQuery,
} from './useEbicAPI';
import { useAuth0 } from '@auth0/auth0-react';
import { QueryKey } from 'react-query';
import { useMemo } from 'react';
import { Nullable } from '../types/custom';
import { OrganizationQueries } from "./organization.api";
import { ClassesQueries } from "./classes.api";
import { ALogQueries } from "./activitylog.api";

export interface SearchUserOptions {
    query?: string;
    member_of_organization?: number;

}

export interface FetchUserOptions {
    active?: boolean;
    inactive?: boolean;
    sysadmin?: boolean;
    contentadmin?: boolean;
    coordinator?: boolean;

    query?: string;
    limit?: number;
}

export const UserQueries = {
    //Your own user
    USER: ['user', 'details'] as const,
    USER_DETAILS: (userId: number) => ['user', 'profile', userId] as const,
    USER_VERIFICATION: ['user', 'verification'] as const,
    USER_SEARCH: (params: SearchUserOptions) => ['users', 'search', params] as const,
    ALL_USERS: (params: FetchUserOptions) => ['users', 'search', params] as const,

};

export function useUserVerification() {
    type Verification = {
        email: string;
        email_verified: boolean;
    }
    return useEbicAPI<Verification>(UserQueries.USER_VERIFICATION, `/api/user/verification`, true, {
        refetchInterval: 10 * 1000 //refetch every 10 seconds
    })
}


export function useAllUsers(params: FetchUserOptions = {}, enabled = true) {
    //set defaults (to keep query keys more accurate)
    const { limit = 20, ...rest } = params;

    const options = {
        limit, ...rest
    }

    const searchParams = new URLSearchParams();
    for (const key in options) {
        const value = options[key as keyof typeof options]
        if (value !== undefined) {
            searchParams.set(key, String(value))
        }
    }

    return useEbicPaginatedQuery<EBIC.User.User[]>(
        UserQueries.ALL_USERS(options),
        `/api/user/search/all?${searchParams.toString()}`,
        true,
        { enabled }
    );
}


export function useEbicUser() {
    const { isAuthenticated } = useAuth0();
    return useEbicAPI<EBIC.User.User>(UserQueries.USER, '/api/user', true, {
        enabled: isAuthenticated,
        cacheTime: Infinity,
        retry: (_, error) => error.status !== 404,
    });
}

export type UserDetails = Required<EBIC.User.UserInfo> & Nullable<EBIC.User.User>;
export function useUserDetails(userId: number, enabled = true) {
    return useEbicAPI<UserDetails>(
        UserQueries.USER_DETAILS(userId),
        `/api/user/${userId}`,
        true,
        {
            cacheTime: 60 * 1000, //cache for only 1 min
            enabled,
        }
    );
}

type DeleteUserForm = Pick<
    EBIC.User.User,
    | 'user_id'
    | 'org_id'
>

type TCreateUser = Pick<
    EBIC.User.User,
    | 'user_firstname'
    | 'user_lastname'
    | 'user_mobileno'
    | 'user_role'
> & {
    user_pic: File | null;
};

interface EditUserStatus {
    user_id: number;
    user_status: number;
}

export function useUserSearch(
    {
        query,
        member_of_organization,

    }: SearchUserOptions = {},
    enabled = true
) {
    const [url, queryKey] = useMemo(() => {
        const options: SearchUserOptions = {
            query,
            member_of_organization,
        };
        const searchParams = new URLSearchParams();

        let usedKeys: Array<keyof SearchUserOptions> = [];

        for (const _key in options) {
            const key = _key as keyof SearchUserOptions;
            const value = options[key];

            if (value !== undefined) {
                usedKeys.push(key);
                searchParams.set(key, String(value));
            }
        }
        //normal query key
        let queryKey: QueryKey | undefined = UserQueries.USER_SEARCH(options);

        const url = `/api/user/search?${searchParams.toString()}`;
        return [url, queryKey] as const;
    }, [
        query,
        member_of_organization,
    ])
    type SearchResult = Array<Required<EBIC.User.UserInfo>>;

    return useEbicPaginatedQuery<SearchResult>(queryKey ?? [], url, true, {
        cacheTime: 60 * 1000, //cache for 1 min
        //temporarily disable query until current user is fetched, should be fetched before anything else
        enabled: queryKey !== undefined && enabled
    });

}

// type IPaginatedUser = InfiniteData<EbicPaginatedResult<Required<EBIC.User.UserInfo>[]>>;

const UserMutations = {
    CREATE: (body: TCreateUser): EbicMutationOptions => ({
        url: `/api/user`,
        method: 'POST',
        requestBody: body,
        useFormData: true,
        updater: [
            {
                queryKey: UserQueries.USER,
                action: 'refetch'
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },

        ]

    }),
    EDIT: (body: Partial<EBIC.User.User>): EbicMutationOptions => ({
        url: `/api/user`,
        method: 'PUT',
        requestBody: body,
        updater: [
            {
                queryKey: UserQueries.USER,
                action: 'refetch'
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASSMEMBERS(Number(body?.classId??0)),
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASSESBYORGID(body.org_id??0),
                action: 'refetch'
            },
        ],
    }),
    DELETE: ({ user_id, org_id }: DeleteUserForm): EbicMutationOptions => ({
        url: `/api/user/${org_id}/${user_id}`,
        method: 'DELETE',
        updater: [
            {
                queryKey: OrganizationQueries.ORGANIZATIONUSER(org_id),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: OrganizationQueries.ORG_PROFILE_SEARCH({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },
        ]
    }),
    SEND_VERIFY_EMAIL: (): EbicMutationOptions => ({
        url: `/api/user/verification`,
        method: 'POST'
    }),
    EDIT_PHOTO: (image: Blob, user_id: number): EbicMutationOptions => ({
        url: '/api/user/photo',
        method: 'PUT',
        requestBody: {
            user_pic: image,
        },
        useFormData: true,
        updater: [
            {
                queryKey: UserQueries.USER,
                action: 'refetch'
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: OrganizationQueries.ORGANIZATIONUSER(user_id),
                action: 'refetch'
            },
            {
                queryKey: ClassesQueries.CLASSMEMBERS(user_id),
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },

        ]
    }),
    EDIT_STATUS: ({ user_id, ...user }: EditUserStatus): EbicMutationOptions => ({
        url: `/api/user/status/${user_id}`,
        method: 'PUT',
        requestBody: user,
        updater: [
            {
                queryKey: UserQueries.USER_DETAILS(user_id),
                action: (prev?: EBIC.User.User) => {
                    if (prev !== undefined) {
                        prev.user_status = user.user_status;
                    }
                    return prev;
                },
            },
            {
                queryKey: UserQueries.ALL_USERS({}),
                partialMatch: true,
                action: 'refetch'
            },
            {
                queryKey: ALogQueries.ALLACTIVITIES({}),
                partialMatch: true,
                action: 'refetch'
            },

        ]
    }),
}

export function useUserMutations<Key extends keyof typeof UserMutations>(key: Key) {
    //You won't get type suggestions in the mutate function without this assertion
    const mutationFn = UserMutations[key] as (
        params: Parameters<typeof UserMutations[Key]>[0]
    ) => EbicMutationOptions;
    return useEbicMutation(mutationFn);
}

