import { Row, Col } from "react-bootstrap";
import IconButton from "../../../utils/IconButton";
import styles from "./passage.module.css";
import { Delete } from "@mui/icons-material";
import {
  useLessonById,
  usePassageMutations,
} from "./../../../../api/lessons.api";
import * as EBIC from "@ebic-bbrm/types";
import useModal from "../../../../hooks/useModal";
import DestructiveModal from "../../../utils/DestructiveModal";
import { NewLessonPassageInterface } from "../LessonCreate";
import PassageEditModal from "./PassageEditModal";
import { useMemo } from "react";
import { useActivityLogMutations } from "../../../../api/activitylog.api";

interface OldLessonPassageItemProps {
  passage: EBIC.Content.Passage;
  editPassage?: never;
  removePassage?: never;
}

interface NewLessonPassageItemProps {
  passage: NewLessonPassageInterface;
  editPassage?: (passage: NewLessonPassageInterface) => void;
  removePassage?: () => void;
}

type PassageItemProps = NewLessonPassageItemProps | OldLessonPassageItemProps;

export default function PassageItem({
  passage,
  editPassage,
  removePassage,
}: PassageItemProps) {
  const { mutate: deletePassage } = usePassageMutations("DELETE_PASSAGE");
  const { data: lesson } = useLessonById(
    (passage as EBIC.Content.Passage).less_id
  );

  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const [deleteModal, openDelete] = useModal(DestructiveModal, {
    title: "Delete passage 删除经文",
    description: (
      <>
        <p>
          Delete the {passage.pass_ref} passage? All keywords under this passage
          will be deleted too!
        </p>
        <p>删除《{passage.pass_refCSL}》经文? 经文里的所有生词也将被删除!</p>
      </>
    ),
    onConfirm: async () => {
      if (removePassage !== undefined) {
        removePassage();
      } else if ("pass_id" in passage) {
        await deletePassage(passage.pass_id, {
          onSuccess: () => {
            addLog({
              alog_type: "I",
              alog_module: "C",
              alog_action: "D",
              alog_accessfrom: 0,
              alog_modulename: lesson ? lesson.less_name : "",
              alog_details: "Delete Passage",
              org_name: null,
            });
          },
          onError: () => {
            addLog({
              alog_type: "E",
              alog_module: "C",
              alog_action: "D",
              alog_accessfrom: 0,
              alog_modulename: lesson ? lesson.less_name : "",
              alog_details: "Delete Passage Failed.",
              org_name: null,
            });
          },
        });
      }
    },
    confirmBtnText: "Delete 删除",
  });

  const props = useMemo(() => {
    if ("less_id" in passage && editPassage === undefined) {
      return {
        passage: passage,
      };
    } else if (passage) {
      return {
        passage: passage,
        editPassage: editPassage,
      };
    }
  }, [passage, editPassage]);

  const [editModal, openEdit] = useModal(PassageEditModal, props);

  return (
    <Row className={"p-1 " + styles.itemBody} title={passage.pass_ref}>
      <Col className="d-flex justify-content-between align-items-center">
        <div onClick={openEdit}>
          <p className="m-0 linkUnderline">{passage.pass_refCSL}</p>
          <small className="m-0 text-muted">{passage.pass_ref}</small>
        </div>
        <IconButton
          style={{ float: "right" }}
          Icon={Delete}
          transparent
          className={styles.dropdown}
          onClick={openDelete}
        />
      </Col>
      {editModal}
      {deleteModal}
    </Row>
  );
}
