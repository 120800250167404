import { Content } from "./content";
import { Order } from "./order";
import { User } from "./user";

export namespace Classes {
    export type ClassStatusKey = keyof typeof ClassStatus;
    export enum ClassStatus {
        'INACTIVE' = 0,
        'ACTIVE' = 1
    }

    export type ClassMemberKey = keyof typeof ClassMemberRole;
    export enum ClassMemberRole {
        S = 'Student',
        T = 'Teacher',
    }

    export interface ClassInfo extends Pick <Order.Product, 'prod_name' | 'prod_teachers' | 'prod_students'> {
        class_id: number;
        org_id: number;
        cour_id: number | null;
        class_name: string;
        class_desc: string | null;
        class_pic: string | null;
        class_status: ClassStatus | null;

        class_size: number;
        teacher_size: number;
        student_size: number;
        teacher_name: string;
        cour_name: string;
        org_name: string;
    }

    export interface ClassMembers 
    extends Pick<User.User, 'user_firstname' | 'user_lastname' | 'user_email' | 'user_mobileno' | 'user_pic' | 'user_lastlogin' > {

        class_id: number;
        user_id: number;
        date_added: string;
        cmem_role: ClassMemberKey | null; 

        isTeacher: 0 | 1;

    }

}