/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-mixed-operators */
import { useOrgProfileById } from "../../api/organization.api";
import { useMemo, useState } from "react";
import { style } from "@mui/system";
import OrgProfileSetup from "./OrgProfileSetup";
import OrgDetails from "./OrgDetails";
import BootstrapSpinner from "../utils/BootstrapSpinner";
import EbicCard from "../utils/EbicCard";
import { OrgHeader } from "./OrgUsers/OrgUsers";
import { Row, Col, ToastContainer, Toast } from "react-bootstrap";
import { useEbicUser } from "../../api/user.api";
import { useOrganizationUsers } from "../../api/organization.api";
import * as EBIC from "@ebic-bbrm/types";
import { AddCircle, WarningRounded } from "@mui/icons-material";
import IconButton from "../utils/IconButton";
import { useParams } from "react-router-dom";
import OrgClassesView from "./OrgClasses/OrgClassesView";
import OrgAddModal from "./OrgUsers/OrgAddModal";
import useModal from "../../hooks/useModal";
import { OrgOrder } from "./OrgOrders/OrgOrder";
import OrgAddOrder from "./OrgOrders/OrgAddOrder";
import { useEbicContacts } from "../../api/announcements.api";

interface OrgProfileProps {
  classes: EBIC.Classes.ClassInfo;
  classMembers: EBIC.Classes.ClassMembers;
  emptyMessage?: string;
}

const OrgProfile = ({
  classes,
  classMembers,
  emptyMessage,
}: OrgProfileProps) => {
  const _orgId = useParams<{ orgId: string | undefined }>().orgId;
  const { data: dbUser } = useEbicUser();
  const { data: contact, isLoading: contactLoading } = useEbicContacts();

  const orgId = useMemo(() => {
    if (_orgId)
      // const _orgId = parseInt(orgId ?? 0);
      return parseInt(_orgId ?? "0");

    return dbUser?.org_id ?? 0;
  }, [_orgId, dbUser]);

  const { data: workProfile, isLoading } = useOrgProfileById(orgId);
  const { data: orgUsers } = useOrganizationUsers(orgId);

  const [showToast, setShowToast] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showModal, setShowModal] = useState(false);

  const [addUserModal, openAddUser] = useModal(OrgAddModal, {
    orgId: orgId,
  });
  const [addOrderModal, openAddOrder] = useModal(OrgAddOrder, {
    orgId: orgId,
  });

  //roles
  const isOrgOwner = Boolean(workProfile?.org_id === dbUser?.org_id && dbUser?.user_role === 'O');
  const isSysAdmin = Boolean(dbUser?.user_role === "S");
  const normaluser = Boolean(dbUser?.user_role === "U" && !isOrgOwner);

  if (orgId === 0) return <OrgProfileSetup />;

  if (isLoading || !workProfile || !orgUsers) return <BootstrapSpinner />;
  else
    return (
      <>
        <div className="p-5 pb-2">
          {/* ACTIVE status */}
          {workProfile.org_status === 1 && (
            <>
              {normaluser && (
                <>
                  <h1 className="text-center">Please access via Mobile.</h1>
                </>
              )}
              {/* organization profile */}
              {(isSysAdmin || isOrgOwner) && (
                <EbicCard
                  items={{ title: "Institution 机构" }}
                  style={{
                    borderRadius: "24px",
                    ...style,
                  }}
                  className="border-0 mb-3"
                >
                  <OrgDetails organization={workProfile} />
                </EbicCard>
              )}

              {/* organization users */}
              {(isSysAdmin || isOrgOwner) && (
                <>
                  <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                    <div
                      className=" ms-auto d-flex"
                      style={{
                        height: "fit-content",
                        position: "relative",
                        bottom: "-25px",
                      }}
                    >
                      <IconButton
                        Icon={AddCircle}
                        title="Add A User"
                        onClick={() => openAddUser()}
                        style={{
                          backgroundColor: "transparent",
                          color: "#aa8067",
                        }}
                      />
                      {addUserModal}
                    </div>
                  </div>
                  <EbicCard
                    items={{ title: "Users 用户" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <Row xs={1} md={2} xl={5}>
                      {orgUsers.map((orgUser) => (
                        <Col>
                          <OrgHeader
                            orgUser={orgUser}
                            isSysAdmin={isSysAdmin}
                            isOrgOwner={isOrgOwner}
                          />
                        </Col>
                      ))}
                    </Row>
                  </EbicCard>
                </>
              )}
              {/* classes */}

              {(isSysAdmin || isOrgOwner) && (
                <div>
                  <OrgClassesView
                    emptyMessage={emptyMessage}
                    orgId={orgId}
                    classes={classes}
                    classMembers={classMembers}
                    activeOrg={true}
                  />
                </div>
              )}
              {(isSysAdmin || isOrgOwner) && (
                <>
                  {/* order */}
                  <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                    <div
                      className=" ms-auto d-flex"
                      style={{
                        height: "fit-content",
                        position: "relative",
                        bottom: "-25px",
                      }}
                    >
                      {isSysAdmin && (
                        <>
                          <IconButton
                            Icon={AddCircle}
                            title="Add An Order"
                            onClick={() => openAddOrder()}
                            style={{
                              backgroundColor: "transparent",
                              color: "#aa8067",
                            }}
                          />
                          {addOrderModal}
                        </>
                      )}
                    </div>
                  </div>
                  <EbicCard
                    items={{ title: "ORDER 订单" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <OrgOrder orgId={orgId} />
                  </EbicCard>
                </>
              )}
            </>
          )}

          {/* PENDING status */}
          {workProfile.org_status === 0 && (isSysAdmin || isOrgOwner) && (
            <>
              {normaluser && (
                <>
                  <h1 className="text-center">
                    Your organization is pending approval by the Administrator
                    of EBICUS. You can contact us for further details.
                  </h1>
                </>
              )}
              <div className="d-flex justify-content-end align-items-end">
                <IconButton
                  Icon={WarningRounded}
                  title="Pending"
                  style={{ borderRadius: "50px" }}
                  variant="danger"
                  onClick={() => setShowToast(true)}
                  className="mt-3 me-3"
                />
              </div>

              <ToastContainer className="te-corner">
                <Toast
                  show={showToast}
                  onClose={() => setShowToast(false)}
                  style={{ width: "300px" }}
                >
                  <Toast.Header>
                    <strong className="me-auto">EBICUS</strong>
                  </Toast.Header>
                  <Toast.Body>
                    {contactLoading ? <BootstrapSpinner /> : (
                      <>
                      You can contact{" "}
                      <a href={`mailto:${contact?.contact_email ?? 'support@ebic.com.sg'}`}>{contact?.contact_email ?? "support@ebic.com.sg"}</a>{" "}
                      for further details.
                      </>
                    )}
                  </Toast.Body>
                </Toast>
              </ToastContainer>
              {/* organization profile */}
              {(isSysAdmin || isOrgOwner) && (
                <EbicCard
                  items={{ title: "Institution 机构" }}
                  style={{
                    borderRadius: "24px",
                    ...style,
                  }}
                  className="border-0 mb-3"
                >
                  <OrgDetails organization={workProfile} />
                </EbicCard>
              )}
              {/* organization users */}
              {(isSysAdmin || isOrgOwner) && (
                <>
                  {isSysAdmin && (
                    <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                      <div
                        className=" ms-auto d-flex"
                        style={{
                          height: "fit-content",
                          position: "relative",
                          bottom: "-25px",
                        }}
                      >
                        <IconButton
                          Icon={AddCircle}
                          title="Add A User"
                          onClick={() => openAddUser()}
                          style={{
                            backgroundColor: "transparent",
                            color: "#aa8067",
                          }}
                        />
                        {addUserModal}
                      </div>
                    </div>
                  )}
                  <EbicCard
                    items={{ title: "Users 用户" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <Row xs={1} md={2} xl={5}>
                      {orgUsers.map((orgUser) => (
                        <Col>
                          <OrgHeader
                            orgUser={orgUser}
                            isSysAdmin={isSysAdmin}
                            isOrgOwner={isOrgOwner}
                          />
                        </Col>
                      ))}
                    </Row>
                  </EbicCard>
                </>
              )}
              {/* classes */}
              <div>
                {(isSysAdmin || isOrgOwner) && (
                  <OrgClassesView
                    emptyMessage={emptyMessage}
                    orgId={orgId}
                    classes={classes}
                    classMembers={classMembers}
                    activeOrg={false}
                  />
                )}
              </div>
              {(isSysAdmin || isOrgOwner) && (
                <>
                  {/* order */}
                  <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                    <div
                      className=" ms-auto d-flex"
                      style={{
                        height: "fit-content",
                        position: "relative",
                        bottom: "-25px",
                      }}
                    >
                      {isSysAdmin && (
                        <>
                          <IconButton
                            Icon={AddCircle}
                            title="Add An Order"
                            onClick={() => openAddOrder()}
                            style={{
                              backgroundColor: "transparent",
                              color: "#aa8067",
                            }}
                          />
                          {addOrderModal}
                        </>
                      )}
                    </div>
                  </div>
                  <EbicCard
                    items={{ title: "ORDER 订单" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <OrgOrder orgId={orgId} />
                  </EbicCard>
                </>
              )}
            </>
          )}
          {/* SUSPENDED status */}
          {workProfile.org_status === 2 && (isSysAdmin || isOrgOwner) && (
            <>
              {normaluser && (
                <>
                  <h1 className="text-center">
                    Your organization has been suspended by the Administrator of
                    EBICUS. Please contact us for further details.
                  </h1>
                </>
              )}
              <div className="d-flex justify-content-end align-items-end">
                <IconButton
                  Icon={WarningRounded}
                  title="Pending"
                  style={{ borderRadius: "50px" }}
                  variant="danger"
                  onClick={() => setShowToast(true)}
                  className="mt-3 me-3"
                />
              </div>

              <ToastContainer className="te-corner">
                <Toast
                  show={showToast}
                  onClose={() => setShowToast(false)}
                  style={{ width: "300px" }}
                >
                  <Toast.Header>
                    <strong className="me-auto">EBICUS</strong>
                  </Toast.Header>
                  <Toast.Body>
                    Your account has been suspended by the Administrator of
                    EBICUS. Please contact us for further details.
                  </Toast.Body>
                </Toast>
              </ToastContainer>
              {/* organization profile */}
              {(isSysAdmin || isOrgOwner) && (
                <EbicCard
                  items={{ title: "Institution 机构" }}
                  style={{
                    borderRadius: "24px",
                    ...style,
                  }}
                  className="border-0 mb-3"
                >
                  <OrgDetails organization={workProfile} />
                </EbicCard>
              )}

              {/* organization users */}
              {(isSysAdmin || isOrgOwner) && (
                <>
                  {isSysAdmin && (
                    <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                      <div
                        className=" ms-auto d-flex"
                        style={{
                          height: "fit-content",
                          position: "relative",
                          bottom: "-25px",
                        }}
                      >
                        <IconButton
                          Icon={AddCircle}
                          title="Add A User"
                          onClick={() => openAddUser()}
                          style={{
                            backgroundColor: "transparent",
                            color: "#aa8067",
                          }}
                        />
                        {addUserModal}
                      </div>
                    </div>
                  )}
                  <EbicCard
                    items={{ title: "Users 用户" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <Row xs={1} md={2} xl={5}>
                      {orgUsers.map((orgUser) => (
                        <Col>
                          <OrgHeader
                            orgUser={orgUser}
                            isSysAdmin={isSysAdmin}
                            isOrgOwner={isOrgOwner}
                          />
                        </Col>
                      ))}
                    </Row>
                  </EbicCard>
                </>
              )}
              {/* classes */}
              <div>
                {(isSysAdmin || isOrgOwner) && (
                  <OrgClassesView
                    emptyMessage={emptyMessage}
                    orgId={orgId}
                    classes={classes}
                    classMembers={classMembers}
                    activeOrg={false}
                  />
                )}
              </div>
              {(isSysAdmin || isOrgOwner) && (
                <>
                  {/* order */}
                  <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                    <div
                      className=" ms-auto d-flex"
                      style={{
                        height: "fit-content",
                        position: "relative",
                        bottom: "-25px",
                      }}
                    >
                      {isSysAdmin && (
                        <>
                          <IconButton
                            Icon={AddCircle}
                            title="Add An Order"
                            onClick={() => openAddOrder()}
                            style={{
                              backgroundColor: "transparent",
                              color: "#aa8067",
                            }}
                          />
                          {addOrderModal}
                        </>
                      )}
                    </div>
                  </div>
                  <EbicCard
                    items={{ title: "ORDER 订单" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <OrgOrder orgId={orgId} />
                  </EbicCard>
                </>
              )}
            </>
          )}
          {/* TERMINATED status */}
          {workProfile.org_status === 3 && (isSysAdmin || isOrgOwner) && (
            <>
              {normaluser && (
                <>
                  <h1 className="text-center">
                    Your organization has been terminated by the Administrator
                    of EBICUS. Please contact us for further details.
                  </h1>
                </>
              )}
              <div className="d-flex justify-content-end align-items-end">
                <IconButton
                  Icon={WarningRounded}
                  title="Pending"
                  style={{ borderRadius: "50px" }}
                  variant="danger"
                  onClick={() => setShowToast(true)}
                  className="mt-3 me-3"
                />
              </div>

              <ToastContainer className="te-corner">
                <Toast
                  show={showToast}
                  onClose={() => setShowToast(false)}
                  style={{ width: "300px" }}
                >
                  <Toast.Header>
                    <strong className="me-auto">EBICUS</strong>
                  </Toast.Header>
                  <Toast.Body>
                    Your account has been terminated by the Administrator of
                    EBICUS. Please contact us for further details.
                  </Toast.Body>
                </Toast>
              </ToastContainer>
              {/* organization profile */}
              {(isSysAdmin || isOrgOwner) && (
                <EbicCard
                  items={{ title: "Institution 机构" }}
                  style={{
                    borderRadius: "24px",
                    ...style,
                  }}
                  className="border-0 mb-3"
                >
                  <OrgDetails organization={workProfile} />
                </EbicCard>
              )}

              {/* organization users */}
              {(isSysAdmin || isOrgOwner) && (
                <>
                  {isSysAdmin && (
                    <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                      <div
                        className=" ms-auto d-flex"
                        style={{
                          height: "fit-content",
                          position: "relative",
                          bottom: "-25px",
                        }}
                      >
                        <IconButton
                          Icon={AddCircle}
                          title="Add A User"
                          onClick={() => openAddUser()}
                          style={{
                            backgroundColor: "transparent",
                            color: "#aa8067",
                          }}
                        />
                        {addUserModal}
                      </div>
                    </div>
                  )}
                  <EbicCard
                    items={{ title: "Users 用户" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <Row xs={1} md={2} xl={5}>
                      {orgUsers.map((orgUser) => (
                        <Col>
                          <OrgHeader
                            orgUser={orgUser}
                            isSysAdmin={isSysAdmin}
                            isOrgOwner={isOrgOwner}
                          />
                        </Col>
                      ))}
                    </Row>
                  </EbicCard>
                </>
              )}
              {/* classes */}
              <div>
                {(isSysAdmin || isOrgOwner) && (
                  <OrgClassesView
                    emptyMessage={emptyMessage}
                    orgId={orgId}
                    classes={classes}
                    classMembers={classMembers}
                    activeOrg={false}
                  />
                )}
              </div>
              {(isSysAdmin || isOrgOwner) && (
                <>
                  {/* order */}
                  <div className="d-flex justify-content-end align-items-end mt-3 mb-0">
                    <div
                      className=" ms-auto d-flex"
                      style={{
                        height: "fit-content",
                        position: "relative",
                        bottom: "-25px",
                      }}
                    >
                      {isSysAdmin && (
                        <>
                          <IconButton
                            Icon={AddCircle}
                            title="Add An Order"
                            onClick={() => openAddOrder()}
                            style={{
                              backgroundColor: "transparent",
                              color: "#aa8067",
                            }}
                          />
                          {addOrderModal}
                        </>
                      )}
                    </div>
                  </div>
                  <EbicCard
                    items={{ title: "ORDER 订单" }}
                    style={{
                      borderRadius: "24px",
                      ...style,
                    }}
                    className="border-0 mb-3 mt-0"
                  >
                    <OrgOrder orgId={orgId} />
                  </EbicCard>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
};

export default OrgProfile;
