import { Form, Modal, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { BootstrapInput } from '../../utils/FormikBootstrapInputs';
import { CloseRounded } from '@mui/icons-material';
import { useEffect, useMemo, useState } from 'react';
import IconButton from '../../utils/IconButton';
import { useOrderMutations } from '../../../api/order.api';
import { useOrgProfileById } from '../../../api/organization.api';
import { useActivityLogMutations } from '../../../api/activitylog.api';
import { SearchUserOptions, useUserSearch } from '../../../api/user.api';
import { useProducts } from '../../../api/products.api';
import BootstrapSpinner from '../../utils/BootstrapSpinner';


interface OrderForm {

    order_ref: string;
    org_id: number;
    order_expiry: string | null;

}

interface OrgAddOrderProps {
    show: boolean;
    onClose: () => void;

    orgId: number;
}

function OrgAddOrder({
    show,
    onClose,
    orgId
}: OrgAddOrderProps) {
    const { mutate: addOrder, isLoading } = useOrderMutations("CREATE");
    const { mutate: addLog } = useActivityLogMutations("CREATE");
    const { data: organization } = useOrgProfileById(orgId);


    //select products
    const { data: _prod } = useProducts();
    const prod = useMemo(() => {
        if (!_prod) return [];
        let prodArray = [];
        if (Array.isArray(_prod)) prodArray = _prod;
        else prodArray = [_prod];

        return prodArray;
        // eslint-disable-next-line
    }, [_prod]);

    const [selectedProduct, setSelectedProduct] = useState<number>(0);

    useEffect(() => {
        if (prod && prod.length !== 0)
            setSelectedProduct(prod[0].prod_id)
    }, [prod])

    const handleProductChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedProduct(Number(e.target.value));

    }


    //select user options
    const userParams = useMemo(() => {
        const params: SearchUserOptions = { member_of_organization: orgId };

        //Note: `t` search key is used in SocialSearch component to determine which tab the search is in.


        return params;

    }, [orgId])

    const { data: _users } = useUserSearch(userParams)
    const users = _users?.pages.find((p) => p.page === 1)?.result


    //select users dropdown
    const [selectedUser, setSelectedUser] = useState<number>(0);

    useEffect(() => {
        if (users && users.length !== 0)
            setSelectedUser(users[0].user_id)
    }, [_users, users])

    const handleUserChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedUser(Number(e.target.value));

    }

    const initialValues: OrderForm = {
        order_ref: '',
        org_id: orgId,
        order_expiry: ''

    };

    const validationSchema = yup.object().shape({
        order_ref: yup
            .string()
            .required("Please enter an Order Reference")
            .max(30, "Maximum 30 characters"),
        order_expiry: yup
            .string()
            .required("Please key in the expiry date")
    })

    const handleAdd = async (values: OrderForm, { resetForm }: any) => {
        addOrder(
            {
                prod_id: selectedProduct,
                order_by: selectedUser,        
                ...values
            },
            {
                onSuccess: () => {
                    addLog(
                        {
                            alog_type: 'I',
                            alog_module: 'O',
                            alog_action: 'A',
                            alog_accessfrom: 0,
                            alog_modulename: values.order_ref,
                            alog_details: 'Add Order',
                            org_name: organization?.org_name ?? null
                        }
                    )
                    resetForm();
                    onClose();
                },
                onError: () => {
                    addLog(
                        {
                            alog_type: 'E',
                            alog_module: 'O',
                            alog_action: 'A',
                            alog_accessfrom: 0,
                            alog_modulename: values.order_ref,
                            alog_details: 'Add Order Failed.',
                            org_name: organization?.org_name ?? null
                        }
                    )
                    resetForm();
                    onClose();
                },
            }
        )
    }

    if (!users || !prod) return <BootstrapSpinner />

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleAdd}
            >
                {({ touched, errors, values, handleBlur, handleSubmit, setFieldError, setFieldValue }) => (
                    <Modal
                        show={show}
                        centered
                        onHide={onClose}
                        backdrop={isLoading ? 'static' : undefined}
                        keyboard={!isLoading}
                    >
                        <Modal.Header className="p-3">
                            <Modal.Title className="fw-normal">
                                Add new Order 添加新订单
                            </Modal.Title>
                            <IconButton
                                Icon={CloseRounded}
                                title="Close"
                                className="ms-auto"
                                disabled={isLoading}
                                onClick={onClose}
                            />
                        </Modal.Header>
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body className="small px-4 py-3">
                                <div className="d-flex flex-grow-1">
                                    <div className="mb-2 d-flex flex-column flex-grow-1">
                                        <BootstrapInput
                                            id="order_ref"
                                            required={true}
                                            label="Order Reference 订单参考"
                                            placeholder="Order Ref"
                                            aria-label="Order Ref"
                                            disabled={isLoading}
                                        />
                                    </div>
                                </div>
                                <Form.Group>
                                    <Form.Label className="fw-bold text-uppercase small undefined form-label">
                                        Select a Product 选择产品
                                        <span className="text-danger">*</span>
                                    </Form.Label>

                                    <InputGroup>
                                        <Form.Select
                                            value={selectedProduct}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                handleProductChange(e);
                                                setFieldValue('prod_id', Number(e.target.value));
                                            }}>
                                            {prod.map((p) => (
                                                <option key={p.prod_id ?? 0} value={p.prod_id ?? 0}>
                                                    {p.prod_name}
                                                </option>
                                            ))}
                                        </Form.Select>

                                    </InputGroup>


                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="fw-bold text-uppercase small undefined form-label">
                                        Ordered by 订购人
                                        <span className="text-danger">*</span>
                                    </Form.Label>

                                    <InputGroup>
                                        <Form.Select
                                            value={selectedUser}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                handleUserChange(e);
                                                setFieldValue('order_by', Number(e.target.value));
                                            }}>
                                            {users.map((u) => (
                                                <option key={u.user_id ?? 0} value={u.user_id ?? 0}>
                                                    {u.user_firstname + ' ' + u.user_lastname}
                                                </option>
                                            ))}
                                        </Form.Select>

                                    </InputGroup>


                                </Form.Group>

                                <div>

                                    <BootstrapInput
                                        id="order_expiry"
                                        type="date"
                                        placeholder="Expire Date "
                                        aria-label="Expire Date"
                                        disabled={isLoading}
                                        required={true}
                                        label="Expiry Date 结束日期"
                                        className="col-xs-4"

                                    />
                                </div>

                            </Modal.Body>
                            <Modal.Footer>

                                <IconButton
                                    iconHtmlColor="var(--primary)"
                                    disabled={isLoading}
                                    label="CANCEL 取消"
                                    transparent
                                    className="text-primary border-primary px-4 me-3"
                                    onClick={() => onClose()}
                                />
                                <IconButton
                                    disabled={isLoading}
                                    type="submit"
                                    label="SAVE 保存"
                                    className="px-4"
                                />
                            </Modal.Footer>

                        </Form>
                    </Modal>
                )}
            </Formik>
        </>
    )


}

export default OrgAddOrder;