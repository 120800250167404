import * as EBIC from "@ebic-bbrm/types";
import { HTMLAttributes } from "react";
import { Card, Image, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import styles from "../asset.module.css";
import ebicPlaceholder from "../../../assets/img_placeholder.jpg";
import LineClampText from "../../utils/LineClampText";
import IconButton from "../../utils/IconButton";
import {
  EditOutlined,
  DeleteOutline,
  MoreHoriz,
  Groups,
} from "@mui/icons-material";
import { createOrganizationRoute, OrgRoutes } from "../Organizations";
import useModal from "../../../hooks/useModal";
import OrgDeleteModal from "./OrgDeleteModal";
import { useActivityLogMutations } from "../../../api/activitylog.api";
import { useOrganizationMutations } from "../../../api/organization.api";

interface OrganizationItemProps extends HTMLAttributes<HTMLDivElement> {
  organization: EBIC.Organization.Profile;
}

export default function OrgItem({ organization }: OrganizationItemProps) {
  const history = useHistory();

  const { mutate: editStatus } = useOrganizationMutations("EDIT_STATUS");
  const { mutate: addLog } = useActivityLogMutations("CREATE");

  const onActivate = () => {
    editStatus(
      {
        org_id: organization.org_id,
        org_status: 1,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "O",
            alog_action: "P",
            alog_accessfrom: 0,
            alog_modulename: organization.org_name ?? null,
            alog_details: "Activate Institution",
            org_name: organization.org_name ?? null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "O",
            alog_action: "P",
            alog_accessfrom: 0,
            alog_modulename: organization.org_name ?? null,
            alog_details: "Activate Institution Failed.",
            org_name: organization.org_name ?? null,
          });
        },
      }
    );
  };

  const onSuspend = () => {
    editStatus(
      {
        org_id: organization.org_id,
        org_status: 2,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "O",
            alog_action: "S",
            alog_accessfrom: 0,
            alog_modulename: organization.org_name ?? null,
            alog_details: "Suspend Institution",
            org_name: organization.org_name ?? null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "O",
            alog_action: "S",
            alog_accessfrom: 0,
            alog_modulename: organization.org_name ?? null,
            alog_details: "Suspend InstitutionFailed.",
            org_name: organization.org_name ?? null,
          });
        },
      }
    );
  };

  const onTerminate = () => {
    editStatus(
      {
        org_id: organization.org_id,
        org_status: 3,
      },
      {
        onSuccess: () => {
          addLog({
            alog_type: "I",
            alog_module: "O",
            alog_action: "T",
            alog_accessfrom: 0,
            alog_modulename: organization.org_name ?? null,
            alog_details: "Terminate Institution",
            org_name: organization.org_name ?? null,
          });
        },
        onError: () => {
          addLog({
            alog_type: "E",
            alog_module: "O",
            alog_action: "T",
            alog_accessfrom: 0,
            alog_modulename: organization.org_name ?? null,
            alog_details: "Terminate Institution Failed.",
            org_name: organization.org_name ?? null,
          });
        },
      }
    );
  };

  const [deleteModal, openDelete] = useModal(OrgDeleteModal, {
    organization: organization,
  });

  return (
    <>
      <Card className={"border-0 shadow h-100 " + styles.organizationListCard}>
        <div
          className={
            "card-img-top " + styles.organizationListCardImageContainer
          }
        >
          <Card.Img
            className={"cursor-pointer " + styles.organizationListCardImage}
            as={Image}
            src={organization.org_pic ?? ebicPlaceholder}
            onClick={(ev) => {
              history.push(
                createOrganizationRoute(OrgRoutes.ORGANIZATIONS, {
                  orgId: organization.org_id,
                })
              );
              ev.stopPropagation();
            }}
            variant="top" // needed to have proper corner radius
          />
        </div>
        <Card.Body className="p-3">
          <LineClampText
            maxLines={1}
            title={organization.org_name}
            className={"fw-bold m-0 "}
          >
            {organization.org_name}
          </LineClampText>
          <LineClampText
            maxLines={1}
            title={organization.org_type}
            className="text-uppercase text-muted"
            style={{ fontSize: "0.81rem" }}
          >
            {
              EBIC.Organization.Type[
                organization.org_type as EBIC.Organization.TypeKey
              ]
            }
          </LineClampText>
          <LineClampText
            maxLines={1}
            title="Number of Users in Institution"
            className={"card-text small m-0"}
          >
            <Groups fontSize="small" /> {organization.org_size}
          </LineClampText>
          <LineClampText
            maxLines={4}
            title={organization.org_desc ?? "No description available."}
            className={
              "card-text small " +
              styles.organizationListCardDescription +
              " mb-0"
            }
          >
            {organization.org_desc ?? "No description available."}
          </LineClampText>
        </Card.Body>
        <Card.Footer className="d-flex p-0">
          <IconButton
            transparent
            border={false}
            Icon={EditOutlined}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              history.push(
                createOrganizationRoute(OrgRoutes.ORGANIZATIONS, {
                  orgId: organization.org_id,
                })
              );
              ev.stopPropagation();
            }}
            title={"Edit 修改"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomLeftRadius: 12,
            }}
          />
          <div className="vr my-1" />
          <Dropdown
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
            onToggle={(_, meta) => {
              meta.originalEvent?.stopPropagation();
              meta.originalEvent?.preventDefault();
            }}
          >
            <Dropdown.Toggle as="span" bsPrefix=" ">
              <IconButton
                transparent
                border={false}
                Icon={MoreHoriz}
                iconHtmlColor="var(--primary)"
                title={"Status"}
                className="py-3"
                style={{ width: "100%", borderRadius: 0 }}
              />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              {organization.org_status !== 1 && (
                <Dropdown.Item onClick={() => onActivate()}>
                  Activate 启动
                </Dropdown.Item>
              )}
              {organization.org_status !== 2 && (
                <Dropdown.Item onClick={() => onSuspend()}>
                  Suspend 暂停
                </Dropdown.Item>
              )}

              {organization.org_status !== 3 && (
                <Dropdown.Item onClick={() => onTerminate()}>
                  Terminate 终止
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>

          <div className="vr my-1" />
          <IconButton
            transparent
            border={false}
            Icon={DeleteOutline}
            iconHtmlColor="var(--primary)"
            onClick={(ev) => {
              openDelete();
              ev.stopPropagation();
            }}
            title={"Delete 删除"}
            className="py-3"
            style={{
              width: "calc(50% - 0.5px)",
              borderRadius: 0,
              borderBottomRightRadius: 12,
            }}
          />
        </Card.Footer>
        {deleteModal}
      </Card>
    </>
  );
}
